import styled from 'styled-components';
import { Box } from '../base';

export const Wrapper = styled(Box).attrs({
  horizontal: true,
  justify: 'space-between',
  width: '100%',
  align: 'center',
  flow: 10,
})``;
export const Content = styled(Box).attrs({
  horizontal: true,
  flow: 8,
  align: 'center',
})`
  overflow: hidden;
`;
