import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 12,
  viewboxWidth: 12,
};

export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path d="M3.85502 4.07C3.84383 4.05885 3.83495 4.0456 3.8289 4.03102C3.82284 4.01643 3.81972 4.00079 3.81972 3.985C3.81972 3.96921 3.82284 3.95357 3.8289 3.93898C3.83495 3.9244 3.84383 3.91115 3.85502 3.9L6.27502 1.345C6.34216 1.27459 6.3873 1.18611 6.4049 1.09042C6.42251 0.994736 6.41181 0.89599 6.37413 0.80629C6.33644 0.716591 6.27341 0.639834 6.19275 0.585429C6.11208 0.531024 6.0173 0.501335 5.92002 0.5H3.54002C3.47267 0.500257 3.40606 0.514119 3.3442 0.540754C3.28233 0.56739 3.22649 0.606249 3.18002 0.655L0.345018 3.655C0.257831 3.74773 0.20929 3.87022 0.20929 3.9975C0.20929 4.12478 0.257831 4.24727 0.345018 4.34L3.17502 7.34C3.22164 7.39027 3.2781 7.43041 3.34089 7.45794C3.40368 7.48546 3.47146 7.49978 3.54002 7.5H5.92002C6.01717 7.49969 6.11213 7.47108 6.19328 7.41767C6.27444 7.36426 6.33827 7.28837 6.37699 7.19927C6.41571 7.11016 6.42763 7.01171 6.4113 6.91594C6.39496 6.82017 6.35108 6.73123 6.28502 6.66L3.85502 4.07Z" />
    <path d="M11.655 6.66L9.22501 4.07C9.20358 4.04689 9.19166 4.01653 9.19166 3.985C9.19166 3.95348 9.20358 3.92312 9.22501 3.9L11.645 1.345C11.7126 1.27457 11.758 1.18591 11.7758 1.08996C11.7936 0.994004 11.7829 0.894948 11.745 0.805C11.7067 0.714626 11.6427 0.637515 11.5609 0.583279C11.4791 0.529043 11.3832 0.50008 11.285 0.5H8.90501C8.83766 0.500257 8.77105 0.514119 8.70919 0.540754C8.64733 0.56739 8.59149 0.606249 8.54501 0.655L5.71501 3.655C5.62783 3.74773 5.57928 3.87022 5.57928 3.9975C5.57928 4.12478 5.62783 4.24727 5.71501 4.34L8.54501 7.34C8.59207 7.38936 8.64872 7.42858 8.71149 7.45523C8.77426 7.48189 8.84182 7.49542 8.91001 7.495H11.29C11.3872 7.49469 11.4821 7.46608 11.5633 7.41267C11.6444 7.35926 11.7083 7.28337 11.747 7.19427C11.7857 7.10516 11.7976 7.00671 11.7813 6.91094C11.765 6.81517 11.7211 6.72623 11.655 6.655V6.66Z" />
  </SVGLoader>
);
