import { gspx } from '@rsos/capstone';

export const trackSessionStart = () => {
  gspx.trackCustomEvent('Auth Session', {
    name: 'Session Start',
    Category: 'Session tracking',
    'Launched date': new Date(),
  });
};

export const trackCSPlogin = () => {
  gspx.trackCustomEvent('Alert', {
    name: `CSP user login successfully`,
    Category: 'Alert',
    'Launched date': new Date(),
  });
};

/**
 * LEGACY: Track NS SSO login for CSP. This is a custom event for CSP users
 * predating the implementation of our general purpose SSO login project.
 * Consider this a legacy event. For SSO login tracking use trackSSOLogin().
 */
export const trackNSLoginForCSP = () => {
  gspx.trackCustomEvent('Alert', {
    name: `SSO CSP user login successfully`,
    Category: 'Alert',
    'Launched date': new Date(),
  });
};

export const trackRequestResetPasswordSuccess = () => {
  gspx.trackCustomEvent('Auth', {
    Category: 'Auth',
    name: 'User reset password',
    'Launched date': new Date(),
  });
};

export const trackChangedPasswordSuccess = () => {
  gspx.trackCustomEvent('Auth', {
    Category: 'Auth',
    name: 'User changed password',
    'Launched date': new Date(),
  });
};

export const trackSSOLogin = (appName = '') => {
  gspx.trackCustomEvent('Auth', {
    Category: 'Auth',
    name: `SSO login ${appName}`,
    'Launched date': new Date(),
  });
};
