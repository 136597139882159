import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 16,
  height: 16,
  viewboxWidth: 16,
  viewboxHeight: 16,
};

const TrainIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  color,
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      color={color}
    >
      <g id="Railroad" clipPath="url(#clip0_810_9461)">
        <g id="Group">
          <path
            id="Vector"
            d="M2.66667 0C2.48986 0 2.32029 0.0702379 2.19526 0.195262C2.07024 0.320286 2 0.489856 2 0.666667V1.83333C2 1.87754 1.98244 1.91993 1.95118 1.95118C1.91993 1.98244 1.87754 2 1.83333 2H0.666667C0.489856 2 0.320286 2.07024 0.195262 2.19526C0.0702379 2.32029 0 2.48986 0 2.66667L0 4.66667C0 4.84348 0.0702379 5.01305 0.195262 5.13807C0.320286 5.2631 0.489856 5.33333 0.666667 5.33333H1.83333C1.87754 5.33333 1.91993 5.35089 1.95118 5.38215C1.98244 5.4134 2 5.4558 2 5.5V6.5C2 6.5442 1.98244 6.5866 1.95118 6.61785C1.91993 6.64911 1.87754 6.66667 1.83333 6.66667H0.666667C0.489856 6.66667 0.320286 6.73691 0.195262 6.86193C0.0702379 6.98695 0 7.15652 0 7.33333L0 9.33333C0 9.51014 0.0702379 9.67971 0.195262 9.80474C0.320286 9.92976 0.489856 10 0.666667 10H1.83333C1.87754 10 1.91993 10.0176 1.95118 10.0488C1.98244 10.0801 2 10.1225 2 10.1667V11.1667C2 11.2109 1.98244 11.2533 1.95118 11.2845C1.91993 11.3158 1.87754 11.3333 1.83333 11.3333H0.666667C0.489856 11.3333 0.320286 11.4036 0.195262 11.5286C0.0702379 11.6536 0 11.8232 0 12L0 14C0 14.1768 0.0702379 14.3464 0.195262 14.4714C0.320286 14.5964 0.489856 14.6667 0.666667 14.6667H1.83333C1.87754 14.6667 1.91993 14.6842 1.95118 14.7155C1.98244 14.7467 2 14.7891 2 14.8333V15.3333C2 15.5101 2.07024 15.6797 2.19526 15.8047C2.32029 15.9298 2.48986 16 2.66667 16C2.84348 16 3.01305 15.9298 3.13807 15.8047C3.2631 15.6797 3.33333 15.5101 3.33333 15.3333V0.666667C3.33333 0.489856 3.2631 0.320286 3.13807 0.195262C3.01305 0.0702379 2.84348 0 2.66667 0Z"
            fill="currentColor"
          />
          <path
            id="Vector_2"
            d="M10.6667 0C10.4899 0 10.3204 0.0702379 10.1953 0.195262C10.0703 0.320286 10.0001 0.489856 10.0001 0.666667V1.83333C10.0001 1.87754 9.98252 1.91993 9.95127 1.95118C9.92001 1.98244 9.87762 2 9.83341 2H6.16675C6.12255 2 6.08015 1.98244 6.0489 1.95118C6.01764 1.91993 6.00008 1.87754 6.00008 1.83333V0.666667C6.00008 0.489856 5.92984 0.320286 5.80482 0.195262C5.67979 0.0702379 5.51023 0 5.33341 0C5.1566 0 4.98703 0.0702379 4.86201 0.195262C4.73699 0.320286 4.66675 0.489856 4.66675 0.666667V15.3333C4.66675 15.5101 4.73699 15.6797 4.86201 15.8047C4.98703 15.9298 5.1566 16 5.33341 16C5.51023 16 5.67979 15.9298 5.80482 15.8047C5.92984 15.6797 6.00008 15.5101 6.00008 15.3333V14.8333C6.00008 14.7891 6.01764 14.7467 6.0489 14.7155C6.08015 14.6842 6.12255 14.6667 6.16675 14.6667H9.83341C9.87762 14.6667 9.92001 14.6842 9.95127 14.7155C9.98252 14.7467 10.0001 14.7891 10.0001 14.8333V15.3333C10.0001 15.5101 10.0703 15.6797 10.1953 15.8047C10.3204 15.9298 10.4899 16 10.6667 16C10.8436 16 11.0131 15.9298 11.1382 15.8047C11.2632 15.6797 11.3334 15.5101 11.3334 15.3333V0.666667C11.3334 0.489856 11.2632 0.320286 11.1382 0.195262C11.0131 0.0702379 10.8436 0 10.6667 0ZM10.0001 11.1667C10.0001 11.2109 9.98252 11.2533 9.95127 11.2845C9.92001 11.3158 9.87762 11.3333 9.83341 11.3333H6.16675C6.12255 11.3333 6.08015 11.3158 6.0489 11.2845C6.01764 11.2533 6.00008 11.2109 6.00008 11.1667V10.1667C6.00008 10.1225 6.01764 10.0801 6.0489 10.0488C6.08015 10.0176 6.12255 10 6.16675 10H9.83341C9.87762 10 9.92001 10.0176 9.95127 10.0488C9.98252 10.0801 10.0001 10.1225 10.0001 10.1667V11.1667ZM10.0001 6.5C10.0001 6.5442 9.98252 6.5866 9.95127 6.61785C9.92001 6.64911 9.87762 6.66667 9.83341 6.66667H6.16675C6.12255 6.66667 6.08015 6.64911 6.0489 6.61785C6.01764 6.5866 6.00008 6.5442 6.00008 6.5V5.5C6.00008 5.4558 6.01764 5.4134 6.0489 5.38215C6.08015 5.35089 6.12255 5.33333 6.16675 5.33333H9.83341C9.87762 5.33333 9.92001 5.35089 9.95127 5.38215C9.98252 5.4134 10.0001 5.4558 10.0001 5.5V6.5Z"
            fill="currentColor"
          />
          <path
            id="Vector_3"
            d="M14.1667 5.33333H15.3334C15.5102 5.33333 15.6798 5.2631 15.8048 5.13807C15.9298 5.01305 16.0001 4.84348 16.0001 4.66667V2.66667C16.0001 2.48986 15.9298 2.32029 15.8048 2.19526C15.6798 2.07024 15.5102 2 15.3334 2H14.1667C14.1225 2 14.0802 1.98244 14.0489 1.95118C14.0176 1.91993 14.0001 1.87754 14.0001 1.83333V0.666667C14.0001 0.489856 13.9298 0.320286 13.8048 0.195262C13.6798 0.0702379 13.5102 0 13.3334 0C13.1566 0 12.987 0.0702379 12.862 0.195262C12.737 0.320286 12.6667 0.489856 12.6667 0.666667V15.3333C12.6667 15.5101 12.737 15.6797 12.862 15.8047C12.987 15.9298 13.1566 16 13.3334 16C13.5102 16 13.6798 15.9298 13.8048 15.8047C13.9298 15.6797 14.0001 15.5101 14.0001 15.3333V14.8333C14.0001 14.7891 14.0176 14.7467 14.0489 14.7155C14.0802 14.6842 14.1225 14.6667 14.1667 14.6667H15.3334C15.5102 14.6667 15.6798 14.5964 15.8048 14.4714C15.9298 14.3464 16.0001 14.1768 16.0001 14V12C16.0001 11.8232 15.9298 11.6536 15.8048 11.5286C15.6798 11.4036 15.5102 11.3333 15.3334 11.3333H14.1667C14.1225 11.3333 14.0802 11.3158 14.0489 11.2845C14.0176 11.2533 14.0001 11.2109 14.0001 11.1667V10.1667C14.0001 10.1225 14.0176 10.0801 14.0489 10.0488C14.0802 10.0176 14.1225 10 14.1667 10H15.3334C15.5102 10 15.6798 9.92976 15.8048 9.80474C15.9298 9.67971 16.0001 9.51014 16.0001 9.33333V7.33333C16.0001 7.15652 15.9298 6.98695 15.8048 6.86193C15.6798 6.73691 15.5102 6.66667 15.3334 6.66667H14.1667C14.1225 6.66667 14.0802 6.64911 14.0489 6.61785C14.0176 6.5866 14.0001 6.5442 14.0001 6.5V5.5C14.0001 5.4558 14.0176 5.4134 14.0489 5.38215C14.0802 5.35089 14.1225 5.33333 14.1667 5.33333Z"
            fill="currentColor"
          />
        </g>
      </g>
    </SVGLoader>
  );
};

export default TrainIcon;
