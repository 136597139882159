/**
 * Checks if array is an array of strings.
 * @param {Array.<String>} array - An array of strings.
 * @returns Boolean
 */
const isStringsArray = array => {
  if (!array) {
    throw Error('An Array of strings is required.');
  }

  return array?.length && Array.isArray(array)
    ? array.every(index => typeof index === 'string')
    : false;
};

export default isStringsArray;
