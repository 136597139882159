import React from 'react';
import useComponentVisible from '@rsos/base-utils/useComponentVisible';
import { DropdownWrap } from './Dropdown.styles';

const Dropdown = ({ ...props }) => {
  const {
    triggerElement,
    triggerOnHover,
    triggerOnClose,
    children,
    zIndex,
  } = props;
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const handleComponentVisible = () => {
    setIsComponentVisible(!isComponentVisible);
  };
  const TriggerElement = triggerElement;
  return (
    <DropdownWrap ref={ref} zIndex={zIndex}>
      <TriggerElement
        selected={isComponentVisible}
        onClick={!triggerOnHover && handleComponentVisible}
        onMouseOver={triggerOnHover && handleComponentVisible}
      />
      {isComponentVisible
        ? triggerOnClose
          ? children(handleComponentVisible)
          : children
        : null}
    </DropdownWrap>
  );
};
export default Dropdown;
