import styled from 'styled-components';
import { Box, Text } from '@rsos/components/capstone/base';

export const ButtonWrapper = styled(Box).attrs({
  mr: '20px',
  borderRadius: '5px',
  cursor: 'pointer',
  align: 'center',
  justify: 'center',
})``;

export const SelectorBanner = styled(Box).attrs({
  width: '100%',
  borderRadius: '5px',
  align: 'center',
  justify: 'space-between',
  bg: 'primaryBase',
})`
  max-width: 480px;
  min-width: 0;
  height: calc(100% - 8px);
`;
export const TextIconWrapper = styled(Box).attrs({
  align: 'center',
  justify: 'flex-start',
  ml: '20px',
})`
  flex: 1;
  min-width: 0;
`;
export const BannerText = styled(Text).attrs({
  color: 'background',
  ml: '6px',
  size: 'normal',
})`
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  max-width: 300px;
`;
export const IconWrapper = styled(Box).attrs({
  color: 'background',
})``;
