import styled from 'styled-components';

import Box from '@rsos/base-components/Box';

const ModalFooter = styled(Box).attrs(p => ({
  horizontal: p.stacked ? false : true,
  justify: p.justify || 'flex-end',
  align: p.align || 'flex-end',
}))``;

export default ModalFooter;
