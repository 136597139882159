import React from 'react';
import { OrderedList, ListItem, Paragraph } from './LicenseAgreement.styles';

const LicenseAgreementContent = () => {
  return (
    <>
      <Paragraph>Effective Date: May 21, 2024</Paragraph>

      <Paragraph isCentered>
        <b>Unite End User License Agreement</b>
      </Paragraph>

      <Paragraph>
        This Unite End User License Agreement governs all use by you (“
        <b>you</b>”) of the RapidSOS, Inc. (“<b>RapidSOS</b>”, “<b>we</b>,” or “
        <b>us</b>”) Unite platform and any related online services and APIs
        (collectively, the “<b>Service(s)</b>”), unless a separate written
        agreement is in effect that specifically governs the subject matter
        hereof. The Services are offered subject to your acceptance without
        modification of all of the terms and conditions contained herein and all
        other operating rules, policies (including, without limitation, the{' '}
        <a
          href="https://rapidsos.com/legal/emergency-related-services-privacy-policy"
          target="_blank"
        >
          RapidSOS Emergency-Related Services Privacy Policy
        </a>{' '}
        (“<b>Privacy Policy</b>”)) and procedures that may be published from
        time to time by RapidSOS (collectively, this “<b>Agreement</b>”), which
        are made a part of and incorporated by reference herein.
      </Paragraph>

      <Paragraph>
        USE OF THE SERVICES IS SUBJECT TO THE TERMS SET FORTH BELOW. BY USING
        THE SERVICES, YOU ARE CONSENTING TO BE BOUND BY THESE TERMS. IF YOU DO
        NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THE SERVICES.
      </Paragraph>

      <Paragraph>
        If you are entering into this Agreement on behalf of an Agency, you
        represent that you have the authority to bind such Agency to these terms
        and conditions, in which case the terms “you” or “your” shall refer to
        such Agency. If you do not have such authority, or if you do not agree
        with these terms and conditions, you may not use or login to the
        Services.
      </Paragraph>

      <Paragraph>
        By agreeing to the terms of this Agreement, you represent and warrant
        that: (a) you have not previously been suspended or removed from access
        to any RapidSOS services, and (b) your registration and use of the
        Services complies with all applicable laws and regulations. By using the
        Services, you acknowledge that you are responsible for compliance with
        this Policy, including for violations of this Policy by any Authorized
        User. You must ensure that your Authorized Users are aware of and
        understand this information.
      </Paragraph>

      <OrderedList>
        <ListItem>
          <b>SERVICES OVERVIEW.</b> The Services provide you with access to
          RapidSOS Emergency Information used by you, your Agency (as
          applicable), or authorized third parties to assist in responding to
          emergency events.
        </ListItem>
        <ListItem>
          <Paragraph>
            <b>DEFINITIONS.</b>
          </Paragraph>
          <OrderedList type="lower-alpha">
            <ListItem>
              “<b>Agency</b>” means a Public Safety Answering Point (PSAP) or
              Emergency Communications Center (ECC), as defined by the Federal
              Communications Commission (FCC), requesting access to RapidSOS
              Emergency Information.
            </ListItem>
            <ListItem>
              “<b>Authorized User</b>” means those inpiduals authorized to
              access the Services through your account.
            </ListItem>
            <ListItem>
              “<b>RapidSOS Emergency Information</b>” includes but is not
              limited to supplemental 9-1-1 caller location data, profile data,
              including health data, associated with a 9-1-1 caller, or other
              9-1-1 incident data. In certain instances, RapidSOS Emergency
              Information does not provide 9-1-1 call routing or associated
              information, like automatic number information or automatic
              location information, as such terms are defined by the FCC and
              acts as supplemental information.
            </ListItem>
            <ListItem>
              “<b>RapidSOS Emergency Information Provider</b>” is any person
              that provides RapidSOS Emergency Information to RapidSOS,
              including without limitation manufacturers of smartphones or other
              connected devices, connected vehicles, operating systems service
              providers, application service providers, and monitoring
              companies.
            </ListItem>
            <ListItem>
              “<b>Software</b>” means any software provided to you as part of
              the Services.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <Paragraph>
            <b>LICENSE GRANT.</b> Subject to your and your Agency’s (as
            applicable) strict compliance with this Agreement, RapidSOS grants
            to you a non-exclusive, non-transferable, non-sublicensable, limited
            license to use the Services. All rights not expressly granted to you
            are reserved by RapidSOS and its licensors.
          </Paragraph>
          <OrderedList type="lower-alpha">
            <ListItem>
              <i>Authorized Users.</i> The license granted to you under this
              Agreement permits use of the Services by your Authorized Users
              solely in furtherance of your internal business purposes. You are
              responsible for (i) each Authorized User’s compliance with this
              Agreement; (ii) notifying each Authorized User of the obligations
              set forth herein; and (iii) each Authorized User’s actions while
              using the Services.
            </ListItem>
            <ListItem>
              <i>License Termination.</i> Your license will terminate
              immediately when you cease to be authorized to use the Services.
              All licenses granted to you in this Agreement are conditional on
              your and your Agency’s (as applicable) continued compliance with
              this Agreement, and will immediately and automatically terminate
              if, in RapidSOS’s sole discretion, you or your Agency (as
              applicable) do not comply with any term or condition of this
              Agreement.
            </ListItem>
            <ListItem>
              <i>Use Restrictions and Prohibited Acts.</i> You may not use the
              Services in any manner or for any purpose other than as expressly
              permitted by this Agreement. You agree not to: (a) use the
              Services for any unlawful purpose; (b) use the Services for any
              purpose or in any manner prohibited by this Agreement; (c) engage
              in any conduct that RapidSOS, in its sole discretion, believes is
              or may be harmful to RapidSOS, another user or to any other party,
              directly or indirectly, or which infringes the rights of another
              user or any third party; (d) violate any foreign, federal, state
              or local law or regulation, including without limitation
              regulations promulgated by the U.S. Securities and Exchange
              Commission, and rules of any national or regional securities
              exchange; (e) use the Services in a manner that could impair,
              disable, overburden or damage any RapidSOS server, or the
              network(s) connected to any RapidSOS server, or interfere with,
              inhibit, or restrict any other party’s use of the Services; (f)
              attempt to gain unauthorized access to the Services, other
              accounts, computer systems or networks connected to any RapidSOS
              server or to any of the Services, through hacking, password mining
              or any other means; (g) decompile, disassemble, modify, translate,
              adapt, reverse engineer, create derivative works from or
              sublicense any Software; (h) loan, rent, lease, sublicense,
              distribute, sell or otherwise transfer all or any portion of the
              Services to third parties; (i) violate any code of conduct or
              other guidelines which may apply to any particular Service; (j)
              collect information about others outside of your organization,
              including email addresses; (k) remove, delete, alter, or obscure
              any trademarks or any copyright, trademark, patent, or other
              intellectual property or proprietary rights notices provided on or
              with the Services or Software; (l) modify, translate, adapt, or
              otherwise create derivative works or improvements, whether or not
              patentable, of the Software or Services or any part thereof; or
              (m) combine the Software or any part thereof with, or incorporate
              the Software or any part thereof in, any other programs; (n)
              upload or otherwise any disseminate virus, adware, spyware, worm,
              or other malicious code; (o) make any unsolicited offer or
              advertisement to another user of the Platform or the RapidSOS
              Service.
            </ListItem>
            <ListItem>
              <i>Access to ALI.</i> You acknowledge and agree that in order to
              use automatic location identification data (“<b>ALI Data</b>”)
              within the Services, you may be required to install hardware, as
              determined by RapidSOS. You further acknowledge and agree to grant
              RapidSOS access to all ALI Data generated by you or your Agency
              (as applicable) through such installed hardware or such other
              means directed by RapidSOS (your “<b>ALI Feed</b>”), for use by
              you, your Authorized Users and your Agency (as applicable) within
              the Services for emergency incident response. RapidSOS will not be
              responsible for any deficiencies or failure in access to ALI Data
              or the provision of the Services if you do not have all requisite
              hardware installed or do not grant RapidSOS access to your ALI
              Feed. You or your Agency (as applicable) retain all right, title
              and interest in and to your ALI Feed. You hereby grant RapidSOS a
              non-exclusive, royalty-free, irrevocable, worldwide license to use
              and share ALI Data and perform all acts as may be necessary for
              RapidSOS to provide the Services to you, your Agency (as
              applicable), and other RapidSOS Emergency Information Providers
              and users of the Services, such as geocoding services.
            </ListItem>
            <ListItem>
              <i>Monitoring.</i> In its sole discretion, RapidSOS reserves the
              right to review and refuse to post, remove, or edit any materials
              posted to the Services, in whole or in part, or disclose any
              information it deems necessary or appropriate to satisfy any
              applicable law, regulation, legal process or governmental request.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <Paragraph>
            <b>ACCEPTABLE USES.</b> Agency explicitly acknowledges that the
            RapidSOS Emergency Information is provided for informational
            purposes only and is supplemental to location data provided from
            other sources, and that RapidSOS Emergency Information should not
            replace other emergency location information and should not be
            exclusively relied upon in an emergency scenario. RapidSOS Emergency
            Information will only be used by you and your Agency to respond to
            9-1-1 emergency events. You and your Agency will not use the
            RapidSOS Emergency Information in any manner or for any purpose that
            violates any applicable local, state, national, or international
            law, any right of any person, or for any illegal, unauthorized or
            other improper purposes. You specifically authorize RapidSOS, and
            each RapidSOS Emergency Information Provider, to provide the other
            emergency communications services contemplated herein to provision
            RapidSOS Emergency Information to you.
          </Paragraph>
          <OrderedList type="lower-alpha">
            <ListItem>
              <Paragraph>
                <i>Digital Alerts.</i> You acknowledge that during your use of
                the Services you may receive relevant alert types via Digital
                Alerts, unless you disable this feature. For purposes of this
                Agreement, “Digital Alerts” means alerts from monitoring
                companies and third parties for specific critical events such as
                active assailants, train derailments, and alarms, which may also
                include RapidSOS Emergency Information. With Digital Alerts, the
                alert notifying you of an applicable emergency event may be
                displayed concurrently with a phone call from a safety agent or
                other method.
              </Paragraph>
              <Paragraph>
                For any use of Digital Alerts, you acknowledge and agree:
              </Paragraph>
              <OrderedList type="lower-roman">
                <ListItem>
                  You are specifically requesting data to be presented through
                  the Digital Alerts function of the Services and receipt of
                  such data may require changes to your operational workflow
                  when responding to an emergency. RapidSOS shall be held
                  harmless for any liability arising from such use or failure to
                  use.
                </ListItem>
                <ListItem>
                  You acknowledge that RapidSOS is not transmitting a 9-1-1
                  call, and is only transmitting the data rather than
                  originating the data. Therefore, RapidSOS cannot guarantee the
                  accuracy of data provided or originated by a third party and
                  shall be held harmless for any liability arising from any use
                  or reliance on such data.
                </ListItem>
                <ListItem>
                  You acknowledge that RapidSOS will make reasonable efforts to
                  digitally display requests for dispatch of emergency services
                  and that a phone call request may not be placed.
                </ListItem>
                <ListItem>
                  You acknowledge that some Digital Alerts may utilize
                  Artificial Intelligence (AI) to assist with the capture and
                  display of an alert.
                </ListItem>
                <ListItem>
                  You have been trained on how to use and respond to the Digital
                  Alerts function of the Services. This is separate and distinct
                  from the Supplemental Alerts function of the Services, where a
                  phone call remains the primary request for service.
                </ListItem>
                <ListItem>
                  You acknowledge that RapidSOS does not warrant or represent
                  that all digitally displayed requests through Digital Alerts
                  shall be free of error. You further acknowledge that an act,
                  omission, or failure may occur that prevents notification of a
                  request from being received by you. Where an error, act,
                  omission, or failure occurs that prevents notification of a
                  request from being received, RapidSOS shall not be held
                  liable.
                </ListItem>
                <ListItem>
                  You acknowledge that false alarms may occur and that RapidSOS
                  makes no warranties or representations, express, implied, or
                  otherwise, as to the validity, accuracy, completeness, or
                  performance of a request for dispatch displayed through the
                  Digital Alerts function of the Services. ALL DATA RELATING TO
                  THE DIGITAL ALERTS SERVICE IS PROVIDED “AS IS.”
                </ListItem>
                <ListItem>
                  You agree that RapidSOS may identify your Agency as capable of
                  receiving Digital Alerts, and that RapidSOS may use the name
                  of your Agency on RapidSOS materials indicating agencies that
                  may receive Digital Alerts.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <Paragraph>
                <i>RapidSOS Field Responder SMS Services.</i> As part of the
                Services, you may have the ability to send an outbound SMS from
                a dedicated short-code to field responders with a link to
                critical data for emergency response.
              </Paragraph>
              <OrderedList type="lower-roman">
                <ListItem>
                  You can cancel the SMS service at any time. Just text “STOP”
                  to the short code. After you send the SMS message “STOP”, you
                  will receive an SMS message to confirm that you have been
                  unsubscribed. After this, you will no longer receive SMS
                  messages as part of this program. If you want to join again,
                  sign up as you did the first time and you will receive SMS
                  messages again.
                </ListItem>
                <ListItem>
                  If you are experiencing issues with the messaging program you
                  can reply with the keyword HELP for more assistance, or you
                  can get help directly at{' '}
                  <a href="mailto:support@rapidsos.com">support@rapidsos.com</a>
                  .
                </ListItem>
                <ListItem>
                  Carriers are not liable for delayed or undelivered messages.
                </ListItem>
                <ListItem>
                  Message and data rates may apply for any messages sent.
                  Message frequency varies. If you have any questions about your
                  text plan or data plan, contact your wireless provider.
                </ListItem>
                <ListItem>
                  If you have any questions regarding privacy, please read the
                  RapidSOS Privacy Policy.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <i>Text-from-911; Video-from-911.</i> You acknowledge that during
              your use of the Services, you have Text-from-911 (TF911) and
              Video-from-911 (VF911), which may be provided through RapidSOS or
              one of its partners, enabled unless you disable this feature. You
              acknowledge and agree that RapidSOS cannot control whether a text
              is delivered to a phone number. Furthermore, RapidSOS is not
              responsible for the content of any text message or video you send
              or receive.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <b>THIRD-PARTY MATERIALS.</b> The Services may include software,
          content, data, or other materials, including related documentation,
          that are owned by persons other than RapidSOS and that are provided to
          you on licensee terms that are in addition to and/or different from
          those contained in this Agreement (“<b>Third-Party Licenses</b>”). You
          are bound by and shall comply with all Third-Party Licenses. Any
          breach by you or any of your Authorized Users of any Third-Party
          License is also a breach of this Agreement.
        </ListItem>
        <ListItem>
          <b>BETA SERVICES.</b> RapidSOS may make available to you a trial or
          evaluation use of the Services, including services, software or
          features that may not yet be generally available, including
          pre-release or beta versions of the foregoing which may not operate
          correctly (collectively, “<b>Beta Services</b>”). You may use such
          Beta Services solely for the purpose of evaluating and testing such
          Beta Services, and for no other purpose. You acknowledge and agree
          that all Beta Services are offered “as is” and without any
          representations or warranties or other commitments or protections from
          RapidSOS. RapidSOS will determine the duration of the evaluation
          period for any Beta Service, in its sole discretion, and RapidSOS may
          discontinue any Beta Service at any time. You acknowledge that Beta
          Services, by their nature, have not been fully tested and may contain
          defects or deficiencies.{' '}
        </ListItem>
        <ListItem>
          <b>CREDENTIAL ACCESS.</b> RapidSOS will assign and provide you with a
          unique set of credentials to access the Services. You acknowledge that
          you are responsible for proper safekeeping of those credentials, and
          will immediately notify RapidSOS electronically to{' '}
          <a href="mailto:support@rapidsos.com">support@rapidsos.com</a> should
          these unique credentials ever get exposed to unauthorized personnel or
          are otherwise compromised. For security reasons, RapidSOS reserves the
          right to disable credentials and re-issue new credentials at their
          sole discretion. In such a case, you will be notified by RapidSOS. You
          are further responsible for managing your Authorized Users’
          credentials and are responsible for any access to your account and use
          of the Services under your account, with or without your knowledge or
          consent.
        </ListItem>
        <ListItem>
          <Paragraph>
            <b>PROPRIETARY RIGHTS.</b>
          </Paragraph>
          <OrderedList type="lower-alpha">
            <ListItem>
              RapidSOS owns and retains all right title and interest in and to
              the Services and Software. The visual interfaces, graphics,
              design, compilation, information, data, computer code (including
              source code or object code), products, software, services, and all
              other elements of the Services (“<b>Materials”</b>) provided to
              you by RapidSOS pursuant to this Agreement are protected by
              intellectual property and other laws. All Materials contained in
              the RapidSOS Service are the property of RapidSOS or our
              third-party licensors or subcontractors. RapidSOS reserves all
              rights to the Materials not granted expressly in Section 3 of this
              Agreement.
            </ListItem>
            <ListItem>
              You acknowledge and agree that any feedback, comments, or
              suggestions you may provide to us regarding the Services (“
              <b>Feedback</b>”) will be the sole and exclusive property of
              RapidSOS, and you hereby irrevocably assign to us all of your
              right, title, and interest in and to all Feedback.
            </ListItem>
            <ListItem>
              You acknowledge and agree that RapidSOS may from time to time
              monitor, collect, and analyze data and other information relating
              to the provision, use, and performance of various aspects of the
              Services and the use of the RapidSOS Emergency Information,
              including, but not limited to, the receipt of requests for
              dispatch of emergency services, and/or records of actions taken by
              you or your Agency, as applicable, and configuration, performance,
              usage, and consumption data relating to the use of the Services.
              Company will be free (during and after the term hereof) to use
              such data and information to (a) facilitate or improve the
              delivery of RapidSOS Emergency Information or the Services; (b)
              improve RapidSOS Emergency Information and the Services; (c)
              provide anonymized data and analytics to current and potential
              RapidSOS Emergency Information Providers; and (d) ensure your
              compliance with the terms of this Agreement. Any such data that we
              generate from your usage of the Services will be owned by RapidSOS
              and will be handled subject to the terms of our Privacy Policy.
            </ListItem>
            <ListItem>
              You or your Agency (as applicable) shall own all right, title and
              interest in and to all non-public data provided by you to RapidSOS
              to enable the provision of the Services (“<b>Agency Data</b>”).
              Notwithstanding anything to the contrary herein, you expressly
              agree and hereby authorize RapidSOS to disclose Agency Data to
              RapidSOS Emergency Information Providers and such other third
              parties as is necessary to enable RapidSOS to provide the Services
              to you, your Agency (as applicable) and all other users of the
              Services.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <b>CONFIDENTIALITY.</b> You acknowledge and agree that the Services,
          Software, and related documentation constitute and contain valuable
          confidential/proprietary information and trade secrets of RapidSOS,
          its licensors and/or its suppliers, embodying substantial creative
          efforts and confidential information, ideas, and expressions.
          Accordingly, you agree to treat (and ensure that your employees,
          agents and members treat) the Services, Software, and documentation as
          confidential, and to protect the confidentiality thereof, at all times
          exercising at least a reasonable degree of care in the protection of
          such confidential information. You shall not under any circumstances
          share with or permit access to the Services, or provide any images of,
          or information about the Services to any actual or potential
          competitor of RapidSOS.
        </ListItem>
        <ListItem>
          <b>MODIFICATIONS.</b> This Agreement constitutes the entire agreement
          between RapidSOS and you concerning the subject matter hereof.
          RapidSOS may modify the terms of this Agreement at any time, with the
          modifications becoming effective upon the publication of this
          Agreement. You will ensure that your Authorized Users comply at all
          times with the most recently published Agreement provided by RapidSOS.
          Continued access to or use of the Services following such
          modifications will be deemed your acceptance of the modified
          Agreement. Disputes arising under this Agreement will be resolved in
          accordance with the version of this Agreement that were in effect at
          the time the dispute arose.
        </ListItem>
        <ListItem>
          <Paragraph>
            <b>DISCLAIMER. NO WARRANTIES.</b> BY USING THE SERVICES, YOU AGREE
            THAT RAPIDSOS CANNOT CONTROL THE MANNER IN WHICH EMERGENCY SERVICES
            ARE RENDERED. RAPIDSOS IS NOT RESPONSIBLE FOR THE ACTIONS OF ANY
            EMERGENCY SERVICE PROVIDER, NOTIFICATION PROVIDER, OR OTHER USERS OF
            THE SERVICES. RAPIDSOS DOES NOT GUARANTEE DELIVERY OF ANY MESSAGE OR
            INFORMATION AND IS NOT RESPONSIBLE FOR ANY ACT OR OMISSION BETWEEN
            OR AMONG USERS OF THE SERVICES. RAPIDSOS DOES NOT HAVE CONTROL OVER
            THE VOIP SERVICE, TELEPHONE SERVICE, INTERNET SERVICE PROVIDERS, OR
            OTHER EMERGENCY PROVIDERS’ DATA SOURCES NECESSARY FOR PROVIDING THE
            SERVICES, AND ANY FAILURE DUE TO A THIRD-PARTY SERVICE PROVIDER IS
            NOT THE RESPONSIBILITY OF RAPIDSOS. RAPIDSOS IS NOT A TELEPHONE
            COMPANY, INTERNET PROVIDER, WIRELESS NETWORK PROVIDER, OR OTHER
            TELECOMMUNICATIONS SERVICE PROVIDER. YOU ARE SOLELY RESPONSIBLE FOR
            PROVIDING AND MAINTAINING THE COMMUNICATION SERVICES NEEDED FOR THE
            SERVICES. ADDITIONALLY, IT IS YOUR RESPONSIBILITY TO PROVIDE AND
            MAINTAIN A RELIABLE POWER SOURCE FOR ANY USE OF THE SERVICES.
          </Paragraph>
          <Paragraph>
            THE SERVICES AND ALL DATA, MATERIALS, AND CONTENT AVAILABLE THROUGH
            THE SERVICES ARE PROVIDED “AS IS”, WITH ALL FAULTS AND DEFECTS
            WITHOUT WARRANTY OF ANY KIND, AND ON AN “AS AVAILABLE” BASIS. TO THE
            FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, RAPIDSOS DISCLAIMS
            ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO
            THE SERVICES AND ALL DATA, MATERIALS AND CONTENT AVAILABLE THROUGH
            THE SERVICES, INCLUDING: (A) ANY IMPLIED WARRANTY OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
            ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF
            COURSE OF DEALING, USAGE, OR TRADE. RAPIDSOS AND ITS AFFILIATES, AND
            ITS AND THEIR RESPECTIVE LICENSORS DO NOT WARRANT THAT THE SERVICES,
            OR ANY DATA, MATERIALS OR CONTENT OFFERED THROUGH THE SERVICES, WILL
            BE UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER
            HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THOSE ISSUES WILL
            BE CORRECTED.
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph>
            <b>LIMITATION OF LIABILITY.</b> TO THE FULLEST EXTENT PERMITTED
            UNDER APPLICABLE LAW:
          </Paragraph>
          <OrderedList type="lower-alpha">
            <ListItem>
              IN NO EVENT WILL RAPIDSOS OR ITS AFFILIATES, OR ANY OF THEIR
              RESPECTIVE LICENSORS BE LIABLE TO YOU FOR ANY INDIRECT,
              INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING
              DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE
              LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR
              YOUR INABILITY TO ACCESS OR USE, THE SERVICES OR ANY DATA,
              MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICES, WHETHER BASED
              ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
              ANY OTHER LEGAL THEORY, AND WHETHER OR NOT RAPIDSOS HAS BEEN
              INFORMED OF THE POSSIBILITY OF DAMAGE. YOU UNDERSTAND AND AGREE
              THAT RAPIDSOS IS NOT RESPONSIBLE FOR THE DISPATCH MEDICAL, FIRE,
              OR POLICE ASSISTANCE AND THAT RAPIDSOS SHALL HAVE NO LIABILITY FOR
              MEDICAL, FIRE, OR POLICE ASSISTANCE NOT RECEIVING NOTIFICATION OR
              NOT ACTING ON NOTIFICATION.
            </ListItem>
            <ListItem>
              IN NO EVENT WILL RAPIDSOS AND ITS AFFILIATES, AND ANY OF THEIR
              RESPECTIVE LICENSORS COLLECTIVE AGGREGATE LIABILITY FOR ALL CLAIMS
              ARISING OUT OF OR RELATING TO THE USE OF OR ANY INABILITY TO USE
              ANY PORTION OF THE SERVICES OR OTHERWISE UNDER THIS AGREEMENT,
              WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER
              OF: (A) THE AMOUNT YOU HAVE PAID DIRECTLY TO RAPIDSOS FOR ACCESS
              TO AND USE OF THE RAPIDSOS SERVICE IN THE 12 MONTHS PRIOR TO THE
              CLAIM; OR (B) $100.
            </ListItem>
          </OrderedList>
        </ListItem>
        <ListItem>
          <b>TERMINATION.</b> This Agreement will continue for the duration of
          your use of the Services unless earlier terminated as provided in this
          Agreement. RapidSOS may terminate your license to the Services
          immediately without notice to you for your failure to comply with any
          of the terms set forth herein or as otherwise determined by RapidSOS.
          Upon termination, you must immediately cease use of the Services.
        </ListItem>
        <ListItem>
          <b>FORCE MAJEURE.</b> In no event shall RapidSOS be liable to you or
          your Agency (as applicable), or be deemed to have breached this
          Agreement, for any failure or delay in performing its obligations
          under this Agreement, if and to the extent such failure or delay is
          caused by any circumstances beyond RapidSOS’s reasonable control,
          including but not limited to: (i) acts of God; (ii) flood, fire,
          earthquake, or explosion; (iii) war, invasion, hostilities (whether
          war is declared or not), terrorist threats or acts, riot or other
          civil unrest; (iv) government order, law, or actions; (v) embargoes or
          blockades in effect on or after the date of this Agreement; (vi)
          national or regional emergency; (vii) strikes, labor stoppages or
          slowdowns, or other industrial disturbances; and (viii) shortage of
          adequate power or transportation facilities.
        </ListItem>
        <ListItem>
          <b>NO THIRD PARTY BENEFICIARIES.</b> This Agreement does not create
          any third-party beneficiary rights in any individual or entity that is
          not a party to this Agreement.
        </ListItem>
        <ListItem>
          <b>ASSIGNMENT.</b> You may not assign this Agreement without
          RapidSOS’s prior written consent. RapidSOS may assign this Agreement,
          its rights, or obligations without consent: (a) to an affiliate or
          subsidiary; or (b) for purposes of financing, merger, acquisition,
          corporate reorganization, or sale of all or substantially all its
          assets. This Agreement is binding upon the Parties’ respective
          successors and assigns.
        </ListItem>
        <ListItem>
          <b>WAIVER.</b> The failure by either party to enforce any provision of
          this Agreement will not constitute a present or future waiver of the
          provision nor limit the party’s right to enforce the provision later.
          All waivers by a party must be in writing.
        </ListItem>
        <ListItem>
          <b>SEVERABILITY.</b> If any provision of this Agreement is determined
          by a court of competent jurisdiction to be invalid or unenforceable,
          then the remaining provisions of this Agreement will nevertheless be
          given full force and effect.
        </ListItem>
      </OrderedList>
      <Paragraph>Last updated: May 21, 2024</Paragraph>
    </>
  );
};

export default LicenseAgreementContent;
