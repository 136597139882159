import React, { useEffect, useRef, useState } from 'react';
import { Absolute, Box } from '@rsos/components/capstone/base';
import { StyledTooltip } from './Tooltip.styles';

/**
 * This tooltip component is a work-in-progress as the design requirements have not been
 * finalized yet.
 *
 * @param {node} children - react node that triggers the tooltip
 * @param {boolean} isVisible - condition that determines the tooltip's visibility
 * @param {string} maxWidth - max width of the tooltip
 * @param {function} onMouseOver - mouse over handler for the tooltip
 * @param {function} onMouseLeave - mouse leave handler for the tooltip
 * @param {string} position - placement of the tooltip, can be top, right, bottom, left
 * @param {string} text - tooltip text
 * @param {number} zIndex - z-index of the tooltip relative to the children

 */
const Tooltip = ({
  children,
  isVisible,
  maxWidth,
  onMouseEnter,
  onMouseLeave,
  position = 'bottom',
  text,
  zIndex,
}) => {
  const triggerRef = useRef();
  const tooltipRef = useRef();
  const [top, setTop] = useState('auto');
  const [right, setRight] = useState('auto');
  const [bottom, setBottom] = useState('auto');
  const [left, setLeft] = useState('auto');

  useEffect(() => {
    if (isVisible && position) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const triggerRect = triggerRef.current.getBoundingClientRect();
      if (position === 'top') {
        setTop(-Math.abs(tooltipRect.height + 5));
        if (maxWidth && maxWidth <= tooltipRect.width)
          setLeft(Math.abs((triggerRect.width - maxWidth) / 2));
        else setLeft(Math.abs(tooltipRect.width - triggerRect.left));
      } else if (position === 'bottom') {
        setBottom(-Math.abs(tooltipRect.height + 5));
        if (maxWidth && maxWidth <= tooltipRect.width)
          setLeft(Math.abs((triggerRect.width - maxWidth) / 2));
        else setLeft(Math.abs(tooltipRect.width - triggerRect.left));
      } else if (position === 'right') {
        setRight(-Math.abs(tooltipRect.width + 5));
        setTop(Math.abs((triggerRect.height - tooltipRect.height) / 2));
      } else if (position === 'left') {
        setLeft(-Math.abs(tooltipRect.width + 5));
        setTop(Math.abs((triggerRect.height - tooltipRect.height) / 2));
      }
    }
  }, [isVisible, maxWidth, position]);

  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      position="relative"
      ref={triggerRef}
    >
      {children}
      {isVisible && (
        <Absolute
          bottom={bottom}
          left={left}
          right={right}
          top={top}
          zIndex={zIndex}
        >
          <StyledTooltip maxWidth={maxWidth} ref={tooltipRef}>
            {text}
          </StyledTooltip>
        </Absolute>
      )}
    </Box>
  );
};

export default Tooltip;
