import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SINATRA_LOGOUT } from '@rsos/sinatra';
import {
  getAPIHost,
  getCallQueueMaxAge,
  getCallQueueMaxSize,
} from '@rsos/utils/metaTags';
import {
  CHANGE_PASSWORD_SUCCESS,
  FIRST_TIME_LOGIN,
  FORCED_LOGOUT_SUCCESS,
  GET_OAUTH_TOKEN_SUCCESS,
  GET_OAUTH_TOKEN_FAILED,
  GET_PSAP_INFO_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_FAILED_COUNT,
  SAVE_CREATE_ACCOUNT_INFO,
  SET_USER_INFO,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  SAVE_PSAP_INFO,
} from '../actions/actionTypes';

const initialState = {
  config: {
    api_host: getAPIHost(),
    call_queue_max_age: getCallQueueMaxAge(),
    call_queue_max_size: getCallQueueMaxSize(),
  },
  currentPSAP: JSON.parse(localStorage.getItem('agencyInfo')) || {
    account_id: null,
    id: null,
    fcc_id: '',
    agency_name: '',
    agency_state: '',
    contact_name: '',
    contact_title: '',
    contact_email: '',
    contact_phone: '',
    display_name: '',
    name: '',
    non_emergency_phone: '',
    population: '',
    phone_system: '',
    cad_system: '',
    mapping_system: '',
    jurisdictions: [],
    activeCapabilities: {},
  },

  createAccountInfo: JSON.parse(localStorage.getItem('createAccount')) || {},
  currentUserRoleID: null,
  errorMessage: '', // Currently unused
  failedCount: 0,
  failedCountExpiration: null,
  firstTimeLoginDetails: {
    email: null,
    password: null,
    isFirstTime: null,
  },
  isLoggedIn: false,
  oAuthToken: null,
  refreshToken: null,
  signUpError: null,
  userInfo: {},
  loading: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case FORCED_LOGOUT_SUCCESS:
      return {
        ...initialState,
        config: state.config,
      };
    case GET_OAUTH_TOKEN_SUCCESS:
      return {
        ...state,
        failedCount: 0,
        failedCountExpiration: null,
        oAuthToken: action.oAuthToken,
        oAuthTokenExpiration: new Date().getTime() + 3600000, // 60 mins
        refreshToken: action.refreshToken,
        refreshTokenExpiration: new Date().getTime() + 7776000000, // 90 days
      };
    case GET_OAUTH_TOKEN_FAILED:
      return {
        ...state,
        errorMessage: action.error.message,
        failedCount: state.failedCount + 1,
        failedCountExpiration: new Date().getTime() + 900000, // 15 mins
      };
    case LOGOUT_SUCCESS:
    case SINATRA_LOGOUT:
      return {
        ...initialState,
        config: state.config,
      };
    case RESET_FAILED_COUNT:
      return {
        ...state,
        failedCount: 0,
        failedCountExpiration: null,
      };
    case SIGNUP_FAILED:
      return {
        ...state,
        signUpError: action.signUpError,
      };
    case SIGNUP_START:
      return {
        ...state,
        signUpError: null,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        createAccountInfo: {},
        currentPSAP: initialState.currentPSAP,
        signUpError: null,
      };
    case GET_PSAP_INFO_SUCCESS:
      return {
        ...state,
        isGettingUserInfo: false, // handle loading state with this case
      };
    case SAVE_PSAP_INFO:
      return {
        ...state,
        currentPSAP: {
          ...state.currentPSAP,
          agency_state:
            action.psapInfo.agency_state || state.currentPSAP.agency_state,
          account_id:
            action.psapInfo.account_id || state.currentPSAP.account_id,
          id: action.psapInfo.id || state.currentPSAP.id,
          fcc_id: action.psapInfo.fcc_id || state.currentPSAP.fcc_id,
          cad_system: action.psapInfo.cad_system,
          contact_name:
            action.psapInfo.contact_name || state.currentPSAP.contact_name,
          contact_email:
            action.psapInfo.contact_email || state.currentPSAP.contact_email,
          contact_phone:
            action.psapInfo.contact_phone || state.currentPSAP.contact_phone,
          contact_title:
            action.psapInfo.contact_title || state.currentPSAP.contact_title,
          display_name:
            action.psapInfo.display_name || state.currentPSAP.display_name,
          agency_name:
            action.psapInfo.agency_name || state.currentPSAP.agency_name,
          name: action.psapInfo.agency_name || state.currentPSAP.agency_name,
          non_emergency_phone: action.psapInfo.non_emergency_phone,
          mapping_system: action.psapInfo.mapping_system,
          phone_system: action.psapInfo.phone_system,
          population: action.psapInfo.population,
          jurisdictions:
            action.psapInfo.jurisdictions || state.currentPSAP.jurisdictions,
        },
      };
    case SET_USER_INFO:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.userInfo,
      };
    case SAVE_CREATE_ACCOUNT_INFO:
      return {
        ...state,
        createAccountInfo: action.createAccountInfo,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        firstTimeLoginDetails: {
          email: null,
          password: null,
          isFirstTime: false,
        },
      };
    case FIRST_TIME_LOGIN:
      return {
        ...state,
        firstTimeLoginDetails: action.details,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: [
    'config',
    'failedCount',
    'failedCountExpiration',
    'isLoggedIn',
    'oAuthToken',
    'refreshToken',
  ],
};

export default persistReducer(persistConfig, auth);
