import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { AppPathsConsumer } from '../../../Layout/AppPathsContext';
import { sharedStyleMenuItemRow } from './common.styles';

/**
 * SmartLink either links to the given source target (to)
 * internally using React router's NavLink or externally using an HTML anchor.
 * It determines which one to use by checking against the defined
 * top-level route paths provided by the AppPathsConsumer.
 *
 * @param {string} to - Where the link should point to.
 * @param {object} children - The components children.
 * @param {string} useAnchor - A prop to override logic and default to an anchor tag.
 * @param {object} rest - Any other props given are passed along to
 *  the returned component.
 */
const SmartLink = ({
  to,
  children,
  name,
  useAnchor = false,
  disableNavigation,
  ...rest
}) => {
  if (disableNavigation) {
    return (
      <DisabledNavigationLink data-name={name} {...rest}>
        {children}
      </DisabledNavigationLink>
    );
  }
  return (
    <AppPathsConsumer>
      {appPaths => {
        const isInternal = appPaths.includes(to) && !useAnchor;
        return isInternal ? (
          <StyledLink to={to} data-name={name} {...rest}>
            {children}
          </StyledLink>
        ) : (
          <StyledAnchor href={to} data-name={name} {...rest}>
            <>{children}</>
          </StyledAnchor>
        );
      }}
    </AppPathsConsumer>
  );
};
const StyledAnchor = styled.a`
  ${sharedStyleMenuItemRow}
`;

const DisabledNavigationLink = styled(Box)`
  ${sharedStyleMenuItemRow}
  pointer-events: none;
`;
const StyledLink = styled(NavLink)`
  ${sharedStyleMenuItemRow}
`;

export default SmartLink;
