import { getAPIHost } from '@rsos/utils/metaTags';

export const LOGIN_PATH = '/ns';

export const createRedirectURI = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}${LOGIN_PATH}`;
};

const createLoginURI = () => {
  return `${getAPIHost()}/v1/scorpius/openid-connect/ns?redirect_uri=${createRedirectURI()}`;
};

export default createLoginURI;
