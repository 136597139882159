import isEmpty from 'lodash.isempty';

export class UnsupportedApplicationType extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnsupportedApplicationType';
  }
}

/**
 * Determine the application path based on the user's current role.
 * @param {Object} user
 */
const determineAppPath = user => {
  if (!user?.currentRole || isEmpty(user?.currentRole)) {
    throw new UnsupportedApplicationType('No recognized application found');
  }

  let { currentRole } = user;
  const currentApp = currentRole?.application;

  if (currentApp === 'capstone') {
    return '/home';
  }

  if (currentApp === 'central_station') {
    return '/central-station';
  }

  throw new UnsupportedApplicationType(`Unsupported app type: ${currentApp}`);
};

export default determineAppPath;
