import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box } from '@rsos/base-components';

const wave = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.6);
  }
  20% {
    transform: scaleY(1);
  }
`;

export const LoaderContainer = styled(Box)`
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : `rgba(0, 0, 0, 0.3)`};
  display: flex;
  height: calc(100% - 60px);
  margin: auto;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ fluid }) => (fluid ? '100%' : '60px')};
  height: 60px;
`;

export const Bars = styled.div`
  display: flex;
  justify-content: space-between;
  width: 27px;
  height: 30px;
`;

export const Bar = styled.div`
  display: inline-block;
  width: 3px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primaryBase};
  animation: ${wave} 1.2s infinite ease-in-out;
  &:nth-child(1) {
    animation-delay: -1.2s;
  }
  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -1s;
  }
  &:nth-child(4) {
    animation-delay: -0.9s;
  }
  &:nth-child(5) {
    animation-delay: -0.8s;
  }
`;

export const Text = styled.div`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.primaryText};
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
`;

export const Loader = ({
  text = <>Loading...</>,
  fluid = false,
  noText = false,
  dataName,
}) => (
  <Container fluid={fluid} data-name={dataName}>
    <Bars>
      <Bar />
      <Bar />
      <Bar />
      <Bar />
      <Bar />
    </Bars>
    {!noText && <Text>{text}</Text>}
  </Container>
);

export default Loader;
