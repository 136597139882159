import React from 'react';
import StaticMessagePage from './StaticMessagePage';

const NotFound = () => {
  return (
    <StaticMessagePage
      id="route-not-found-component"
      title="Page Not Found"
      message={
        <>
          Click <a href="/">here</a> to go to RapidSOS Portal.
        </>
      }
    />
  );
};

export default NotFound;
