import { setTokens } from '../utils/tokens';
import instance from './scorpiusInstance';
import {
  PROVIDER_LOGIN_START,
  PROVIDER_LOGIN_SUCCESS,
  PROVIDER_LOGIN_ERROR,
} from './types';

export const loginProviderStart = () => ({
  type: PROVIDER_LOGIN_START,
});

export const loginProviderSuccess = tokens => ({
  type: PROVIDER_LOGIN_SUCCESS,
  tokens,
});

export const loginProviderError = error => ({
  type: PROVIDER_LOGIN_ERROR,
  error: error.message,
});

/**
 * Login with a provider via OIDC token exchange.
 * @param {string} provider
 * @param {Object} params
 * @param {string} params.code
 * @param {string} params.state
 */
export const loginWithProvider = (provider, params = {}) => async dispatch => {
  // this will delete bearer token for 404 issue, see https://rapidsos.atlassian.net/browse/MC-10913
  if (instance.defaults && instance.defaults.headers) {
    delete instance.defaults.headers.Authorization;
  }
  dispatch(loginProviderStart());
  try {
    const response = await instance.post(
      `/openid-connect/${provider}`,
      {
        code: params.code,
        state: params.state,
      },
      {
        withCredentials: true,
      },
    );
    const tokens = response.data;
    setTokens(tokens);
    return dispatch(loginProviderSuccess(tokens));
  } catch (error) {
    throw dispatch(loginProviderError(error));
  }
};
