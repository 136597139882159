import instance from '../scorpiusInstance';
import {
  UPDATE_APP_CIDR_START,
  UPDATE_APP_CIDR_SUCCESS,
  UPDATE_APP_CIDR_ERROR,
} from '../types';

export const updateAppCIDRStart = (orgId, appName) => ({
  type: UPDATE_APP_CIDR_START,
  orgId,
  appName,
});

export const updateAppCIDRSuccess = (orgId, appName, cidr) => ({
  type: UPDATE_APP_CIDR_SUCCESS,
  orgId,
  appName,
  cidr,
});

export const updateAppCIDRError = (orgId, appName, error) => ({
  type: UPDATE_APP_CIDR_ERROR,
  orgId,
  appName,
  error,
});

/**
 * Update an existing CIDR address record.
 * @param {String} orgId - The organization's ID
 * @param {String} appName
 * @param {Object} cidr - CIDR record
 * @param {Number} cidr.id - id of the CIDR address record
 * @param {String} cidr.cidr - IP address
 * @param {Boolean} cidr.enabled - Enabled state for the IP address
 */
export const updateAppCIDR = (orgId, appName, cidr) => async dispatch => {
  dispatch(updateAppCIDRStart(orgId, appName));
  try {
    await instance.patch(
      `/organizations/${orgId}/cidrs/${cidr.id}`, {
        cidr: cidr.cidr,
        enabled: cidr.enabled,
      },
      { headers: { retry: true } },
    );
    return dispatch(updateAppCIDRSuccess(orgId, appName, cidr));
  }
  catch (error) {
    throw dispatch(updateAppCIDRError(orgId, appName, error));
  }
};

export default updateAppCIDR;
