import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@rsos/components/capstone/base/Box';
import { INBOUND_CONTENT } from '@rsos/constants/adminContents';
import NewNotification from '../../../Navigation/RSP/NavigationControl/NewNotification';
import {
  ItemCategoryName,
  ItemIconWrapper,
  ItemTitle,
  Circle,
} from '../common/SideBarItem.styles';
import SmartLink from '../common/SmartLink';

const SideBarItem = ({
  activeItem,
  hasNewDataSource,
  menuItem,
  sideBarOpen,
}) => {
  const displayNew = hasNewDataSource && menuItem.name === 'data-source';
  const isActive = useMemo(() => !!(menuItem.name === activeItem), [
    menuItem,
    activeItem,
  ]);
  return (
    <>
      {menuItem.name === INBOUND_CONTENT ? (
        <ItemCategoryName sideBarOpen={sideBarOpen}>
          {sideBarOpen ? 'Manage Credentials' : ''}
        </ItemCategoryName>
      ) : null}

      <SmartLink
        to={menuItem.link}
        toolTip={menuItem.hoverText}
        className={isActive ? ' active' : ''}
      >
        <Box horizontal flow={10}>
          <ItemIconWrapper isActive={isActive}>
            {menuItem.menuIcon}
          </ItemIconWrapper>
          <ItemTitle
            isActive={isActive}
            titleWrap={menuItem.name === INBOUND_CONTENT}
          >
            {sideBarOpen ? menuItem.menuTitle : ''}
          </ItemTitle>
          <Box ml={1} position="relative" justify="center">
            {displayNew && sideBarOpen ? (
              <NewNotification hasNewDataSource={hasNewDataSource} />
            ) : displayNew && !sideBarOpen ? (
              <Circle />
            ) : null}
          </Box>
        </Box>
      </SmartLink>
    </>
  );
};
SideBarItem.propTypes = {
  activeItem: PropTypes.string.isRequired,
  menuItem: PropTypes.object.isRequired,
  sideBarOpen: PropTypes.bool.isRequired,
};
export default SideBarItem;
