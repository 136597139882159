import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 16,
  viewboxWidth: 16,
  width: 16,
  height: 16,
};

export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <g>
      <path d="M15.6133 7.0179C13.8533 5.07788 10.9467 3.01787 7.99332 3.0712C5.03999 3.12454 2.13332 5.07788 0.37332 7.0179C0.123152 7.29085 -0.015625 7.64765 -0.015625 8.0179C-0.015625 8.38815 0.123152 8.74496 0.37332 9.01791C1.54665 10.2846 4.48665 12.9379 7.99332 12.9379C11.5 12.9379 14.4333 10.2712 15.6133 8.98457C15.8542 8.71365 15.9872 8.36374 15.9872 8.00123C15.9872 7.63872 15.8542 7.28882 15.6133 7.0179ZM4.92665 8.00457C4.92665 7.39803 5.10651 6.80512 5.44348 6.30081C5.78045 5.7965 6.2594 5.40343 6.81976 5.17132C7.38012 4.93921 7.99672 4.87848 8.5916 4.99681C9.18647 5.11514 9.7329 5.40721 10.1618 5.83609C10.5907 6.26498 10.8827 6.81141 11.0011 7.40629C11.1194 8.00117 11.0587 8.61777 10.8266 9.17814C10.5944 9.7385 10.2014 10.2175 9.69707 10.5544C9.19276 10.8914 8.59985 11.0713 7.99332 11.0713C7.17999 11.0713 6.39997 10.7482 5.82486 10.173C5.24975 9.59793 4.92665 8.8179 4.92665 8.00457Z" />
      <path d="M7.98957 9.33833C8.72594 9.33833 9.32289 8.74138 9.32289 8.00501C9.32289 7.26864 8.72594 6.67169 7.98957 6.67169C7.2532 6.67169 6.65625 7.26864 6.65625 8.00501C6.65625 8.74138 7.2532 9.33833 7.98957 9.33833Z" />
    </g>
  </SVGLoader>
);
