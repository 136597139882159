import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@rsos/components/capstone/base';
import exportToGlobalNamespace from '@rsos/utils/exportToGlobalNamespace';

// NOTE REACT_APP_FE_VERSION is set in .env, value which is mapped
// from $FE_VERSION at build-time.
const envVersion = process.env.REACT_APP_FE_VERSION || 'default';

exportToGlobalNamespace('env', process.env);
exportToGlobalNamespace('version', envVersion);

/**
 * Strips the 'mw-' prefix from the given version string.
 * @param {String} version - The version to be cleaned
 */
export const cleanupVersion = (version = '') => {
  if (!version) {
    return '';
  }

  if (typeof version !== 'string') {
    return version;
  }
  return version
    .toLowerCase()
    .replace('mw-', '')
    .replace('-dev', '')
    .replace('develop-', '');
};

const VersionNumber = ({ version = envVersion, color }) => {
  return (
    <Text size="small" color={color || 'secondaryText'} ellipsis>
      {`v${cleanupVersion(version)}`}
    </Text>
  );
};

VersionNumber.propTypes = {
  version: PropTypes.string,
};

export default VersionNumber;
