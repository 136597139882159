import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { BP_DESKTOP, BP_LAPTOP, BP_TABLET } from '@rsos/constants/breakpoints';
import {
  BP_DESKTOP_XXL,
  BP_DESKTOP_XL,
  BP_DESKTOP_L,
  BP_DESKTOP_S,
  WIDTH_DESKTOP_XL,
  WIDTH_DESKTOP_L,
  WIDTH_DESKTOP,
  WIDTH_DESKTOP_S,
} from '../../../constants/cssConstants';
import { calcLeft } from '../helpers';

export const SecondRowWrapper = styled(Box).attrs({
  height: 970,
  bg: 'foreground',
})`
  position: relative;
  overflow-x: hidden;
  @media (max-width: ${BP_DESKTOP}px) {
    height: 799px;
  }
`;

export const SecondRowTextBox = styled(Box).attrs({
  width: 524,
  mr: 120,
})`
  @media (max-width: ${BP_DESKTOP_XL}px) {
    width: 480px;
  }
  @media (max-width: ${BP_DESKTOP_L}px) {
    width: 448px;
    margin-right: 64px;
  }
  @media (max-width: ${BP_DESKTOP_S}px) {
    width: 358px;
  }
  @media (max-width: ${BP_LAPTOP}px) {
    margin-right: 0;
  }
`;
export const SecondRowContentWrapper = styled(Box).attrs({
  justify: 'space-between',
  horizontal: true,
  align: 'flex-start',
  height: 470,
  pl: 116,
})`
  position: absolute;
  left: ${calcLeft(WIDTH_DESKTOP_XL)};

  @media (max-width: ${BP_DESKTOP_XXL}px) {
    margin-right: -80px;
  }
  @media (max-width: ${BP_DESKTOP_L}px) {
    left: ${calcLeft(WIDTH_DESKTOP_L)};
  }
  @media (max-width: ${BP_DESKTOP}px) {
    padding: 0 0 0 80px;
    margin-right: -100px;
    left: ${calcLeft(WIDTH_DESKTOP)};
  }
  @media (max-width: ${BP_DESKTOP_S}px) {
    left: ${calcLeft(WIDTH_DESKTOP_S)};
  }
  @media (max-width: ${BP_LAPTOP}px) {
    padding: 0 75px;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
    position: initial;
    align-items: center;
  }
  @media (max-width: ${BP_TABLET}px) {
    padding: 0 50px;
  }
`;

export const ImageContainer = styled(Box).attrs({
  width: 800,
})`
  @media (max-width: ${BP_DESKTOP}px) {
    width: 512px;
  }
  @media (max-width: ${BP_LAPTOP}px) {
    width: 500px;
  }
`;
