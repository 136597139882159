import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../../actions';
import NewPassword from '../Common/NewPassword';

const ResetPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { firstTimeLoginDetails } = useSelector(state => state.auth);

  const handleSubmit = values => {
    let email =
      firstTimeLoginDetails.email ||
      JSON.parse(localStorage.getItem('temp-details')).email;
    let temp_password =
      firstTimeLoginDetails.password ||
      JSON.parse(localStorage.getItem('temp-details')).password;
    let updatedInfo = {
      email,
      temp_password,
      new_password: values.password,
    };

    dispatch(changePassword(updatedInfo, navigate));
  };
  return (
    <NewPassword
      handleSubmit={handleSubmit}
      title="Please set a new password"
      buttonLabel="Reset Password"
    />
  );
};

export default ResetPassword;
