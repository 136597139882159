import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import { getFontSize, getFontWeight, getTextTransform } from './helpers';

const TextBase = styled.div`
  ${space};
  ${color};

  display: ${p => (p.inline ? 'inline-block' : 'block')};
  font-family: 'Roboto', sans-serif;
  font-size: ${p => getFontSize(p)};
  font-weight: ${p => getFontWeight(p)};
  font-style: ${p => (p.italic ? 'italic' : 'inherit')};
  text-decoration: ${p => (p.underline ? 'underline' : 'none')};
  line-height: ${p => ('lineHeight' in p ? p.lineHeight : 1.2)};
  text-transform: ${p => getTextTransform(p)};
  white-space: ${p => (p.noWrap ? 'nowrap' : 'normal')};
  text-align: ${p => (p.textAlign ? p.textAlign : 'inherit')};
  overflow-wrap: ${p => (p.overflowWrap ? p.overflowWrap : 'inherit')};
  word-break: ${p => (p.wordBreak ? p.wordBreak : 'inherit')};
  letter-spacing: normal;

  ${p => (p.selectable ? `user-select: text; cursor: text` : ``)}
  ${p =>
    p.ellipsis
      ? `text-overflow: ellipsis; white-space: nowrap; overflow: hidden`
      : ``}
`;

// done this way if we need to include internationalization later
export default function Text(props) {
  return <TextBase {...props}>{props.children}</TextBase>;
}
Text.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf([
    'xxsmall',
    'xsmall',
    'small',
    'normal',
    'large',
    'xlarge',
    'subheader',
    'header',
    'xheader',
  ]),
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf(['bold', 'semibold', 'regular']),
  ellipsis: PropTypes.bool,
  selectable: PropTypes.bool,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noWrap: PropTypes.bool,
  textAlign: PropTypes.string,
  inline: PropTypes.bool,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  style: PropTypes.object,
  textTransform: PropTypes.oneOf([
    'lowercase',
    'uppercase',
    'capitalize',
    'none',
    'inherit',
  ]),
  wordBreak: PropTypes.string,
};
