import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isStringsArray from '@rsos/utils/isStringsArray';

/**
 * Custom hook that checks if the current PSAP has the capability or capabilities enabled.
 * @param {(String|Array.<String>)} capabilities - Capability name or list of capability names.
 * @returns Boolean
 */
const useHasCapability = capabilities => {
  let error = null;

  if (!capabilities) {
    error = Error('A capability name is required.');
  } else if (
    (typeof capabilities !== 'string' && !Array.isArray(capabilities)) ||
    (Array.isArray(capabilities) && !isStringsArray(capabilities))
  ) {
    error = Error('Capability name must be a string or an array of strings.');
  }

  const activeCapabilities = useSelector(
    state => state?.psaps?.currentPSAP?.activeCapabilities || {},
  );

  const activeCapabilitiesKeys = Object.keys(activeCapabilities);

  const hasCapability = useMemo(() => {
    if (typeof capabilities === 'string') {
      return Boolean(activeCapabilities?.[capabilities]);
    } else if (Array.isArray(capabilities) && isStringsArray(capabilities)) {
      return capabilities.every(capability =>
        activeCapabilitiesKeys.includes(capability),
      );
    }
  }, [activeCapabilities, activeCapabilitiesKeys, capabilities]);

  return { hasCapability, error };
};

export default useHasCapability;
