import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPSAPAccount, savePSAPInfo } from '../../actions';
import { Button, FormSite, Modal, SystemsForm } from '../capstoneLegacy';

const IntegrationInfo = ({ handleStatus }) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const currentPSAP = useSelector(state => state.psaps.currentPSAP);

  const createAccountInfo = useSelector(state => state.auth.createAccountInfo);

  const [state, setState] = useState({
    addingNewIntegration: false,
    selectedCADSystem: currentPSAP.cad_system || 'Select CAD System',
    selectedMappingSystem:
      currentPSAP.mapping_system || 'Select Mapping System',
    selectedPhoneSystem:
      currentPSAP.phone_system || 'Select Phone System (911/CPE)',
    showCADSystemDropdown: false,
    showMappingSystemDropdown: false,
    showPhoneSystemDropdown: false,
    formSubmitted: false,
  });

  const {
    addingNewIntegration,
    selectedCADSystem,
    selectedMappingSystem,
    selectedPhoneSystem,
    showCADSystemDropdown,
    showMappingSystemDropdown,
    showPhoneSystemDropdown,
    formSubmitted,
  } = state;

  const addNewIntegration = () => {
    setState(prevState => ({
      ...prevState,
      addingNewIntegration: !addingNewIntegration,
    }));
  };

  const handleSelectedSystem = (system, enumMapping) => {
    setState(prevState => ({
      ...prevState,
      [`selected${system}System`]: enumMapping,
    }));
  };

  const isBtnDisabled = () => {
    return !(
      selectedCADSystem !== 'Select CAD System' &&
      selectedMappingSystem !== 'Select Mapping System' &&
      selectedPhoneSystem !== 'Select Phone System (911/CPE)'
    );
  };

  const isEnabled = !isBtnDisabled();

  const nextSection = e => {
    e.preventDefault();

    setState(prevState => ({
      ...prevState,
      formSubmitted: true,
    }));

    if (!isBtnDisabled()) {
      const {
        agency_state,
        agency_name,
        fcc_id,
        contact_name,
        contact_title,
        contact_email,
        contact_phone,
        non_emergency_phone,
        population,
      } = currentPSAP;

      const updatePSAPdata = {
        state: agency_state,
        display_name: agency_name,
        fcc_id: fcc_id,
        contact_name,
        contact_title,
        contact_email,
        contact_phone,
        name: agency_name,
        non_emergency_phone,
        population,
        phone_system: selectedPhoneSystem,
        cad_system: selectedCADSystem,
        mapping_system: selectedMappingSystem,
      };

      localStorage.setItem('systemInfo', JSON.stringify(updatePSAPdata));

      dispatch(savePSAPInfo(updatePSAPdata));

      dispatch(createPSAPAccount(createAccountInfo, updatePSAPdata, navigate));

      handleStatus(true);
    }
  };

  const toggleDropdown = system => {
    // TODO: possibly add click event listener to close dropdown when click
    // occurs outside of the dropdown elements
    setState(prevState => ({
      ...prevState,
      [`show${system}Dropdown`]: !prevState[`show${system}Dropdown`],
    }));
  };

  return (
    <div
      id="systems-info-container"
      className="provide-info-container hero pt-30"
    >
      <form className="pt-20" onSubmit={nextSection}>
        <div className="sign-up-wrapper bubble-blue-bg">
          <div className="custom-page-title pb-30 black-text mt-10">
            Please Fill Out Your Software Solutions Environment
            <div className="integration-page-subtitle">
              Please fill out the fields below
            </div>
          </div>
          <div className="columns">
            <div className="column is-6">
              <SystemsForm
                handleSelectedSystem={handleSelectedSystem}
                isSignUp={true}
                selectedCADSystem={selectedCADSystem}
                selectedMappingSystem={selectedMappingSystem}
                selectedPhoneSystem={selectedPhoneSystem}
                showCADSystemDropdown={showCADSystemDropdown}
                showMappingSystemDropdown={showMappingSystemDropdown}
                showPhoneSystemDropdown={showPhoneSystemDropdown}
                toggleDropdown={toggleDropdown}
              />
            </div>
            <div className="column is-6">
              <p>Integration Solution</p>
              <div className="integration-solution-container">
                If you are new to RapidSOS Portal and have existing integrations
                you do not need to add the integrations. RapidSOS will populate
                existing integrations after you complete the sign up process. If
                you are adding a new integration please select add integration
                below.
                <span className="integration-info-text">
                  If you only intend to use RapidSOS Portal and are not
                  integrating with a solution partner, you can skip this step.
                </span>
                <div
                  className="add-integration-text"
                  style={{}}
                  onClick={addNewIntegration}
                >
                  + Request New Integration Credential{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            name="Next"
            buttonClicked={nextSection}
            className={
              'rapidlite-forms-button mt-10 ' +
              (!isEnabled || formSubmitted ? 'unclickable' : 'clickable')
            }
            disabled={!isEnabled || formSubmitted}
          >
            CONTINUE {'>'}
          </Button>
        </div>
      </form>
      <Modal
        className="form-site-modal"
        isActive={addingNewIntegration}
        modalContentClassName="form-site-content"
        showClose={true}
        toggleModal={addNewIntegration}
      >
        <FormSite isSignUp={true} />
      </Modal>
    </div>
  );
};

export default IntegrationInfo;
