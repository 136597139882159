import styled from 'styled-components';

import Box from '@rsos/base-components/Box';

const DropdownFooter = styled(Box).attrs(p => ({
  justify: p.justify || 'center',
  align: p.align || 'center',
}))``;

export default DropdownFooter;
