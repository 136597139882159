import { toast, Slide } from 'react-toastify';

const TOAST_EXPIRATION_TIME = 1000 * 3;

const toastOptionsDefault = {
  autoClose: TOAST_EXPIRATION_TIME,
  bodyClassName: 'Toastify__overwrite-body',
  closeButton: true,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  position: 'top-center',
  rtl: false,
  pauseOnVisibilityChange: true,
  transition: Slide,
};

export const displayToast = data => {
  const { message, options, className } = data;
  const toastOptions = { ...toastOptionsDefault, ...options };
  return toast(message, {
    ...toastOptions,
    className: className ? className : 'Toastify__overwrite-default',
  });
};
export default displayToast;
