import md5 from 'md5';
import { getSessionAge } from '@rsos/base-utils/metaTags';
import { getAPIHost } from '@rsos/utils/metaTags';
import { captureExceptionWithScope } from '@rsos/utils/sentry';
import { JURISDICTION_VIEW } from './constants/capabilities';

export const GSPX_LAST_IDENTIFIED = 'GSPX_LAST_IDENTIFIED';

export const PRODUCTION_HOSTNAMES = [
  'rapidlite.rapidsos.com',
  'rapidsosportal.com',
];

export const GSPX_TRACKING_IDS = {
  prod: 'AP-PEXDVMYJXIFL-2',
  staging: 'AP-PEXDVMYJXIFL-2-2', //staging and sandbox
  qa: 'AP-PEXDVMYJXIFL-2-3',
  dev: 'AP-PEXDVMYJXIFL-2-4', // This is the key for dev
};

/**
 * Returns the corresponding GainSightPX tag key for the stack (non-prod or
 * prod).
 */
export const determineGSPXTrackingID = () => {
  const { hostname } = window.location;
  let gspxKey = GSPX_TRACKING_IDS.dev;
  const found = PRODUCTION_HOSTNAMES.find(prod => hostname === prod);
  if (found || hostname.includes('prod')) {
    gspxKey = GSPX_TRACKING_IDS.prod;
  } else if (hostname.includes('staging') || hostname.includes('sandbox')) {
    gspxKey = GSPX_TRACKING_IDS.staging;
  } else if (hostname.includes('qa')) {
    gspxKey = GSPX_TRACKING_IDS.qa;
  } else {
    gspxKey = GSPX_TRACKING_IDS.dev;
  }
  return gspxKey;
};

class CapstoneGainSightPX {
  constructor() {
    this.store = null;
    this.trackingID = determineGSPXTrackingID();
  }

  addIdentifier(userInfo = {}, accountInfo = {}) {
    try {
      window.aptrinsic('identify', userInfo, accountInfo);
      localStorage.setItem(GSPX_LAST_IDENTIFIED, Math.floor(Date.now() / 1000));
    } catch {
      //eslint-disable-next-line no-empty
    }
  }

  identify() {
    try {
      this.checkStore();

      // Being defensive here and checking against the session's start in the
      // rare event that the flag was not cleaned up in localStorage on logout.
      // If localStorage clears correctly, then it's enough to just check if
      // the flag is set.
      const sessionAge = getSessionAge();
      const lastIdentified = localStorage.getItem(GSPX_LAST_IDENTIFIED);
      const identifyStart = Date.now() / 1000 - sessionAge;

      // If the user has already been identified, don't identify again.
      if (lastIdentified && lastIdentified > identifyStart) {
        return;
      }

      const currentState = this.store?.getState();

      const user = currentState?.sinatra?.user;
      const psap = currentState?.psaps?.currentPSAP;

      const isJVEnabled = !!psap?.active_capabilities[JURISDICTION_VIEW];

      const userInfo = this.normalizeGSPXUserInfo(user);
      const accountInfo = {
        id: psap.account_id,
        name: psap.display_name,
        PrimaryPointOfContact: psap.contact_email,
        JurisdictionViewEnabled: isJVEnabled,
        AddressablePopulation: psap.population,
        AgencyState: psap.state,
        DispatchType: psap.dispatch_type,
      };

      this.addIdentifier(userInfo, accountInfo);
    } catch (error) {
      captureExceptionWithScope(new Error('Failed to identify user'), {
        error,
      });
    }
  }

  checkStore() {
    // The following should only be shown during development.
    if (!this.store && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(
        'The reference to the store was not set. First call initialize().',
      );
    }
  }

  /**
   * Returns an MD5 hash of the current user's email.
   */
  getHashedEmail() {
    if (this.store === null) {
      return '';
    }
    const { sinatra } = this.store?.getState();
    const { email } = sinatra.user.profile;

    let hashedEmail;
    if (email) {
      hashedEmail = md5(email);
    }

    return hashedEmail;
  }

  /**
   * Initializes store with the corresponding tracking key for the
   * current stack.
   * @param {Object} store - A reference to the redux store instance.
   */
  initialize(store) {
    this.store = store;
  }

  normalizeGSPXUserInfo(userState) {
    let storeRef = this.store;
    let user = userState;
    if (!userState) {
      user = storeRef?.getState().sinatra?.user;
      captureExceptionWithScope(new Error('Undefined user'), {
        userState,
      });
    }

    const role = user.currentRole;
    const {
      date_joined,
      email,
      first_name,
      id,
      last_active_session,
      last_name,
    } = user.profile;

    let profileID = id;
    const appName = role.application;
    const appVersion = process.env.REACT_APP_FE_VERSION || '';
    const hashedEmail = this.getHashedEmail() || '';
    const formattedDateJoined = Date.parse(date_joined);
    const formattedLastActive = Date.parse(last_active_session);
    const host = getAPIHost();

    if (profileID === undefined) {
      user = storeRef.getState().sinatra?.user;
      profileID = storeRef.getState().sinatra?.user?.profile.id;
    }

    let profileIDWithStack = profileID;
    if (this.trackingID === GSPX_TRACKING_IDS.staging) {
      profileIDWithStack = `${profileID}-staging`;
    } else if (this.trackingID === GSPX_TRACKING_IDS.qa) {
      profileIDWithStack = `${profileID}-qa`;
    } else if (this.trackingID === GSPX_TRACKING_IDS.dev) {
      profileIDWithStack = `${profileID}-dev`;
    } else {
      profileIDWithStack = `${profileID}-prod`;
    }

    return {
      appName,
      appVersion,
      email,
      firstName: first_name,
      hashedEmail,
      host,
      id: profileIDWithStack,
      lastActive: formattedLastActive,
      lastName: last_name,
      signUpDate: formattedDateJoined,
      trackingID: this.trackingID,
      userRole: role.name,
    };
  }

  /**
   * Track Custom event
   * @param {string} category - app and category that calling the tracking
   * event, could be Data Map, Data CallQueue, like the component name we use
   * @param {object} eventInfo - any info that you want to be tracked
   * @param {number} eventInfo.'Launched date' - timestamp representing when
   * the event was triggered.
   */
  trackCustomEvent(category, eventInfo) {
    try {
      if (window.aptrinsic) {
        if (eventInfo && !eventInfo['Launched date']) {
          eventInfo['Launched date'] = new Date();
        }
        if (eventInfo && !eventInfo['Category']) {
          eventInfo['Category'] = category;
        }
        const sinatra = this.store?.getState().sinatra;
        if (sinatra?.irp?.isICSP && eventInfo && !eventInfo['irpVersion']) {
          eventInfo['irpVersion'] = sinatra?.irp?.irpVersion;
        }
        //the first parameter has to be 'track' for custom event
        window.aptrinsic('track', category, eventInfo);
      }
      //eslint-disable-next-line no-empty
    } catch {}
  }
}

/**
 * NOTE This returns a singleton of CapstoneGainSightPX
 */
export default new CapstoneGainSightPX();
