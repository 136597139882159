// TODO: generalize and extract out. The existing Loader is not generic enough to vary size, color, etc
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Box from '@rsos/base-components/Box';

const wave = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.6);
  }
  20% {
    transform: scaleY(1);
  }
`;

const Container = styled(Box).attrs({
  align: 'center',
  justify: 'center',
})`
  height: ${p => (p.size ? p.size : 30)}px;
  width: ${p => (p.size ? p.size : 30)}px;
`;

const Bars = styled(Box).attrs({
  horizontal: true,
  justify: 'space-between',
})`
  height: ${p => (p.size ? p.size : 20)}px;
  width: ${p => (p.size ? p.size : 20)}px;
`;

const Bar = styled(Box).attrs(p => ({
  display: 'inline',
  bg: p.color ? p.color : 'white',
}))`
  width: 2px;
  height: 100%;
  animation: ${wave} 1.2s infinite ease-in-out;
  &:nth-child(1) {
    animation-delay: -1.2s;
  }
  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -1s;
  }
  &:nth-child(4) {
    animation-delay: -0.9s;
  }
`;

export const Loader = p => {
  const { size, color, style } = p;
  return (
    <Container data-testid="loader-component" size={size} {...style}>
      <Bars size={size}>
        <Bar color={color} />
        <Bar color={color} />
        <Bar color={color} />
        <Bar color={color} />
      </Bars>
    </Container>
  );
};

export default Loader;
