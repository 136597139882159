import styled, { keyframes } from 'styled-components';
import { Box, Text, Absolute } from '@rsos/base-components';

const enter = keyframes`
 from {
   opacity: 0;
   transform: translateX(-5px);
 }
 to {
   opacity: 1;
   transform: translateX(0);
 }
`;
export const HintsContainer = styled(Box).attrs({
  py: 10,
  px: 20,
  position: 'relative',
  bg: 'background',
})`
  max-width: 200px;
  top: 5px;
  color: ${p =>
    p.status === 'unchecked'
      ? p.theme.colors.secondaryText
      : p.status === 'valid'
      ? p.theme.colors.success
      : p.theme.colors.lightDanger}};
  border: ${p => `1px solid ${p.theme.colors.heavyLineOutline}`};
  border-radius: 4px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.13);
  animation: 250ms ease ${enter};
  pointer-events: auto;
  z-index: 1
`;
export const HintsWrap = styled(Absolute).attrs({
  top: '100%',
  right: 0,
})`
  pointer-events: none;
`;
export const Ball = styled(Absolute).attrs({
  top: '7px',
  left: '-8px',
})`
  width: 5px;
  height: 5px;
  background: ${p =>
    p.status === 'unchecked'
      ? p.theme.colors.secondaryText
      : p.status === 'valid'
      ? p.theme.colors.success
      : p.theme.colors.lightDanger};
  border-radius: 50%;
`;

export const HintsLabel = styled(Text).attrs(({ theme, status }) => ({
  size: 'small',
  color:
    status === 'unchecked' || status === 'valid'
      ? theme.colors.secondaryText
      : theme.colors.lightDanger,
}))``;
