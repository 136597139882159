import React from 'react';
import styled from 'styled-components';
import Absolute from '@rsos/base-components/Absolute';
import { DARK_THEME, DARKER_THEME } from '@rsos/theme/constants';

const DropdownContainer = styled(Absolute)`
  width: ${p => p.width || 'auto'};
  height: ${p => p.height || 'auto'};
  max-width: ${p => p.maxWidth || 'initial'};
  max-height: ${p => p.maxHeight || 'initial'};
  background-color: ${p => p.theme.colors.background};
  overflow: auto;
  border-radius: 5px;
  box-shadow: ${({ theme }) => {
    if (
      theme?.colors?.name === DARK_THEME ||
      theme?.colors?.name === DARKER_THEME
    ) {
      return `0px 1px 4px 0px rgb(0 0 0 / 50%)`;
    } else {
      return `0px 1px 10px 0px rgb(0 0 0 / 10%)`;
    }
  }};
`;
const DropdownDialog = p => {
  return <DropdownContainer {...p} />;
};

export default DropdownDialog;
