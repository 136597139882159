import React from 'react';
import {
  registerUser,
  requestResetPassword,
  confirmUser as sinatraConfirmUser,
  login,
  changeUserPassword,
  confirmRequestUser,
  setUserPassword,
  sessionStart,
} from '@rsos/sinatra';
import {
  CREDENTIAL_CHANGE_REQUIRED,
  USER_NOT_CONFIRMED,
} from '@rsos/sinatra/src/constants/ScorpiusStatusCodes';
import {
  configureUserScope,
  captureException,
  clearGlobalScope,
} from '@rsos/utils/sentry';
import { notifyError, notifySuccess } from '@rsos/utils/toastUtils';
import handleRedirect from '../utils/handleRedirect';
import {
  FORCED_LOGOUT_START,
  FORCED_LOGOUT_SUCCESS,
  CONFIRM_USER_START,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_FAILED,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  GET_OAUTH_TOKEN_START,
  GET_OAUTH_TOKEN_SUCCESS,
  GET_OAUTH_TOKEN_FAILED,
  SET_USER_INFO,
  REQUEST_CONFIRMATION_START,
  REQUEST_CONFIRMATION_SUCCESS,
  REQUEST_CONFIRMATION_FAILED,
  REQUEST_PASSWORD_START,
  REQUEST_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_FAILED,
  RESET_FAILED_COUNT,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  SAVE_CREATE_ACCOUNT_INFO,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  FIRST_TIME_LOGIN,
} from './actionTypes';
import {
  trackSessionStart,
  trackChangedPasswordSuccess,
  trackRequestResetPasswordSuccess,
  trackCSPlogin,
} from './authTrackings';
import { createPSAPgeofence } from './index';

const errMsgUtil = message => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    ></div>
  );
};

// Forced logout
export const forcedLogOutStart = () => ({
  type: FORCED_LOGOUT_START,
});

export const forcedLogOutSuccess = () => ({
  type: FORCED_LOGOUT_SUCCESS,
});

export const forcedLogOut = navigate => dispatch => {
  dispatch(forcedLogOutStart());
  dispatch(forcedLogOutSuccess());
  localStorage.clear();
  if (window.indexedDB) {
    // Clear indexedDB which could be caching persistor data
    window.indexedDB.deleteDatabase('localforage');
  }
  clearGlobalScope();
  navigate('/');
};

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  userInfo,
  isLoggedIn: true,
});

export const setFirstTimeLogin = details => ({
  type: FIRST_TIME_LOGIN,
  details,
});

// getOAuthTokenSuccess and getUserInfo both success count as logged in
export const getOAuthTokenStart = () => ({
  type: GET_OAUTH_TOKEN_START,
});

export const getOAuthTokenFail = error => {
  Promise.resolve(error).then(parsedErr => {
    if (parsedErr.email && parsedErr.email.length > 0) {
      notifyError({ message: errMsgUtil(parsedErr.email[0]) });
    } else if (parsedErr.password && parsedErr.password.length > 0) {
      notifyError({ message: errMsgUtil(parsedErr.password[0]) });
    } else {
      notifyError({ message: errMsgUtil(parsedErr) });
    }
  });

  return {
    type: GET_OAUTH_TOKEN_FAILED,
    error, // the error is currently unused
  };
};

export const getOAuthTokenSuccess = (token, refreshToken) => ({
  type: GET_OAUTH_TOKEN_SUCCESS,
  oAuthToken: token,
  refreshToken,
});

// For Login
export const getOAuthToken = (username, password, navigate) => (
  dispatch,
  getState,
) => {
  dispatch(getOAuthTokenStart());
  dispatch(login(username, password))
    .then(() => {
      const { sinatra } = getState();
      const { tokens } = sinatra.auth;
      dispatch(sessionStart());
      dispatch(getOAuthTokenSuccess(tokens.token, tokens.refresh_token));
      trackSessionStart();
      try {
        configureUserScope({
          email: username,
          version: process.env.REACT_APP_FE_VERSION || '',
        });
      } catch {
        // Doing nothing, in case Sentry ever fails, it won't affect
        // the login flow.
      }
      const currentApp = sinatra.user.currentRole.application;

      if (currentApp === 'central_station') {
        trackCSPlogin();
      }
      handleRedirect(dispatch, sinatra, navigate)
        .then(path => (window.location = path))
        .catch(() => {
          dispatch(forcedLogOut(navigate));
        });
    })
    .catch(err => {
      if (!err.error) {
        dispatch(forcedLogOut(navigate));
        captureException(err);
      } else if (err.error.response) {
        const errStatus = err.error.response.status;
        const errData = err.error.response.data.detail;
        if (errStatus === CREDENTIAL_CHANGE_REQUIRED) {
          const details = {
            email: username,
            password,
            isFirstTime: true,
          };
          dispatch(setFirstTimeLogin(details));
          localStorage.setItem('temp-details', JSON.stringify(details));
          navigate('/change-password');
        } else if (errStatus === USER_NOT_CONFIRMED) {
          // TODO: update this to use errData. scorpius should be returning the same message as below
          const errMsg =
            'This email address has not been confirmed. Please ' +
            'click the link in the confirmation email. You can also ' +
            '<a href="/request-confirmation" style="text-decoration:underline">request a ' +
            'new confirmation email</a> or ' +
            '<a href="mailto:support@rapidsos.com" style="text-decoration:underline">contact ' +
            'support</a>.';
          notifyError({ message: errMsgUtil(errMsg) });
          navigate('/request-confirmation');
        }

        if (
          ![CREDENTIAL_CHANGE_REQUIRED, USER_NOT_CONFIRMED].includes(errStatus)
        ) {
          dispatch(getOAuthTokenFail(errData));
        }
      }
    });
};

export const confirmUserStart = () => ({
  type: CONFIRM_USER_START,
});

export const confirmUserSuccess = () => {
  notifySuccess(
    'Your email has been confirmed, you can go ahead and log in now.',
  );
  return {
    type: CONFIRM_USER_SUCCESS,
  };
};

export const confirmUserFail = () => ({
  type: CONFIRM_USER_FAILED,
});

export const confirmUser = token => dispatch => {
  dispatch(confirmUserStart());
  dispatch(sinatraConfirmUser(token))
    .then(() => {
      dispatch(confirmUserSuccess());
    })
    .catch(err => {
      // Fail silently. User will be redirected to '/request-confirmation' when
      // trying to log in with an unconfirmed email
      captureException(err);
      dispatch(confirmUserFail());
    });
};

// sign up
export const signUpStart = () => ({
  type: SIGNUP_START,
});

export const signUpSuccess = res => ({
  type: SIGNUP_SUCCESS,
  account: res,
  signUpError: null,
});

export const signUpFail = error => {
  Promise.resolve(error).then(parsedErr => {
    if (parsedErr.detail) {
      notifyError({ message: errMsgUtil(parsedErr.detail) });
    }
  });
  return {
    type: SIGNUP_FAILED,
    signUpError: error.detail,
  };
};

export const resetFailedCount = () => ({
  type: RESET_FAILED_COUNT,
});

export const logOutStart = () => ({
  type: LOGOUT_START,
});

export const logOutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logOutFail = error => ({
  // Do not show error message. Simulate log out success
  type: LOGOUT_FAIL,
  error,
});

export const logOut = navigate => dispatch => {
  localStorage.clear();
  dispatch(logOutStart());
  dispatch(logOutSuccess());
  clearGlobalScope();
  navigate('/');
};

export const requestConfirmationStart = () => ({
  type: REQUEST_CONFIRMATION_START,
});

export const requestConfirmationSuccess = () => {
  notifySuccess('We have sent you a new confirmation email.');
  return {
    type: REQUEST_CONFIRMATION_SUCCESS,
  };
};

export const requestConfirmationFail = error => {
  if (error.detail && error.detail.email) {
    notifyError({ message: errMsgUtil(error.detail.email) });
  }
  return {
    type: REQUEST_CONFIRMATION_FAILED,
    error,
  };
};

export const requestConfirmation = (email, navigate) => dispatch => {
  dispatch(requestConfirmationStart());
  dispatch(confirmRequestUser('capstone', email))
    .then(() => {
      dispatch(requestConfirmationSuccess());
      navigate('/');
    })
    .catch(err => {
      // If user is already confirmed, send them back to login
      captureException(err);
      dispatch(requestConfirmationFail(err.json()));
    });
};

export const requestPasswordStart = () => ({
  type: REQUEST_PASSWORD_START,
});

export const requestPasswordSuccess = () => {
  notifySuccess('We have sent a password reset email.');
  return {
    type: REQUEST_PASSWORD_SUCCESS,
  };
};

export const requestPasswordFail = error => {
  Promise.resolve(error).then(parsedErr => {
    if (parsedErr) {
      notifyError({ message: errMsgUtil(parsedErr) });
    }
  });
  return {
    type: REQUEST_PASSWORD_FAILED,
    error,
  };
};

export const requestPassword = (email, navigate) => dispatch => {
  dispatch(requestPasswordStart);
  dispatch(requestResetPassword(email, 'capstone'))
    .then(() => {
      trackRequestResetPasswordSuccess();
      // NOTE Success is a 204, nothing to pass along.
      dispatch(requestPasswordSuccess());
      navigate('/');
    })
    .catch(err => {
      const errMsg = err.error.message;
      captureException(errMsg);
      dispatch(requestPasswordFail(errMsg));
    });
};

export const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
});

export const resetPasswordSuccess = () => {
  notifySuccess('Reset password confirmed.');
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFail = error => {
  Promise.resolve(error).then(parsedErr => {
    if (parsedErr.detail) {
      notifyError({ message: errMsgUtil(parsedErr.detail) });
    } else {
      notifyError({ message: errMsgUtil(parsedErr) });
    }
  });
  return {
    type: RESET_PASSWORD_FAILED,
  };
};

export const resetPassword = (newData, navigate) => dispatch => {
  dispatch(resetPasswordStart);
  dispatch(setUserPassword(newData.password, newData.token))
    .then(() => {
      trackChangedPasswordSuccess();
      dispatch(resetPasswordSuccess());
      navigate('/');
    })
    .catch(err => {
      const errStatus = err.error.response.status;
      const errData = err.error.response.data.detail;
      // 403 when password reset link is expired
      if (errStatus !== 403) {
        captureException(err);
      }
      dispatch(resetPasswordFail(errData));
    });
};

export const changePasswordStart = () => ({
  type: CHANGE_PASSWORD_START,
});

export const changePasswordSuccess = () => {
  notifySuccess('New password confirmed.');
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFailed = error => {
  Promise.resolve(error).then(parsedErr => {
    if (parsedErr.detail) {
      notifyError({ message: errMsgUtil(parsedErr.detail) });
    }
  });
  return {
    type: CHANGE_PASSWORD_FAILED,
  };
};

export const changePassword = (updatedInfo, navigate) => dispatch => {
  dispatch(changePasswordStart());
  dispatch(
    changeUserPassword(
      updatedInfo.email,
      updatedInfo.temp_password,
      updatedInfo.new_password,
    ),
  )
    .then(() => {
      dispatch(changePasswordSuccess());
      dispatch(
        getOAuthToken(updatedInfo.email, updatedInfo.new_password, navigate),
      );
      navigate('/');
    })
    .catch(err => {
      dispatch(changePasswordFailed(err.json()));
    });
};

export const saveCreateAccountInfo = createAccountInfo => ({
  type: SAVE_CREATE_ACCOUNT_INFO,
  createAccountInfo,
});

export const createPSAPAccount = (
  registerInfo,
  currentPSAPdata,
  navigate,
) => dispatch => {
  dispatch(signUpStart());

  const registerData = {
    email: registerInfo.email,
    password: registerInfo.password,
    first_name: registerInfo.first_name,
    last_name: registerInfo.last_name,
    organization_name: currentPSAPdata.name,
    application: 'capstone', // optional field hardcoded for capstone
  };

  dispatch(registerUser(registerData))
    .then(res => {
      // NOTE: creating the PSAP needs to be done after the user is
      // registered with scorpius because the PSAP endpoint expects the
      // apigee company to already be created.
      dispatch(createPSAPgeofence(currentPSAPdata));
      dispatch(signUpSuccess(res));
      navigate('/sign-up/review');
    })
    .then(() => {
      try {
        configureUserScope({ email: registerInfo.email });
        localStorage.removeItem('createAccount');
        localStorage.removeItem('agencyInfo');
        localStorage.removeItem('signature');
        localStorage.removeItem('systemInfo');
      } catch (err) {
        // This shouldn't happen, but to be cautious, making sure cleanup doesn't
        // affect the signup flow by catching any error here rather than having
        // it propagate to the catch handler for the promise.
        // Please see MC-11091 for details.
        captureException(err);
      }
    })
    .catch(err => {
      // Make sure the user makes it to the /sign-up/account step first,
      // then try to parse an error message to show in a toast.
      // This is to ensure that any failure from parsing the error details
      // doesn't stop the user from being able to retry the signup.
      navigate('/sign-up/account');

      // The error can be a type Error or an axios error. Try to figure out
      // what type it is here. Use this generic message as a fallback.
      let errDetail = {
        detail:
          'An error occurred. Please try again and <a' +
          ' href="mailto:support@rapidsos.com">contact support</a>' +
          ' if the error repeats.',
      };

      if (err instanceof Error) {
        // it's a native JS error, report it to sentry.
        captureException(err);
        // normalize the message so it can be shown in a toast.
        errDetail = { detail: err.message };
      }

      if (err.error && err.error.response && err.error.response.data) {
        errDetail = err.error.response.data;
        captureException(errDetail);
      }

      // calling signUpFail shows the toast.
      dispatch(signUpFail(errDetail));
    });
};
