export const selectOrgByAppName = (orgs, appName) => {
  const foundOrg = orgs.find(org => {
    const found = org.roles.find(role => role.application === appName);
    return !!found;
  });
  return foundOrg;
};

/**
 * Find the organization that matches the given appName for the current user.
 * @param {Object} sinatra - The sinatra state
 * @param {string} appName - The name of the application to match
 */
export const selectUserOrgByApp = (sinatra, appName) => {
  if (!sinatra.user.isLoggedIn) {
    return;
  }
  const { organizations } = sinatra.user.profile;
  const foundOrg = organizations.find(org => {
    const found = org.roles.find(role => role.application === appName);
    return !!found;
  });
  return foundOrg;
};

export default selectUserOrgByApp;
