import React from 'react';
import styled from 'styled-components';
import {
  PlusIcon,
  MinusIcon,
  ChevronDownIcon,
  CaretIcon,
} from '@rsos/assets/icons';
import Box from '../Box';

export const getTriggerIconType = (headerIcon, isOpened) => {
  const mapper = {
    caret: <AccordionTriggerTypeCaret transformCondition={isOpened} />,
    chevron: <AccordionTriggerTypeChevron transformCondition={isOpened} />,
    plus: <AccordionTriggerTypePlus transformCondition={isOpened} />,
    default: <AccordionTriggerTypePlus transformCondition={isOpened} />,
  };

  return mapper[headerIcon] || mapper['default'];
};
const AccordionTriggerTypeCaret = ({ transformCondition }) => {
  return (
    <IconContainer from="-90" to="0" transformCondition={transformCondition}>
      <CaretIcon width={11} height={11} />
    </IconContainer>
  );
};
const AccordionTriggerTypePlus = ({ transformCondition }) => {
  return (
    <>
      {transformCondition ? (
        <MinusIcon viewBox={'0 30 140 60'} width={11} height={11} />
      ) : (
        <PlusIcon width={14} height={14} />
      )}
    </>
  );
};

const AccordionTriggerTypeChevron = ({ transformCondition }) => {
  return (
    <IconContainer from="0" to="-180" transformCondition={transformCondition}>
      <ChevronDownIcon width={11} height={11} />
    </IconContainer>
  );
};

const IconContainer = styled(Box).attrs({
  transition: '300ms ease transform',
})`
  transform: ${p => `rotate(${p.transformCondition ? p.to : p.from}deg)`};
  color: ${p => p.theme.colors.primaryText};
`;
