import React, { useContext } from 'react';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import Login from '../../Auth/Login';
import { MainHeader, Subheader } from '../Landing.styles.js';
import {
  FirstRowWrapper,
  FirstRowContentWrapper,
  FirstRowTextBox,
  FirstRowDescription,
} from './FirstRow.styles.js';

const FirstRow = () => {
  const { layout } = useContext(LayoutContext);

  return (
    <FirstRowWrapper>
      <FirstRowContentWrapper>
        <FirstRowTextBox>
          {layout.window.width > BP_LAPTOP && (
            <Subheader>RAPIDSOS UNITE</Subheader>
          )}
          <MainHeader
            textAlign={layout.window.width > BP_LAPTOP ? 'left' : 'center'}
          >
            Centralizing data and workflows for faster response
          </MainHeader>
          {layout.window.width > BP_LAPTOP && (
            <FirstRowDescription>
              RapidSOS Portal &amp; Premium is now RapidSOS UNITE, which
              centralizes your NG911 data - including calls, text, video,
              sensors and more - with flexible upgrades based on your agency's needs.
            </FirstRowDescription>
          )}
        </FirstRowTextBox>
        <Login />
      </FirstRowContentWrapper>
    </FirstRowWrapper>
  );
};

export default FirstRow;
