import instance from './scorpiusInstance';
import {
  CHANGE_USER_PASSWORD_START,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
} from './types';

export const changeUserPasswordStart = () => ({
  type: CHANGE_USER_PASSWORD_START,
});

export const changeUserPasswordSuccess = response => ({
  type: CHANGE_USER_PASSWORD_SUCCESS,
  payload: response,
});

export const changeUserPasswordError = error => ({
  type: CHANGE_USER_PASSWORD_ERROR,
  error,
});

export const changeUserPassword = (
  email,
  temp_password,
  new_password,
) => async (dispatch) => {
  dispatch(changeUserPasswordStart());
  try {
    const response = await instance.post('/user/password/change', {
      email,
      temp_password,
      new_password,
    });
    return dispatch(changeUserPasswordSuccess(response.data));
  } catch (error) {
    throw dispatch(changeUserPasswordError(error));
  }
};
