import styled from 'styled-components';

const Absolute = styled.div`
  position: absolute;
  top: ${position('top')};
  left: ${position('left')};
  right: ${position('right')};
  bottom: ${position('bottom')};

  width: ${p => (p.width ? `${p.width}` : 'auto')};

  ${p =>
    p.center
      ? `display: flex; align-items: center; justify-content: center`
      : ''}

  z-index: ${p => (p.zIndex ? `${p.zIndex}` : 'auto')};
`;

function position(pos) {
  return p =>
    p[pos] === true
      ? 0
      : p[pos] === 0
      ? 0
      : p[pos]
      ? typeof p[pos] === 'string'
        ? p[pos]
        : `${p[pos]}px`
      : 'auto';
}

export default Absolute;
