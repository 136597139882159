import instance from './scorpiusInstance';
import {
  REQUEST_SAML_START,
  REQUEST_SAML_SUCCESS,
  REQUEST_SAML_ERROR,
} from './types';
import shortid from 'shortid';

export const requestSAMLStart = service => ({
  type: REQUEST_SAML_START,
  service,
});

export const requestSAMLSuccess = service => ({
  type: REQUEST_SAML_SUCCESS,
  service,
});

export const requestSAMLError = (error, service) => ({
  type: REQUEST_SAML_ERROR,
  error,
  service,
});

/**
 * Handle SAML requests to Scorpius. Either opening a new tab for the service or redirect
 * @param {String} service - Service that the user is trying to access
 * @param {String} relayStateResource - The specific resource (URL) that the
 * user is trying to access after they are signed in
 */
export const requestSAML = (
  service = '',
  relayStateResource = '',
) => async dispatch => {
  dispatch(requestSAMLStart(service));
  try {
    const response = await instance.get(`/sso/saml/${service}`, {
      headers: { retry: true },
    });
    const { redirect_url, relay_url, saml_response } = response.data;
    if (redirect_url) {
      window.open(redirect_url, '_blank', 'noopener noreferrer');
    } else if (saml_response) {
      const { target } = response.data;
      const FORM_ID = shortid.generate();
      const form = document.createElement('form');
      form.id = FORM_ID;
      form.method = 'POST';
      form.action = target;
      form.target = '_blank';
      form.enctype = 'application/x-www-form-urlencoded';
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'SAMLResponse';
      input.value = saml_response;
      form.appendChild(input);
      if (relayStateResource) {
        const relay = document.createElement('input');
        relay.type = 'hidden';
        relay.name = 'RelayState';
        relay.value = `${relay_url}${relayStateResource}`;
        form.appendChild(relay);
      }
      document.body.appendChild(form);
      const formRef = document.getElementById(FORM_ID);
      formRef.submit();
      document.body.removeChild(formRef);
    } else {
      throw new Error(`Failed to load ${service}`);
    }
    return dispatch(requestSAMLSuccess(service));
  } catch (error) {
    throw dispatch(requestSAMLError(error, service));
  }
};

export default requestSAML;
