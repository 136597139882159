import React from 'react';
import styled from 'styled-components';
import {
  CaretIcon,
  NoResultsIcon,
  SelectLogoEmptyStateIcon,
} from '@rsos/base-assets/icons';
import Box from '@rsos/base-components/Box';
import Text from '@rsos/base-components/Text';

export const customGenericStyles = {
  clearIndicator: (styles, state) => ({
    ...styles,
    color: state.selectProps.theme.colors.secondaryLink,
    padding: 0,
  }),
  input: styles => ({
    ...styles,
  }),
  singleValue: styles => ({
    ...styles,
    color: 'inherit',
    fontSize: '14px',
  }),
  dropdownIndicator: styles => ({
    ...styles,
  }),
  control: (styles, state) => ({
    ...styles,
    minHeight: 35,
    borderColor: `${
      state.menuIsOpen || state.isFocused
        ? state.selectProps.hasError
          ? state.selectProps.theme.colors.lightDanger
          : state.selectProps.theme.colors.primaryBase
        : state.selectProps.theme.colors.heavyLineOutline
    } !important`,
    borderRadius:
      state.selectProps.noGap && state.selectProps.menuIsOpen
        ? '5px 5px 0 0'
        : 5,
    boxShadow: 'none',
    color: state.selectProps.theme.colors.primaryText,
  }),
  valueContainer: styles => ({
    ...styles,
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: '2px 0px',
  }),
  noOptionsMessage: (styles, state) => ({
    ...styles,
    color: state.selectProps.theme.colors.primaryText,
  }),
  menu: (styles, state) => ({
    ...styles,
    marginTop: state.selectProps.noGap ? 0 : '10px',
    border: `1px solid ${state.selectProps.theme.colors.lightLineDivider}`,
    background: state.selectProps.theme.colors.background,
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.13)',
    borderRadius: state.selectProps.noGap ? '0 0 5px 5px' : 5,
  }),
  menuList: styles => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 5,
  }),
  option: (styles, state) => ({
    ...styles,
    color: state.selectProps.theme.colors.primaryText,
    fontSize: '14px',
    background: state.isSelected
      ? state.selectProps.theme.colors.highlights
      : state.selectProps.theme.colors.background,
    '&:hover': {
      background:
        !state.isSelected && state.selectProps.theme.colors.foreground,
    },
    '&:active': {
      background: state.isSelected && state.selectProps.theme.colors.highlights,
    },
  }),
};

export const customMultiSelectStyles = {
  multiValue: (styles, state) => ({
    ...styles,
    backgroundColor: state.selectProps.theme.colors.foreground,
  }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    ':hover': {
      backgroundColor: state.selectProps.theme.colors.lightLineDivider,
      cursor: 'pointer',
    },
    ':active': {
      backgroundColor: state.selectProps.theme.colors.secondaryLink,
    },
  }),
  multiValueLabel: (styles, state) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    color: state.selectProps.theme.colors.primaryText,
    height: 25,
    borderRadius: 5,
    fontSize: '12px',
    padding: '5px',
  }),
};
const SelectPlaceholder = styled(Text).attrs({
  color: 'secondaryText',
  pl: '2px',
  size: 'normal',
})``;
export const Placeholder = props => (
  <Box position="absolute">
    <SelectPlaceholder theme={props.theme}>{props.children}</SelectPlaceholder>
  </Box>
);

// TODO: confirm the following: according to the design system, the caret height and width are 6px and 11px respectively https://app.sympli.io/app#!/designs/60675e0df475fb470607a790/specs/assets. the CRG design has 5px and 9px https://app.sympli.io/app#!/designs/60da07fb9a78ce01d1d914a1/specs
export const ChevronContainer = styled(Box).attrs({
  align: 'center',
  justify: 'center',
  width: 30,
  height: 30,
  cursor: 'pointer',
  color: 'secondaryLink',
  transition: '300ms ease transform',
})``;

export const DropdownIndicator = props => (
  <ChevronContainer
    style={{
      transform: `rotate(${props.selectProps.menuIsOpen ? -180 : 0}deg)`,
    }}
    data-name="select-arrow"
  >
    <CaretIcon width={11} height={13} />
  </ChevronContainer>
);

export const NoOptionsMessage = props => {
  return props.options.length ? (
    <Box justify="center" align="center" py={20} flow={9}>
      <Box horizontal flow={7} color="secondaryText">
        <NoResultsIcon />
        <Text fontWeight="bold">No Results</Text>
      </Box>
      <Text size="small" color="secondaryText">
        0 Results found for "{props.selectProps.inputValue}"
      </Text>
    </Box>
  ) : (
    <Box align="center" justify="center" p={30} flow={7} color="secondaryText">
      <SelectLogoEmptyStateIcon
        viewboxHeight={100}
        viewboxWidth={100}
        width={55}
        height={55}
      />
    </Box>
  );
};
