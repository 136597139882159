import React, { useContext } from 'react';
import { merge } from 'lodash';
import {
  createGlobalStyle,
  ThemeContext,
  ThemeProvider as SCThemeProvider,
} from 'styled-components';
import './fontStyles.scss';
import { getTheme } from './themes.js';

/**
 * Get the theme name of the currently active theme.
 * @returns {string} themeName
 */
export const useThemeName = () => {
  const { colors = { name: 'light' } } = useContext(ThemeContext);
  return colors.name;
};

const GlobalStyle = createGlobalStyle`
  html,
  body {
    text-rendering: optimizelegibility;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
  * {
    font-family: 'Roboto', sans-serif;
   }
  /* Make sure box-sizing is consistent across browsers */
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

/**
 * This Redux disconnected ThemeProvider, accepts theme as a prop
 * @param {React.Children} props.children
 */
const ThemeProvider = ({
  themeName,
  children,
  themeOverrides,
  injectGlobalStyles = true,
}) => {
  const theme = merge({}, getTheme(themeName), themeOverrides);

  return (
    <SCThemeProvider theme={theme}>
      {injectGlobalStyles && <GlobalStyle />}
      {children}
    </SCThemeProvider>
  );
};

export default ThemeProvider;
