import React from 'react';
import {
  UserIcon,
  PermissionsIcon,
  SecurityKeyIcon,
  UsersIconFilled,
  ArrowForwardIcon,
  AlertsTabIcon,
  InfoIcon,
  AudioControlIcon,
  InboundIcon,
  OutboundIcon,
  PoliceIcon,
  SMSIcon,
} from '@rsos/assets/icons';
import {
  AGENCY_SHARE_CHAT,
  ALERTS_NOTIFICATION,
  ALERTS_TRAIN_INCIDENT,
  TF911,
} from '@rsos/capstone/src/constants/capabilities';
import {
  AGENCY_INFO_CONTENT,
  INBOUND_CONTENT,
  OUTBOUND_CONTENT,
  ROLES_CONTENT,
  SECURITY_CONTENT,
  USERS_CONTENT,
  PEER_AGENCIES,
  DATA_SOURCE_CONTENT,
  SETTINGS_CONTENT,
  ALERTS_ROUTING_CONTENT,
  RESPONDERS_CONTENT,
  COMMUNICATION_CONTENT,
  SSO_CONTENT,
} from '@rsos/constants/adminContents';
import {
  MANAGE_AGENCY_INFO,
  MANAGE_USERS,
  MANAGE_PEER_AGENCIES,
  MANAGE_SECURITY,
  MANAGE_DATA_SOURCES,
  MANAGE_INTEGRATIONS,
  MANAGE_SOUND,
  MANAGE_SSO,
  MANAGE_ALERT_PARTNERS,
  MANAGE_RESPONDERS,
  MANAGE_COMMUNICATION,
} from '@rsos/constants/pageAccess';

const rspSideBarItems = [
  {
    id: 'configPsapInfo',
    link: '/admin/psap',
    menuTitle: 'Agency Info',
    menuIcon: <InfoIcon width={12} height={12} />,
    name: AGENCY_INFO_CONTENT,
    beName: MANAGE_AGENCY_INFO,
  },

  {
    id: 'configUsers',
    link: '/admin/access/users',
    menuTitle: 'Users',
    menuIcon: <UserIcon width={12} height={12} />,
    name: USERS_CONTENT,
    beName: MANAGE_USERS,
  },
  {
    id: 'configRoles',
    link: '/admin/access/roles',
    menuTitle: 'Roles & Access',
    menuIcon: <UsersIconFilled width={12} height={12} />,
    name: ROLES_CONTENT,
    beName: MANAGE_USERS,
  },
  {
    id: 'configDataSource',
    link: '/admin/data-source',
    menuTitle: 'Data Source',
    menuIcon: <PermissionsIcon width={12} height={12} />,
    name: DATA_SOURCE_CONTENT,
    beName: MANAGE_DATA_SOURCES,
  },
  {
    id: 'configAlertsRouting',
    link: '/admin/alerts-routing',
    menuTitle: 'Alerts Routing',
    menuIcon: <AlertsTabIcon width={12} height={12} />,
    name: ALERTS_ROUTING_CONTENT,
    beName: MANAGE_ALERT_PARTNERS,
    capabilityAccess: ALERTS_NOTIFICATION,
  },
  {
    id: 'configPeerAgencies',
    link: '/admin/share-and-chat',
    menuTitle: 'Share and Chat',
    menuIcon: <ArrowForwardIcon width={12} height={12} />,
    name: PEER_AGENCIES,
    beName: MANAGE_PEER_AGENCIES,
    capabilityAccess: AGENCY_SHARE_CHAT,
  },
  {
    id: 'configResponders',
    link: '/admin/responders',
    menuTitle: 'Responders',
    menuIcon: <PoliceIcon width={12} height={12} />,
    name: RESPONDERS_CONTENT,
    beName: MANAGE_RESPONDERS,
    capabilityAccess: ALERTS_TRAIN_INCIDENT,
  },
  {
    id: 'configCommunication',
    link: '/admin/text-from-911',
    menuTitle: 'Text-from-911',
    menuIcon: <SMSIcon width={12} height={12} />,
    name: COMMUNICATION_CONTENT,
    beName: MANAGE_COMMUNICATION,
    capabilityAccess: TF911,
  },
  {
    id: 'configSettings',
    link: '/admin/settings',
    menuTitle: 'Notifications',
    menuIcon: <AudioControlIcon width={12} height={12} />,
    name: SETTINGS_CONTENT,
    beName: MANAGE_SOUND, // to be substituted by the proper permission when ready on the BE
  },
  {
    id: 'configSecurity',
    link: '/admin/access/security',
    menuTitle: 'Security',
    menuIcon: <SecurityKeyIcon width={12} height={12} />,
    beName: MANAGE_SECURITY,
    name: SECURITY_CONTENT,
  },
  {
    id: 'configInbound',
    link: '/admin/integrations/inbound',
    menuTitle: 'Inbound Data',
    menuIcon: <InboundIcon />,
    name: INBOUND_CONTENT,
    beName: MANAGE_INTEGRATIONS,
    hoverText: {
      primary: 'Control what RapidSOS Data your ECC can access',
      secondary: '(eg: Layers, Tools, Call Data).',
    },
  },
  {
    id: 'configOutbound',
    link: '/admin/integrations/outbound',
    menuTitle: 'Outbound Data',
    menuIcon: <OutboundIcon />,
    name: OUTBOUND_CONTENT,
    beName: MANAGE_INTEGRATIONS,
    hoverText: {
      primary: 'Control what RapidSOS Integrations your ECC shares data with',
      secondary: '(eg: CAD, CPE/CHE, 911 equipment).',
    },
  },
  {
    id: 'configSSO',
    link: '/admin/access/sso',
    menuTitle: 'Manage SSO',
    menuIcon: <SecurityKeyIcon width={12} height={12} />,
    beName: MANAGE_SSO,
    name: SSO_CONTENT,
    capabilityAccess: `${MANAGE_SSO}_0`.toLowerCase(),
  },
];

export default rspSideBarItems;
