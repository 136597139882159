import {
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT,
  RESET_NEW_DATASOURCE,
  UPDATE_USER_ATTRIBUTES_START,
  UPDATE_USER_ATTRIBUTES_SUCCESS,
  UPDATE_USER_ATTRIBUTES_ERROR,
  UPDATE_ORG_ATTRIBUTES_START,
  UPDATE_ORG_ATTRIBUTES_SUCCESS,
  UPDATE_ORG_ATTRIBUTES_ERROR,
  PATCH_ORG_ATTRIBUTES_START,
  PATCH_ORG_ATTRIBUTES_SUCCESS,
  PATCH_ORG_ATTRIBUTES_ERROR,
} from '../actions/types';
import persistFactory from './persistFactory';

export const initialState = {
  profile: {},
  isLoggedIn: false,
  loading: false,
  registering: false,
  currentOrg: {},
  currentRole: {},
  hasNewDataSource: false,
  newDataSourceList: [],
  pageAccessList: [],
  errors: {
    loading: null,
    registering: null,
    updatingUserAttrs: null,
    updatingOrgAttrs: null,
  },
};

export const updateOrgAttrs = (orgs, payload) => {
  const { orgID, applicationName, data } = payload;
  return orgs.map(org => {
    if (org.id === orgID) {
      return {
        ...org,
        attributes: {
          [applicationName]: data,
        },
      };
    }
    return org;
  });
};

export const patchOrgAttrs = (orgs, payload) => {
  const { orgID, applicationName, data } = payload;
  return orgs.map(org => {
    if (org.id === orgID) {
      return {
        ...org,
        attributes: {
          [applicationName]:
            org.attributes && org.attributes[applicationName]
              ? { ...org.attributes[applicationName], ...data }
              : data,
        },
      };
    }
    return org;
  });
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        profile: action.profile,
        currentRole: action.currentRole,
        currentOrg: action.currentOrg,
        hasNewDataSource: action.hasNewDataSource,
        newDataSourceList: action.newDataSourceList,
        pageAccessList: action.pageAccessList,
      };
    }
    case FETCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        errors: { ...state.errors, loading: action.error },
      };
    case REGISTER_USER_START:
      return {
        ...state,
        registering: true,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        registering: false,
        profile: action.payload,
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        registering: false,
        errors: {
          ...state.errors,
          registering: action.error,
        },
      };
    case RESET_NEW_DATASOURCE:
      return {
        ...state,
        hasNewDataSource: action.isAcknowledged,
      };
    case UPDATE_USER_ATTRIBUTES_START:
      return {
        ...state,
        updatingUserAttrs: true,
      };
    case UPDATE_USER_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          attributes: {
            [action.payload.applicationName]: {
              ...state.profile.attributes[action.payload.applicationName],
              ...action.payload.data,
            },
          },
          updatingUserAttrs: false,
        },
      };
    case UPDATE_USER_ATTRIBUTES_ERROR:
      return {
        ...state,
        updatingUserAttrs: false,
      };
    case UPDATE_ORG_ATTRIBUTES_START:
      return {
        ...state,
      };
    case UPDATE_ORG_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          organizations: updateOrgAttrs(
            state.profile.organizations,
            action.payload,
          ),
        },
      };
    case UPDATE_ORG_ATTRIBUTES_ERROR:
      return {
        ...state,
      };
    case PATCH_ORG_ATTRIBUTES_START:
      return {
        ...state,
      };
    case PATCH_ORG_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          organizations: patchOrgAttrs(
            state.profile.organizations,
            action.payload,
          ),
        },
      };
    case PATCH_ORG_ATTRIBUTES_ERROR:
      return {
        ...state,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const opts = {
  whitelist: [
    'currentOrg',
    'currentRole',
    'profile',
    'isLoggedIn',
    'hasNewDataSource',
    'newDataSourceList',
    'pageAccessList',
  ],
};

export default persistFactory('user', userReducer, opts);
