import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormsiteFormKey } from '@rsos/utils/metaTags';
import './FormSite.scss';

class FormSite extends Component {
  componentDidMount() {
    const {
      fcc_id,
      agency_name,
      contact_title,
      contact_phone,
      contact_email,
      population,
      agency_state,
    } = this.props.currentPSAP;
    const { first_name, last_name } = this.props.user || this.props.auth;
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://fs16.formsite.com/include/form/embedManager.js?594234805',
    );
    script.setAttribute('id', 'form-site-form');
    script.addEventListener('load', () => {
      //NOTE EmbedManager defined dynamically when script is loaded, adding no-undef so will pass es lint
      // eslint-disable-next-line no-undef
      EmbedManager.embed({
        key: `https://fs16.formsite.com/res/showFormEmbed?EParam=${getFormsiteFormKey()}`,
        width: '100%',
        mobileResponsive: true,
        prePopulate: {
          id66: agency_name,
          id67: fcc_id,
          id64: first_name,
          id65: last_name,
          id68: contact_title,
          id71: contact_phone,
          id72: contact_email,
          id91: agency_state,
          id3: population,
        },
      });
    });
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    const formSiteRef = document.getElementById('form-site-form');
    document.body.removeChild(formSiteRef);
  }
  render() {
    return (
      <div>
        <div id="apply_form">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a name="form594234805" id="formAnchor594234805"></a>
        </div>
      </div>
    );
  }
}
FormSite.defaultProps = {
  handleAccessDeniedLink: null,
};

FormSite.propTypes = {
  handleAccessDeniedLink: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { isSignUp } = ownProps;
  if (isSignUp) {
    return {
      currentPSAP: state.psaps.currentPSAP,
      auth: state.auth.createAccountInfo,
    };
  } else
    return {
      currentPSAP: state.psaps.currentPSAP,
      user: state.sinatra.user.profile,
    };
};

export default connect(mapStateToProps, null)(FormSite);
