import styled from 'styled-components';
import Text from '@rsos/base-components/Text';

export const Paragraph = styled(Text).attrs(({ isCentered }) => ({
  lineHeight: '27px',
  marginBottom: '18px',
  size: 'xlarge',
  textAlign: isCentered ? 'center' : 'inherit',
}))`
  &:last-child {
    margin-bottom: 0;
  }

  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }
`;

export const OrderedList = styled.ol`
  list-style-position: outside;
  list-style-type: ${({ type }) => type};
  margin-bottom: 18px;
  margin-left: 36px;
`;

export const ListItem = styled.li`
  font-size: 18px;
  line-height: 27px;

  & + li {
    margin-top: 4px;
  }
`;
