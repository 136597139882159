import React from 'react';
import styled from 'styled-components';
import { LayoutConsumer } from './LayoutProvider';

const createLayoutConsumer = (Component, refType) => ({ children }) => {
  return (
    <LayoutConsumer>
      {props => {
        if (!props) {
          throw new Error(
            'Layout consumers should be used within a <LayoutProvider />.',
          );
        }
        const ref = props[refType] || undefined;
        const innerProps = {
          ...props,
          ref,
        };
        return <Component {...innerProps}>{children}</Component>;
      }}
    </LayoutConsumer>
  );
};

const StyledLayoutHeader = styled.div.attrs({ className: 'LayoutHeader' })`
  width: 100%;
`;

export const LayoutHeader = createLayoutConsumer(
  StyledLayoutHeader,
  'headerRef',
);

export const LayoutBody = styled.div.attrs({ className: 'LayoutBody' })`
  width: 100%;
`;

const StyledLayoutFooter = styled.div.attrs({ className: 'LayoutFooter' })`
  width: 100%;
`;

export const LayoutFooter = createLayoutConsumer(
  StyledLayoutFooter,
  'footerRef',
);
