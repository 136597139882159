import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@rsos/components/capstone/base';

export const NotificationWrapper = styled(Box).attrs({
  px: '5px',
  py: '3px',
  horizontal: true,
  align: 'center',
  justify: 'center',
  bg: 'background',
})`
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.primaryBase}`};
`;

const NewNotification = ({ hasNewDataSource }) => {
  if (!hasNewDataSource) return null;
  return (
    <NotificationWrapper>
      <Text
        size="xsmall"
        fontWeight="bold"
        textAlign="center"
        color="primaryBase"
      >
        New
      </Text>
    </NotificationWrapper>
  );
};

export default NewNotification;
