import { useSelector } from 'react-redux';
import usePrimaryCallLocations from './usePrimaryCallLocations';
import useSecondaryCallLocations from './useSecondaryCallLocations';

const useHasLocationsToRender = () => {
  const selectedCallerID = useSelector(
    state => state.emergencyCalls.selectedCallerID,
  );

  const primaryCallLocations = usePrimaryCallLocations();

  const secondaryCallLocations = useSecondaryCallLocations();

  const hasLocationsToRender = !!(
    selectedCallerID &&
    (primaryCallLocations.length || secondaryCallLocations.length)
  );

  return hasLocationsToRender;
};

export default useHasLocationsToRender;
