const MEDICALERT = 'medicalert';
const PROFILE_INFO = 'profile_info';

/**
 * Normalizes an array of capabilities into an object
 * literal whose keys are the name of the capability and
 * values are the capability object.
 * @param {Object[]} capabilities
 * @param {number} capabilities[].id
 * @param {string} capabilities[].name
 * @param {string} capabilities[].display_name
 * @param {number} capabilities[].category
 */
const normalizeCapabilities = (capabilities = []) => {
  const normalized = {};
  capabilities.forEach(capability => {
    if (capability.name === PROFILE_INFO) {
      normalizeMedicalertCapability(normalized, capability);
    } else {
      normalized[`${capability.name}_${capability.category}`] = capability;
    }
  });
  return normalized;
};

/**
 * Normalize the profile_info capability name to medicalert to avoid legacy
 * issues with the naming and to prevent mismatch with the RADE API
 * @param {Object} normalized - normalized capabilities
 * @param {Object} capability - medicalert capability
 */
const normalizeMedicalertCapability = (normalized, capability) => {
  normalized[`${MEDICALERT}_${capability.category}`] = {
    ...capability,
    name: MEDICALERT,
  };
};

export default normalizeCapabilities;
