import React from 'react';
import SVGLoader from './SVGLoader';

export default ({ width, height, viewboxWidth, viewboxHeight, ...rest }) => {
  return (
    <SVGLoader
      {...rest}
      width={width || 12}
      height={height || 12}
      viewboxWidth={viewboxWidth || 12}
      viewboxHeight={viewboxHeight || 12}
    >
      <g transform="matrix(1.2,0,0,1.2,0,0)">
        <path
          d="M8.03,2.72,5.53.22a.749.749,0,0,0-1.06,0l-2.5,2.5A.75.75,0,0,0,3.03,3.78L4.25,2.561V9.25a.75.75,0,0,0,1.5,0V2.561L6.97,3.78A.75.75,0,1,0,8.03,2.72Z"
          fillRule="nonzero"
          stroke="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0"
        />
      </g>
    </SVGLoader>
  );
};
