import axios from 'axios';
import { getTokens, setTokens } from './tokens';

const STATUS_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export function createApiInstance(baseURL) {
  const opts = {
    baseURL,
    headers: {},
    withCredentials: true,
  };

  const tokens = getTokens();

  if (tokens) {
    opts.headers.Authorization = `Bearer ${tokens.token}`;
  }

  const instance = axios.create(opts);

  // When a request is sent along with { headers: { retry: true } },
  // get that value from the configured headers, set it in a custom field,
  // and delete the entry from the headers. This is the only way to get
  // a flag passed along with Axios via interceptors since custom fields
  // in AxiosRequestConfig are automatically ignored by the axios library.
  instance.interceptors.request.use(config => {
    const { retry } = config.headers;
    if (retry) {
      // Since the headers was repurposed to pass along the retry param,
      // delete the entry from the headers and set it within a custom field
      // of the config. This field will eventually make its way to the response
      // interceptor for the instance.
      config.custom = { retry };
      delete config.headers.retry;
    }
    return config;
  });

  // When a request fails and a refresh token is available, attempt to refresh
  // and obtain a new access token. If that's successful, save the new access
  // token and use it to retry the original request.
  instance.interceptors.response.use(null, async error => {
    const retryCodes = [STATUS_CODES.FORBIDDEN, STATUS_CODES.UNAUTHORIZED];

    const tokens = getTokens();

    const shouldRetry = Boolean(
      error.config &&
        error.response &&
        error.config.custom &&
        error.config.custom.retry &&
        retryCodes.includes(error.response.status) &&
        tokens?.refresh_token,
    );

    if (!shouldRetry) {
      return Promise.reject(error);
    }

    try {
      if (!tokens) {
        throw error;
      }

      // Request a new access token
      const { data } = await axios.post(
        `${baseURL}/user/api-token-auth/refresh`,
        {
          refresh_token: tokens.refresh_token,
        },
      );

      // New set of tokens with updated token and same refresh
      const newTokens = {
        ...tokens,
        ...data,
      };

      // Save the new tokens to localStorage. Note: This doesn't update the
      // sinatra.auth.tokens.token value in redux
      setTokens(newTokens);

      const { config } = error;

      // Set the new token into the instance headers
      instance.defaults.headers.Authorization = `Bearer ${newTokens.token}`;

      // Set the new token into the request that will be retried
      config.headers.Authorization = `Bearer ${newTokens.token}`;

      // Retry the original request
      return axios.request(config);
    } catch {
      // NOTE Since something went wrong when retrying, throwing the error for
      // the original request (which is what the user would expect).
      throw error;
    }
  });

  return instance;
}
