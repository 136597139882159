import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 12,
  viewboxHeight: 12,
};
const ArrowLeft = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <path d="M12 4.7488C11.9998 4.67244 11.9821 4.59714 11.9483 4.52867C11.9145 4.4602 11.8655 4.40038 11.805 4.3538L6.305 0.103799C6.21757 0.0364952 6.11033 0 6 0C5.88967 0 5.78243 0.0364952 5.695 0.103799L0.195 4.3538C0.134493 4.40038 0.0854575 4.4602 0.0516557 4.52867C0.0178539 4.59714 0.00018354 4.67244 0 4.7488L0 11.4988C0 11.6314 0.0526784 11.7586 0.146447 11.8524C0.240215 11.9461 0.367392 11.9988 0.5 11.9988H4.5C4.5663 11.9988 4.62989 11.9725 4.67678 11.9256C4.72366 11.8787 4.75 11.8151 4.75 11.7488V9.4988C4.75 9.16728 4.8817 8.84934 5.11612 8.61492C5.35054 8.3805 5.66848 8.2488 6 8.2488C6.33152 8.2488 6.64946 8.3805 6.88388 8.61492C7.1183 8.84934 7.25 9.16728 7.25 9.4988V11.7488C7.25 11.8151 7.27634 11.8787 7.32322 11.9256C7.37011 11.9725 7.4337 11.9988 7.5 11.9988H11.5C11.6326 11.9988 11.7598 11.9461 11.8536 11.8524C11.9473 11.7586 12 11.6314 12 11.4988V4.7488Z" />
    </SVGLoader>
  );
};

export default ArrowLeft;
