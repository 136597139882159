import React from 'react';
import { XCloseIcon } from '@rsos/base-assets/icons';
import Box from '@rsos/base-components/Box';

const DropdownClose = ({ onClose }) => (
  <Box cursor="pointer" onClick={onClose}>
    <XCloseIcon color="secondaryLink" />
  </Box>
);

export default DropdownClose;
