import styled from 'styled-components';

const DropdownListSectionItem = styled.li`
  display: flex;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.heavyLineOutline}`};
  border-radius: 5px 5px 0 0;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:last-child {
    border-bottom: none;
  }
`;

export default DropdownListSectionItem;
