import React from 'react';
import { EllipsisIcon } from '@rsos/assets/icons';
import { Box, Button, Text } from '@rsos/components/capstone/base';

export const AppLauncherButton = ({ onClick, selected }) => (
  <Button
    dataName="tools-button"
    btnType="invisible"
    onClick={onClick}
    selected={selected}
    size="small"
    type="button"
    noMargin
  >
    <Box align="center" flow={7} horizontal justify="center">
      <Text color="primaryText" size="small" lineHeight={1}>
        Tools
      </Text>
      <EllipsisIcon color="secondaryLink" height={12} width={12} />
    </Box>
  </Button>
);

export default AppLauncherButton;
