/**
 * Helper function to determine the route for the Admin link in the menu
 * @param {Array} pageAccessList - List of permissions from Scorpius
 */
export const mapAdminPermissionsToPath = pageAccessList => {
  const adminPathsMapper = {
    MANAGE_USERS: '/admin/access/users',
    MANAGE_DATA_SOURCES: '/admin/data-source',
    MANAGE_AGENCY_INFO: '/admin/psap',
    MANAGE_INTEGRATIONS: [
      '/admin/integrations/inbound',
      '/admin/integrations/outbound',
    ],
    MANAGE_PEER_AGENCIES: '/admin/share-and-chat',
    MANAGE_SECURITY: '/admin/access/security',
    MANAGE_SOUND: '/admin/settings',
    MANAGE_ALERT_PARTNERS: '/admin/alerts-routing',
    MANAGE_RESPONDERS: '/admin/responders',
    MANAGE_COMMUNICATION: '/admin/text-from-911',
    MANAGE_NOTIFICATIONS: '/admin/notifications',
  };
  const path =
    adminPathsMapper[
      pageAccessList.find(p => p.rsp_rbac && adminPathsMapper[p.name])?.name
    ];
  return path;
};
