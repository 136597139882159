import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 17,
  height: 16,
  viewboxWidth: 17,
  viewboxHeight: 16,
};

const NoResultsIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <g fill="currentColor">
        <path d="m16.438 13.727-3.093-3.06a6.834 6.834 0 1 0-5.68 3.04 6.76 6.76 0 0 0 3.793-1.16l3.1 3.1a1.334 1.334 0 0 0 1.88 0 1.334 1.334 0 0 0 0-1.92ZM7.665 2a4.833 4.833 0 1 1-4.833 4.84A4.84 4.84 0 0 1 7.665 2Z" />
        <path d="m6.619 8.998 1.146-1.146L8.91 8.998a.647.647 0 1 0 .913-.92L8.677 6.94l1.146-1.146a.647.647 0 1 0-.913-.919L7.765 6.02 6.619 4.874a.647.647 0 1 0-.913.92l1.146 1.145-1.146 1.14a.647.647 0 1 0 .913.919Z" />
      </g>
    </SVGLoader>
  );
};

export default NoResultsIcon;
