import React from 'react';
import SVGLoader from './SVGLoader';

const SelectLogoEmptyStateIcon = props => {
  return (
    <SVGLoader {...props}>
      <g id="Page-1">
        <g id="icons" transform="translate(-719.000000, -269.000000)">
          <g id="Group-34" transform="translate(719.000000, 268.500000)">
            <path
              id="Fill-14"
              className="st0"
              d="M82.4,55.4c0,1.9-1.6,3.5-3.6,3.5H65.5c-2,0-3.5-1.6-3.5-3.5l0,0l0-13.3h16.8
  				c2,0,3.6,1.6,3.6,3.5V55.4z M78.8,39H62.9c-0.5,0-0.9-0.4-0.9-0.9l0-15.5c0-3.6-3-6.6-6.7-6.6h-10c-3.7,0-6.7,3-6.7,6.6v9.2
  				c0,0.5,0.4,0.9,0.9,0.9h1.3c0.5,0,0.9-0.4,0.9-0.9v-9.2c0-1.9,1.6-3.5,3.6-3.5h10c2,0,3.6,1.6,3.6,4.1l0,32.8v-0.6
  				c0,3.6,3,6.6,6.7,6.6h13.3c3.7,0,6.7-3,6.7-6.6v-9.8C85.5,41.9,82.5,39,78.8,39L78.8,39z"
            />
            <path
              id="Fill-16"
              className="st0"
              d="M17.6,45.6c0-1.9,1.6-3.5,3.6-3.5h13.2c2,0,3.5,1.6,3.5,3.5v0l0,13.3H21.2
  				c-2,0-3.6-1.6-3.6-3.5V45.6z M21.2,62h15.8c0.5,0,0.9,0.4,0.9,0.9l0,15.5c0,3.6,3,6.6,6.7,6.6h10c3.7,0,6.7-3,6.7-6.6v-9.2
  				c0-0.5-0.4-0.9-0.9-0.9h-1.3c-0.5,0-0.9,0.4-0.9,0.9v9.2c0,1.9-1.6,3.5-3.6,3.5h-10c-2,0-3.6-1.6-3.6-4.1l0-32.8v0.6
  				c0-3.6-3-6.6-6.7-6.6H21.2c-3.7,0-6.7,3-6.7,6.6v9.8C14.5,59,17.5,62,21.2,62L21.2,62z"
            />
          </g>
        </g>
      </g>
    </SVGLoader>
  );
};

export default SelectLogoEmptyStateIcon;
