import React from 'react';
import SVGLoader from './SVGLoader';

const GripIcon = props => {
  return (
    <SVGLoader {...props} width={16} height={18}>
      <path d="M75.6,34.5C75.7,29,80.5,24.3,86,24.3c5.3,0.1,10.1,4.9,10.1,10.2c0,5.7-4.9,10.7-10.4,10.5S75.5,40.1,75.6,34.5z" />
      <path d="M85.8,75.6c-5.9,0-10.2-4.4-10.2-10.2c0.1-5.7,4.6-10.1,10.4-10.1c5.7,0.1,10.2,4.7,10.1,10.4v0C96,71.3,91.4,75.7,85.8,75.6z" />
      <path d="M24.3,34.7c0,5.6-4.9,10.5-10.2,10.3S4,40.2,3.9,34.8s4.9-10.5,10.2-10.5C19.8,24.4,24.3,29.1,24.3,34.7z" />
      <path d="M60.1,34.7c0,5.7-4.7,10.4-10.2,10.4s-10.2-4.9-10.1-10.5s4.5-10.2,10-10.2S60.1,29,60.1,34.7z" />
      <path d="M50,55.3c5.6,0,10.1,4.5,10.1,10.1c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c-0.1,5.8-4.5,10.2-10.4,10c-6.4-0.3-9.6-4-9.8-10.1C39.6,59.6,44.2,55.3,50,55.3z" />
      <path d="M24.2,65.6c0.1,5.5-4.3,10-9.8,10.1c0,0,0,0,0,0h-0.2C8.6,75.7,3.9,71,3.9,65.4c0.1-5.5,4.6-10,10.2-10.1C19.9,55.3,24.3,59.7,24.2,65.6z" />
    </SVGLoader>
  );
};

export default GripIcon;
