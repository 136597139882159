import React from 'react';
import SVGLoader from './SVGLoader';

const MinusIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 14}
      height={height || 14}
      viewboxWidth={viewboxWidth || 140}
      viewboxHeight={viewboxHeight || 140}
    >
      <path d="M130 80H10a10 10 0 010-20h120a10 10 0 010 20z" />
    </SVGLoader>
  );
};

export default MinusIcon;
