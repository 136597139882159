import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 16,
  viewboxWidth: 16,
  width: 16,
  height: 16,
};

export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path d="M15.4603 7.0067C14.618 6.08236 13.6535 5.27717 12.5937 4.61337L15.2603 1.9467C15.3201 1.8876 15.3669 1.81673 15.3979 1.73858C15.4288 1.66042 15.4431 1.5767 15.44 1.49271C15.4369 1.40872 15.4163 1.32629 15.3797 1.25066C15.343 1.17503 15.291 1.10785 15.227 1.05337C15.1726 0.991094 15.1054 0.941185 15.0301 0.906989C14.9548 0.872794 14.873 0.855103 14.7903 0.855103C14.7076 0.855103 14.6259 0.872794 14.5505 0.906989C14.4752 0.941185 14.4081 0.991094 14.3537 1.05337L1.33367 14.0467C1.24107 14.0952 1.16119 14.1649 1.10048 14.25C1.03977 14.3351 0.999927 14.4333 0.984174 14.5367C0.968421 14.64 0.977197 14.7457 1.00979 14.845C1.04239 14.9443 1.0979 15.0346 1.17182 15.1085C1.24575 15.1825 1.33603 15.238 1.43537 15.2706C1.53471 15.3032 1.64032 15.3119 1.74368 15.2962C1.84704 15.2804 1.94524 15.2406 2.03036 15.1799C2.11547 15.1192 2.18512 15.0393 2.23366 14.9467L5.00033 12.18C5.96661 12.6112 7.01572 12.8251 8.07366 12.8067C10.907 12.8067 13.7337 10.8067 15.407 8.94003C15.6543 8.68188 15.7967 8.34099 15.8065 7.98365C15.8164 7.62632 15.693 7.27809 15.4603 7.0067V7.0067ZM10.8737 8.82003C10.7258 9.28972 10.4668 9.71679 10.1186 10.065C9.77042 10.4132 9.34335 10.6722 8.87367 10.82C8.19305 11.0368 7.45605 10.9916 6.807 10.6934C6.78246 10.6824 6.76108 10.6655 6.74482 10.6441C6.72856 10.6227 6.71795 10.5976 6.71397 10.571C6.70998 10.5444 6.71275 10.5173 6.72202 10.4921C6.73129 10.4668 6.74676 10.4444 6.767 10.4267L10.467 6.72003C10.4854 6.69988 10.5084 6.68455 10.5341 6.67537C10.5598 6.6662 10.5873 6.66347 10.6143 6.66741C10.6413 6.67136 10.6669 6.68187 10.6889 6.69802C10.7108 6.71417 10.7285 6.73546 10.7403 6.76003C11.039 7.40632 11.0866 8.14063 10.8737 8.82003V8.82003Z" />
    <path d="M5.0735 8.9067C5.09523 8.8869 5.11108 8.86148 5.11931 8.83326C5.12755 8.80503 5.12784 8.77508 5.12017 8.7467C5.0449 8.50478 5.00449 8.25336 5.00017 8.00004C4.99753 7.60408 5.07314 7.21149 5.22264 6.84483C5.37214 6.47817 5.5926 6.14465 5.87135 5.86342C6.1501 5.58219 6.48165 5.3588 6.84698 5.20605C7.2123 5.05331 7.6042 4.97424 8.00017 4.97337C8.26244 4.97512 8.52303 5.01556 8.7735 5.09337C8.80139 5.10052 8.83063 5.1006 8.85856 5.09362C8.8865 5.08663 8.91225 5.0728 8.9335 5.05337L10.2335 3.75337C10.2527 3.73203 10.2662 3.70626 10.273 3.67838C10.2798 3.6505 10.2795 3.62137 10.2722 3.59362C10.265 3.56587 10.2509 3.54036 10.2314 3.51937C10.2118 3.49838 10.1873 3.48257 10.1602 3.47337C9.46186 3.2507 8.73311 3.13824 8.00017 3.14004C5.1335 3.14004 2.26683 5.14004 0.540168 7.0067C0.300314 7.27153 0.16748 7.61607 0.16748 7.97337C0.16748 8.33067 0.300314 8.67521 0.540168 8.94004C1.22889 9.68633 1.99548 10.3568 2.82683 10.94C2.85784 10.9655 2.89672 10.9794 2.93683 10.9794C2.97695 10.9794 3.01583 10.9655 3.04683 10.94L5.0735 8.9067Z" />
  </SVGLoader>
);
