import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StyledToastContainer } from '@rsos/components/capstone/base/Toast/index.styles';
import { LayoutProvider, LayoutHeader, LayoutBody } from '../base/Layout';
import AdminLayout from './AdminLayout';
import Navigation from './Navigation';
import SessionPortal from './SessionPortal';

const LayoutContent = ({ children, persistor }) => {
  let location = useLocation();

  const isLoggedIn = useSelector(state => state.sinatra.user.isLoggedIn);

  const sinatra = useSelector(state => state.sinatra);

  let { currentOrg = {}, currentRole = {} } = sinatra.user;

  if (!currentOrg) {
    currentOrg = { name: '' };
  }

  if (!currentRole) {
    currentRole = {
      application: '',
    };
  }

  const [path, setPath] = useState(location?.pathname);
  const adminPaths = [
    '/admin/psap',
    '/admin/access',
    '/admin/integrations',
    '/admin/integrations/inbound',
    '/admin/integrations/outbound',
    '/admin/data-source',
    '/admin/share-and-chat',
    '/admin/settings',
    '/admin/alerts-routing',
    '/admin/responders',
    '/admin/text-from-911',
    '/admin/notifications',
  ];
  const isAdminPathName = adminPaths.some(adminPath =>
    path.includes(adminPath),
  );

  useEffect(() => {
    if (location?.pathname) {
      setPath(location.pathname);
    }
  }, [location]);

  return (
    <LayoutProvider>
      <LayoutHeader>
        <Navigation path={path} persistor={persistor} />
      </LayoutHeader>
      <LayoutBody>
        <StyledToastContainer stacked />
        {isAdminPathName ? <AdminLayout>{children}</AdminLayout> : children}
      </LayoutBody>
      {isLoggedIn && <SessionPortal />}
    </LayoutProvider>
  );
};

export default LayoutContent;
