import styled from 'styled-components';
import Box from '../Box';
import Button from '../Button';

export const AccordionWrapper = styled(Box).attrs(({ width }) => ({
  bg: 'background',
  width: width || '300px',
}))`
  border: ${p =>
    p.noBorder ? 'none' : `1px solid ${p.theme.colors.heavyLineOutline}`};
  border-top: ${p =>
    p.stacked || p.noBorder
      ? 'none'
      : `1px solid ${p.theme.colors.heavyLineOutline}`};
`;
export const AccordionHeader = styled(Box).attrs(
  ({ interactionType, headerIconAlign, isOpened, headerPadding }) => ({
    justify: headerIconAlign ? 'flex-end' : 'space-between',
    p: headerPadding || 15,
    cursor: interactionType === 'button' ? 'inherit' : 'pointer',
    bg: interactionType !== 'button' && isOpened ? 'highlights' : 'background',
  }),
)`
  flex-direction: ${p => (p.headerIconAlign ? 'row-reverse' : 'row')};
  border-bottom: ${p =>
    p.isOpened && !p.noBorder
      ? `1px solid ${p.theme.colors.heavyLineOutline}`
      : 'none'};

  &:hover {
    background: ${p =>
      p.interactionType !== 'button' && !p.isOpened
        ? p.theme.colors.foreground
        : ''};
  }
`;

export const AccordionContentContainer = styled(Box).attrs(
  ({ background, padding, margin }) => ({
    bg: background || 'background',
    p: padding || '0 15px',
    m: margin || 'unset',
    transition: 'max-height 0.6s ease',
  }),
)`
  overflow: auto;
`;

export const AccordionTriggerButton = styled(Button)``;
