import React from 'react';
import SVGLoader from './SVGLoader';

const ExternalLinkIcon = props => {
  return (
    <SVGLoader {...props}>
      <g>
        <path
          d="M73.5,84.6V55.1c0-3.7,1.8-6.1,5.1-6.8c3.4-0.6,6.6,1.6,7.2,5c0,0.1,0,0.2,0.1,0.4c0,0.5,0.1,1,0.1,1.4v35.1
        c0,3.3-1.6,5.8-4.4,6.6c-0.8,0.2-1.7,0.4-2.6,0.3c-22.8,0-45.6,0-68.5,0c-4.1,0-7-2.8-7-7c0.1-22.9,0-45.9,0-68.8
        c0-4.5,2.4-6.9,6.9-6.9h35.3c4,0,6.6,2.7,6.5,6.6c-0.2,3.1-2.6,5.6-5.8,5.8c-0.5,0-1,0-1.5,0H16v57.7H73.5z"
        />
        <path
          d="M75.9,15.2c-4.1,0-8,0-11.8,0c-4.6-0.1-7.6-4.2-6.1-8.4c0.8-2.4,3-4,5.5-4.1c9.1,0,18.1,0,27.2,0c3,0.1,5.5,2.5,5.7,5.5
        c0.1,2.9,0.1,5.9,0.1,8.8v17.6c0,3.5-1.6,5.8-4.6,6.6c-3.6,1-7.4-1.3-7.7-5c-0.3-2.5-0.1-5-0.2-7.5v-3.9c-0.5,0.5-0.8,0.7-1.1,1
        l-32.6,33c-2.6,2.6-5.7,3-8.4,1.1c-3.2-2.3-3.5-6.7-0.6-9.7c4.7-4.8,9.4-9.6,14.2-14.4l19.6-19.9C75.3,15.9,75.5,15.7,75.9,15.2z"
        />
      </g>
    </SVGLoader>
  );
};

export default ExternalLinkIcon;
