import React from 'react';
import { LayoutConsumer } from './LayoutProvider';

export const withLayout = Component => {
  return (props = {}) => (
    <LayoutConsumer>
      {context => {
        return <Component {...context} {...props} />;
      }}
    </LayoutConsumer>
  );
};
