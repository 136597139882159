import React from 'react';
import styled from 'styled-components';
import Loader from '@rsos/components/capstone/Loader';
import { Text, Box } from '@rsos/components/capstone/base';
import { withLayout } from '@rsos/components/capstone/base/Layout';

export const MainHeader = styled(Text).attrs({
  fontWeight: 'bold',
  size: 'xheader',
  mb: 20,
})`
  font-family: 'Soleil';
`;
export const Subheader = styled(Text).attrs({
  size: 'normal',
  fontWeight: 'bold',
  mb: 16,
})`
  font-family: 'Soleil';
`;
export const MainDescription = styled(Text).attrs({
  size: 'large',
})`
  font-family: 'Soleil';
`;

export const StyledAnchor = styled.a`
  font-family: 'Soleil';
  text-decoration: none;
`;

export const RedirectWrapper = withLayout(styled(Box).attrs({
  bg: 'foreground',
  width: '100%',
  justify: 'center',
  align: 'center',
})`
  height: ${({ layout }) => layout.body.height}px;
`);
export const AppRedirecting = () => {
  return (
    <RedirectWrapper>
      <Loader text="Redirecting..." />
    </RedirectWrapper>
  );
};
