import AlertToast from '@rsos/components/capstone/AlertToast/AlertToast';
import {
  displayToast,
  dismissToast,
  dismissAll,
} from '@rsos/components/capstone/base/Toast';
import {
  toastMessageSuccess,
  toastMessageError,
  toastMessageInfo,
  toastMessageDefault,
} from '@rsos/components/capstone/base/Toast/ToastComponents';
import {
  ACTIVE_ASSAILANT,
  TRAIN_DERAILMENT,
} from '@rsos/constants/alertEmergencyTypes';

const THIRTY_SECONDS = 1000 * 30;

export const notifySuccess = data => {
  const defaultOptions = {
    closeButton: false,
  };
  const toastOptions = { ...defaultOptions, ...data?.options };
  const message = typeof data === 'object' ? data?.message : data;

  const toastParams = {
    message: toastMessageSuccess(message, data?.noIcon, data?.customIcon),
    options: toastOptions,
    className: 'Toastify__overwrite-success',
  };

  return displayToast(toastParams);
};

export const notifyError = (data, opts = {}) => {
  const defaultOptions = {
    autoClose: THIRTY_SECONDS,
    ...opts,
  };
  const toastOptions = { ...defaultOptions, ...data?.options };
  const message = typeof data === 'object' ? data?.message : data;

  const toastParams = {
    message: toastMessageError(message, data?.noIcon, data?.customIcon),
    options: toastOptions,
    className: 'Toastify__overwrite-error',
  };

  displayToast(toastParams);
};

export const notifyDefault = data => {
  const defaultOptions = {
    closeButton: false,
  };
  const toastOptions = { ...defaultOptions, ...data?.options };
  const message = typeof data === 'object' ? data?.message : data;

  const toastParams = {
    message: toastMessageDefault(message, data?.noIcon, data?.customIcon),
    options: toastOptions,
    className: 'Toastify__overwrite-default',
  };

  displayToast(toastParams);
};

export const notifyInfo = data => {
  const message = typeof data === 'object' ? data?.message : data;

  const toastParams = {
    message: toastMessageInfo(message, data?.noIcon, data?.customIcon),
    options: data?.options,
    className: 'Toastify__overwrite-info',
  };

  displayToast(toastParams);
};

export const notifyGenericAlert = data => {
  const defaultOptions = {
    closeButton: true,
    autoClose: THIRTY_SECONDS,
    closeOnClick: false,
  };
  const toastOptions = { ...defaultOptions, ...data?.options };
  const toastParams = {
    message: <AlertToast alert={data?.alert} />,
    options: toastOptions,
    className: getToastCssClass(data?.alert?.emergency_type?.name),
  };

  return displayToast(toastParams);
};

const getToastCssClass = emergencyType => {
  if (emergencyType === ACTIVE_ASSAILANT) {
    return 'Toastify__active-assailant';
  } else if (emergencyType === TRAIN_DERAILMENT) {
    return 'Toastify__train-derailment';
  }
  return 'Toastify__alert-default';
};
export const dismissToastByID = toastId => {
  dismissToast(toastId);
};
export const dismissAllToasts = () => {
  dismissAll();
};
