import {
  DECRYPT_IRP_TOKEN_START,
  DECRYPT_IRP_TOKEN_SUCCESS,
  DECRYPT_IRP_TOKEN_ERROR,
  SET_IRP_VERSION,
} from '../actions/types';
import persistFactory from './persistFactory';

export const initialState = {
  irp_access_token: null,
  queryURI: null,
  irpVersion: '0',
};

const irpReducer = (state = initialState, action) => {
  switch (action.type) {
    case DECRYPT_IRP_TOKEN_START:
      return {
        ...state,
        error: null,
      };
    case DECRYPT_IRP_TOKEN_SUCCESS:
      return {
        ...state,
        irp_access_token: action.data.rsp_session_token,
        queryURI: action.data.query,
        error: null,
        isICSP: action.data?.isICSP,
      };
    case DECRYPT_IRP_TOKEN_ERROR:
      return {
        ...state,
        error: action.error,
        irp_access_token: null,
      };
    case SET_IRP_VERSION:
      return {
        ...state,
        irpVersion: action.version,
      };
    default:
      return state;
  }
};

export default persistFactory('irpReducer', irpReducer);
