import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EAGLEVIEW } from '@rsos/capstone/src/constants/capabilities';
import { APP_LAUNCHER_EAGLEVIEW } from '@rsos/constants/appLauncherTypes';
import { PREMIUM_EAGLEVIEW } from '@rsos/constants/permissions';
import useAccess from '@rsos/utils/useAccess';
import usePortalType from '@rsos/utils/usePortalType';

const useIsObliqueImagerySelected = () => {
  const isPremiumUser = usePortalType().isPremiumUser;
  const selectedAppLauncherApp = useSelector(
    state => state?.appLauncher?.selectedAppLauncherApp,
  );

  const hasEagleViewAccess =
    !!useAccess(EAGLEVIEW, PREMIUM_EAGLEVIEW) && isPremiumUser;

  const isObliqueImageryToolSelected = useMemo(
    () =>
      hasEagleViewAccess &&
      selectedAppLauncherApp?.appName === APP_LAUNCHER_EAGLEVIEW,
    [selectedAppLauncherApp, hasEagleViewAccess],
  );

  return isObliqueImageryToolSelected;
};

export default useIsObliqueImagerySelected;
