import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { withLayout } from '@rsos/components/capstone/base/Layout';

export const Wrapper = withLayout(styled(Box).attrs({
  justify: 'center',
  align: 'center',
  bg: 'foreground',
})`
  height: ${({ layout }) => layout.body.height}px;
`);

export const FormWrapper = styled(Box).attrs({
  width: 400,
  p: 45,
  bg: 'background',
  height: 352,
  borderRadius: 5,
})`
  box-shadow: 2px 2px 18px rgba(0, 0, 0, 0.13);
`;
