import React from 'react';
import SVGLoader from './SVGLoader';


export default props => (
  <SVGLoader
    viewboxWidth={14}
    viewboxHeight={14}
    width={16}
    height={16}
    {...props}
  >
    <path
      d="M6.69,2a1.318,1.318,0,0,0-.8.271L3,4.5H1.75A1.752,1.752,0,0,0,0,6.25v1.5A1.752,1.752,0,0,0,1.75,9.5H3l2.9,2.228A1.309,1.309,0,0,0,8,10.69V3.31A1.311,1.311,0,0,0,6.69,2Z"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0"
    ></path>
    <path
      d="M12,2.209a.75.75,0,1,0-1.057,1.064,5.246,5.246,0,0,1,0,7.454A.75.75,0,1,0,12,11.791a6.746,6.746,0,0,0,0-9.582Z"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0"
    ></path>
    <path
      d="M10.345,4.893a.75.75,0,1,0-1.237.849,2.215,2.215,0,0,1,0,2.516.75.75,0,0,0,1.237.849,3.716,3.716,0,0,0,0-4.214Z"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0"
    ></path>
  </SVGLoader>
);