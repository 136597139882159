import { FunctionComponent, ReactNode } from 'react';
import Box from '@rsos/base-components/Box';

const defaults = {
  color: 'currentColor',
  width: '24px',
  height: '24px',
  viewboxWidth: '100',
  viewboxHeight: '100',
};

export interface SVGLoaderProps {
  color?: string;
  width?: string | number;
  height?: string | number;
  viewboxWidth?: string | number;
  viewboxHeight?: string | number;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const SVGLoader: FunctionComponent<SVGLoaderProps> = ({
  color,
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  className,
  onClick,
  children,
}) => {
  return (
    <Box color={color || 'inherit'} cursor="inherit">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={defaults.color}
        width={width}
        height={height}
        viewBox={`0 0 ${viewboxWidth} ${viewboxHeight}`}
        className={className}
        onClick={onClick}
      >
        {children}
      </svg>
    </Box>
  );
};

export default SVGLoader;
