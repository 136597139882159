import React, { useState } from 'react';
import Box from '@rsos/base-components/Box';
import { AbsoluteErrorWrapper, ErrorWrapper } from '@rsos/base-components/form/ErrorsWrapper';
import HintsWrapper from '@rsos/base-components/form/HintsWrapper';
import { evalHints } from '../../HintsWrapper/helpers';
import {
  StyledInput,
  StyledPhoneInput,
  InputTextLabel,
  IconWrapper,
  ClearIcon,
  ShowIcon,
  HideIcon,
} from './InputText.styles.js';

const InputText = ({
  id,
  inputRef,
  value,
  label,
  size,
  align,
  grow,
  inputType,
  type,
  error,
  hints,
  IconLeft,
  isDisabled,
  onChange,
  onBlur = () => {},
  onKeyDown,
  setFieldValue,
  containerProps,
  shouldErrorTakeSpace = false,
  shouldDisplayClearButton = true,
  shouldDisplayErrorIcon = true,
  isSecureField = false,
  enableLastPass,
  onFocus,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isSecured, setIsSecured] = useState(isSecureField);

  const enrichedHints = evalHints(hints, value);

  const handleClearInput = () => {
    setFieldValue(id, '');
  };
  const handleSecureMode = () => {
    setIsSecured(!isSecured);
  };
  const handleBlur = e => {
    setIsFocused(false);
    onBlur(e);
  };
  const handleFocus = (event) => {
    setIsFocused(true);
    onFocus?.(event);
  }

  const isValueDefined = ![undefined, null, ''].includes(value);

  return (
    <Box grow={grow} {...containerProps}>
      {label && (
        <InputTextLabel size={size} disabled={isDisabled}>
          {label}
        </InputTextLabel>
      )}
      <Box position="relative">
        {IconLeft && (
          <IconWrapper Icon={IconLeft} left={0} isFocused={isFocused} />
        )}
        {inputType === 'tel' ? (
          <StyledPhoneInput
            data-lpignore={enableLastPass ? 'false' : 'true'} // prevent LastPass autocomplete see https://stackoverflow.com/a/44984917
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            {...rest}
            value={value}
            id={id}
            ref={inputRef}
            error={error}
            disabled={isDisabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={handleBlur}
            onFocus={handleFocus}
            align={align}
            pl={IconLeft ? 28 : 0}
            size={size}
          />
        ) : (
          <StyledInput
            data-lpignore={enableLastPass ? 'false' : 'true'} // prevent LastPass autocomplete see https://stackoverflow.com/a/44984917
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            {...rest}
            value={value}
            id={id}
            ref={inputRef}
            error={error}
            disabled={isDisabled}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={handleBlur}
            onFocus={handleFocus}
            align={align}
            pl={IconLeft ? 28 : 0}
            size={size}
            isSecured={isSecured}
            type={type}
          />
        )}
        {isValueDefined && shouldDisplayClearButton && (
          <ClearIcon onClick={handleClearInput} />
        )}
        {isValueDefined && isSecureField ? (
          isSecured ? (
            <HideIcon onClick={handleSecureMode} />
          ) : (
            <ShowIcon onClick={handleSecureMode} />
          )
        ) : null}
        {!shouldErrorTakeSpace && error && shouldDisplayErrorIcon && (
          <AbsoluteErrorWrapper error={error} id={id} />
        )}
        {!!hints && isFocused && shouldDisplayErrorIcon && (
          <HintsWrapper hints={enrichedHints} error={error} value={value} />
        )}
      </Box>
      {shouldErrorTakeSpace && error && <ErrorWrapper error={error} id={id} />}
    </Box>
  );
};
export default InputText;
