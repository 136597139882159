import styled from 'styled-components';
import { Text } from '@rsos/components/capstone/base';

export const DropdownTitle = styled(Text).attrs({
  size: 'normal',
  fontWeight: 'bold',
  color: 'secondaryText',
  px: 16,
  py: 11,
})``;
