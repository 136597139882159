const themeDefinition = (palette, fieldsToOverride) => {
  const {
    primary_base,
    primary_base_c2,
    primary_text,
    secondary_link,
    secondary_text,
    heavy_line_outline,
    light_line_divider,
    highlights,
    foreground,
    background,
    light_warning,
    light_warning_text,
    heavy_warning,
    light_danger,
    heavy_danger,
    success,
    heavy_success,
    states,
    transparent,
    pane_primary_text,
    unseen,
    caution,
    tag_success_background,
    tag_caution_background,
    tag_warning_background,
    tag_secondary_background,
    tab_highlight,
  } = palette;
  let baseThemeDefinition = {
    palette,
    primaryText: primary_text,
    secondaryText: secondary_text,
    secondaryLink: secondary_link,
    primaryBase: primary_base,
    primaryBaseC2: primary_base_c2,
    highlights: highlights,
    heavyLineOutline: heavy_line_outline,
    lightLineDivider: light_line_divider,
    lightDanger: light_danger,
    heavyDanger: heavy_danger,
    lightWarning: light_warning,
    heavyWarning: heavy_warning,
    lightWarningText: light_warning_text,
    success: success,
    heavySuccess: heavy_success,
    background: background,
    foreground: foreground,
    tabHighlight: tab_highlight,
    transparent: transparent,
    states: states,
    panePrimaryText: pane_primary_text,
    unseen: unseen,
    caution: caution,
    boxShadow: 'rgba(0, 0, 0, 0.25)',
    buttons: {
      primary: {
        bg: primary_base,
        hover: primary_base_c2,
        text: background,
        borderColor: transparent,
      },
      secondary: {
        bg: foreground,
        hover: light_line_divider,
        text: primary_text,
        borderColor: heavy_line_outline,
        selected: {
          bg: highlights,
          text: primary_text,
          borderColor: primary_base,
        },
      },
      tertiary: {
        bg: background,
        hover: light_line_divider,
        text: primary_text,
        borderColor: heavy_line_outline,
        selected: {
          bg: highlights,
          text: primary_text,
          borderColor: primary_base,
        },
      },
      invisible: {
        bg: transparent,
        hover: light_line_divider,
        text: primary_text,
        borderColor: transparent,
        selected: {
          bg: highlights,
          text: primary_text,
          borderColor: primary_base,
        },
      },
      warning_primary: {
        bg: light_danger,
        hover: heavy_danger,
        text: background,
        borderColor: transparent,
      },
      warning_tertiary: {
        bg: background,
        hover: light_line_divider,
        text: light_danger,
        borderColor: light_danger,
      },
      success: {
        bg: success,
        hover: heavy_success,
        text: background,
        borderColor: transparent,
      },
    },
    modal: {
      scrim: primary_text, // overriden for dark mode
    },
    massDisasterToast: {
      text: background,
    },
    trainIncidentAlert: {
      iconColor: primary_text,
      iconBg: '#F2AE00',
      hoverBg: '#D09705',
    },
    notifications: {
      info: {
        bg: primary_base,
        text: background,
      },
      warning: {
        bg: light_warning,
        text: primary_text,
      },
      error: {
        bg: light_danger,
        text: primary_text,
      },
    },
    callerID: {
      boxShadow: 'rgba(0, 0, 0, 0.1)',
    },
    queue: {
      emergencyCall: {
        unseen: unseen,
      },
    },
    agencyShareChat: {
      toast: {
        bgReceived: unseen,
      },
    },
    mapCard: {
      disclaimer: {
        // all have overrides
        bg: light_warning,
        floorsIcon: secondary_text,
        primaryText: primary_text,
        secondaryText: secondary_text,
      },
    },
    tag: {
      success: {
        bg: tag_success_background,
        text: success,
      },
      caution: {
        bg: tag_caution_background,
        text: caution,
      },
      warning: {
        bg: tag_warning_background,
        text: heavy_warning,
      },
      secondary: {
        bg: tag_secondary_background,
        text: secondary_text,
      },
    },
  };

  if (fieldsToOverride) {
    fieldsToOverride.forEach(a => {
      const f = new Function(
        'baseObjectDef',
        'return baseObjectDef.' + a.key + ' = "' + a.value + '"',
      );
      try {
        f(baseThemeDefinition);
      } catch (e) {
        /* eslint no-console: ["error", { allow: ["warn"] }] */
        console.warn('warning in theme definition', e);
      }
    });
  }
  return baseThemeDefinition;
};

export default themeDefinition;
