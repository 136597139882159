import fetchUser from './fetchUser';
import postAuthToken from './postAuthToken';

/**
 * Login retrieves auth tokens and then fetches the user information.
 * @param {string} email - The user account email
 * @param {string} password - The user account password
 */
export const login = (email, password) => async dispatch => {
  const tokensAction = await dispatch(postAuthToken(email, password));
  if (tokensAction.error) {
    throw tokensAction.error;
  }
  const userAction = await dispatch(fetchUser());
  if (userAction.error) {
    throw userAction.error;
  }
  return userAction.profile;
};

export default login;
