import styled from 'styled-components';
import Absolute from '@rsos/components/capstone/base/Absolute';
import Box from '@rsos/components/capstone/base/Box';

export const Wrapper = styled(Box).attrs(({ sideBarOpen }) => ({
  bg: 'foreground',
  width: sideBarOpen ? '180px' : '52px',
}))`
  height: 100%;
  transition: 0.3s;
  position: relative;
`;

export const FooterContainer = styled(Absolute).attrs({
  bottom: 0,
})`
  padding: 20px;
  color: ${p => p.theme.colors.primaryBaseC2};
  width: 100%;
  cursor: pointer;
`;
