import { resolveThemeColors } from './themeDefinitions/index';

const baseColors = {
  white: 'rgba(255,255,255,1)',
  black: 'rgba(0,0,0,1)',
};

const colors = mode => {
  return {
    baseColors,
    ...resolveThemeColors(mode),
  };
};

export function getTheme(mode) {
  return {
    colors: colors(mode),
    fonts: {},
    styles: {},
  };
}
