import React from 'react';
import Box from '@rsos/base-components/Box';

import {
  HintsContainer,
  HintsWrap,
  HintsLabel,
  Ball,
} from './HintsWrapper.styles.js';

function HintsWrapper({ hints, value }) {
  if (!hints || !hints.length) return null;
  return (
    <HintsWrap>
      <HintsContainer>
        {hints.map(hint => {
          const { label, key, status } = hint;
          const displayedLabel =
            typeof label === 'function' ? label(value) : label;
          return (
            <Box position="relative" key={key} horizontal flow={5} noShrink>
              <Ball status={status} />
              <HintsLabel status={status}>{displayedLabel}</HintsLabel>
            </Box>
          );
        })}
      </HintsContainer>
    </HintsWrap>
  );
}
export default HintsWrapper;
