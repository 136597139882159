import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPermissionsByApp } from '@rsos/sinatra';

const useAccess = (capability, permission) => {
  const capabilities = useSelector(
    state => state.psaps.currentPSAP.activeCapabilities,
  );
  const permissions = useSelector(state =>
    selectUserPermissionsByApp(state.sinatra.user, 'capstone'),
  );

  const hasAccess = useMemo(() => {
    return capabilities?.[capability] && permissions?.includes(permission);
  }, [capability, permission, capabilities, permissions]);

  return hasAccess;
};

export default useAccess;
