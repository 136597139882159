/**
 * This file overrides the __webpack_public_path__ variable for Webpack's
 * runtime, allowing for the CDN host to be set at runtime.
 *
 * To use it, import it at the top of each FE application's index.js:
 *
 * `import '@rsos/capstone/src/overridePublicPath';`
 */

// The server injects the corresponding CDN URL - read it from the meta tag.

const meta = document.querySelector(`meta[name='cdn-host']`);
let CDNHost = '';
if (meta !== null) {
  CDNHost = meta.getAttribute('content');
}

const appName = process.env.REACT_APP_NAME;

let publicPath = CDNHost;
if (process.env.NODE_ENV === 'development') {
  // During development the CDN is stubbed to a different path.
  publicPath = `${CDNHost}/cdn`;
}

// Check if proxy-env is active, if so override the path to be relative.
const proxyMeta = document.querySelector(`meta[name='proxy-env']`);
if (proxyMeta) {
  publicPath = `/`;
}

// REACT_APP_PUBLIC_PATH serves as a global override option for situations
// where its needed (e.g: demos).
// In most cases, as long as CDN_HOST and MCO_API_HOST are set in
// portal_renderer everything should just work. Use this as a last resort.
const baseURL = process.env.REACT_APP_PUBLIC_PATH || publicPath;

// The webpack public path sets the base path for all assets.
let webpackPublicPath = `${baseURL}/${appName}/`;

if (proxyMeta) {
  webpackPublicPath = `/`;
}

// Set webpack runtime global!
// eslint-disable-next-line no-undef
__webpack_public_path__ = webpackPublicPath;

export default webpackPublicPath;
