import * as Yup from 'yup';
import * as validator from '@rsos/utils/validators';

const CONFIRM_PASSWORD_ERROR = 'Passwords do not match.';

export const validationSchema = Yup.object().shape({
  password: Yup.string().test('isValidPassword', '', function (value) {
    const { path, createError } = this;
    const result = validator.isValidPassword(value)
      ? true
      : createError({ path, message: '' });
    return result;
  }),
  confirmPassword: Yup.string().test(
    'isValidConfirmPassword',
    CONFIRM_PASSWORD_ERROR,
    function (value) {
      const { password } = this.parent;
      const { path, createError } = this;
      const result =
        password === value
          ? true
          : createError({ path, message: CONFIRM_PASSWORD_ERROR });
      return result;
    },
  ),
});

function containsNumber(str) {
  return /\d/.test(str);
}
function containsSpecialChar(str) {
  return /[!@#$%^&*\-_?]/.test(str);
}
function containsSpaces(str) {
  return !/\s/.test(str);
}
export const hints = [
  {
    key: 'minChars',
    label: `8 chars min`,
    check: v => v !== '' && v.length >= 8,
  },
  {
    key: 'maxChars',
    label: v => `256 chars max (${256 - v.length} more)`,
    check: v => v !== '' && v.length <= 256,
  },
  {
    key: 'upperLowerCase',
    label: `Use upper-case and lower-case letters`,
    check: v => /[a-z]/.test(v) && /[A-Z]/.test(v),
  },
  {
    key: 'number',
    label: `Use at least 1 numerical digit`,
    check: v => containsNumber(v),
  },
  {
    key: 'specialChar',
    label: 'Include at least 1 special character: ! @ # $ % & ? - _',
    check: v => containsSpecialChar(v),
  },
  {
    key: 'whiteSpaces',
    label: 'No spaces',
    check: v => containsSpaces(v),
  },
];
