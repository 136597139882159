/**
 * determine which page should be loaded for the Data page based on:
 * @param {string} application - application name, can be either capstone or central_station
 * @param {boolean} isJVenabled - if JV capability is enabled
 * @param {string} irpAccessToken - iRP token to indicate that the app is in iRP mode
 * @param {boolean} isLoggedIn - indicates user's authentication status
 * @returns string path
 */
import NS_SSO from '@rsos/constants/ns_sso';

const determineDataPath = (
  application = '',
  isJVenabled,
  irpAccessToken,
  isLoggedIn,
) => {
  if (!isLoggedIn) {
    return localStorage.getItem(NS_SSO) ? '/ns' : '/';
  }
  if (application === 'central_station' && !irpAccessToken) {
    return '/central-station';
  }

  if (application === 'central_station' && irpAccessToken) {
    return '/irp-central-station';
  }

  if (isJVenabled && !irpAccessToken) {
    return '/home';
  }

  if (isJVenabled && irpAccessToken) {
    return '/irp-home';
  }

  if (!isJVenabled && irpAccessToken) {
    return '/irp-query-interface';
  }
  return '/query-interface';
};

export default determineDataPath;
