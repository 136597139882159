import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 12,
  viewboxWidth: 12,
};

export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <g transform="matrix(0.5,0,0,0.5,0,0)">
      <g>
        <path d="M18.78,14.69,18,14.43c-.07,0-.17-.14-.24-.4a3.35,3.35,0,0,1,.08-1.95c.95-1,1.87-2.33,1.87-5.53,0-3.49-2.38-5-4.75-5a5.2,5.2,0,0,0-1.17.14.26.26,0,0,0-.18.17.26.26,0,0,0,0,.24,6.74,6.74,0,0,1,1.55,4.5c0,3.28-.92,5-2,6.23a.19.19,0,0,0,0,.13.76.76,0,0,0,0,.15.25.25,0,0,0,.16.25c2.41.86,6.14,2.2,6.14,7.19V22c0,.07,0,.15,0,.22a.25.25,0,0,0,.06.2.23.23,0,0,0,.18.08H23.5A.5.5,0,0,0,24,22V20.5C24,16.55,21.23,15.57,18.78,14.69Z"></path>
        <path d="M18,20.5c0-3.95-2.77-4.93-5.22-5.81L12,14.43c-.07,0-.17-.14-.24-.4a3.35,3.35,0,0,1,.08-1.95c.95-1,1.87-2.33,1.87-5.53,0-3.49-2.38-5-4.75-5S4.25,3.06,4.25,6.55c0,3.2.92,4.49,1.87,5.52a3.4,3.4,0,0,1,.08,2c-.07.26-.17.37-.24.4l-.76.27C2.76,15.56,0,16.54,0,20.5V22a.5.5,0,0,0,.5.5h17A.5.5,0,0,0,18,22Z"></path>
      </g>
    </g>
  </SVGLoader>
);
