import { createSlice } from '@reduxjs/toolkit';

/**
 * @isClickable - added to check against Google's addEventListenerOnce.
 * user can turn on selector tool then exit out without clicking on the map.
 * therefore, the event listener is still active.
 * because we're unable to remove a specific event on an instance,
 * this check here acts as a safeguard to prevent opening the EV map modal.
 */

const initialState = {
  coordinates: null,
  error: null,
  loading: false,
  isClickable: false,
};

const obliqueImagerySlice = createSlice({
  name: 'obliqueImagery',
  initialState: initialState,
  reducers: {
    setObliqueImageryCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    resetObliqueImageryCoordinates: state => {
      state.coordinates = initialState.coordinates;
    },
    setClickable: (state, action) => {
      state.isClickable = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase('FORCED_LOGOUT_SUCCESS', () => {
        return initialState;
      })
      .addCase('LOGOUT_SUCCESS', () => {
        return initialState;
      })
      .addCase('SINATRA_LOGOUT', () => {
        return initialState;
      });
  },
});

export const {
  setObliqueImageryCoordinates,
  resetObliqueImageryCoordinates,
  setClickable,
} = obliqueImagerySlice.actions;

export default obliqueImagerySlice.reducer;
