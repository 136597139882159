import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 17,
  viewboxHeight: 16,
};
const MarkerIcon = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <g>
        <path
          d="M8.832 0a5.333 5.333 0 0 0-5.333 5.333c0 2.34 3.333 8 4.766 10.347a.666.666 0 0 0 1.134 0c1.433-2.347 4.766-8.007 4.766-10.347A5.333 5.333 0 0 0 8.832 0Zm0 7.667a2.333 2.333 0 1 1 0-4.667 2.333 2.333 0 0 1 0 4.667Z"
          fill="currentColor"
        />
      </g>
    </SVGLoader>
  );
};

export default MarkerIcon;
