import React from 'react';
import Box from '../Box';
import Text from '../Text';
import { SwitchWrapper, Toggle } from './Switch.styles.js';

const Switch = ({
  isOn,
  onClick,
  disabled,
  onOffLabel,
  wrapperHeight,
  wrapperWidth,
  toggleHeight,
  toggleWidth,
  toggleBottom,
  toggleShadow,
  toggleLeft,
}) => {
  return (
    <Box horizontal flow={7} align="center">
      <SwitchWrapper {...{ wrapperHeight, wrapperWidth, disabled }}>
        <Toggle
          role='switch'
          {...{
            isOn,
            onClick,
            toggleHeight,
            toggleWidth,
            toggleBottom,
            toggleShadow,
            toggleLeft,
          }}
        />
      </SwitchWrapper>
      {onOffLabel && (
        <Text size="small" color={isOn ? 'success' : 'secondaryText'}>
          {isOn ? 'On' : 'Off'}
        </Text>
      )}
    </Box>
  );
};

export default Switch;
