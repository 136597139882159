export const SCORPIUS_KEY = '@scorpius/tokens';
export const PRODUCT_TOKEN = 'productToken';

/**
 * NOTE When introducing actions, make sure SINATRA_ is used as a prefix
 * to prevent collisions with any actions that might also be used within
 * the end-user application.
 * e.g:
 * FETCH_USERS_ can mean an app expects to fetch its own users vs sinatra users
 */

export const POST_AUTH_TOKEN_START = 'SINATRA_POST_AUTH_TOKEN_START';
export const POST_AUTH_TOKEN_SUCCESS = 'SINATRA_POST_AUTH_TOKEN_SUCCESS';
export const POST_AUTH_TOKEN_ERROR = 'SINATRA_POST_AUTH_TOKEN_ERROR';

export const PROVIDER_LOGIN_START = 'SINATRA_PROVIDER_LOGIN_START';
export const PROVIDER_LOGIN_SUCCESS = 'SINATRA_PROVIDER_LOGIN_SUCCESS';
export const PROVIDER_LOGIN_ERROR = 'SINATRA_PROVIDER_LOGIN_ERROR';

export const SESSION_START = 'SINATRA_SESSION_START';
export const UPDATE_SESSION = 'SINATRA_UPDATE_SESSION';
export const SESSION_END = 'SINATRA_SESSION_END';

export const USER_AUTH_API_TOKEN_START = 'SINATRA_USER_AUTH_API_TOKEN_START';
export const USER_AUTH_API_TOKEN_SUCCESS =
  'SINATRA_USER_AUTH_API_TOKEN_SUCCESS';
export const USER_AUTH_API_TOKEN_ERROR = 'SINATRA_USER_AUTH_API_TOKEN_ERROR';

export const SCORPIUS_LOGOUT_START = 'SINATRA_SCORPIUS_LOGOUT_START';
export const SCORPIUS_LOGOUT_SUCCESS = 'SINATRA_SCORPIUS_LOGOUT_SUCCESS';
export const SCORPIUS_LOGOUT_ERROR = 'SINATRA_SCORPIUS_LOGOUT_ERROR';
export const LOGOUT = 'SINATRA_LOGOUT';

export const FETCH_USER_START = 'SINATRA_FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'SINATRA_FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'SINATRA_FETCH_USER_ERROR';
export const RESET_NEW_DATASOURCE = 'RESET_NEW_DATASOURCE';

export const REGISTER_USER_START = 'SINATRA_REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'SINATRA_REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'SINATRA_REGISTER_USER_ERROR';

export const CONFIRM_REQUEST_START = 'SINATRA_CONFIRM_REQUEST_START';
export const CONFIRM_REQUEST_SUCCESS = 'SINATRA_CONFIRM_REQUEST_SUCCESS';
export const CONFIRM_REQUEST_ERROR = 'SINATRA_CONFIRM_REQUEST_ERROR';

export const CONFIRM_USER_START = 'SINATRA_CONFIRM_USER_START';
export const CONFIRM_USER_SUCCESS = 'SINATRA_CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_ERROR = 'SINATRA_CONFIRM_USER_ERROR';

export const REQUEST_RESET_PASSWORD_START =
  'SINATRA_REQUEST_RESET_PASSWORD_START';
export const REQUEST_RESET_PASSWORD_SUCCESS =
  'SINATRA_REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_ERROR =
  'SINATRA_REQUEST_RESET_PASSWORD_ERROR';

export const SET_USER_PASSWORD_START = 'SINATRA_SET_USER_PASSWORD_START';
export const SET_USER_PASSWORD_SUCCESS = 'SINATRA_SET_USER_PASSWORD_SUCCESS';
export const SET_USER_PASSWORD_ERROR = 'SINATRA_SET_USER_PASSWORD_ERROR';

export const CHANGE_USER_PASSWORD_START = 'SINATRA_CHANGE_USER_PASSWORD_START';
export const CHANGE_USER_PASSWORD_SUCCESS =
  'SINATRA_CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'SINATRA_CHANGE_USER_PASSWORD_ERROR';

export const REFRESH_USER_TOKEN_START = 'SINATRA_REFRESH_USER_TOKEN_START';
export const REFRESH_USER_TOKEN_SUCCESS = 'SINATRA_REFRESH_USER_TOKEN_SUCCESS';
export const REFRESH_USER_TOKEN_ERROR = 'SINATRA_REFRESH_USER_TOKEN_ERROR';

export const FETCH_ORG_TOKEN_START = 'SINATRA_FETCH_ORG_TOKEN_START';
export const FETCH_ORG_TOKEN_SUCCESS = 'SINATRA_FETCH_ORG_TOKEN_SUCCESS';
export const FETCH_ORG_TOKEN_ERROR = 'SINATRA_FETCH_ORG_TOKEN_ERROR';

/* User Management by App */

export const FETCH_APP_USERS_START = 'SINATRA_FETCH_APP_USERS_START';
export const FETCH_APP_USERS_SUCCESS = 'SINATRA_FETCH_APP_USERS_SUCCESS';
export const FETCH_APP_USERS_ERROR = 'SINATRA_FETCH_APP_USERS_ERROR';

export const CREATE_APP_USER_START = 'SINATRA_CREATE_APP_USER_START';
export const CREATE_APP_USER_SUCCESS = 'SINATRA_CREATE_APP_USER_SUCCESS';
export const CREATE_APP_USER_ERROR = 'SINATRA_CREATE_APP_USER_ERROR';

export const UPDATE_APP_USER_START = 'SINATRA_UPDATE_APP_USER_START';
export const UPDATE_APP_USER_SUCCESS = 'SINATRA_UPDATE_APP_USER_SUCCESS';
export const UPDATE_APP_USER_ERROR = 'SINATRA_UPDATE_APP_USER_ERROR';

export const DELETE_APP_USER_START = 'SINATRA_DELETE_APP_USER_START';
export const DELETE_APP_USER_SUCCESS = 'SINATRA_DELETE_APP_USER_SUCCESS';
export const DELETE_APP_USER_ERROR = 'SINATRA_DELETE_APP_USER_ERROR';

/* Role Management by App */

export const FETCH_APP_ROLES_START = 'SINATRA_FETCH_APP_ROLES_START';
export const FETCH_APP_ROLES_SUCCESS = 'SINATRA_FETCH_APP_ROLES_SUCCESS';
export const FETCH_APP_ROLES_ERROR = 'SINATRA_FETCH_APP_ROLES_ERROR';

export const CREATE_APP_ROLE_START = 'SINATRA_CREATE_APP_ROLE_START';
export const CREATE_APP_ROLE_SUCCESS = 'SINATRA_CREATE_APP_ROLE_SUCCESS';
export const CREATE_APP_ROLE_ERROR = 'SINATRA_CREATE_APP_ROLE_ERROR';

export const UPDATE_APP_ROLE_START = 'SINATRA_UPDATE_APP_ROLE_START';
export const UPDATE_APP_ROLE_SUCCESS = 'SINATRA_UPDATE_APP_ROLE_SUCCESS';
export const UPDATE_APP_ROLE_ERROR = 'SINATRA_UPDATE_APP_ROLE_ERROR';

export const DELETE_APP_ROLE_START = 'SINATRA_DELETE_APP_ROLE_START';
export const DELETE_APP_ROLE_SUCCESS = 'SINATRA_DELETE_APP_ROLE_SUCCESS';
export const DELETE_APP_ROLE_ERROR = 'SINATRA_DELETE_APP_ROLE_ERROR';

export const ASSIGN_PERMISSIONS_TO_ROLES_START =
  'SINATRA_ASSIGN_PERMISSIONS_TO_ROLES_START';
export const ASSIGN_PERMISSIONS_TO_ROLES_SUCCESS =
  'SINATRA_ASSIGN_PERMISSIONS_TO_ROLES_SUCCESS';
export const ASSIGN_PERMISSIONS_TO_ROLES_ERROR =
  'SINATRA_ASSIGN_PERMISSIONS_TO_ROLES_ERROR';

/* Role Permissions */
export const FETCH_ALL_PERMISSIONS_START =
  'SINATRA_FETCH_ALL_PERMISSIONS_START';
export const FETCH_ALL_PERMISSIONS_SUCCESS =
  'SINATRA_FETCH_ALL_PERMISSIONS_SUCCESS';
export const FETCH_ALL_PERMISSIONS_ERROR =
  'SINATRA_FETCH_ALL_PERMISSIONS_ERROR';

export const UPDATE_PERMISSION_ROLES_START =
  'SINATRA_UPDATE_PERMISSION_ROLES_START';
export const UPDATE_PERMISSION_ROLES_SUCCESS =
  'SINATRA_UPDATE_PERMISSION_ROLES_SUCCESS';
export const UPDATE_PERMISSION_ROLES_ERROR =
  'SINATRA_UPDATE_PERMISSION_ROLES_ERROR';

/* SAML */

export const REQUEST_SAML_START = 'REQUEST_SAML_START';
export const REQUEST_SAML_SUCCESS = 'REQUEST_SAML_SUCCESS';
export const REQUEST_SAML_ERROR = 'REQUEST_SAML_ERROR';

/* IP Management*/
export const FETCH_APP_CIDRS_START = 'SINATRA_FETCH_APP_CIDRS_START';
export const FETCH_APP_CIDRS_SUCCESS = 'SINATRA_FETCH_APP_CIDRS_SUCCESS';
export const FETCH_APP_CIDRS_ERROR = 'SINATRA_FETCH_APP_CIDRS_ERROR';
export const CREATE_APP_CIDR_START = 'SINATRA_CREATE_APP_CIDR_START';
export const CREATE_APP_CIDR_SUCCESS = 'SINATRA_CREATE_APP_CIDR_SUCCESS';
export const CREATE_APP_CIDR_ERROR = 'SINATRA_CREATE_APP_CIDR_ERROR';
export const UPDATE_APP_CIDR_START = 'SINATRA_UPDATE_APP_CIDR_START';
export const UPDATE_APP_CIDR_SUCCESS = 'SINATRA_UPDATE_APP_CIDR_SUCCESS';
export const UPDATE_APP_CIDR_ERROR = 'SINATRA_UPDATE_APP_CIDR_ERROR';
export const DELETE_APP_CIDR_START = 'SINATRA_DELETE_APP_CIDR_START';
export const DELETE_APP_CIDR_SUCCESS = 'SINATRA_DELETE_APP_CIDR_SUCCESS';
export const DELETE_APP_CIDR_ERROR = 'SINATRA_DELETE_APP_CIDR_ERROR';

/* Audio */
export const SET_AUDIO_VOLUME = 'SET_AUDIO_VOLUME';

export const DECRYPT_IRP_TOKEN_START = 'DECRYPT_IRP_TOKEN_START';
export const DECRYPT_IRP_TOKEN_SUCCESS = 'DECRYPT_IRP_TOKEN_SUCCESS';
export const DECRYPT_IRP_TOKEN_ERROR = 'DECRYPT_IRP_TOKEN_ERROR';
export const SET_IRP_VERSION = 'SET_IRP_VERSION';

/* Themes */
export const SELECT_THEME = 'SELECT_THEME';

/* Test Mode */
export const SET_TEST_MODE = 'SET_TEST_MODE';

export const FETCH_USER_ATTRIBUTES_START =
  'SINATRA_FETCH_USER_ATTRIBUTES_START';
export const FETCH_USER_ATTRIBUTES_SUCCESS =
  'SINATRA_FETCH_USER_ATTRIBUTES_SUCCESS';
export const FETCH_USER_ATTRIBUTES_ERROR =
  'SINATRA_FETCH_USER_ATTRIBUTES_ERROR';

export const UPDATE_USER_ATTRIBUTES_START =
  'SINATRA_UPDATE_USER_ATTRIBUTES_START';
export const UPDATE_USER_ATTRIBUTES_SUCCESS =
  'SINATRA_UPDATE_USER_ATTRIBUTES_SUCCESS';
export const UPDATE_USER_ATTRIBUTES_ERROR =
  'SINATRA_UPDATE_USER_ATTRIBUTES_ERROR';

export const UPDATE_ORG_ATTRIBUTES_START =
  'SINATRA_UPDATE_ORG_ATTRIBUTES_START';
export const UPDATE_ORG_ATTRIBUTES_SUCCESS =
  'SINATRA_UPDATE_ORG_ATTRIBUTES_SUCCESS';
export const UPDATE_ORG_ATTRIBUTES_ERROR =
  'SINATRA_UPDATE_ORG_ATTRIBUTES_ERROR';

export const PATCH_ORG_ATTRIBUTES_START = 'SINATRA_PATCH_ORG_ATTRIBUTES_START';
export const PATCH_ORG_ATTRIBUTES_SUCCESS =
  'SINATRA_PATCH_ORG_ATTRIBUTES_SUCCESS';
export const PATCH_ORG_ATTRIBUTES_ERROR = 'SINATRA_PATCH_ORG_ATTRIBUTES_ERROR';

// Basemap menu
export const SET_MAP_PROVIDER = 'SET_MAP_PROVIDER';
export const SET_MAP_ADDON = 'SET_MAP_ADDON';
export const SET_BASEMAP = 'SET_BASEMAP';
export const SET_IS_BASEMAP_VISIBLE = 'SET_IS_BASEMAP_VISIBLE';

// Initialize app
export const APP_INIT_START = 'APP_INIT_START';
