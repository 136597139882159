import React from 'react';
import {
  ExternalLinkIcon,
  UsersIconFilled,
  ArrowForwardIcon,
} from '@rsos/assets/icons';
import { Box } from '@rsos/components/capstone/base';

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const handleAppData = (
  dataFields,
  selectedCallerID,
  mostRecentLocation,
) => {
  const data = {};
  if (selectedCallerID) {
    if (dataFields && dataFields.includes('query')) {
      data.query = `tel://${selectedCallerID}`;
    }
    if (
      dataFields &&
      dataFields.includes('incident_location') &&
      mostRecentLocation
    ) {
      const {
        latitude,
        longitude,
        location_time,
        uncertainty_radius,
      } = mostRecentLocation;

      data.incident_location = {
        latitude,
        longitude,
        location_time,
        uncertainty_radius,
      };
    }
  }
  return Object.keys(data).length ? data : null;
};

export const ensureMinDisplayTime = start => {
  const finish = Date.now();
  if (Math.floor((finish - start) / 1000) < 2) {
    return delay(1000);
  }
};

export const resolveAppIcon = app => {
  const { type } = app;
  const iconByAppType = {
    app_launch: () => <ExternalLinkIcon height={14} width={14} />,
    data_share: () => (
      <Box horizontal flow={2}>
        <ArrowForwardIcon height={12} width={12} />
        <UsersIconFilled height={12} width={12} />
      </Box>
    ),
  };
  return iconByAppType[type] ? iconByAppType[type]() : null;
};
