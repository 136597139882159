import React from 'react';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import { withTheme } from 'styled-components';
import {
  customGenericStyles,
  customMultiSelectStyles,
  Placeholder,
  DropdownIndicator,
  NoOptionsMessage,
} from './Select.styles';

const customComponents = {
  Placeholder,
  DropdownIndicator,
  NoOptionsMessage,
};

const Select = ({
  async,
  components,
  styles,
  theme,
  isMulti,
  isDisabled,
  isClearable = true,
  isSearchable = true,
  ...props
}) => {
  const SelectComponent = async ? AsyncReactSelect : ReactSelect;
  const customMultiStyles = isMulti && customMultiSelectStyles;
  return (
    <SelectComponent
      styles={{ ...customGenericStyles, ...styles, ...customMultiStyles }}
      components={{ ...customComponents, ...components }}
      theme={theme}
      isMulti={isMulti}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      {...props}
    />
  );
};

export default withTheme(Select);
