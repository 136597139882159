import React from 'react';
import styled from 'styled-components';
import Box from '@rsos/base-components/Box';
import DropdownClose from './DropdownClose';

const DropdownHeaderContainer = styled(Box).attrs(({ padding }) => ({
  horizontal: true,
  align: 'center',
  justify: 'space-between',
  p: padding ? padding : '11px 16px',
}))`
  border-bottom: ${p => `1px solid ${p.theme.colors.heavyLineOutline}`};
`;

const DropdownHeader = ({ children, onClose, ...rest }) => (
  <DropdownHeaderContainer {...rest}>
    {children}
    {onClose && <DropdownClose onClose={onClose} />}
  </DropdownHeaderContainer>
);

export default DropdownHeader;
