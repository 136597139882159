const REQUEST_ERROR_MESSAGE =
  'Error: Something went wrong with your request. If this error persists, submit a ticket via the "Support" tab.';

/**
 * Serializes a http request error object.
 * @param {Object} error - An unserialized error object.
 * @returns {Object|String} - A serialized object or string.
 */
const serializeRequestError = async error => {
  let errorDetail;

  if (error?.ok === false) {
    const serializedError = await error?.json();
    errorDetail = serializedError?.detail;
  }

  return errorDetail || REQUEST_ERROR_MESSAGE;
};

export default serializeRequestError;
