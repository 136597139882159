import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 12,
  viewboxWidth: 12,
};

export default ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    width={width}
    height={height}
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path d="M8.34803 7.78093C8.0714 7.78093 7.80609 7.89083 7.61048 8.08644C7.41487 8.28205 7.30498 8.54735 7.30498 8.82399C7.30583 8.86461 7.29678 8.90484 7.27861 8.94118C7.26043 8.97753 7.23369 9.0089 7.20067 9.0326L6.99206 9.18906C6.97164 9.20149 6.94818 9.20807 6.92426 9.20807C6.90035 9.20807 6.87689 9.20149 6.85647 9.18906C6.8337 9.17865 6.81457 9.16167 6.80155 9.14028C6.78854 9.1189 6.78223 9.09411 6.78345 9.0691V7.78093C6.78372 7.74424 6.77592 7.70793 6.7606 7.67458C6.74528 7.64124 6.72281 7.61167 6.69479 7.58797C6.66728 7.56344 6.63505 7.54477 6.60008 7.53312C6.56511 7.52146 6.52813 7.51705 6.4914 7.52017C6.33056 7.54098 6.16855 7.55143 6.00638 7.55146C5.84073 7.55143 5.67526 7.54098 5.51093 7.52017C5.47454 7.51604 5.4377 7.51946 5.4027 7.53023C5.3677 7.541 5.3353 7.55888 5.30753 7.58275C5.2799 7.6079 5.25776 7.63848 5.2425 7.67258C5.22725 7.70669 5.2192 7.74357 5.21887 7.78093V11.6924C5.21887 11.8999 5.30129 12.0988 5.448 12.2455C5.59471 12.3923 5.79369 12.4747 6.00116 12.4747C6.20864 12.4747 6.40762 12.3923 6.55433 12.2455C6.70103 12.0988 6.78345 11.8999 6.78345 11.6924V10.7745C6.78385 10.7341 6.79345 10.6943 6.81152 10.6581C6.82959 10.622 6.85567 10.5904 6.88776 10.5659L7.82651 9.86704C7.8594 9.84378 7.89699 9.828 7.93663 9.82079C7.97626 9.81358 8.017 9.81513 8.05598 9.82532C8.15046 9.85479 8.24907 9.86888 8.34803 9.86704C8.62467 9.86704 8.88997 9.75715 9.08558 9.56154C9.28119 9.36593 9.39109 9.10062 9.39109 8.82399C9.39109 8.54735 9.28119 8.28205 9.08558 8.08644C8.88997 7.89083 8.62467 7.78093 8.34803 7.78093Z" />
    <path d="M9.55795 2.63339C9.49449 2.64629 9.42851 2.63462 9.37333 2.60073C9.31814 2.56685 9.27788 2.51328 9.26068 2.45086C9.08257 1.83307 8.73244 1.27867 8.25115 0.852349C7.76986 0.426031 7.17725 0.145378 6.54248 0.0431365C5.90771 -0.0591049 5.25695 0.0212824 4.66614 0.274918C4.07533 0.528554 3.56884 0.944979 3.20575 1.4756C3.17616 1.5119 3.13716 1.53937 3.09301 1.555C3.04886 1.57064 3.00126 1.57384 2.95542 1.56426C2.53755 1.50803 2.11228 1.55396 1.71605 1.69812C1.31983 1.84228 0.964471 2.08037 0.68044 2.39199C0.396408 2.7036 0.192176 3.07944 0.0852477 3.48729C-0.0216804 3.89515 -0.0281144 4.32284 0.0664969 4.73373C0.161108 5.14461 0.353943 5.52643 0.628472 5.84644C0.903002 6.16646 1.25104 6.41513 1.64275 6.57115C2.03445 6.72716 2.45815 6.78587 2.87753 6.74223C3.2969 6.69859 3.69943 6.55391 4.05063 6.32058C4.09228 6.29526 4.14008 6.28186 4.18883 6.28186C4.23758 6.28186 4.28539 6.29526 4.32704 6.32058C4.90586 6.65303 5.57051 6.80576 6.2364 6.75932C6.90228 6.71289 7.5393 6.46939 8.06638 6.05982C8.10856 6.02171 8.16339 6.00061 8.22023 6.00061C8.27708 6.00061 8.33191 6.02171 8.37408 6.05982C8.61843 6.32715 8.92816 6.52626 9.2728 6.63756C9.61745 6.74886 9.98513 6.76851 10.3397 6.69459C10.6942 6.62067 11.0234 6.45572 11.2949 6.21597C11.5663 5.97622 11.7707 5.66992 11.8878 5.32723C12.005 4.98453 12.0309 4.61723 11.963 4.26148C11.8952 3.90573 11.7359 3.57377 11.5008 3.29827C11.2657 3.02277 10.9629 2.81322 10.6223 2.69024C10.2816 2.56726 9.91481 2.53509 9.55795 2.59688V2.63339Z" />
  </SVGLoader>
);
