import { DEFAULT_THEME } from '@rsos/theme/defaultTheme';

const pyBySize = {
  large: 10,
  med: 10,
  small: 10,
  xsmall: 10,
  xxsmall: 10,
};

export function getPyBySize({ size }) {
  return (size && pyBySize[size]) || 10;
}

const pxBySize = {
  large: 20,
  med: 20,
  small: 10,
  xsmall: 10,
  xxsmall: 10,
};

export function getPxBySize({ size }) {
  return (size && pxBySize[size]) || 20;
}

const heightBySize = {
  large: 40,
  med: 35,
  small: 30,
  xsmall: 25,
  xxsmall: 20,
};

export function getHeightBySize({ size }) {
  return (size && heightBySize[size]) || 35;
}

const fontSizeBySize = {
  large: 14,
  med: 12,
  small: 12,
  xsmall: 10,
  xxsmall: 10,
};

export function getFontSizeBySize({ size }) {
  return (size && fontSizeBySize[size]) || 12;
}
const loaderBySize = {
  large: 20,
  med: 20,
  small: 15,
  xsmall: 15,
  xxsmall: 15,
};

export function getLoaderBySize({ size }) {
  return (size && loaderBySize[size]) || 20;
}

export function getLoaderByColor(props) {
  return fetchColor(props, 'text');
}

const fetchColor = (p, prop) => {
  const { btnType, selected } = p;
  const theme = p.theme && p.theme.colors ? p.theme : DEFAULT_THEME;
  return selected && theme.colors.buttons[btnType]['selected']
    ? theme.colors.buttons[btnType]['selected'][prop]
    : theme.colors.buttons[btnType][prop];
};

export function getStylesByState(p, state) {
  const stateMapper = {
    default: p => `
        background: ${fetchColor(p, 'bg')};
        color: ${fetchColor(p, 'text')};
        border: 1px solid ${fetchColor(p, 'borderColor')};
      `,
    hover: p => `
        background: ${fetchColor(p, 'hover')};
      `,
    focus: () => `
        box-shadow: 0px 0px 6px 0px #24b3f3;
      `,
    selected: () => `
      background: ${fetchColor(p, 'bg')};

      `,
  };
  return stateMapper[state] || stateMapper['default'];
}
