import React, { useEffect } from 'react';
import { notifyInfo } from '@rsos/utils/toastUtils';
import { Tile } from '../capstoneLegacy';

const Review = () => {
  useEffect(() => {
    notifyInfo('Your account has been successfully created.');
  }, []);

  return (
    <div id="review-container" className="review-container">
      <div className="review-wrapper bubble-blue-bg">
        <Tile parentClass="columns is-centered review-confirmation-wrapper">
          <div className="is-child">
            <div className="column custom-page-title">
              Please Confirm Your Email!
            </div>
            <div className="column review-detail">
              <p>
                Thank you for signing up for RapidSOS Portal! Please check your
                email for an email with the subject line:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  RapidSOS Portal Email Confirmation & Next Steps.
                </span>
              </p>
              <p>
                To ensure your account isn{'\u2019'}t held up, please confirm
                your email address using the link in this email.
              </p>
            </div>
          </div>
        </Tile>
        <div className="review-image-wrapper" />
      </div>
    </div>
  );
};

export default Review;
