import {
  FETCH_ALL_PERMISSIONS_START,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_PERMISSIONS_ERROR,
  UPDATE_PERMISSION_ROLES_START,
  UPDATE_PERMISSION_ROLES_SUCCESS,
  UPDATE_PERMISSION_ROLES_ERROR,
} from '../../actions/types';

export const initialState = {
  records: [],
  loading: false,
  errors: {
    loading: null,
  },
};
const updatePermission = (records, permission) => {
  return records.map(record => {
    if (record.name === permission.name) {
      return {
        ...record,
        ...permission,
      };
    }
    return record;
  });
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PERMISSIONS_START:
      return {
        ...state,
        loading: true,
        errors: {
          ...state.errors,
          loading: null,
        },
      };
    case FETCH_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.permissions,
      };
    case FETCH_ALL_PERMISSIONS_ERROR:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          loading: action.error,
        },
      };
    case UPDATE_PERMISSION_ROLES_START:
      return {
        ...state,
        loading: true,
        errors: {
          ...state.errors,
          loading: null,
        },
      };
    case UPDATE_PERMISSION_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        records: updatePermission(state.records, action.permission),
      };

    case UPDATE_PERMISSION_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          loading: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default permissionsReducer;
