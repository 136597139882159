import styled from 'styled-components';
import { Box, Text } from '@rsos/components/capstone/base';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import {
  linerRapidSOSGradient,
  BP_DESKTOP_L,
  BP_DESKTOP_S,
  WIDTH_DESKTOP_XL,
  WIDTH_DESKTOP_L,
  WIDTH_DESKTOP,
  WIDTH_DESKTOP_S,
} from '../../../constants/cssConstants';
import { calcLeft } from '../helpers';

export const FooterWrapper = styled(Box)`
  height: 293px;
  flex-shrink: 0;
  position: relative;
  background: ${linerRapidSOSGradient};
  @media (max-width: ${BP_LAPTOP}px) {
    height: 383px;
  }
`;

export const FooterContentWrapper = styled(Box).attrs({
  height: '100%',
  px: 116,
  py: 80,
  width: WIDTH_DESKTOP_XL,
})`
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: absolute;
  left: ${calcLeft(WIDTH_DESKTOP_XL)};

  @media (max-width: ${WIDTH_DESKTOP_XL}px) {
    grid-template-columns: 1fr 2fr;
    left: ${calcLeft(WIDTH_DESKTOP_XL)};
  }
  @media (max-width: ${BP_DESKTOP_L}px) {
    width: ${WIDTH_DESKTOP_L}px;
    left: ${calcLeft(WIDTH_DESKTOP_L)};
  }
  @media (max-width: ${WIDTH_DESKTOP}px) {
    width: ${WIDTH_DESKTOP}px;
    left: ${calcLeft(WIDTH_DESKTOP)};
    padding: 80px 87px;
  }
  @media (max-width: ${BP_DESKTOP_S}px) {
    width: ${WIDTH_DESKTOP_S}px;
    left: ${calcLeft(WIDTH_DESKTOP_S)};
  }
  @media (max-width: ${BP_LAPTOP}px) {
    grid-template-columns: 1fr 1fr;
    padding: 80px 34px;
    left: 0;
    width: 100%;
  }
`;

export const InfoLinksWrapper = styled(Box).attrs({
  height: '100%',
})`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 45px;
  @media (max-width: ${BP_LAPTOP}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoLinkCategoryName = styled(Text).attrs({
  size: 'large',
  fontWeight: 'bold',
  color: 'background',
  mb: 15,
})`
  font-family: 'Soleil';
`;
