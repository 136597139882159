import React, { useState } from 'react';
import useHasLocationsToRender from 'emergency_data/src/hooks/useHasLocationsToRender';
import PropTypes from 'prop-types';
import { MarkerIcon } from '@rsos/assets/icons';
import { Box, Text } from '@rsos/components/capstone/base';
import {
  DropdownBody,
  DropdownListItem,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import {
  DropdownDialog,
  DropdownHeader,
  DropdownHeaderText,
  DropdownList,
  ListItemRow,
  ListItemCTA,
  ListItemDisplayName,
  AppListPane,
  AppDetailsPane,
  AppDetailsCTA,
  AppDetailsDisplayName,
  AppDetailsDescription,
  AppDetailsRequiredInfo,
} from './AppLauncher.styles';
import { applicationPropTypes } from './propTypes';
import { resolveAppIcon } from './utils';

/**
 * Sub-component of AppLauncher that renders the app/tools launcher dropdown menu component.
 * This is displayed when there is no selected call.
 * @param {Array} applications - List of app/tools launcher apps
 * @param {Function} onClose - Toggles the visibility of the app/tools launcher dropdown
 * @param {Function} onHandleApp - Function that gets called when an app/tools is selected
 */
const AppLauncherDropdown = ({ applications, onClose, onHandleApp }) => {
  const hasLocationsToRender = useHasLocationsToRender();

  const [appCTA, setAppCTA] = useState(applications?.[0]?.call_to_action || '');
  const [appDisplayName, setAppDisplayName] = useState(
    applications?.[0]?.display_name_short || '',
  );
  const [appDescription, setAppDescription] = useState(
    applications?.[0]?.description || '',
  );

  // If a partner application can be launched in either all calls view and call details view, both
  // `launch_global` and `launch_call` can be configured as true. the required information blurb
  // should only be displayed if `launch_global` is false and `launch_call` is true.
  const [displayRequiredInfo, setDisplayRequiredInfo] = useState(
    (!applications?.[0]?.details?.launch_global &&
      applications?.[0]?.details?.launch_call) ||
      false,
  );

  const handleDetails = app => {
    const {
      call_to_action,
      display_name_short,
      description,
      details: { launch_global, launch_call },
    } = app;
    setAppCTA(call_to_action);
    setAppDisplayName(display_name_short);
    setAppDescription(description);
    setDisplayRequiredInfo(!launch_global && launch_call);
  };

  /**
   * Determines if an application is disabled.
   * - If `launchGlobal` is set to true, then it can be launched in all calls view.
   * - If `launchCall` is also set to true, a call must be selected and must have a location so
   * that it can be launched in call details view.
   *
   * @param {Boolean} launchGlobal - can the application launch in all calls view
   * @param {Boolean} launchCall - can the application launch in call details view
   * @returns boolean
   */
  const handleDisabled = (launchGlobal, launchCall) =>
    launchGlobal ? false : launchCall && !hasLocationsToRender;

  /**
   * Determines if an application can be launched in all calls view and/or call details view.
   *
   * @param {Object} app - application in app/tools launcher
   */
  const handleClick = app => {
    const launchGlobal = app.details?.launch_global;
    const launchCall = app.details?.launch_call;
    const canBeLaunched = launchGlobal
      ? true
      : launchCall && hasLocationsToRender;

    if (canBeLaunched) {
      onHandleApp(app, 'tools menu');
      onClose();
    }
  };

  return (
    <DropdownDialog>
      <DropdownHeader onClose={onClose}>
        <DropdownHeaderText>
          Tools {applications && `(${applications.length})`}
        </DropdownHeaderText>
      </DropdownHeader>
      <DropdownBody horizontal>
        <AppListPane>
          <DropdownList>
            {applications.map(app => (
              <DropdownListItem
                key={app.id}
                noRadius
                disabled={handleDisabled(
                  app.details?.launch_global,
                  app.details?.launch_call,
                )}
                onClick={() => handleClick(app)}
                onMouseEnter={() => handleDetails(app)}
              >
                <ListItemRow>
                  <Box>
                    <ListItemCTA>{app.call_to_action}</ListItemCTA>
                    <ListItemDisplayName>
                      {app.display_name_short}
                    </ListItemDisplayName>
                  </Box>
                  {resolveAppIcon(app)}
                </ListItemRow>
              </DropdownListItem>
            ))}
          </DropdownList>
        </AppListPane>
        <AppDetailsPane>
          <Box flow={2}>
            <AppDetailsCTA>{appCTA}</AppDetailsCTA>
            <AppDetailsDisplayName>{appDisplayName}</AppDetailsDisplayName>
          </Box>
          <AppDetailsDescription
            dangerouslySetInnerHTML={{
              __html: appDescription,
            }}
          />
          {displayRequiredInfo && (
            <AppDetailsRequiredInfo>
              <MarkerIcon color="success" width={13} height={12} />
              <AppDetailsDescription ml="5px">
                Tool only available in an opened{' '}
                <Text color="success" inline>
                  911 event
                </Text>
                .
              </AppDetailsDescription>
            </AppDetailsRequiredInfo>
          )}
        </AppDetailsPane>
      </DropdownBody>
    </DropdownDialog>
  );
};

AppLauncherDropdown.propTypes = {
  applications: applicationPropTypes.isRequired,
  onClose: PropTypes.func.isRequired,
  onHandleApp: PropTypes.func.isRequired,
};

export default AppLauncherDropdown;
