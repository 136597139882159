import styled from 'styled-components';
import { Box, Absolute } from '@rsos/components/capstone/base';

export const Circle = styled(Absolute)`
  right: 2px;
  top: 2px;
  width: 9px;
  height: 9px;
  background-color: ${({ theme }) => theme.colors.background};
  border: ${({ theme }) => `1px solid ${theme.colors.primaryBase}`};
  content: '';
  border-radius: 50%;
  z-index: 1;
`;

export const StyledNavigation = styled(Box).attrs({
  bg: 'background',
})`
  height: 52px;
  padding: 0 12px;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  z-index: ${({ isLanding }) => (isLanding ? 5 : 1)};
`;

export const Logo = styled.img`
  max-height: 1.75rem;
  display: flex;
`;
