import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BASEMAP_ESRI_NAVIGATION_DARK,
  BASEMAP_ESRI_NAVIGATION_LIGHT,
  BASEMAP_ESRI_SATELLITE,
  BASEMAP_GOOGLE_HYBRID,
  BASEMAP_GOOGLE_ROADMAP,
} from '@rsos/constants/basemapAddonNames';
import useSelectedTheme from '@rsos/hooks/useSelectedTheme';
import { ESRI, GOOGLE } from '@rsos/maps/constants/mapProviders';
import { setBasemap } from '@rsos/sinatra';
import { LIGHT_THEME } from '@rsos/theme/constants';

const useToggleBasemap = shouldToggleBasemap => {
  const dispatch = useDispatch();

  const mapProvider = useSelector(state => state.sinatra.ui.mapProvider);

  const basemap = useSelector(state => state.sinatra.ui.basemap);

  const isSatelliteBasemap = useMemo(() => {
    return (
      basemap === BASEMAP_ESRI_SATELLITE || basemap === BASEMAP_GOOGLE_HYBRID
    );
  }, [basemap]);

  const themeName = useSelectedTheme();

  const [newBasemap, setNewBasemap] = useState('');

  useEffect(() => {
    let basemapToSwitchTo = '';

    if (shouldToggleBasemap && mapProvider && !isSatelliteBasemap) {
      if (mapProvider === ESRI) {
        basemapToSwitchTo =
          themeName === LIGHT_THEME
            ? BASEMAP_ESRI_NAVIGATION_LIGHT
            : BASEMAP_ESRI_NAVIGATION_DARK;
      } else if (mapProvider === GOOGLE) {
        basemapToSwitchTo = BASEMAP_GOOGLE_ROADMAP;
      }

      dispatch(setBasemap(basemapToSwitchTo));

      setNewBasemap(basemapToSwitchTo);
    }
  }, [
    dispatch,
    isSatelliteBasemap,
    mapProvider,
    shouldToggleBasemap,
    themeName,
  ]);

  return newBasemap;
};

export default useToggleBasemap;
