import {
  SELECT_THEME,
  SET_AUDIO_VOLUME,
  SET_MAP_ADDON,
  SET_MAP_PROVIDER,
  SET_BASEMAP,
  SET_IS_BASEMAP_VISIBLE,
  SET_TEST_MODE,
} from '../actions/types';
import persistFactory from './persistFactory';

export const initialState = {
  audioVolume: 0,
  theme: 'light', // default
  testMode: false,
  mapProvider: '',
  mapAddons: [],
  basemap: '',
  isBasemapVisible: true,
};

/**
 * Function to update map addons.
 * @param {Object} mapAddonsState - The ui.mapAddons state
 * @param {String} mapAddon - The map addon to be added or removed
 */
export const updateMapAddons = (mapAddonsState, mapAddon) => {
  const updatedMapAddons = [...mapAddonsState];

  if (updatedMapAddons && mapAddon) {
    if (!updatedMapAddons.includes(mapAddon)) {
      // Add mapAddon to updatedMapAddons
      updatedMapAddons.push(mapAddon);
    } else {
      // Remove mapAddon from updatedMapAddons
      const index = updatedMapAddons.indexOf(mapAddon);
      updatedMapAddons.splice(index, 1);
    }
  }

  return updatedMapAddons;
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIO_VOLUME:
      return {
        ...state,
        audioVolume: action.volume,
      };
    case SET_TEST_MODE:
      return {
        ...state,
        testMode: action.testMode,
      };
    case SELECT_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case SET_MAP_PROVIDER:
      return {
        ...state,
        mapProvider: action.mapProvider,
      };
    case SET_MAP_ADDON:
      return {
        ...state,
        mapAddons: updateMapAddons(state.mapAddons, action.mapAddon),
      };
    case SET_BASEMAP:
      return {
        ...state,
        basemap: action.basemap,
      };
    case SET_IS_BASEMAP_VISIBLE:
      return {
        ...state,
        isBasemapVisible: action.isBasemapVisible,
      };
    default:
      return state;
  }
};

export default persistFactory('uiReducer', uiReducer);
