// AUTH
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CONFIRM_USER_START = 'CONFIRM_USER_START';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAILED = 'CONFIRM_USER_FAILED';
export const FIRST_TIME_LOGIN = 'FIRST_TIME_LOGIN';
export const FORCED_LOGOUT_START = 'FORCED_LOGOUT_START';
export const FORCED_LOGOUT_SUCCESS = 'FORCED_LOGOUT_SUCCESS';
export const GET_OAUTH_TOKEN_START = 'GET_OAUTH_TOKEN_START';
export const GET_OAUTH_TOKEN_SUCCESS = 'GET_OAUTH_TOKEN_SUCCESS';
export const GET_OAUTH_TOKEN_FAILED = 'GET_OAUTH_TOKEN_FAILED';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const REQUEST_CONFIRMATION_START = 'REQUEST_CONFIRMATION_START';
export const REQUEST_CONFIRMATION_SUCCESS = 'REQUEST_CONFIRMATION_SUCCESS';
export const REQUEST_CONFIRMATION_FAILED = 'REQUEST_CONFIRMATION_FAILED';
export const REQUEST_PASSWORD_START = 'REQUEST_PASSWORD_START';
export const REQUEST_PASSWORD_SUCCESS = 'REQUEST_PASSWORD_SUCCESS';
export const REQUEST_PASSWORD_FAILED = 'REQUEST_PASSWORD_FAILED';
export const RESET_FAILED_COUNT = 'RESET_FAILED_COUNT';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const SAVE_CREATE_ACCOUNT_INFO = 'SAVE_CREATE_ACCOUNT_INFO';
export const SET_SIGNATURE = 'SET_SIGNATURE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

// PSAPS
export const CREATE_PSAP_GEOFENCE_START = 'CREATE_PSAP_GEOFENCE_START';
export const CREATE_PSAP_GEOFENCE_SUCCESS = 'CREATE_PSAP_GEOFENCE_SUCCESS';
export const CREATE_PSAP_GEOFENCE_FAILED = 'CREATE_PSAP_GEOFENCE_FAILED';
export const GET_PSAP_INFO_START = 'GET_PSAP_INFO_START';
export const GET_PSAP_INFO_SUCCESS = 'GET_PSAP_INFO_SUCCESS';
export const GET_PSAP_INFO_FAILED = 'GET_PSAP_INFO_FAILED';
export const SAVE_PSAP_INFO = 'SAVE_PSAP_INFO';

export const APP_INIT_START = 'APP_INIT_START';
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS';
export const APP_INIT_FAILED = 'APP_INIT_FAILED';
