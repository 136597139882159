import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { ThemeControlIcon } from '@rsos/assets/icons';
import { Box, Text, Switch } from '@rsos/base-components';
import useSelectedTheme from '@rsos/hooks/useSelectedTheme';
import useThemeToSwitchTo from '@rsos/hooks/useThemeToSwitchTo';
import useToggleBasemap from '@rsos/hooks/useToggleBasemap';
import { updateUserAttributes } from '@rsos/sinatra';
import { LIGHT_THEME } from '@rsos/theme/constants';
import useIsIRP from '@rsos/utils/useIsIRP';
import { trackThemeControlClicks } from '../../../../common/ThemeControl/themeControlTrackings';

const ThemeSettings = withTheme(() => {
  const { irpVersion } = useIsIRP();
  const dispatch = useDispatch();
  const applicationName = useSelector(
    state => state.sinatra.user.currentRole?.application,
  );
  const [shouldToggleBasemap, setShouldToggleBasemap] = useState(false);

  const themeName = useSelectedTheme();

  const themeToSwitchTo = useThemeToSwitchTo();

  useToggleBasemap(shouldToggleBasemap);

  const toggleSwitch = () => {
    const themeSettings = {
      theme_settings: {
        theme: themeToSwitchTo,
      },
    };
    trackThemeControlClicks(themeToSwitchTo, irpVersion);
    dispatch(updateUserAttributes(applicationName, themeSettings));
    setShouldToggleBasemap(true);
  };

  return (
    <Box
      horizontal
      align="center"
      color="primaryText"
      justify="space-between"
      width="100%"
    >
      <Box align="center" horizontal flow={10}>
        <ThemeControlIcon
          viewboxWidth={24}
          viewboxHeight={24}
          width={18}
          height={18}
          color="secondaryLink"
        />
        <Text size="small" ml="7px" mr="5px">
          Dark Mode
        </Text>
      </Box>
      <Switch
        onOffLabel
        isOn={themeName !== LIGHT_THEME ? true : false}
        onClick={toggleSwitch}
        toggleHeight="14px"
        toggleWidth="14px"
        wrapperHeight="18px"
        wrapperWidth="29px"
        toggleLeft="1px"
        toggleBottom="2px"
      />
    </Box>
  );
});

export default ThemeSettings;
