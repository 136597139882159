import React from 'react';
import SVGLoader from './SVGLoader';

const SMSIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 16}
      height={height || 16}
      viewboxWidth={viewboxWidth || 140}
      viewboxHeight={viewboxHeight || 140}
    >
      <g>
        <path d="M128.333 5.833H11.667A11.667 11.667 0 000 17.5v79.053a11.393 11.393 0 0011.667 11.364H26.25v20.416a5.833 5.833 0 009.152 4.801l36.418-25.217h56.513A11.667 11.667 0 00140 96.25V17.5a11.667 11.667 0 00-11.667-11.667zm0 88.959a1.458 1.458 0 01-1.458 1.458H70a5.833 5.833 0 00-3.32 1.038l-28.763 19.91v-15.115a5.833 5.833 0 00-5.834-5.833H13.125a1.458 1.458 0 01-1.458-1.458V18.958a1.458 1.458 0 011.458-1.458h113.75a1.458 1.458 0 011.458 1.458z" />
        <path d="M35 49.583h70a5.833 5.833 0 100-11.666H35a5.833 5.833 0 100 11.666zM35 75.833h70a5.833 5.833 0 000-11.666H35a5.833 5.833 0 000 11.666z" />
      </g>
    </SVGLoader>
  );
};

export default SMSIcon;
