import { baseQueryArgs, rsosApi, TOKEN_TYPE_PRODUCT_TOKEN } from '../rsosApi';

const endpoint = 'v2/emergency-data/session';

export const emergencySessionApi = rsosApi.injectEndpoints({
  endpoints: builder => ({
    postEmergencySession: builder.mutation({
      query: ({ query }) => ({
        ...baseQueryArgs,
        headers: {
          Authorization: `Bearer ${TOKEN_TYPE_PRODUCT_TOKEN}`,
        },
        url: endpoint,
        method: 'POST',
        body: {
          query,
          meta: true,
        },
      }),
      extraOptions: {
        tokenType: TOKEN_TYPE_PRODUCT_TOKEN,
      },
    }),
  }),
  overrideExisting: false,
});

export const { usePostEmergencySessionMutation } = emergencySessionApi;
