import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { BP_DESKTOP, BP_LAPTOP } from '@rsos/constants/breakpoints';
import { BP_DESKTOP_S } from '../../../constants/cssConstants';

export const LoginWrapper = styled(Box).attrs({
  width: 450,
  bg: 'background',
  justify: 'center',
  align: 'center',
  p: 60,
  flow: 32,
  borderRadius: 5,
})`
  justify-self: center;

  @media (max-width: ${BP_DESKTOP}px) {
    width: 410px;
  }
  @media (max-width: ${BP_DESKTOP_S}px) {
    width: 370px;
  }
  @media (max-width: ${BP_LAPTOP}px) {
    width: 450px;
  }
`;
