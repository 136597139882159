import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NS_SSO from '@rsos/constants/ns_sso';
import { setIRPVersion } from '@rsos/sinatra';
import { getUrlParameter } from '@rsos/utils';
import { forcedLogOut } from '../../actions';
import handleRedirect from '../../utils/handleRedirect';
import FirstRow from './FirstRow';
import Footer from './Footer';
import { AppRedirecting } from './Landing.styles';
import SecondRow from './SecondRow';
import { handleIrpLoginAndRedirect } from './helpers';

const Landing = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isRedirecting, setIsRedirecting] = useState(true);
  const sinatra = useSelector(state => state.sinatra);
  const isLoggedIn = sinatra.user.isLoggedIn;
  const irpAccessToken = sinatra?.irp?.irp_access_token;
  const irpToken = getUrlParameter('irp_token');

  // If NS_SSO is tagged, this was an user that navigated to /ns at some point
  // and then manually changed the URL to land on the homepage. Untag them.
  // This is unlikely to happen to real users since they're trained to visit
  // /ns to login, but is a common case for QA. See NSLogin component for more.
  useEffect(() => {
    if (localStorage.getItem(NS_SSO)) {
      localStorage.removeItem(NS_SSO);
    }
  }, []);

  useEffect(() => {
    if (irpToken || irpAccessToken) {
      dispatch(setIRPVersion('2'));
      return handleIrpLoginAndRedirect(dispatch, sinatra, navigate).catch(
        () => {
          setIsRedirecting(false);
        },
      );
    } else if (isLoggedIn) {
      return handleRedirect(dispatch, sinatra, navigate)
        .then(path => (window.location = path))
        .catch(() => {
          setIsRedirecting(false);
        });
    } else {
      // Not logged in. Explicitly log out to clear state (also handled by
      // individual reducers), local storage, and indexeddb
      dispatch(forcedLogOut(navigate));
    }
    setIsRedirecting(false);
    return () => {
      setIsRedirecting(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isRedirecting ? (
    <AppRedirecting />
  ) : (
    <>
      <FirstRow />
      <SecondRow />
      <Footer />
    </>
  );
};

export default Landing;
