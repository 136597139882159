import { memo } from 'react';
import { useFlag } from './FlagProvider';

const Flag = memo(({ name, children }) => {
  let isFlagEnabled = useFlag(name);

  if (!isFlagEnabled) {
    return null;
  }

  return children;
});

export default Flag;
