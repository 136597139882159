import React, { memo } from 'react';
import { Box } from '@rsos/components/capstone/base';
import ThemeControl from '../../common/ThemeControl';
import Settings from './Settings/index';

const SettingsControl = memo(({ path }) => {
  const isEmergencyData =
    path === '/home' ||
    path === '/irp-home' ||
    path === '/query-interface' ||
    path === '/irp-query-interface';
  return (
    <Box horizontal>
      {isEmergencyData ? (
        <>
          <Settings path={path} />
        </>
      ) : (
        <ThemeControl path={path} data-name="navigation-theme-control" />
      )}
    </Box>
  );
});

export default SettingsControl;
