import {
  POST_AUTH_TOKEN_START,
  POST_AUTH_TOKEN_SUCCESS,
  POST_AUTH_TOKEN_ERROR,
  PROVIDER_LOGIN_START,
  PROVIDER_LOGIN_SUCCESS,
  PROVIDER_LOGIN_ERROR,
  REFRESH_USER_TOKEN_START,
  REFRESH_USER_TOKEN_SUCCESS,
  REFRESH_USER_TOKEN_ERROR,
  REQUEST_SAML_START,
  REQUEST_SAML_SUCCESS,
  REQUEST_SAML_ERROR,
  SESSION_START,
  UPDATE_SESSION,
  SESSION_END,
} from '../actions/types';
import persistFactory from './persistFactory';

// TODO: should loadingSAML and errors.loadingSAML have initial values of null? new keys will need to be added each
//  time a service is added
export const initialState = {
  loading: false,
  loadingSAML: {
    support: false,
    training: false,
  },
  redirecting: false,
  errors: {
    loading: null,
    redirecting: null,
    loadingSAML: {
      support: null,
      training: null,
    },
  },
  tokens: {
    token: '',
    refresh_token: '',
    // NOTE these timestamps are to match parity with Capstone v1. They're used
    // as extra information for sentry error collection.
    tokenExpiration: null,
    refreshExpiration: null,
  },
  session: {
    sessionStart: null,
    sessionExpiration: null,
    sessionEnd: null,
  },
};

/**
 * Helper to calculate the access token expiration.
 */
const newTokenExpiration = () => {
  // TODO The expiration can be decoded from the access token b/c it's a JWT.
  const MINUTE = 60 * 1000;
  return Date.now() + 60 * MINUTE;
};

/**
 * Helper to calculate the refresh token expiration.
 */
const newRefreshExpiration = () => {
  const MINUTE = 60 * 1000;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  return Date.now() + 90 * DAY;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_AUTH_TOKEN_START:
    case PROVIDER_LOGIN_START:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case POST_AUTH_TOKEN_SUCCESS:
    case PROVIDER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        tokens: {
          ...action.tokens,
          tokenExpiration: newTokenExpiration(),
          refreshExpiration: newRefreshExpiration(),
        },
        errors: { ...state.errors, loading: null },
      };
    case POST_AUTH_TOKEN_ERROR:
    case PROVIDER_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        tokens: initialState.tokens,
        errors: { ...state.errors, loading: action.error },
      };
    case REFRESH_USER_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_USER_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        tokens: {
          ...state.tokens,
          token: action.token,
          tokenExpiration: newTokenExpiration(),
        },
        errors: { ...state.errors, loading: null },
      };
    case REFRESH_USER_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        errors: { ...state.errors, loading: action.error },
      };
    case REQUEST_SAML_START:
      return {
        ...state,
        loadingSAML: {
          ...state.loadingSAML,
          [action.service]: true,
        },
      };
    case REQUEST_SAML_SUCCESS:
      return {
        ...state,
        loadingSAML: {
          ...state.loadingSAML,
          [action.service]: false,
        },
        errors: {
          ...state.errors,
          loadingSAML: {
            ...state.loadingSAML,
            [action.service]: null,
          },
        },
      };
    case REQUEST_SAML_ERROR:
      return {
        ...state,
        loadingSAML: {
          ...state.loadingSAML,
          [action.service]: false,
        },
        errors: {
          ...state.errors,
          loadingSAML: {
            ...state.loadingSAML,
            [action.service]: action.error,
          },
        },
      };
    case SESSION_START:
      return {
        ...state,
        session: {
          ...state.session,
          sessionStart: action.sessionStart,
          sessionExpiration: action.sessionExpiration,
        },
      };
    case UPDATE_SESSION: {
      return {
        ...state,
        session: {
          ...state.session,
          sessionExpiration:
            state.session.sessionExpiration + action.hourInSeconds,
        },
      };
    }
    case SESSION_END:
      return {
        ...state,
        session: {
          sessionStart: null,
          sessionExpiration: null,
          sessionEnd: action.sessionEnd,
        },
      };
    default:
      return state;
  }
};

const opts = {
  whitelist: ['tokens', 'session'],
};

export default persistFactory('authReducer', authReducer, opts);
