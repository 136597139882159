import { useEffect, useState } from 'react';

const useMutationObserver = (ref, opts = {}) => {
  let observer;
  const [dimensionsChanged, setDimensionsChanged] = useState();
  useEffect(() => {
    if (ref && ref.current && !observer) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer = new MutationObserver(mutationList => {
        setDimensionsChanged(mutationList);
      });
      observer.observe(ref.current, {
        attributes: true,
        attributeFilter: ['style'],
        childList: true,
        subtree: true,
        ...opts,
      });
    }
    return () => {
      try {
        observer.disconnect();
      } catch {
        // it's possible that the observer is already disconnected, ok to ignore
      }
    };
  }, [ref]);
  return dimensionsChanged;
};

export default useMutationObserver;
