import {
  FETCH_ORG_TOKEN_START,
  FETCH_ORG_TOKEN_SUCCESS,
  FETCH_ORG_TOKEN_ERROR,
} from '../actions/types';

export const initialState = {
  byName: {}, // { productName: token }
  loading: false,
  errors: {
    loading: null,
  },
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORG_TOKEN_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ORG_TOKEN_SUCCESS: {
      const MINUTE = 60 * 1000;
      const expiration = Date.now() + 60 * MINUTE;
      return {
        loading: false,
        byName: {
          ...state.byName,
          [action.productName]: {
            token: action.token,
            expiration,
          },
        },
      };
    }
    case FETCH_ORG_TOKEN_ERROR: {
      return {
        ...state,
        loading: false,
        errors: {
          byName: {
            [action.productName]: action.error,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
export default productsReducer;
