import React from 'react';
import { useLocation } from 'react-router-dom';
import './ProgressBar.scss';

const ProgressBar = ({
  isCreateAccountCompleted,
  isProvideInfoCompleted,
  isLicenseAndReviewCompleted,
  isSystemInfoCompleted,
}) => {
  let location = useLocation();

  // TODO: this is the initial progress bar. might not need to import
  // bulma-steps js if we are going to use custom click handlers on the steps.
  // just need to pass props based on route and set 'is-active', and
  // 'is-completed' classes
  // Overwrite css for colors and font sizes
  return (
    <div id="progress-bar-component" className="progress-bar-component">
      <div className="steps" id="progress-bar-steps">
        <div
          className={
            'step-item ' +
            (location.pathname === '/sign-up/account' ? 'is-active ' : '') +
            (isCreateAccountCompleted ? ' is-completed' : '')
          }
        >
          <div className="step-marker" />
          <div className="step-details">
            <p
              className={
                'step-title' + (isCreateAccountCompleted ? ' grey-text' : '')
              }
            >
              Register Account
            </p>
          </div>
        </div>
        <div
          className={
            'step-item ' +
            (location.pathname === '/sign-up/provide-info'
              ? 'is-active '
              : '') +
            (isProvideInfoCompleted ? ' is-completed' : '')
          }
        >
          <div className="step-marker" />
          <div className="step-details">
            <p
              className={
                'step-title' + (isProvideInfoCompleted ? ' grey-text' : '')
              }
            >
              Agency Info
            </p>
          </div>
        </div>
        <div
          className={
            'step-item ' +
            (location.pathname === '/sign-up/license' ? 'is-active ' : '') +
            (isLicenseAndReviewCompleted ? ' is-completed' : '')
          }
        >
          <div className="step-marker" />
          <div className="step-details">
            <p
              className={
                'step-title' + (isLicenseAndReviewCompleted ? ' grey-text' : '')
              }
            >
              License Agreement
            </p>
          </div>
        </div>
        <div
          className={
            'step-item ' +
            (location.pathname === '/sign-up/integration-info'
              ? 'is-active '
              : '') +
            (isSystemInfoCompleted ? ' is-completed' : '')
          }
        >
          <div className="step-marker" />
          <div className="step-details">
            <p
              className={
                'step-title' + (isSystemInfoCompleted ? ' grey-text' : '')
              }
            >
              Integration Info
            </p>
          </div>
        </div>
        <div
          className={
            'step-item ' +
            (location.pathname === '/sign-up/review' ? 'is-active ' : '') +
            (isSystemInfoCompleted ? ' is-completed' : '')
          }
        >
          <div className="step-marker" />
          <div className="step-details">
            <p
              className={
                'step-title' + (isSystemInfoCompleted ? ' grey-text' : '')
              }
            >
              Check for confirmation email
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
