import React, {
  useEffect,
  useState,
  useRef,
  createContext,
  useContext,
} from 'react';
import { useMemo } from 'react';
import useMutationObserver from './useMutationObserver';
import useWindowSize from './useWindowSize';

export const initialValue = {
  window: { width: 0, height: 0 },
  header: { width: 0, height: 0 },
  body: { width: 0, height: 0 },
  footer: { width: 0, height: 0 },
};

export const LayoutContext = createContext({ layout: initialValue });
export const LayoutConsumer = LayoutContext.Consumer;

export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }) => {
  const headerRef = useRef();
  const footerRef = useRef();
  const headerChanged = useMutationObserver(headerRef);
  const footerChanged = useMutationObserver(footerRef);
  const [windowWidth, windowHeight] = useWindowSize();
  const [layoutDimensions, setLayoutDimensions] = useState(initialValue);

  const calculateLayout = ({
    windowWidth,
    windowHeight,
    headerRef,
    footerRef,
  }) => {
    const defaultRefValues = {
      current: {
        offsetWidth: 0,
        offsetHeight: 0,
      },
    };
    const {
      offsetWidth: headerWidth,
      offsetHeight: headerHeight,
    } = headerRef.current || { ...defaultRefValues.current };
    const {
      offsetWidth: footerWidth,
      offsetHeight: footerHeight,
    } = footerRef.current || { ...defaultRefValues.current };

    const bodyWidth = windowWidth;
    const bodyHeight = windowHeight - headerHeight - footerHeight;

    return {
      window: { width: windowWidth, height: windowHeight },
      header: { width: headerWidth, height: headerHeight },
      body: { width: bodyWidth, height: bodyHeight },
      footer: { width: footerWidth, height: footerHeight },
    };
  };

  useEffect(() => {
    const newLayoutDimensions = calculateLayout({
      windowWidth,
      windowHeight,
      headerRef,
      footerRef,
    });
    setLayoutDimensions({
      ...layoutDimensions,
      ...newLayoutDimensions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, windowHeight, headerChanged, footerChanged]);

  return useMemo(
    () => (
      <>
        <LayoutContext.Provider
          value={{ layout: layoutDimensions, headerRef, footerRef }}
        >
          {children}
        </LayoutContext.Provider>
      </>
    ),
    [layoutDimensions, headerRef, footerRef, children],
  );
};
