import React from 'react';
import SVGLoader from './SVGLoader';

const ERAHideIcon = props => {
  return (
    <SVGLoader {...props}>
      <path
        d="M12.31,94.69c-.47-.11-1-1-1.34-1.37-.53-.54-1-1.14-1.52-1.7-.75-.8-2-1.68-1.79-2.93.27-1.45,1.26-2.34,2.24-3.34L13.21,82l6.84-7a21.67,21.67,0,0,0-1.63-1.51A57.77,57.77,0,0,1,3.19,55.91a7.89,7.89,0,0,1-1.09-2.4A7.55,7.55,0,0,1,2,52.25c0-2,1.3-3.92,2.27-5.57a53.74,53.74,0,0,1,11.6-13.75A53.67,53.67,0,0,1,38.2,21.64a55,55,0,0,1,27.68.9c.47.14,4.27,1.65,4.45,1.47,1.93-2,4-4.06,6.08-6.18,2.24-2.31,4.42-4.68,6.69-7a3.93,3.93,0,0,1,4.14-1.36l4.92,5a2.74,2.74,0,0,1-.93,3.16c-.59.55-1.13,1.14-1.7,1.71-8.93,9-17.9,17.89-26.79,26.89Q39.82,69.44,17,92.68C15.87,93.78,15,95.34,12.31,94.69ZM50.55,30.83c-10.72.61-17.86,5.62-20.93,15.7a17.86,17.86,0,0,0,2.47,16.35L38,57c-1.52-5.14-.91-9.8,2.81-13.79,3.86-4.15,8.68-4.59,13.83-3.38l5.43-5.67C57,31.63,53.52,31.45,50.55,30.83Z"
        transform="translate(-2.01 -9.34)"
      />
      <path
        d="M39.17,83l8.5-9.31C62.54,72.94,70.3,65,71,50.84L86.73,35.17A49.16,49.16,0,0,1,97.49,49.92a4.87,4.87,0,0,1-.17,4.64A57.24,57.24,0,0,1,84.55,71.1,55.23,55.23,0,0,1,67.08,81.28a56.06,56.06,0,0,1-12,2.66,43.53,43.53,0,0,1-11.63-.12C42.11,83.64,40.84,83.31,39.17,83Z"
        transform="translate(-2.01 -9.34)"
      />
    </SVGLoader>
  );
};

export default ERAHideIcon;
