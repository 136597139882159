import styled from 'styled-components';

const DropdownSectionList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export default DropdownSectionList;
