import { refreshUserToken } from '@rsos/sinatra';
import { getUrlParameter } from '@rsos/utils';

/**
 * createFetchRetry returns a function that closes over the given store.
 * fetchRetry attempts the given fetch request, automatically
 * appending the access token from the redux store. If the request
 * fails because of expired credentials, an automatic attempt to
 * refresh the token is done before retrying the original request.
 * @param {Object} store - A reference to the redux store
 * @returns {function} - fetchRetry
 */
export const createFetchRetry = store => (url, options = {}) => {
  // NOTE the access token is read here to guarantee that it's the
  // latest value in the store.

  const state = store.getState();
  const { token } = state.sinatra.auth.tokens;

  const accessToken = getUrlParameter('access_token');
  const productToken = token || accessToken;

  const defaults = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${productToken}`,
    },
  };

  const configuredOptions = {
    ...defaults,
    ...options,
  };

  if (options.headers) {
    configuredOptions.headers = {
      ...defaults.headers,
      ...options.headers,
    };
  }

  return fetch(url, configuredOptions).then(response => {
    if (response.ok) {
      return response;
    }

    if (response.status === 541) {
      // Ported from original pre-3.0 code, original checkStatus comment:
      // > Force reload on deprecated application status code response
      window.location.reload(true);
      throw new Error('Forced refresh due to 541');
    }

    if (response.status !== 401 && response.status !== 403) {
      throw response;
    }

    const { refresh_token } = store.getState().sinatra.auth.tokens;
    return store.dispatch(refreshUserToken(refresh_token)).then(() => {
      const { token } = store.getState().sinatra.auth.tokens;
      const newOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      };
      return fetch(url, newOptions).then(response => {
        if (response.ok) {
          return response;
        }
        if (response.status === 541) {
          window.location.reload(true);
          throw new Error('Forced refresh due to 541');
        }
        throw response;
      });
    });
  });
};
