import React from 'react';
import { Box, Text } from '@rsos/components/capstone/base';
import SupportedBrowsers from '../../constants/SupportedBrowsers';
import Footer from '../Landing/Footer';
import { Wrapper, ContentWrapper, GridWrapper } from './BrowserSupport.styles';

const BrowserSupport = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Box width={500}>
          <Box flow={16} mb={32}>
            <Text fontWeight="bold" size="xlarge" color="primaryText">
              RapidSOS Portal Support Requirements
            </Text>
            <Text size="normal" color="secondaryLink">
              For the most optimal support experience with RapidSOS Portal,
              please ensure that you have a desktop or laptop computer. Mobile
              devices or tablets do not satisfy this requirement.
            </Text>
            <Text size="normal" color="secondaryLink">
              In addition, please ensure that you are using one of the following
              browsers with the minimum supported version or newer:
            </Text>
          </Box>
          <GridWrapper>
            {SupportedBrowsers.map((browser, browserKey) => {
              return (
                <Box key={`${browser.name}-${browserKey}`} flow={8}>
                  <Box horizontal flow={5} align="center">
                    {browser.icon}
                    <Text fontWeight="bold" color="primaryText">
                      {browser.name}
                    </Text>
                  </Box>
                  <Text color="secondaryLink">Version {browser.version}</Text>
                </Box>
              );
            })}
          </GridWrapper>
        </Box>
      </ContentWrapper>

      <Footer />
    </Wrapper>
  );
};

export default BrowserSupport;
