export const selectProductByName = (sinatra, productName) => {
  const { products } = sinatra;
  return products.byName[productName]; // returns undefined if not there
};

/**
 * return the productToken and productTokenExpiration by product name
 * @param {Object} sinatra - The sinatra state
 * @param {String} productName - The name of the product
 */
export const selectProductTokenByName = (sinatra, productName) => {
  const product =  selectProductByName(sinatra, productName);
  if (!product) {
    return { token: null, expiration: null }
  }
  const { token, expiration } = product;
  return { token, expiration };
};
