import React from 'react';
//NOTE: this input is a bare bone input with no css that you would normally pass a country to or if not, it just will format the number as international. It doesn't have a country dropdown. When we need proper internationalization, we might have to import a different component and apply our css styles to it: import PhoneInputInternational from 'react-phone-number-input'. This one has a country dropdown.
//the reference to both components are here: https://www.npmjs.com/package/react-phone-number-input/v/3.1.46
import PhoneInput from 'react-phone-number-input/input';
import styled, { withTheme, css } from 'styled-components';
import { XCloseIcon, EyeHideIcon, EyeShowIcon } from '@rsos/base-assets/icons';
import { Text, Absolute } from '@rsos/base-components';

const heightBySize = {
  xs: '25px',
  small: '30px',
  med: '35px',
  large: '40px',
  xl: '45px',
};

export function getHeightSize({ size }) {
  return (size && heightBySize[size]) || '35px';
}
const labelFontSize = {
  xs: 'small',
  small: 'small',
  med: 'normal',
  large: 'normal',
  xl: 'large',
};
export function getLabelFontSizebySize(size) {
  return (size && labelFontSize[size]) || 'normal';
}

export const InputStyles = css`
  display: block;
  background: ${p =>
    p.bgType === 'secondary'
      ? p.theme.colors.foreground
      : p.theme.colors.background};
  color: ${p => p.theme.colors.primaryText};
  width: ${p => (p.width ? p.width : '100%')};
  height: ${p => getHeightSize(p)};
  border-radius: 4px;
  opacity: ${p => (p.disabled ? 0.4 : 1)};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'inherit')};
  text-align: ${p => (p.align === 'right' ? 'right' : 'left')};
  border: 1px solid
    ${p =>
      p.error ? p.theme.colors.lightDanger : p.theme.colors.heavyLineOutline};

  padding-left: ${p => p.pl + 10}px;
  padding-right: 10px;
  -webkit-text-security: ${p => (p.isSecured ? 'disc' : 'none')};
  &:focus {
    outline: none;
    border-color: ${p =>
      p.error ? p.theme.colors.lightDanger : p.theme.colors.primaryBase};
  }
  ::placeholder {
    color: ${p => p.theme.colors.secondaryText};
  }
`;

export const StyledInput = styled.input`
  ${InputStyles}
`;

export const StyledPhoneInput = styled(PhoneInput)`
  ${InputStyles};
  // update the parent border-color when the child input is in focus
  &:focus-within {
    border-color: ${p =>
      p.error ? p.theme.colors.lightDanger : p.theme.colors.primaryBase};
  }
  .react-phone-number-input__row {
    height: 100%;
  }
  input {
    border-bottom: none;
    font-size: 14px;
    color: ${p => p.theme.colors.primaryText};
    background: ${p =>
      p.bgType === 'secondary'
        ? p.theme.colors.foreground
        : p.theme.colors.background};
    ::placeholder {
      color: ${p => p.theme.colors.secondaryText};
    }
  }
`;
export const InputTextLabel = styled(Text).attrs(
  ({ theme, size = 'regular' }) => ({
    size: getLabelFontSizebySize(size),
    color: theme.colors.primaryText,
    mb: '7px',
  }),
)`
  opacity: ${p => (p.disabled ? 0.4 : 1)};
`;

export const IconWrapper = withTheme(({ Icon, isFocused, theme, ...rest }) => (
  <Absolute top={0} bottom={0} center width="40px" {...rest}>
    <Icon
      color={isFocused ? theme.colors.primaryText : theme.colors.secondaryText}
      size={16}
    />
  </Absolute>
));

export const ClearIcon = ({ onClick }) => {
  return (
    <Absolute
      top={0}
      bottom={0}
      center
      width="21px"
      right={0}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <XCloseIcon color="secondaryLink" />
    </Absolute>
  );
};
export const HideIcon = ({ onClick }) => {
  return (
    <Absolute
      top={0}
      bottom={0}
      center
      width="25px"
      right={0}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <EyeHideIcon width={16} height={16} color="secondaryLink" />
    </Absolute>
  );
};
export const ShowIcon = ({ onClick }) => {
  return (
    <Absolute
      top={0}
      bottom={0}
      center
      width="25px"
      right={0}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <EyeShowIcon width={16} height={16} color="secondaryLink" />
    </Absolute>
  );
};
