import React from 'react';
import SVGLoader from './SVGLoader';

const ArrowForwardIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 12}
      height={height || 9}
      viewboxWidth={viewboxWidth || 12}
      viewboxHeight={viewboxHeight || 9}
    >
      <path
        d="M11.892 4.343a.356.356 0 01-.114.12L7.33 7.952a.335.335 0 01-.263.055.347.347 0 01-.223-.156.407.407 0 01-.06-.188V5.828S3.783 4.964.612 8.873a.338.338 0 01-.491.038.378.378 0 01-.12-.3c.15-1.88 1.924-6.423 6.772-6.423V.346a.376.376 0 01.12-.255.344.344 0 01.258-.09.367.367 0 01.18.063l4.447 3.49a.577.577 0 01.114.79z"
        fillRule="nonzero"
      />
    </SVGLoader>
  );
};

export default ArrowForwardIcon;
