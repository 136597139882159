import React from 'react';
import SVGLoader from './SVGLoader';

const FirefoxIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 14}
      height={height || 14}
      viewboxWidth={viewboxWidth || 14}
      viewboxHeight={viewboxHeight || 14}
    >
      <path d="M4.88867 7.00003C4.88867 7.41768 5.01252 7.82595 5.24455 8.17321C5.47658 8.52047 5.80638 8.79113 6.19224 8.95096C6.5781 9.11079 7.00268 9.1526 7.4123 9.07112C7.82193 8.98965 8.19819 8.78853 8.49351 8.49321C8.78883 8.19789 8.98995 7.82162 9.07143 7.412C9.15291 7.00238 9.11109 6.57779 8.95126 6.19193C8.79144 5.80608 8.52078 5.47628 8.17352 5.24425C7.82626 5.01221 7.41799 4.88837 7.00034 4.88837C6.44029 4.88837 5.90318 5.11085 5.50716 5.50686C5.11115 5.90287 4.88867 6.43998 4.88867 7.00003Z" />
      <path d="M3.90309 5.04585C3.9293 5.08349 3.96418 5.11428 4.00479 5.1356C4.04541 5.15692 4.09055 5.16815 4.13642 5.16835C4.18162 5.16791 4.22613 5.15717 4.26656 5.13696C4.30699 5.11674 4.34228 5.08758 4.36975 5.05168C4.6743 4.64032 5.07078 4.30584 5.52756 4.07492C5.98434 3.844 6.48876 3.72304 7.00059 3.72168H12.6764C12.7288 3.72122 12.7801 3.70695 12.8251 3.68032C12.8702 3.65369 12.9074 3.61564 12.9331 3.57002C12.9587 3.52568 12.9722 3.47538 12.9722 3.42418C12.9722 3.37299 12.9587 3.32269 12.9331 3.27835C12.3715 2.3866 11.6158 1.63316 10.7224 1.07422C9.82902 0.515286 8.82094 0.165283 7.77339 0.0503213C6.72584 -0.0646404 5.66585 0.0584063 4.67248 0.410283C3.67912 0.76216 2.77803 1.33378 2.03642 2.08252C1.98852 2.13143 1.95934 2.19563 1.95401 2.26389C1.94868 2.33214 1.96753 2.40009 2.00725 2.45585L3.90309 5.04585Z" />
      <path d="M13.7378 5.1042C13.7212 5.04254 13.6848 4.98802 13.6342 4.94903C13.5837 4.91004 13.5217 4.88873 13.4578 4.88837H10.0687C10.0176 4.88808 9.96747 4.90145 9.92336 4.92709C9.87925 4.95274 9.84281 4.98972 9.81782 5.0342C9.79227 5.07962 9.77884 5.13085 9.77884 5.18295C9.77884 5.23506 9.79227 5.28629 9.81782 5.3317C10.0523 5.72405 10.2016 6.16129 10.2562 6.61507C10.3107 7.06884 10.2693 7.52902 10.1345 7.96575C9.99975 8.40248 9.77468 8.806 9.47392 9.15014C9.17315 9.49428 8.8034 9.77135 8.38865 9.96337C8.35978 9.98041 8.3357 10.0045 8.31865 10.0334L6.37615 13.7667C6.36327 13.7878 6.35645 13.812 6.35645 13.8367C6.35645 13.8614 6.36327 13.8856 6.37615 13.9067C6.38814 13.9277 6.40498 13.9456 6.42528 13.9588C6.44557 13.972 6.46873 13.9801 6.49282 13.9825C6.65615 13.9825 6.82532 13.9825 6.99449 13.9825C8.07403 13.9825 9.13896 13.7329 10.106 13.253C11.073 12.7732 11.916 12.0761 12.5689 11.2164C13.2219 10.3568 13.6672 9.35769 13.8699 8.29736C14.0727 7.23703 14.0275 6.14416 13.7378 5.1042Z" />
      <path d="M6.68541 10.3892C6.66585 10.3467 6.63575 10.3099 6.59798 10.2824C6.5602 10.2548 6.516 10.2373 6.46957 10.2317C5.68537 10.1032 4.97471 9.69377 4.47021 9.0798C3.96572 8.46582 3.70187 7.68925 3.72791 6.89502C3.72917 6.82812 3.70871 6.76262 3.66957 6.70835L1.37707 3.57002C1.34784 3.53171 1.30974 3.50108 1.26604 3.48075C1.22235 3.46043 1.17437 3.45103 1.12624 3.45335C1.07678 3.45623 1.02886 3.47167 0.987014 3.49821C0.945169 3.52474 0.910774 3.5615 0.887074 3.60502C0.386593 4.50813 0.0920972 5.51078 0.0246965 6.5411C-0.0427042 7.57142 0.118659 8.60389 0.49722 9.56451C0.875781 10.5251 1.46217 11.3901 2.21434 12.0974C2.96652 12.8048 3.86586 13.337 4.84791 13.6558C4.9141 13.676 4.98527 13.6725 5.04915 13.6459C5.11303 13.6193 5.16564 13.5712 5.19791 13.51L6.67957 10.6517C6.70193 10.6117 6.71415 10.5669 6.71517 10.5212C6.71619 10.4754 6.70597 10.4301 6.68541 10.3892Z" />
    </SVGLoader>
  );
};

export default FirefoxIcon;
