import { useSelector } from 'react-redux';
import { useFlags } from '@rsos/flags';
import { shouldSideBarItemRender } from './utils';

/**
 * Custom hook to determine an array of sidebar items that should render. It intakes the entire list,
 * performs checks on 3 aspects: feature flag, permission, capability and returns
 * the appropriate list for the current user
 */

export default function useRenderSideBarItems(sideBarItems = []) {
  const flags = useFlags();
  const activeCapabilities = useSelector(
    state => state.psaps?.currentPSAP?.activeCapabilities,
  );
  const pageAccessList = useSelector(
    state => state.sinatra.user.pageAccessList,
  );

  return sideBarItems.filter(
    item =>
      !!shouldSideBarItemRender(
        item,
        flags,
        activeCapabilities,
        pageAccessList,
      ),
  );
}
