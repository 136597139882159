import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 9,
  viewboxHeight: 14,
};
const AlertsTabIcon = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <g id="Alertstab_icon" stroke="none" strokeWidth="1" fillRule="evenodd">
        <path
          d="M7.7,0 C8.25228475,3.89074334e-16 8.7,0.44771525 8.7,1 L8.7,9.23025978 C8.7,9.76739663 8.48394197,10.281957 8.10047448,10.6580802 L4.85011862,13.8461845 C4.65566794,14.036911 4.34433206,14.036911 4.14988138,13.8461845 L0.899525519,10.6580802 C0.51605803,10.281957 0.3,9.76739663 0.3,9.23025978 L0.3,1 C0.3,0.44771525 0.74771525,1.01453063e-16 1.3,0 L7.7,0 Z M4.63333333,2.68098387 C3.74967773,2.68098387 3.03333333,3.38360866 3.03333333,4.25034028 C3.03333333,5.11707189 3.74967773,5.81969669 4.63333333,5.81969669 C5.51698893,5.81969669 6.23333333,5.11707189 6.23333333,4.25034028 C6.23333333,3.38360866 5.51698893,2.68098387 4.63333333,2.68098387 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </SVGLoader>
  );
};
export default AlertsTabIcon;
