import React from 'react';
import { withTheme } from 'styled-components';
import { Box, Text, Absolute } from '@rsos/base-components';
import { AttentionTriangleIcon } from '@rsos/base-assets/icons';
import { StyledErrorsWrapper } from './ErrorsWrapper.styles';

export const AbsoluteErrorWrapper = ({ error }) => {
  return (
    <Absolute top="100%" width="100%">
      <StyledErrorsWrapper>
        <InputErrorMessage error={error} />
      </StyledErrorsWrapper>
    </Absolute>
  );
};

export const ErrorWrapper = ({ error }) => {
  return (
    <Box>
      <StyledErrorsWrapper>
        <InputErrorMessage error={error} />
      </StyledErrorsWrapper>
    </Box>
  );
};

export const InputErrorMessage = withTheme(({ error, theme }) => {
  return (
    <Box horizontal flow={4} align="center">
      <Box>
        <AttentionTriangleIcon
          height={12}
          width={13}
          color={theme.colors.lightDanger}
        />
      </Box>
      <Text size="small" color="lightDanger">
        {error}
      </Text>
    </Box>
  );
});
