import { createSelector } from '@reduxjs/toolkit';
import {
  ALI_GEOCOMM,
  ALI_INDIGITAL,
} from '@rsos/capstone/src/constants/capabilities';
import {
  PREMIUM_ALI_GEOCOMM,
  PREMIUM_ALI_INDIGITAL,
} from '@rsos/constants/permissions';

const checkAniAliAccessSelector = createSelector(
  state => state,
  state => {
    const aliGeocomm = !!(
      state.psaps?.currentPSAP?.activeCapabilities?.[ALI_GEOCOMM] &&
      state.sinatra?.user?.currentRole?.permissions?.includes(
        PREMIUM_ALI_GEOCOMM,
      )
    );

    const aliIndigital = !!(
      state.psaps?.currentPSAP?.activeCapabilities?.[ALI_INDIGITAL] &&
      state.sinatra?.user?.currentRole?.permissions?.includes(
        PREMIUM_ALI_INDIGITAL,
      )
    );

    return aliGeocomm || aliIndigital;
  },
);

export default checkAniAliAccessSelector;
