import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setObliqueImageryCoordinates } from 'emergency_data/src/components/EagleView/obliqueImagerySlice';
import { APP_LAUNCHER_EAGLEVIEW } from '@rsos/constants/appLauncherTypes';
import {
  setSelectedCallerID,
  viewAllCalls,
} from '@rsos/reduxDataStore/src/features/emergencyCalls/emergencyCallsSlice';
import normalizeError from '@rsos/sinatra/src/utils/normalizeError';
import { closeAllCards } from '../mapCard/mapCardSlice';
import { getAppLauncherApps, postDeeplinkURL } from './appLauncherAPI';

export const initialState = {
  applications: [],
  selectedAppLauncherApp: null,
  error: {
    fetchAppLauncherApps: null,
    createDeeplinkURL: null,
  },
  loading: {
    fetchAppLauncherApps: false,
    createDeeplinkURL: false,
  },
};

export const fetchAppLauncherApps = createAsyncThunk(
  'appLauncher/fetchAppLauncherApps',
  async (orgName, { rejectWithValue }) => {
    try {
      const response = await getAppLauncherApps(orgName);
      return response.data;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const createDeeplinkURL = createAsyncThunk(
  'appLauncher/createDeeplinkURL',
  async (data, { rejectWithValue }) => {
    try {
      const response = await postDeeplinkURL(data);
      return response.data;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const appLauncherSlice = createSlice({
  name: 'appLauncher',
  initialState,
  reducers: {
    selectAppLauncherApp: (state, action) => {
      const { payload } = action;
      if (!payload) {
        state.selectedAppLauncherApp = initialState.selectedAppLauncherApp;
        return;
      }
      const { appName = '', phone = '' } = payload;
      state.selectedAppLauncherApp = { appName, phone };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAppLauncherApps.pending, state => {
        state.loading.fetchAppLauncherApps = true;
      })
      .addCase(fetchAppLauncherApps.fulfilled, (state, action) => {
        const response = action.payload;
        state.error.fetchAppLauncherApps = null;
        state.loading.fetchAppLauncherApps = false;
        state.applications = response?.applications;
      })
      .addCase(fetchAppLauncherApps.rejected, (state, action) => {
        state.error.fetchAppLauncherApps = action.payload;
        state.loading.fetchAppLauncherApps = false;
      })
      .addCase(createDeeplinkURL.pending, state => {
        state.loading.createDeeplinkURL = true;
      })
      .addCase(createDeeplinkURL.fulfilled, state => {
        state.error.createDeeplinkURL = null;
        state.loading.createDeeplinkURL = false;
      })
      .addCase(createDeeplinkURL.rejected, (state, action) => {
        if (action.payload) {
          state.error.createDeeplinkURL = action.payload;
          state.loading.createDeeplinkURL = false;
        }
      })
      .addCase(viewAllCalls, state => {
        if (state.selectedAppLauncherApp?.appName !== APP_LAUNCHER_EAGLEVIEW) {
          return {
            ...state,
            selectedAppLauncherApp: null,
          };
        }
      })
      .addCase(setSelectedCallerID, state => {
        if (state.selectedAppLauncherApp?.appName !== APP_LAUNCHER_EAGLEVIEW) {
          return {
            ...state,
            selectedAppLauncherApp: null,
          };
        }
      })
      .addCase(closeAllCards, state => {
        if (state.selectedAppLauncherApp?.appName !== APP_LAUNCHER_EAGLEVIEW) {
          state.selectedAppLauncherApp = initialState.selectedAppLauncherApp;
        }
      })
      .addCase(setObliqueImageryCoordinates, state => {
        state.selectedAppLauncherApp = initialState.selectedAppLauncherApp;
      });
  },
});

export const { selectAppLauncherApp } = appLauncherSlice.actions;
export default appLauncherSlice.reducer;
