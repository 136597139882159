import {
  approvalVibesSoundFile,
  bouyantKettleSoundFile,
  calypsoAltSoundFile,
  chimeySoundFile,
  glassVibesSoundFile,
  hollowTappingLongSoundFile,
  cowbellsSoundFile,
  jungleDrumSoundFile,
  incomingAltSoundFile,
  incomingSoundFile,
  kettleJoySoundFile,
  logTripleSoundFile,
  notifySoundFile,
  onPointSoundFile,
  peacefulWarningSoundFile,
  popSoundFile,
  rapidoSoundFile,
  seaQueenSoundFile,
  softAlertSoundFile,
  talletSoundFile,
  tripleVibeSoundFile,
  tripleBellVibesSoundFile,
  tripleEggShakerFastSoundFile,
  warningSoundFile,
  xyloToggleSoundFile,
} from '@rsos/assets/audio';

const soundFileMapper = [
  {
    name: 'approvalVibes',
    displayName: 'Approval Vibes',
    path: approvalVibesSoundFile,
  },
  {
    name: 'bouyantKettle',
    displayName: 'Bouyant Kettle',
    path: bouyantKettleSoundFile,
  },
  {
    name: 'calypsoAlt',
    displayName: 'Calypso Alt',
    path: calypsoAltSoundFile,
  },
  {
    name: 'chimey',
    displayName: 'Chimey',
    path: chimeySoundFile,
  },
  {
    name: 'glassVibes',
    displayName: 'Glass Vibes',
    path: glassVibesSoundFile,
  },
  {
    name: 'hollowTappingLong',
    displayName: 'Hollow Tapping Long',
    path: hollowTappingLongSoundFile,
  },
  {
    name: 'cowbells',
    displayName: 'Cowbells',
    path: cowbellsSoundFile,
  },
  {
    name: 'jungleDrum',
    displayName: 'Jungle Drum',
    path: jungleDrumSoundFile,
  },
  {
    name: 'incoming',
    displayName: 'Incoming',
    path: incomingSoundFile,
  },
  {
    name: 'incomingAlt',
    displayName: 'Incoming Alt',
    path: incomingAltSoundFile,
  },
  {
    name: 'kettleJoy',
    displayName: 'Kettle Joy',
    path: kettleJoySoundFile,
  },
  {
    name: 'logTriple',
    displayName: 'Log Triple',
    path: logTripleSoundFile,
  },
  {
    name: 'notify',
    displayName: 'Notify',
    path: notifySoundFile,
  },
  {
    name: 'onPoint',
    displayName: 'On Point',
    path: onPointSoundFile,
  },
  {
    name: 'peacefulWarning',
    displayName: 'Peaceful Warning',
    path: peacefulWarningSoundFile,
  },
  {
    name: 'pop',
    displayName: 'Pop',
    path: popSoundFile,
  },
  {
    name: 'rapido',
    displayName: 'Rapido',
    path: rapidoSoundFile,
  },
  {
    name: 'seaQueen',
    displayName: 'Sea Queen',
    path: seaQueenSoundFile,
  },
  {
    name: 'softAlert',
    displayName: 'Soft Alert',
    path: softAlertSoundFile,
  },
  {
    name: 'tallet',
    displayName: 'Tallet',
    path: talletSoundFile,
  },
  {
    name: 'tripleVibe',
    displayName: 'Triple Vibe',
    path: tripleVibeSoundFile,
  },
  {
    name: 'tripleBellVibes',
    displayName: 'Triple Bell Vibes',
    path: tripleBellVibesSoundFile,
  },
  {
    name: 'tripleEggShakerFast',
    displayName: 'Triple Egg Shaker Fast',
    path: tripleEggShakerFastSoundFile,
  },
  {
    name: 'warning',
    displayName: 'Warning',
    path: warningSoundFile,
  },
  {
    name: 'xyloToggle',
    displayName: 'Xylo Toggle',
    path: xyloToggleSoundFile,
  },
];

export default soundFileMapper;
