import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getInitialPhoneValue,
  sanitizedPhoneNumber,
} from '@rsos/utils/phoneNumberUtils';
import {
  isValidAgencyName,
  isValidEmail,
  isValidPhone,
  isValidPopulation,
  isValidTextField,
  optionalTextField,
} from '@rsos/utils/validators';
import { savePSAPInfo } from '../../actions';
import { Button, PSAPInfoForm } from '../capstoneLegacy';

const ProvideInformation = ({ handleStatus }) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const currentPSAP = useSelector(state => state.auth.currentPSAP);

  const createAccountInfo = useSelector(state => state.auth.createAccountInfo);

  const [state, setState] = useState({
    contactEmail: currentPSAP.contact_email || createAccountInfo.email,
    contactEmailError: '',
    contactName: currentPSAP.contact_name || createAccountInfo.contact_name,
    contactNameError: '',
    contactPhone: getInitialPhoneValue(currentPSAP.contact_phone),
    contactPhoneError: '',
    contactTitle: currentPSAP.contact_title || '',
    contactTitleError: '',
    displayName: currentPSAP.display_name,
    displayNameError: '',
    fccPSAPID: currentPSAP.fcc_id,
    fccPSAPIDError: '',
    nonEmergencyNum: getInitialPhoneValue(currentPSAP.non_emergency_phone),
    nonEmergencyNumError: '',
    population: currentPSAP.population || '',
    populationError: '',
    selectedAgencyState: currentPSAP.agency_state || 'Select',
    showAgencyStateDropdown: false,
  });

  const {
    contactEmail,
    contactEmailError,
    contactName,
    contactNameError,
    contactPhone,
    contactPhoneError,
    contactTitle,
    contactTitleError,
    displayName,
    displayNameError,
    fccPSAPID,
    fccPSAPIDError,
    nonEmergencyNum,
    nonEmergencyNumError,
    population,
    populationError,
    selectedAgencyState,
    showAgencyStateDropdown,
  } = state;

  const isBtnDisabled = () => {
    return !(
      isValidEmail(contactEmail) &&
      isValidTextField(contactName, 2, 30) &&
      isValidPhone(sanitizedPhoneNumber(contactPhone)) &&
      isValidTextField(contactTitle, 3, 50) &&
      isValidAgencyName(displayName) &&
      isValidPhone(sanitizedPhoneNumber(nonEmergencyNum)) &&
      optionalTextField(fccPSAPID, 2, 50) &&
      isValidPopulation(population) &&
      selectedAgencyState !== 'Select'
    );
  };

  const isEnabled = !isBtnDisabled();

  const handleError = (name, value) => {
    let error;
    if (name === 'nonEmergencyNum' && !isValidPhone(value)) {
      error = `Invalid North American phone number`;
    } else if (
      name === 'population' &&
      !isValidPopulation(value, { min: 10, max: 99999999 })
    ) {
      error =
        'Population must be an integer between 10 and 99,999,999 (excluding commas)';
    } else if (name === 'fccPSAPID' && !optionalTextField(value, 2, 50)) {
      error = (
        <ul>
          <li>Must be between 2 and 50 characters</li>
          <li>
            Only alphanumeric characters, spaces, dashes, and underscores
            allowed
          </li>
        </ul>
      );
    } else if (name === 'displayName' && !isValidAgencyName(value)) {
      error = (
        <ul>
          <li>Must be between 5 and 65 characters</li>
          <li>
            Only alphanumeric characters, spaces, dashes, underscores and commas
            allowed
          </li>
        </ul>
      );
    } else if (name === 'contactEmail' && !isValidEmail(value)) {
      error = (
        <ul>
          <li>Invalid email</li>
          <li>Must be less than 50 characters</li>
        </ul>
      );
    } else if (name === 'contactName' && !isValidTextField(value, 2, 30)) {
      error = (
        <ul>
          <li>Must be between 2 and 30 characters</li>
          <li>
            Only alphanumeric characters, spaces, dashes, and underscores
            allowed
          </li>
        </ul>
      );
    } else if (name === 'contactPhone' && !isValidPhone(value)) {
      error = `Invalid North American phone number`;
    } else if (name === 'contactTitle' && !isValidTextField(value, 3, 50)) {
      error = (
        <ul>
          <li>Must be between 3 and 50 characters</li>
          <li>Only alphanumeric characters, dashes, and underscores allowed</li>
          <li>No leading/trailing spaces</li>
        </ul>
      );
    } else {
      error = null;
    }
    return error;
  };

  const onChange = e => {
    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: handleError(e.target.name, e.target.value),
    }));
  };

  const handleOnChangePhone = (name, phone) => {
    setState(prevState => ({
      ...prevState,
      [name]: phone,
      [`${name}Error`]: handleError(name, phone),
    }));
  };

  const handleSelectedState = state => {
    setState(prevState => ({
      ...prevState,
      selectedAgencyState: state,
    }));
  };

  const nextSection = e => {
    e.preventDefault();
    if (!isBtnDisabled()) {
      let inputInfo = {
        agency_name: displayName.trim(),
        agency_state: selectedAgencyState,
        id: null,
        fcc_id: fccPSAPID,
        contact_name: contactName,
        contact_title: contactTitle,
        contact_email: contactEmail,
        contact_phone: sanitizedPhoneNumber(contactPhone),
        display_name: displayName.trim(),
        non_emergency_phone: sanitizedPhoneNumber(nonEmergencyNum),
        population: parseInt(population, 10),
      };

      dispatch(savePSAPInfo(inputInfo));

      localStorage.setItem('agencyInfo', JSON.stringify(inputInfo));

      navigate('/sign-up/license');

      handleStatus(true);
    }
  };

  const toggleDropdown = system => {
    // TODO: possibly add click event listener to close dropdown when click
    // occurs outside of the dropdown elements
    setState(prevState => ({
      ...prevState,
      [`show${system}Dropdown`]: !prevState[`show${system}Dropdown`],
    }));
  };

  return (
    <div
      id="provide-info-container"
      className="provide-info-container hero pt-30"
    >
      <form className="pt-20" onSubmit={nextSection}>
        <div className="sign-up-wrapper bubble-blue-bg">
          <div className="custom-page-title pb-30 black-text mt-10">
            Please Fill Out Your Agency Information
            <div className="agency-page-subtitle black-text">
              All fields below are required with the exception of the FCC ID,
              please provide your FCC ID if you have it.{' '}
            </div>
          </div>
          <div className="columns">
            <div className="column content-container">
              <PSAPInfoForm
                contactEmail={contactEmail}
                contactEmailError={contactEmailError}
                contactName={contactName}
                contactNameError={contactNameError}
                contactPhone={contactPhone}
                contactPhoneError={contactPhoneError}
                contactTitle={contactTitle}
                contactTitleError={contactTitleError}
                displayName={displayName}
                displayNameError={displayNameError}
                fccPSAPID={fccPSAPID}
                fccPSAPIDError={fccPSAPIDError}
                handleOnChangePhone={handleOnChangePhone}
                handleSelectedState={handleSelectedState}
                isSignUp={true}
                onChange={onChange}
                nonEmergencyNum={nonEmergencyNum}
                nonEmergencyNumError={nonEmergencyNumError}
                population={population}
                populationError={populationError}
                selectedAgencyState={selectedAgencyState}
                showAgencyStateDropdown={showAgencyStateDropdown}
                toggleDropdown={toggleDropdown}
              />
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            name="Next"
            buttonClicked={nextSection}
            className={
              'rapidlite-forms-button mt-10 ' +
              (!isEnabled ? 'unclickable' : 'clickable')
            }
            disabled={!isEnabled}
          >
            CONTINUE {'>'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProvideInformation;
