import { useSelector } from 'react-redux';
import { callQueueSelector } from '@rsos/reduxDataStore/src/features/selectors/callQueueSelector';
import checkAniAliAccessSelector from '@rsos/utils/checkAniAliAccessSelector';

const useSecondaryCallLocations = () => {
  const hasAniAliAccess = useSelector(state =>
    checkAniAliAccessSelector({ psaps: state.psaps, sinatra: state.sinatra }),
  );
  const filteredCalls = useSelector(state =>
    callQueueSelector(state.emergencyCalls.callQueue, hasAniAliAccess),
  );

  const selectedCallerID = useSelector(
    state => state.emergencyCalls.selectedCallerID,
  );

  const secondaryCallLocations =
    filteredCalls?.[selectedCallerID]?.core?.secondaryLocations || [];

  return secondaryCallLocations;
};

export default useSecondaryCallLocations;
