import {
  CHANGE_USER_PASSWORD_START,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  REQUEST_RESET_PASSWORD_START,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_ERROR,
  SET_USER_PASSWORD_START,
  SET_USER_PASSWORD_SUCCESS,
  SET_USER_PASSWORD_ERROR,
  CONFIRM_USER_START,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_ERROR,
  CONFIRM_REQUEST_START,
  CONFIRM_REQUEST_SUCCESS,
  CONFIRM_REQUEST_ERROR
} from '../actions/types';

export const initialState = {
  changing: false,
  requestingResetPassword: false,
  confirming: false,
  resettingPassword: false,
  errors: {
    changing: null,
    requestingResetPassword: null,
    confirming: null,
    resettingPassword: null
  }
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USER_PASSWORD_START:
      return {
        ...state,
        changing: true
      };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changing: false,
        errors: {
          ...state.errors,
          changing: null
        }
      };
    case CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        changing: false,
        errors: {
          ...state.errors,
          changing: action.error
        }
      };

    case REQUEST_RESET_PASSWORD_START:
      return {
        ...state,
        requestingResetPassword: true
      };
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requestingResetPassword: false,
        errors: {
          ...state.errors,
          requestingResetPassword: null
        }
      };
    case REQUEST_RESET_PASSWORD_ERROR:
      return {
        ...state,
        requestingResetPassword: false,
        errors: {
          ...state.errors,
          requestingResetPassword: action.error
        }
      };
    case SET_USER_PASSWORD_START:
      return {
        ...state,
        resettingPassword: true
      };
    case SET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        errors: {
          ...state.errors,
          resettingPassword: null
        }
      };
    case SET_USER_PASSWORD_ERROR:
      return {
        ...state,
        resettingPassword: false,
        errors: {
          ...state.errors,
          resettingPassword: action.error
        }
      };
    case CONFIRM_USER_START:
      return {
        ...state,
        confirming: true
      };
    case CONFIRM_USER_SUCCESS:
      return {
        ...state,
        confirming: false,
        errors: {
          ...state.errors,
          confirming: null
        }
      };
    case CONFIRM_USER_ERROR:
      return {
        ...state,
        confirming: false,
        errors: {
          ...state.errors,
          confirming: action.error
        }
      };
    case CONFIRM_REQUEST_START:
      return {
        ...state,
        confirming: true
      };
    case CONFIRM_REQUEST_SUCCESS:
      return {
        ...state,
        confirming: false,
        errors: {
          ...state.errors,
          confirming: null
        }
      };
    case CONFIRM_REQUEST_ERROR:
      return {
        ...state,
        confirming: false,
        errors: {
          ...state.errors,
          confirming: action.error
        }
      };

    default:
      return { ...state };
  }
};

export default accountReducer;
