import React from 'react';
import {
  CADSystems,
  MappingSystems,
  PhoneSystems,
} from '@rsos/capstone/src/constants/Systems';
import Tile from './Tile';

const SystemsForm = props => {
  const {
    handleSelectedSystem,
    selectedCADSystem,
    selectedMappingSystem,
    selectedPhoneSystem,
    showCADSystemDropdown,
    showMappingSystemDropdown,
    showPhoneSystemDropdown,
    toggleDropdown,
    isSignUp = false,
  } = props;

  let cadSysName, mappingSysName, phoneSysName;

  const cadSys = CADSystems.find(
    ({ enumMapping }) => enumMapping === selectedCADSystem,
  );
  if (cadSys) {
    cadSysName = cadSys.name;
  }

  const mappingSys = MappingSystems.find(
    ({ enumMapping }) => enumMapping === selectedMappingSystem,
  );
  if (mappingSys) {
    mappingSysName = mappingSys.name;
  }
  const phoneSys = PhoneSystems.find(
    ({ enumMapping }) => enumMapping === selectedPhoneSystem,
  );
  if (phoneSys) {
    phoneSysName = phoneSys.name;
  }
  return (
    <div className="psap-info-wrapper">
      {isSignUp ? (
        <>
          <div className="columns">
            <div className="column is-12">
              <Tile ancestorClass="h-100" parentClass="level">
                <div className="input-label">Phone System (911 / CPE)</div>
                <div
                  onClick={() => toggleDropdown('PhoneSystem')}
                  id="phoneSystemDropdown"
                  className={
                    'dropdown is-fullwidth' +
                    (showPhoneSystemDropdown ? ' is-active' : '')
                  }
                >
                  <div className="dropdown-trigger is-fullwidth">
                    <div
                      className="button dropdown-button has-text-left"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span>{phoneSysName ? phoneSysName : 'Select'}</span>
                      <span className="icon is-small">
                        <i
                          className={
                            showPhoneSystemDropdown ? 'arrow-up' : 'arrow-down'
                          }
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="phone-system-dropdown-menu"
                    role="menu"
                  >
                    <div className="dropdown-content">
                      {PhoneSystems.map(system => {
                        return (
                          <span
                            onClick={() =>
                              handleSelectedSystem('Phone', system.enumMapping)
                            }
                            className="dropdown-item"
                            key={system.name}
                            id={system.name}
                          >
                            {system.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Tile>
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <Tile ancestorClass="h-100" parentClass="level">
                <div className="input-label">CAD System</div>
                <div
                  onClick={() => toggleDropdown('CADSystem')}
                  id="cadSystemDropdown"
                  className={
                    'dropdown is-fullwidth' +
                    (showCADSystemDropdown ? ' is-active' : '')
                  }
                >
                  <div className="dropdown-trigger is-fullwidth">
                    <div
                      className="button dropdown-button has-text-left"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span>{cadSysName ? cadSysName : 'Select'}</span>
                      <span className="icon is-small">
                        <i
                          className={
                            showCADSystemDropdown ? 'arrow-up' : 'arrow-down'
                          }
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="cad-system-dropdown-menu"
                    role="menu"
                  >
                    <div className="dropdown-content">
                      {CADSystems.map(system => {
                        return (
                          <span
                            onClick={() =>
                              handleSelectedSystem('CAD', system.enumMapping)
                            }
                            className="dropdown-item"
                            key={system.name}
                            id={system.name}
                          >
                            {system.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Tile>
            </div>
          </div>{' '}
        </>
      ) : (
        <div className="columns">
          <div className="column is-6">
            <Tile ancestorClass="h-100" parentClass="level">
              <div className="input-label">Phone System (911 / CPE)</div>
              <div
                onClick={() => toggleDropdown('PhoneSystem')}
                id="phoneSystemDropdown"
                className={
                  'dropdown is-fullwidth' +
                  (showPhoneSystemDropdown ? ' is-active' : '')
                }
              >
                <div className="dropdown-trigger is-fullwidth">
                  <div
                    className="button dropdown-button has-text-left"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <span>{phoneSysName ? phoneSysName : 'Select'}</span>
                    <span className="icon is-small">
                      <i
                        className={
                          showPhoneSystemDropdown ? 'arrow-up' : 'arrow-down'
                        }
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-menu"
                  id="phone-system-dropdown-menu"
                  role="menu"
                >
                  <div className="dropdown-content">
                    {PhoneSystems.map(system => {
                      return (
                        <span
                          onClick={() =>
                            handleSelectedSystem('Phone', system.enumMapping)
                          }
                          className="dropdown-item"
                          key={system.name}
                          id={system.name}
                        >
                          {system.name}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tile>
          </div>
          <div className="column is-6">
            <Tile ancestorClass="h-100" parentClass="level">
              <div className="input-label">CAD System</div>
              <div
                onClick={() => toggleDropdown('CADSystem')}
                id="cadSystemDropdown"
                className={
                  'dropdown is-fullwidth' +
                  (showCADSystemDropdown ? ' is-active' : '')
                }
              >
                <div className="dropdown-trigger is-fullwidth">
                  <div
                    className="button dropdown-button has-text-left"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <span>{cadSysName ? cadSysName : 'Select'}</span>
                    <span className="icon is-small">
                      <i
                        className={
                          showCADSystemDropdown ? 'arrow-up' : 'arrow-down'
                        }
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-menu"
                  id="cad-system-dropdown-menu"
                  role="menu"
                >
                  <div className="dropdown-content">
                    {CADSystems.map(system => {
                      return (
                        <span
                          onClick={() =>
                            handleSelectedSystem('CAD', system.enumMapping)
                          }
                          className="dropdown-item"
                          key={system.name}
                          id={system.name}
                        >
                          {system.name}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tile>
          </div>
        </div>
      )}
      <div className="columns mb-0">
        <div className={isSignUp ? 'column is-12' : 'column is-6'}>
          <Tile parentClass="level">
            <div className="input-label">Mapping System</div>
            <div
              onClick={() => toggleDropdown('MappingSystem')}
              id="mappingSystemDropdown"
              className={
                'dropdown is-fullwidth' +
                (showMappingSystemDropdown ? ' is-active' : '')
              }
            >
              <div className="dropdown-trigger is-fullwidth">
                <div
                  className="button dropdown-button has-text-left"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>{mappingSysName ? mappingSysName : 'Select'}</span>
                  <span className="icon is-small">
                    <i
                      className={
                        showMappingSystemDropdown ? 'arrow-up' : 'arrow-down'
                      }
                    />
                  </span>
                </div>
              </div>
              <div
                className="dropdown-menu"
                id="mapping-system-dropdown-menu"
                role="menu"
              >
                <div className="dropdown-content">
                  {MappingSystems.map(system => {
                    return (
                      <span
                        onClick={() =>
                          handleSelectedSystem('Mapping', system.enumMapping)
                        }
                        className="dropdown-item"
                        key={system.name}
                        id={system.name}
                      >
                        {system.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};

export default SystemsForm;
