import React from 'react';
import SVGLoader from './SVGLoader';

const ForbiddenIcon = ({ ...rest }) => {
  return (
    <SVGLoader {...rest}>
      <path d="M50,17.22A32.78,32.78,0,1,1,17.23,50,32.81,32.81,0,0,1,50,17.22ZM71.6,50a21.85,21.85,0,0,0-2.9-10.76L39.24,68.7A21.13,21.13,0,0,0,50,71.6,21.59,21.59,0,0,0,71.6,50ZM31.3,60.76,60.76,31.3A21.13,21.13,0,0,0,50,28.4,21.59,21.59,0,0,0,28.4,50,21.85,21.85,0,0,0,31.3,60.76Z" />
    </SVGLoader>
  );
};

export default ForbiddenIcon;
