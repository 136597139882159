import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  AUTHENTICATION,
  INCIDENT_CENTRAL_STATION,
} from '@rsos/constants/appNames';
import useSelectedTheme from '@rsos/hooks/useSelectedTheme';
import ThemeProvider from '@rsos/theme';
import { LIGHT_THEME } from '@rsos/theme/constants';
import { readMetaTag } from '@rsos/utils/metaTags';
import { AppPathsProvider } from './AppPathsContext';
import LayoutContent from './LayoutContent';

const ICSP_THEME = 'icsp';

const feAppName = readMetaTag('fe-app-name');

const fetchTheme = (app, theme) => {
  const themeByApp = {
    [AUTHENTICATION]: LIGHT_THEME,
    [INCIDENT_CENTRAL_STATION]: ICSP_THEME,
  };

  return themeByApp[app] || theme;
};

const Layout = ({ children, appPaths, persistor }) => {
  const selectedTheme = useSelectedTheme();

  const [themeName, setThemeName] = useState(selectedTheme);

  useEffect(() => {
    setThemeName(fetchTheme(feAppName, selectedTheme));
  }, [selectedTheme]);

  return (
    <AppPathsProvider value={appPaths}>
      <ThemeProvider themeName={themeName}>
        <LayoutContent children={children} persistor={persistor} />
      </ThemeProvider>
    </AppPathsProvider>
  );
};

export default Layout;
