import { instance } from '@rsos/sinatra';
import { getAPIHost } from '@rsos/utils/metaTags';

const baseURL = `${getAPIHost()}/v1`;

const APP_LAUNCH = 'app_launch';
const DATA_SHARE = 'data_share';

export const getAppLauncherApps = orgName =>
  instance.get(`${baseURL}/capstone/psaps/${orgName}/applications`, {
    headers: { retry: true },
  });

export const postDeeplinkURL = data => {
  const { appEndpoint, appID, appData, orgID } = data;
  let url = `${baseURL}/deeplink/${appEndpoint}/${orgID}`;
  if (appEndpoint === APP_LAUNCH || appEndpoint === DATA_SHARE)
    url += `/${appID}`;

  // If a partner application can be launched in all calls view, then `appData` is undefined since
  // we don't need to pass the call data to the backend. If there is no `appData` then force the
  // `content-type` to `application/json` or else it would default to
  // `application/x-www-form-urlencoded`, which gets rejected by the backend.
  return appData
    ? instance.post(url, appData, { headers: { retry: true } })
    : instance.post(url, {
        'Content-Type': 'application/json',
        headers: { retry: true },
      });
};
