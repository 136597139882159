import { gspx } from '@rsos/capstone';

const CENTRAL_STATION = 'central_station';

export const trackLogOutClick = (currentApp, irpVersion) => {
  const appName = currentApp === CENTRAL_STATION ? 'CSP' : 'RSP';

  gspx.trackCustomEvent('Auth', {
    irpVersion,
    name: `user clicks on logout button from menu in ${appName}`,
    Category: 'Auth',
    'Launched date': new Date(),
  });
};
