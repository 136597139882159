import { fetchOrgToken, selectUserOrgByApp } from '@rsos/sinatra';
import { getProductToken } from '@rsos/utils/getProductToken';

/**
 * createFetchRetryUsingProduct returns a function that closes over the given store.
 * fetchRetry attempts the given fetch request, automatically
 * appending the product token from sinatra in the redux store. If the request
 * fails because of expired credentials, an automatic attempt to
 * refresh the token is done before retrying the original request.
 * @param {Object} store - A reference to the redux store
 * @returns {function} - fetchRetry
 */
export const createFetchRetryUsingProduct = store => (
  url,
  options = {},
  config = {},
) => {
  // NOTE the product token is read here to guarantee that it's the
  // latest value in the store.

  const state = store.getState();
  const { productToken } = getProductToken(state.sinatra);

  const defaults = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${productToken}`,
    },
  };

  const configuredOptions = {
    ...defaults,
    ...options,
  };

  if (options.headers) {
    configuredOptions.headers = {
      ...defaults.headers,
      ...options.headers,
    };
  }

  return fetch(url, configuredOptions).then(response => {
    if (response.ok) {
      return response;
    }

    if (response.status === 541) {
      // Ported from original pre-3.0 code, original checkStatus comment:
      // > Force reload on deprecated application status code response
      window.location.reload(true);
      throw new Error('Forced refresh due to 541');
    }

    if (response.status !== 401 && response.status !== 403) {
      throw response;
    }

    const org = selectUserOrgByApp(store.getState().sinatra, config?.appName);

    return store
      .dispatch(fetchOrgToken(org?.id, config?.productName))
      .then(() => {
        const { productToken } = getProductToken(store.getState().sinatra);
        const newOptions = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${productToken}`,
          },
        };
        return fetch(url, newOptions).then(response => {
          if (response.ok) {
            return response;
          }
          if (response.status === 541) {
            window.location.reload(true);
            throw new Error('Forced refresh due to 541');
          }
          throw response;
        });
      });
  });
};
