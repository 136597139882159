import React from 'react';
import SVGLoader from './SVGLoader';

const AviveStatusIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight, ...rest } = props;

  return (
    <SVGLoader
      {...rest}
      width={width || 26}
      height={height || 26}
      viewboxWidth={viewboxWidth || 26}
      viewboxHeight={viewboxHeight || 26}
    >
      <g
        id="08-05-21---Avive-phase-1-final"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <g id="08_map_state02" transform="translate(-302.000000, -361.000000)">
          <g id="Group-15" transform="translate(295.000000, 354.000000)">
            <g id="Group-12-Copy" transform="translate(10.000000, 10.000000)">
              <g id="Avive-white-resized" fillRule="nonzero">
                <path
                  d="M9.45229738,6.9208999 C8.89157386,6.35889397 8.01629784,6.35889397 7.45557432,6.9208999 L6.96596662,7.41162737 L6.96596662,3.93815528 C8.54146345,3.34051011 10.3275734,3.72706047 11.481844,4.9196102 C13.1284566,6.60562801 13.0573408,9.27310074 11.4134625,10.9563779 L5.42602771,16.9218672 C5.32208833,17.0260443 5.14703313,17.0260443 5.00480148,16.9218672 C4.93642001,16.8505887 4.9008621,16.7820509 4.9008621,16.7107724 L4.9008621,13.4127559 L9.38118156,8.92218961 C9.97746299,8.36018367 9.97746299,7.48290584 9.45229738,6.9208999 Z"
                  id="Path"
                ></path>
                <path
                  d="M0.420542642,8.92218961 C-0.140180881,8.36018367 -0.140180881,7.48290584 0.420542642,6.9208999 C0.978531802,6.35889397 1.85380782,6.35889397 2.41453134,6.9208999 L2.90413904,7.41162737 L2.90413904,1.83268848 C2.90413904,1.69287215 2.97525486,1.51741659 3.07919424,1.41049888 L4.4112544,0.07813282 C4.51519378,-0.0260442733 4.69024898,-0.0260442733 4.82974627,0.07813282 C4.9008621,0.149411293 4.93642001,0.21794915 4.93642001,0.289227623 L4.93642001,13.4127559 L0.420542642,8.92218961 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SVGLoader>
  );
};

export default AviveStatusIcon;
