import styled from 'styled-components';

export const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  height: ${({ wrapperHeight = '25px' }) => wrapperHeight};
  width: ${({ wrapperWidth = '42px' }) => wrapperWidth};
  margin: 5px 0;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Toggle = styled.div`
  position: absolute;
  background-color: ${({ isOn, theme }) =>
    isOn ? theme.colors.success : theme.colors.heavyLineOutline};
  border-radius: 34px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;

  :before {
    position: absolute;
    border-radius: 50%;
    content: '';
    height: ${({ toggleHeight = '17px' }) => toggleHeight};
    width: ${({ toggleWidth = '17px' }) => toggleWidth};
    left: ${({ toggleLeft = '4px' }) => toggleLeft};
    bottom: ${({ toggleBottom = '4px' }) => toggleBottom};
    background-color: ${p => p.theme.colors.baseColors.white};
    transition: 0.4s;
    ${({ isOn, toggleHeight = '17px' }) =>
      isOn && `transform: translateX(${toggleHeight})`};
    ${({ toggleShadow }) => toggleShadow && 'box-shadow: ' + toggleShadow};
  }
`;
