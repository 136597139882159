/**
 * Exports internal references to the _RSOS global namespace.
 * Useful for debugging and injecting state via automation.
 * @param {String} name
 * @param {Object} reference
 */
const exportToGlobalNamespace = (name, reference) => {
  try {
    window.__RSOS = {
      ...(window.__RSOS || {}),
      [name]: reference,
    };
  } catch {
    /* okay to fail silently */
  }
};

export default exportToGlobalNamespace;
