import normalizeError from '../utils/normalizeError';
import instance from './scorpiusInstance';
import {
  LOGOUT,
  SCORPIUS_LOGOUT_START,
  SCORPIUS_LOGOUT_SUCCESS,
  SCORPIUS_LOGOUT_ERROR,
} from './types';
import { clearTokens } from '../utils/tokens';

/**
 * Logs the user out by clearing localStorage and resetting redux state,
 * see reducers/index.js.
 * NOTE that DELETE /tokens to Scorpius API is purposefully not done. This is
 * because PSAPs are known to use the same user credentials to generate multiple
 * user sessions. Normal behavior for Scorpius is to invalidate all tokens
 * associated with the user account rather than a specific set of tokens.
 * Until that's built into the Scorpius API, the keys are cleared from localStorage
 * and left valid until they expire.
 * TODO Update with token invalidation once Scorpius supports it.
 */
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
  clearTokens();
  return Promise.resolve();
};

export const scorpiusLogoutStart = () => ({
  type: SCORPIUS_LOGOUT_START
});

export const scorpiusLogoutSuccess = () => ({
  type: SCORPIUS_LOGOUT_SUCCESS
});

export const scorpiusLogoutError = error => ({
  type: SCORPIUS_LOGOUT_ERROR,
  error
});

/**
 * Logs the user out by invalidating their refresh token and removes the tokens
 * from localStorage. This invalidates existing refresh tokens for the
 * associated user, so all users sharing the account will get logged out when
 * their access token expires and an authenticated request to a RapidSOS service
 * returns a 401
 */
export const scorpiusLogout = () => async dispatch => {
  dispatch(scorpiusLogoutStart());
  dispatch(logout());
  try {
    await instance.post('/user/logout', { headers: { retry: true } });
    return dispatch(scorpiusLogoutSuccess());
  }
  catch (error) {
    throw dispatch(scorpiusLogoutError(normalizeError(error)));
  }
};

export default logout;
