import { selectProductTokenByName } from '@rsos/sinatra';
import { getProductName } from '@rsos/utils/metaTags';

/**
 * Returns product token and expiration for the current product.
 * @param {Object} sinatra - sinatra state
 */
export const getProductToken = sinatra => {
  const productName = getProductName();
  const {
    token: productToken,
    expiration: productTokenExpiration,
  } = selectProductTokenByName(sinatra, productName);
  return {
    productToken,
    productTokenExpiration,
  };
};
