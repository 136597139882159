import instance from './scorpiusInstance';
import {
  CONFIRM_REQUEST_START,
  CONFIRM_REQUEST_SUCCESS,
  CONFIRM_REQUEST_ERROR,
} from './types';

export const confirmRequestUserStart = () => ({
  type: CONFIRM_REQUEST_START,
});

export const confirmRequestUserSuccess = () => ({
  type: CONFIRM_REQUEST_SUCCESS,
});

export const confirmRequestUserError = error => ({
  type: CONFIRM_REQUEST_ERROR,
  error,
});

/**
 * Request an account confirmation email
 * @param {String} application
 * @param {String} email
 */
export const confirmRequestUser = (application, email) => async (dispatch) => {
  dispatch(confirmRequestUserStart());
  try {
    await instance.post('/user/confirm/request', {
      application,
      email,
    });
    // NOTE Response is a 204 on success, nothing to pass along.
    return dispatch(confirmRequestUserSuccess());
  } catch (error) {
    throw dispatch(confirmRequestUserError(error));
  }
};
