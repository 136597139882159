import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AudioControlIcon, AudioMuteIcon } from '@rsos/assets/icons';
import { Box, Slider, Text } from '@rsos/components/capstone/base';
import { updateUserAttributes } from '@rsos/sinatra';
import checkAgent511Access from '@rsos/utils/checkAgent511Access';
import { checkObjStructure } from '@rsos/utils/tt911SoundSettingsHelpers';
import useIsIRP from '@rsos/utils/useIsIRP';
import {
  trackClickMuteAudioControl,
  trackChangeAudioVolume,
} from '../audioControltrackings';
import { mockUserSettings } from '../helpers';
import { SliderWrapper, VolumeSliderTooltip } from './VolumeSlider.styles';

const VolumeSlider = ({ min = 0, max = 1, step = '0.1', type }) => {
  const { irpVersion } = useIsIRP();
  const sinatra = useSelector(state => state.sinatra);
  const psaps = useSelector(state => state.psaps);
  const hasAgent511Access = checkAgent511Access(psaps, sinatra);

  const dispatch = useDispatch();
  const { application: applicationName } = useSelector(
    state => state.sinatra.user.currentRole,
  );
  const resolvedUserAudioSettings = useSelector(
    state =>
      state.sinatra.user.profile?.attributes?.[applicationName]
        ?.audio_settings || mockUserSettings,
  );
  const userAudioSettings = useMemo(
    () =>
      checkObjStructure(resolvedUserAudioSettings, [
        hasAgent511Access && 'tt911UserAudioSettings',
      ]),
    [resolvedUserAudioSettings, hasAgent511Access],
  );
  const volumeVal = userAudioSettings[type]?.volume;
  const [sliderValue, setSliderValue] = useState(volumeVal) || 0.7;
  const [isVisible, setIsVisible] = useState(false);
  const onMouseEnter = () => {
    setIsVisible(true);
  };
  const onMouseLeave = () => {
    setIsVisible(false);
  };

  const handleMute = () => {
    const updatedSettings = {
      audio_settings: {
        ...userAudioSettings,
        [type]: { volume: 0 },
      },
    };
    setSliderValue(0);
    dispatch(updateUserAttributes(applicationName, updatedSettings));
    trackClickMuteAudioControl(irpVersion, type);
  };
  const handleVolumeChange = volVal => {
    setSliderValue(volVal);
  };
  const handleMouseUp = () => {
    const data = {
      audio_settings: {
        ...userAudioSettings,
        [type]: { volume: parseFloat(sliderValue) },
      },
    };
    dispatch(updateUserAttributes(applicationName, data));
    trackChangeAudioVolume(irpVersion, type);
  };
  const getLabelByType = () => {
    const labelByType = {
      calls: '911 Calls',
      alerts: 'Alerts',
      sms: '911 Texts',
      tt911: 'Text-to-911',
    };
    return labelByType[type];
  };
  return (
    <SliderWrapper>
      <Text size="normal" color="primaryText">
        {getLabelByType()}
      </Text>
      <Box
        position="relative"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor="pointer"
      >
        {isVisible && <VolumeSliderTooltip />}
        <AudioMuteIcon color="secondaryLink" onClick={handleMute} />
      </Box>
      <Slider
        onChange={e => handleVolumeChange(e.target.value)}
        onMouseUp={handleMouseUp}
        value={sliderValue}
        min={min}
        max={max}
        step={step}
        style={{
          width: '100%',
          '--min': min,
          '--max': max,
          '--val': sliderValue,
        }}
      />
      <AudioControlIcon color="secondaryLink" />
    </SliderWrapper>
  );
};

export default VolumeSlider;
