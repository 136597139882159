import instance from './scorpiusInstance';
import {
  REQUEST_RESET_PASSWORD_START,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_ERROR
} from './types';

export const requestResetPasswordStart = () => ({
  type: REQUEST_RESET_PASSWORD_START
});

export const requestResetPasswordSuccess = () => ({
  type: REQUEST_RESET_PASSWORD_SUCCESS,
});

export const requestResetPasswordError = error => ({
  type: REQUEST_RESET_PASSWORD_ERROR,
  error
});

export const requestResetPassword = (email, application) => async dispatch => {
  dispatch(requestResetPasswordStart());
  try {
    await instance.post('/user/password/reset', {
      email,
      application
    });
    // NOTE Success is a 204, nothing to pass along.
    return dispatch(requestResetPasswordSuccess());
  } catch (error) {
    throw dispatch(requestResetPasswordError(error));
  }
};
