import { gspx } from '@rsos/capstone';

export const trackAppLauncherClick = (app, source, irpVersion) => {
  gspx.trackCustomEvent('Data Map', {
    irpVersion,
    name: `Click on '${app.call_to_action} - ${app.display_name_short}' link from ${source}`,
    Category: 'third party',
    'Launched date': new Date(),
  });
};
export const trackDataShareConfirmClick = (app, irpVersion) => {
  gspx.trackCustomEvent('Data Map', {
    irpVersion,
    name: `${app.display_name} click on confirm data share`,
    Category: 'third party',
    'Launched date': new Date(),
  });
};
export const trackDataShareCancelClick = (app, irpVersion) => {
  gspx.trackCustomEvent('Data Map', {
    irpVersion,
    name: `${app.display_name} click on cancel data share`,
    Category: 'third party',
    'Launched date': new Date(),
  });
};
