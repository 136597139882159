import React from 'react';
import SVGLoader from './SVGLoader';


export default props => (
  <SVGLoader
    viewboxWidth={14}
    viewboxHeight={14}
    width={16}
    height={16}
    {...props}
  >
    <path
      d="M6.69,2a1.318,1.318,0,0,0-.8.271L3,4.5H1.75A1.752,1.752,0,0,0,0,6.25v1.5A1.752,1.752,0,0,0,1.75,9.5H3l2.9,2.228A1.309,1.309,0,0,0,8,10.69V3.31A1.311,1.311,0,0,0,6.69,2Z"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0"
    ></path>
    <path
      d="M12.811,7l.969-.97a.75.75,0,0,0-1.06-1.06l-.97.969-.97-.969A.75.75,0,0,0,9.72,6.03l.969.97-.969.97a.75.75,0,1,0,1.06,1.06l.97-.969.97.969a.75.75,0,1,0,1.06-1.06Z"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0"
    ></path>
  </SVGLoader>
);