import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  fetchAlertAdrICSP,
  fetchInitialAlertAdr,
} from '@rsos/components/capstone/DataCards/adrDataSlice';
import {
  ALERTDATAEVENT,
  parseDataByCategories,
  parseNestedData,
} from '@rsos/components/capstone/DataCards/utils';
import {
  ACCEPTED,
  DECLINED,
  DISPATCH_REQUESTED,
} from '@rsos/constants/alertStatuses';
import {
  FEEDBACKFORM,
  LIVESTREAMS,
} from '@rsos/constants/alerts/adrCategories';
import { CONVERSATION_ENDED } from '@rsos/constants/chatStatuses';
import { PRODUCT_TOKEN } from '@rsos/sinatra/src/actions/types';
import normalizeError from '@rsos/sinatra/src/utils/normalizeError';
import { checkStatus, parseJSON } from '@rsos/utils';
import { getAPIHost } from '@rsos/utils/metaTags';
import serializeRequestError from '@rsos/utils/serializeRequestError';
import { ALERT_PREFIX, CHAT_RAPTOR_PREFIX } from '@rsos/utils/talkJS';
import {
  deleteAlertConversation,
  getAlertConversationStatus,
} from './alertsAPI';
import { receivedAlertUpdatedStatus } from './incidentsSlice';

const STATUS_UPDATE = 'STATUS_UPDATE';

/**
 * Parse the `Suggested Script Responses` parent object out of the adr and use it only
 * when it's available. There are 2 possible structures:
 * 1. { "Chatbot Responses": { ... }, "Suggested Script Responses": { ... } }
 * 2. { "Suggested Script Responses": { ... } }
 * @param {Object} adr - adr data from the RAD session endpoint
 */
export const parseScriptedResponses = adr => {
  const scriptedResponsesKey = 'Suggested Script Responses';
  let scriptedResponses = {};
  const parseScriptedResponsesJsonPath = data => {
    if (typeof data !== 'object' || data === null) {
      return;
    }
    const keys = Object.keys(data);
    keys.forEach(key => {
      if (key === scriptedResponsesKey) {
        scriptedResponses = data;
        return;
      } else {
        parseScriptedResponsesJsonPath(data[key]);
      }
    });
  };
  parseScriptedResponsesJsonPath(adr);
  return scriptedResponses;
};

const reduceAlertDataEvent = adr => {
  const topLevelKeys = [];
  for (const key of Object.keys(adr)) {
    for (const subKey of Object.keys(adr[key]?.meta)) {
      if (adr[key].meta[subKey].category === 'alertdataevent') {
        topLevelKeys.push(key);
      }
    }
  }

  return Object.keys(adr)
    .filter(key => !topLevelKeys.includes(key))
    .reduce((acc, key) => {
      acc[key] = adr[key];
      return acc;
    }, {});
};

/**
 * Fetch an alert's chat history to be used to render all messages prior to
 * alert creation.
 * By default, the data is sorted by Platform (ascending) - Epoch (ascending)
 * Possible ascending values are `1` and `0`.
 * If `0` is passed, the data will be sorted by Epoch (descending).
 * If `1` or any other value the data will be sorted by Epoch (ascending)
 * @param {String} midasAlertId
 */
export const fetchAlertConversationHistory = createAsyncThunk(
  'chat/fetchAlertConversationHistory',
  async (alertId, { rejectWithValue }) => {
    const token = localStorage.getItem(PRODUCT_TOKEN);

    const midasAlertId = alertId.split('alert-')[1];
    try {
      const data = await fetch(
        `${getAPIHost()}/v1/retriever/chat?alert_id=${midasAlertId}&ascending=1`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
        .then(checkStatus)
        .then(parseJSON)
        .catch(error => {
          return Promise.reject(error);
        });
      return data;
    } catch (error) {
      const serializedError = await serializeRequestError(error);
      return rejectWithValue(serializedError);
    }
  },
);

export const deleteConversation = createAsyncThunk(
  'chat/deleteConversation',
  async ({ orgName, conversationID }, { rejectWithValue }) => {
    try {
      const response = await deleteAlertConversation(orgName, conversationID);
      return response.data;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const fetchAlertConversationStatus = createAsyncThunk(
  'chat/fetchAlertConversationStatus',
  async ({ orgName, conversationID }, { rejectWithValue }) => {
    try {
      const response = await getAlertConversationStatus(
        orgName,
        conversationID,
      );
      return {
        data: response.data,
      };
    } catch (error) {
      const errorObject = {
        statusCode: error?.response?.status,
        data: error?.response?.data,
      };
      return rejectWithValue(errorObject);
    }
  },
);

const initialState = {
  chats: {},
  loading: {
    alertAdr: false,
    alertConversationHistory: false,
    alertConversationStatus: false,
    alertEndChat: false,
  },
  error: {
    alertAdr: null,
    alertConversationHistory: null,
    alertConversationStatus: null,
    alertEndChat: null,
  },
  selectedAlertID: null,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatSelectedAlertID: (state, action) => {
      state.selectedAlertID = action.payload;
    },
    addPrivilegedParticipants: (state, action) => {
      state.chats[state.selectedAlertID].privileged_conversation.participants =
        action.payload;
    },
    addUnreadMessages: (state, action) => {
      const messageID = action.payload.messageID;
      const privileged_conversation =
        state.chats[action.payload.alertID]?.privileged_conversation;
      const unreadMessages = privileged_conversation?.unreadMessages;

      // TalkJS unreadMessageCount is unreliable as it will switch to 1 on its own
      // More reliable to track lastMessage id and grab length of array
      if (!privileged_conversation) {
        return;
      }
      if (!privileged_conversation.unreadMessages) {
        privileged_conversation.unreadMessages = [messageID];
      } else if (!unreadMessages.includes(messageID)) {
        privileged_conversation.unreadMessages = [
          ...privileged_conversation.unreadMessages,
          messageID,
        ];
      }
    },
    resetUnreadMessages: (state, action) => {
      state.chats[action.payload].privileged_conversation.unreadMessages = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase('incidents/viewAllAlerts', state => {
        state.selectedAlertID = null;
      })
      .addCase(receivedAlertUpdatedStatus, (state, action) => {
        if (
          action.payload.message_type === STATUS_UPDATE &&
          (action.payload.message.name === ACCEPTED ||
            action.payload.message.name === DECLINED)
        ) {
          state[action.payload.alert_id] = {
            ...state[action.payload.alert_id],
            alertResponseBy: action.payload.sender,
          };
        }
        if (
          action.payload.message_type === STATUS_UPDATE &&
          action.payload.message.name === DISPATCH_REQUESTED
        ) {
          state[action.payload.alert_id] = {
            ...state[action.payload.alert_id],
            alertRquestedBy: action.payload.sender,
          };
        }

        if (state[action.payload.alert_id]) {
          if (!state[action.payload.alert_id].logs) {
            state[action.payload.alert_id] = {
              ...state[action.payload.alert_id],
              logs: [],
            };
            state[action.payload.alert_id].logs.push(action.payload);
          } else {
            state[action.payload.alert_id].logs.push(action.payload);
          }
        }
      })
      .addCase(fetchAlertConversationHistory.pending, state => {
        state.loading.alertConversationHistory = true;
        state.error.alertConversationHistory = null;
      })
      .addCase(fetchAlertConversationHistory.rejected, (state, action) => {
        state.loading.alertConversationHistory = false;
        state.error.alertConversationHistory = action.payload;
      })
      .addCase(fetchAlertConversationHistory.fulfilled, (state, action) => {
        const alertID = `alert-${action.payload.alert_id}`;
        return {
          ...state,
          chats: {
            ...state.chats,
            [alertID]: {
              ...state.chats[alertID],
              ...action.payload.chat_messages,
            },
          },
          loading: {
            ...state.loading,
            alertConversationHistory: false,
          },
          error: {
            ...state.error,
            alertConversationHistory: null,
          },
        };
      })
      .addCase(fetchInitialAlertAdr.pending, state => {
        state.loading.alertAdr = true;
        state.error.alertAdr = null;
      })
      .addCase(fetchInitialAlertAdr.rejected, (state, action) => {
        state.loading.alertAdr = false;
        state.error.alertAdr = action.payload;
      })
      .addCase(fetchInitialAlertAdr.fulfilled, (state, action) => {
        const alertID = action.meta.arg;
        const alertDataEventKeys = ['privileged_conversation'];

        const parsedDataByCategories = parseDataByCategories(action.payload, [
          ALERTDATAEVENT,
        ]);

        const parsedData = alertDataEventKeys
          .map(key => {
            return parseNestedData(parsedDataByCategories, key);
          })
          .reduce((acc, data) => {
            const keyName = Object.keys(data)[0];
            acc[keyName] = data[keyName];
            return acc;
          }, {});

        return {
          ...state,
          chats: {
            ...state.chats,
            [alertID]: {
              ...state.chats[alertID],
              ...(parsedData.privileged_conversation && {
                privileged_conversation: {
                  ...state.chats[alertID]?.privileged_conversation,
                  ...parsedData.privileged_conversation,
                },
              }),
            },
          },
        };
      })
      .addCase(fetchAlertAdrICSP.pending, state => {
        state.loading.alertAdr = true;
        state.error.alertAdr = null;
      })
      .addCase(fetchAlertAdrICSP.rejected, (state, action) => {
        state.loading.alertAdr = false;
        state.error.alertConversationHistory = action.payload;
      })
      .addCase(fetchAlertAdrICSP.fulfilled, (state, action) => {
        const alertID = action.meta.arg;
        const reducedAlertDataEvent = reduceAlertDataEvent(action.payload);
        const parsedScriptedResponses = parseScriptedResponses(
          reducedAlertDataEvent,
        );

        if (Object.keys(parsedScriptedResponses).length) {
          if (state.chats?.[alertID]?.adr) {
            // Deep clone the chat object for an alert
            const currentChat = JSON.parse(
              JSON.stringify(state.chats[alertID]),
            );
            const newAdr = {
              ...currentChat.adr,
              ...parsedScriptedResponses,
            };
            return {
              ...state,
              chats: {
                ...state.chats,
                [alertID]: {
                  ...state.chats[alertID],
                  adr: newAdr,
                },
              },
            };
          } else {
            return {
              ...state,
              chats: {
                ...state.chats,
                [alertID]: {
                  adr: parsedScriptedResponses,
                },
              },
            };
          }
        } else {
          if (state.chats[alertID]) {
            return {
              ...state,
              chats: {
                ...state.chats,
                [alertID]: {
                  ...state.chats[alertID],
                  adr: {},
                },
              },
            };
          } else {
            return {
              ...state,
              chats: {
                ...state.chats,
                [alertID]: {
                  adr: {},
                },
              },
            };
          }
        }
      })
      .addCase(fetchAlertConversationStatus.pending, state => {
        state.loading.alertConversationStatus = true;
      })
      .addCase(fetchAlertConversationStatus.rejected, (state, action) => {
        state.loading.alertConversationStatus = false;
        const conversationID = action?.meta?.arg?.conversationID;

        if (conversationID.includes('default-')) {
          const alertID = `alert-${conversationID.split('default-')[1]}`;

          if (state.chats[alertID]) {
            // If the API request returns a 404 status, assume the conversation ID is
            // the alert ID for backwards compatibility
            state.chats[alertID].conversation = {
              status: '',
              id: conversationID,
            };
          } else {
            // This is a non-404 error status. Assume we couldn't get the
            // conversation status
            state.chats[alertID] = {
              conversation: {
                status: '',
                id: conversationID,
              },
            };
          }
        } else if (conversationID.startsWith(CHAT_RAPTOR_PREFIX)) {
          const sessionID = conversationID.substring(CHAT_RAPTOR_PREFIX.length);
          const alertID = `${ALERT_PREFIX}${sessionID}`;

          state.chats[alertID].privileged_conversation.status = '';
        }
      })
      .addCase(fetchAlertConversationStatus.fulfilled, (state, action) => {
        state.loading.alertConversationStatus = false;
        const conversationID = action?.meta?.arg?.conversationID;
        if (conversationID.includes('default-')) {
          const alertID = `alert-${conversationID.split('default-')[1]}`;
          if (state?.chats?.[alertID]) {
            state.chats[alertID].conversation = {
              status: action?.payload?.data?.status,
              id: conversationID,
            };
          } else {
            state.chats[alertID] = {
              conversation: {
                status: action?.payload?.data?.status,
                id: conversationID,
              },
            };
          }
        } else if (conversationID.startsWith(CHAT_RAPTOR_PREFIX)) {
          const sessionID = conversationID.substring(CHAT_RAPTOR_PREFIX.length);
          const alertID = `${ALERT_PREFIX}${sessionID}`;

          state.chats[alertID].privileged_conversation.status =
            action?.payload?.data?.status;
        }
      })
      .addCase(deleteConversation.pending, state => {
        state.loading.alertEndChat = true;
      })
      .addCase(deleteConversation.rejected, state => {
        state.loading.alertEndChat = false;
      })
      .addCase(deleteConversation.fulfilled, (state, action) => {
        const conversationID = action.meta.arg.conversationID;
        state.loading.alertEndChat = false;

        if (conversationID.includes('default-')) {
          const alertID = `alert-${conversationID.split('default-')[1]}`;

          state.chats[alertID].conversation = {
            status: CONVERSATION_ENDED,
            id: conversationID,
          };
        } else if (conversationID.startsWith(CHAT_RAPTOR_PREFIX)) {
          const sessionID = conversationID.substring(CHAT_RAPTOR_PREFIX.length);
          const alertID = `${ALERT_PREFIX}${sessionID}`;

          state.chats[
            alertID
          ].privileged_conversation.status = CONVERSATION_ENDED;
        }
      })
      .addCase('APP_INIT_START', state => {
        return state;
      })
      .addMatcher(
        isAnyOf('FORCED_LOGOUT_SUCCESS', 'LOGOUT_SUCCESS', 'SINATRA_LOGOUT'),
        state => {
          state = initialState;
        },
      );
  },
});

export const {
  setChatSelectedAlertID,
  addPrivilegedParticipants,
  addUnreadMessages,
  resetUnreadMessages,
} = chatSlice.actions;

const persistConfig = {
  key: 'chat',
  storage,
};

const persistedReducer = persistReducer(persistConfig, chatSlice.reducer);

export default persistedReducer;
