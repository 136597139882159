export const IRP_MAP_ENABLED = 'IRP_MAP_ENABLED';
export const WHAT3WORDS = 'WHAT3WORDS';
export const ALERTS = 'ALERTS';

// Agent511 (Text-to-911)
export const AGENT_511 = 'AGENT_511';

// GIS Services
export const GIS_SERVICES = 'GIS_SERVICES';

export const UNITE_EVENT_PANE_PERMISSION = 'UNITE_EVENT_PANE';

