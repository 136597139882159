import { getAPIHost } from '@rsos/utils/metaTags';

const authenticateWithSSO = async (email, redirectURI) => {
  const resp = await fetch(
    `${getAPIHost()}/v1/scorpius/sso/authenticate?email=${email}&redirect_uri=${redirectURI}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  const data = await resp.json();
  return data?.authorization_url;
};

export default authenticateWithSSO;
