export const USERS_CONTENT = 'users';
export const ROLES_CONTENT = 'roles';
export const AGENCY_INFO_CONTENT = 'agency-info';
export const PEER_AGENCIES = 'share-and-chat';
export const INBOUND_CONTENT = 'inbound';
export const OUTBOUND_CONTENT = 'outbound';
export const SECURITY_CONTENT = 'security';
export const DATA_SOURCE_CONTENT = 'data-source';
export const ADMIN_INFO = 'admin';
export const CAPSTONE = 'capstone';
export const CENTRAL_STATION = 'central_station';
export const SETTINGS_CONTENT = 'settings';
export const ALERTS_ROUTING_CONTENT = 'alerts-routing';
export const RESPONDERS_CONTENT = 'responders';
export const COMMUNICATION_CONTENT = 'text-from-911';
export const SSO_CONTENT = 'sso';
export const NOTIFICATIONS_CONTENT = 'notifications';
