import normalizeError from '../utils/normalizeError';
import { getTokens } from '../utils/tokens';
import instance from './scorpiusInstance';
import {
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  RESET_NEW_DATASOURCE,
} from './types';

const CAPSTONE = 'capstone';
const CENTRAL_STATION = 'central_station';

const normalizeCurrentOrg = user => {
  if (user && user.organizations) {
    for (const org of user.organizations) {
      if (org.roles) {
        for (const role of org.roles) {
          if (
            role.application === CAPSTONE ||
            role.application === CENTRAL_STATION
          ) {
            return { currentRole: role, currentOrg: org };
          }
        }
      }
    }
  }
  return { currentRole: {}, currentOrg: {} };
};

const selectFullPermissions = user => {
  if (user && user.organizations) {
    for (const org of user.organizations) {
      if (org.roles) {
        for (const role of org.roles) {
          if (
            role.application === CAPSTONE ||
            role.application === CENTRAL_STATION
          ) {
            return { permissionFull: role.permissions_full };
          }
        }
      }
    }
  }
  return { permissionFull: [] };
};

const getNewDataSourceList = permissions =>
  permissions.filter(permission => permission.is_new === true);

export const getPageAccessList = permissions =>
  permissions.filter(permission => permission.rsp_rbac === true);

const checkNewDataSource = permissions =>
  permissions.some(permission => permission.is_new === true);

export const fetchUserStart = () => ({
  type: FETCH_USER_START,
});

export const fetchUserSuccess = (
  profile,
  currentOrg,
  currentRole,
  hasNewDataSource,
  newDataSourceList,
  pageAccessList,
) => ({
  type: FETCH_USER_SUCCESS,
  profile,
  currentRole,
  currentOrg,
  hasNewDataSource,
  newDataSourceList,
  pageAccessList,
});

export const fetchUserError = error => ({
  type: FETCH_USER_ERROR,
  error: error?.message || error,
});

export const fetchUser = () => async dispatch => {
  dispatch(fetchUserStart());
  try {
    const tokens = getTokens();
    // Update the Scorpius instance with the bearer token if it exists. On initial
    // login and IRP page load, the instance isn't updated with the token yet,
    // which causes 401 status on initial fetchUser() on login and parsing of the
    // access_token query param for IRP. See MC-15242 for more details
    if (tokens) {
      instance.defaults.headers.Authorization = `Bearer ${tokens.token}`;
    }
    const response = await instance.get('/user', { headers: { retry: true } });
    const currentRole = normalizeCurrentOrg(response.data).currentRole;
    const currentOrg = normalizeCurrentOrg(response.data).currentOrg;
    const permissionFull = selectFullPermissions(response.data).permissionFull;
    const isAcknowledge = localStorage.getItem('acknowledgeBanner')
      ? localStorage.getItem('acknowledgeBanner')
      : false;
    const hasNewDataSource =
      checkNewDataSource(permissionFull) && !isAcknowledge;
    const newDataSourceList = getNewDataSourceList(permissionFull);
    const pageAccessList = getPageAccessList(permissionFull);

    return dispatch(
      fetchUserSuccess(
        response.data,
        currentOrg,
        currentRole,
        hasNewDataSource,
        newDataSourceList,
        pageAccessList,
      ),
    );
  } catch (error) {
    throw dispatch(fetchUserError(normalizeError(error)));
  }
};

export const resetNewDataSource = () => {
  const isAcknowledged = localStorage.getItem('acknowledgeBanner')
    ? localStorage.getItem('acknowledgeBanner')
    : false;
  return {
    type: RESET_NEW_DATASOURCE,
    isAcknowledged,
  };
};

export default fetchUser;
