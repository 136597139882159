import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@rsos/components/capstone/base';

const SmartLink = ({ to, children, toolTip, name, ...rest }) => {
  return (
    <StyledLink href={to} data-name={name} {...rest}>
      <>
        {children}
        {toolTip ? (
          <TooltipChild>
            <InlineText size="small" fontWeight="bold">
              {toolTip.primary}
            </InlineText>
            <InlineText size="small">&nbsp;{toolTip.secondary}</InlineText>
          </TooltipChild>
        ) : null}
      </>
    </StyledLink>
  );
};

export default SmartLink;

export const TooltipChild = styled(Box).attrs(() => ({
  position: 'absolute',
  color: 'background',
  bg: 'primaryText',
  borderRadius: '5px',
  p: '10px',
}))`
  display: none;
  width: 197px;
  left: 100%;
  z-index: 1;
  top: -50%;
`;
export const InlineText = styled(Text).attrs()`
  display: inline;
`;

const StyledLink = styled.a`
  text-decoration: none;
  padding: 0px 20px;
  position: relative;
  &:hover {
    background: ${p => p.theme.colors.heavyLineOutline};
    ${TooltipChild} {
      display: inline;
    }
  }
  &.active {
    background: ${p => p.theme.colors.primaryBaseC2};
  }
`;
