import React from 'react';
import {
  ChromeIcon,
  SafariIcon,
  FirefoxIcon,
  EdgeIcon,
} from '@rsos/assets/icons';

const supportedBrowsers = [
  {
    name: 'Google Chrome',
    version: 87,
    icon: <ChromeIcon />,
  },
  {
    name: 'Firefox',
    version: 84,
    icon: <FirefoxIcon />,
  },
  {
    name: 'Edge',
    version: 87,
    icon: <EdgeIcon />,
  },
  {
    name: 'Safari',
    version: 11,
    icon: <SafariIcon />,
  },
];

export default supportedBrowsers;
