/**
 * Find the permissions that matches the given appName for the current user.
 * @param {Object} user - The user state in Sinatra
 * @param {string} appName - The name of the application to match
 */
export const selectUserPermissionsByApp = (user, appName) => {
  if (!user.isLoggedIn) {
    return;
  }
  const { organizations } = user.profile;
  let permissions = [];
  organizations.forEach(org => {
    org.roles.forEach(role => {
      if (role.application === appName) {
        permissions = role.permissions;
      }
    });
  });
  return permissions;
};

/**
 * Find the full permissions that matches the given appName for the current user.
 * @param {Object} user - The user state in Sinatra
 * @param {string} appName - The name of the application to match
 */
export const selectFullUserPermissionsByApp = (user, appName) => {
  if (!user.isLoggedIn) {
    return;
  }
  const { organizations } = user.profile;
  let permissionsFull = [];
  organizations.forEach(org => {
    org.roles.forEach(role => {
      if (role.application === appName) {
        permissionsFull = role.permissions_full;
      }
    });
  });
  return permissionsFull;
};

// TODO: use this function in the functions above and in `selectFullPermissions` in `fetchUser.js`
const MEDICALERT = 'medicalert';
const PROFILE_INFO = 'profile_info';

/**
 * Normalize user permissions due to legacy naming conventions
 * @param {Array} permissions - User permissions
 */
const normalizeUserPermissions = permissions => {
  return permissions.map(p => {
    if (p.toLowerCase() === PROFILE_INFO) {
      return MEDICALERT;
    }
    // TODO: return p.lowerCase() in future release to normalize inconsistency with capitaliziation
    return p;
  });
};

export default normalizeUserPermissions;
