import { soundFileMapper } from '@rsos/constants';
import { captureExceptionWithScope } from '@rsos/utils/sentry';

const ALERTS = 'ALERTS';
const SMS = 'SMS';
const TT911 = 'TT911';

export const getSoundFilePath = name => {
  const soundObject = soundFileMapper.find(s => s.name === name);
  return new Audio(soundObject.path);
};

/**
 * Handles and attempts to playback the audio media. It returns a Promise which is resolved when
 * playback has been successfully started, or is rejected if for any reason playback cannot be
 * started, such as user denied permission to playback audio.
 * @param {Object} audioElement - The audio media element object
 * @param {Object} audioName - The audio name
 */
export const handleAudioPlayback = (audioElement, audioName) => {
  if (audioElement) {
    audioElement.play().catch(error => {
      // If the DOMException name is NotAllowedError, do not send it to Sentry,
      // otherwise, report the DOMException error.
      // Swallow NotAllowedError: The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
      if (error?.name !== 'NotAllowedError') {
        captureExceptionWithScope(error, {
          extra: {
            audioName: audioName,
            audioSrc: audioElement.src,
            errorMessage: error?.message,
          },
        });
      }
    });
  }
};

export const mockOrgSettings = {
  calls: {
    sound_on: true,
    tone: 'xyloToggle',
  },
  alerts: {
    sound_on: true,
    tone: 'glassVibes',
    repeat: true,
  },
  sms: {
    sound_on: true,
    tone: 'tripleVibe',
  },
};

export const mockUserSettings = {
  calls: {
    volume: 0.7,
  },
  alerts: {
    volume: 0.7,
  },
  sms: {
    volume: 0.7,
  },
};

export const setUpTicker = (alert, selectedAudio, alertsTone, onEnd) => {
  const expirationTime = alert.sla_expiration_time;
  selectedAudio.loop = true;
  handleAudioPlayback(selectedAudio, alertsTone);

  setTimeout(() => {
    selectedAudio.loop = false;
    onEnd();
  }, expirationTime - Date.now());
};

export const getAudioTone = (locationType, settings, hasAgent511Access) => {
  const { tt911, sms, calls } = settings;

  let audioName, audioElement;

  if (locationType === SMS.toLocaleLowerCase()) {
    if (hasAgent511Access) {
      audioName = tt911.tone;
      audioElement = getSoundFilePath(tt911.tone);
    } else {
      audioName = sms.tone;
      audioElement = getSoundFilePath(sms.tone);
    }
  } else {
    audioName = calls.tone;
    audioElement = getSoundFilePath(calls.tone);
  }

  return {
    audioName,
    audioElement,
  };
};

/**
 * get filtered org and user audio settings based on JV/QV and alerts permission
 *
 * @param {Object} audioSettings - org or user audio settings from db
 * @param isJVEnabled - is the ECC JV enabled or not
 * @param displayAlert - with Alert permission or not
 */
export const getFilteredAudioSettings = (
  orgAudioSettings,
  isJVEnabled,
  displayAlert,
  hasAgent511Access,
) => {
  if (!isJVEnabled) {
    return displayAlert
      ? Object.keys(orgAudioSettings)
          .filter(key => key === ALERTS.toLowerCase())
          .reduce((res, key) => ((res[key] = orgAudioSettings[key]), res), {})
      : {};
  } else {
    const tt911Condition = hasAgent511Access
      ? SMS.toLowerCase()
      : TT911.toLowerCase();
    const alertsCondition = !displayAlert ? ALERTS.toLowerCase() : null;

    return Object.keys(orgAudioSettings)
      .filter(key => key !== tt911Condition && key !== alertsCondition)
      .reduce((res, key) => ((res[key] = orgAudioSettings[key]), res), {});
  }
};

export const getTT911EventInitialState = phoneNumber => {
  return {
    interval: null,
    status: 'idle',
    phoneNumber: phoneNumber,
  };
};

export const handleWithInterval = (
  obj,
  repeatTimes,
  tt911Events,
  cb,
  tone,
  tt911Volume,
) => {
  const { phoneNumber } = obj;

  let audio = getSoundFilePath(tone);
  audio.volume = tt911Volume;
  let interval,
    counter = 0;

  handleAudioPlayback(audio, tone);

  const checkIfStopAudio = () => {
    if (audio.ended) {
      counter++;
      if (counter === repeatTimes) {
        cb(phoneNumber, { status: 'finish' });
        return clearInterval(interval);
      } else {
        handleAudioPlayback(audio, tone);
      }
    }
  };
  interval = setInterval(checkIfStopAudio, 1000);
  cb(phoneNumber, { status: 'pending', interval: interval });
};
