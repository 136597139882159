import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 11,
  height: 11,
  viewboxWidth: 14,
  viewboxHeight: 14,
};

const XCloseIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <path
        d="M13.506.5a1.666 1.666 0 0 0-2.356 0L7 4.65 2.873.5a1.666 1.666 0 0 0-2.38 0 1.69 1.69 0 0 0 0 2.368l4.163 4.136-4.164 4.123a1.683 1.683 0 1 0 2.381 2.38L7 9.345l4.138 4.162a1.705 1.705 0 0 0 2.369 0 1.664 1.664 0 0 0 0-2.354l-4.151-4.15 4.15-4.135a1.666 1.666 0 0 0 0-2.367Z"
        fill="currentColor"
      />
    </SVGLoader>
  );
};

export default XCloseIcon;
