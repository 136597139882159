import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ExternalLinkIcon } from '@rsos/assets/icons';
import Popover from '@rsos/components/capstone/base/Popover';
import {
  Toolbar,
  ToolbarButton,
  ToolbarButtonContent,
  ToolbarButtonText,
  AppTooltip,
  AppTooltipText,
} from './AppLauncher.styles';
import { applicationPropTypes } from './propTypes';

/**
 * Sub-component of AppLauncher that renders the app/tools launcher toolbar.
 * This is used when there is a selected call.
 * @param {Function} onHandleApp - Function that gets called when an app/tools is selected
 * @param {Array} toolbarApps - List of app/tools launcher apps that can be displayed,
 * currently a maximum of 5 apps can be displayed in the toolbar
 */
const AppLauncherToolbar = ({ onHandleApp, toolbarApps }) => {
  const toolbarAppRef = useRef([]);
  const popoverRef = useRef();
  const [hoveredAppID, setHoveredAppID] = useState(null);
  const [popoverBoundingClient, setPopoverBoundingClient] = useState(null);
  const [toolbarButtonWidths, setToolbarButtonWidths] = useState([]);

  // When hovered over an app, set the popover element rectangle size
  useEffect(() => {
    hoveredAppID && popoverRef?.current
      ? setPopoverBoundingClient(popoverRef.current.getBoundingClientRect())
      : setPopoverBoundingClient(null);
  }, [hoveredAppID]);

  const handleHovered = appID => setHoveredAppID(appID);

  useLayoutEffect(() => {
    setToolbarButtonWidths(buttonWidths => [
      ...buttonWidths,
      ...toolbarAppRef.current,
    ]);
  }, []);

  /**
   * Calculate the x offset of which the popover should appear from the trigger element
   * @param {number} appIndex - the index of the app in the toolbarApps array
   * @returns number
   */
  const calculateXOffset = appIndex => {
    const appWidth = toolbarButtonWidths?.[appIndex];
    const popoverWidth = popoverBoundingClient?.width;
    return (appWidth - popoverWidth) / 2;
  };

  const renderAppLauncherButton = (app, appIndex) => {
    const BUTTON_SPACING = 26;
    const buttonWidth = toolbarButtonWidths?.[appIndex]
      ? toolbarButtonWidths[appIndex] - BUTTON_SPACING
      : 'auto';

    return (
      <ToolbarButton
        type="button"
        onClick={() => onHandleApp(app, 'toolbar')}
        onMouseEnter={() => handleHovered(app.id)}
        onMouseLeave={() => handleHovered(null)}
      >
        <ToolbarButtonContent width={buttonWidth}>
          <ToolbarButtonText>{app.call_to_action}</ToolbarButtonText>
          {app.type === 'app_launch' && app.id === hoveredAppID && (
            <ExternalLinkIcon height={12} width={12} />
          )}
        </ToolbarButtonContent>
      </ToolbarButton>
    );
  };

  return (
    <Toolbar data-name="app-launcher-toolbar">
      {toolbarApps &&
        toolbarApps?.map((app, index) => (
          <div
            key={app.id}
            ref={e => (toolbarAppRef.current[index] = e?.offsetWidth)}
          >
            <Popover
              popoverID={`tooltip-${app.id}`}
              isOpen={app.id === hoveredAppID}
              trigger={renderAppLauncherButton(app, index)}
              useCustomOffset
              xOffset={calculateXOffset(index)}
              yOffset={48}
            >
              <AppTooltip ref={popoverRef}>
                <AppTooltipText>{app.display_name_short}</AppTooltipText>
              </AppTooltip>
            </Popover>
          </div>
        ))}
    </Toolbar>
  );
};

AppLauncherToolbar.propTypes = {
  onHandleApp: PropTypes.func.isRequired,
  toolbarApps: applicationPropTypes.isRequired,
};

export default AppLauncherToolbar;
