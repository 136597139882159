import React from 'react';
import SVGLoader from './SVGLoader';

const CheckIcon = ({ ...rest }) => {
  return (
    <SVGLoader {...rest}>
      <path d="M70.9,29a6,6,0,0,0-4.12,1.81c-9.35,9.37-16.56,17.23-25.13,26l-8.71-7.36a6,6,0,1,0-8,8.91,3.11,3.11,0,0,0,.28.23l13,11a6,6,0,0,0,8.12-.34c10.78-10.8,18.44-19.41,29-30A6,6,0,0,0,70.9,29Z" />
    </SVGLoader>
  );
};

export default CheckIcon;
