import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const DEBOUNCE_MS = 200;
  const [dimensions, setDimensions] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    let timerId;
    const handler = function () {
      clearTimeout(timerId);
      timerId = setTimeout(
        () => setDimensions([window.innerWidth, window.innerHeight]),
        DEBOUNCE_MS,
      );
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);
  return dimensions;
};

export default useWindowSize;
