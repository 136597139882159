import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { JURISDICTION_VIEW as JV_CAPABILITY } from '@rsos/capstone/src/constants/capabilities';
import AppLauncher from '@rsos/components/capstone/AppLauncher';
import { Box } from '@rsos/components/capstone/base';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import {
  EMERGENCY_DATA,
  AUTHENTICATION,
  CAPSTONE,
} from '@rsos/constants/appNames';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import useSelectedTheme from '@rsos/hooks/useSelectedTheme';
import useIsObliqueImagerySelected from '@rsos/reduxDataStore/src/features/appLauncher/useIsObliqueImagerySelected';
import { DARK_THEME, DARKER_THEME } from '@rsos/theme/constants';
import determineDataPath from '@rsos/utils/determineDataPath';
import { readMetaTag } from '@rsos/utils/metaTags';
import onSAMLrequest from '@rsos/utils/onSAMLrequest';
import useIsIRP from '@rsos/utils/useIsIRP';
import usePortalType from '@rsos/utils/usePortalType';
import StyledSmartLink from '../common/StyledSmartLink';
import { RapidSOSLogoCompact, FullLogo } from '../common/logo/RapidSOSLogo';
import EagleViewBanner from './EagleViewBanner/EagleViewBanner';
import NavigationControl from './NavigationControl/NavigationControl';
import PremiumLogo from './PremiumLogo/PremiumLogo';
import SettingsControl from './SettingsControl';

const feAppName = readMetaTag('fe-app-name');

const notFoundPath = '/not-found';
const signUpPath = '/sign-up';

const RSPNavigation = ({ handleLogout, path, persistor }) => {
  const dispatch = useDispatch();
  const { sinatra } = useSelector(state => state);
  const { currentPSAP } = useSelector(state => state.psaps);
  const { currentRole = {} } = sinatra.user;
  const application = currentRole?.application;
  const hasNewDataSource = sinatra.user.hasNewDataSource;
  const themeName = useSelectedTheme();
  const isLoadingSAML = sinatra.auth.loadingSAML;
  const { layout } = useContext(LayoutContext);
  const { activeCapabilities = {} } = currentPSAP;
  const isJVenabled = !!activeCapabilities[JV_CAPABILITY];
  const { irp_access_token } = useIsIRP();
  const isLoggedIn = sinatra?.user?.isLoggedIn;
  const isAnchoredPath = path === notFoundPath || path.includes(signUpPath);
  const disableNavBarNavigation = path === notFoundPath;
  const disableLogoNavigation =
    path ===
    determineDataPath(application, isJVenabled, irp_access_token, isLoggedIn);

  const handleSAMLRequest = service => {
    return onSAMLrequest(service, dispatch, isLoadingSAML, persistor);
  };
  const initialized = useSelector(state => state.app?.initialized);
  const { isPremiumUser } = usePortalType();
  const isPremiumLogo =
    isPremiumUser && application === CAPSTONE && feAppName !== AUTHENTICATION;

  const isObliqueImageryToolSelected = useIsObliqueImagerySelected();

  return (
    <>
      <Box horizontal align="center">
        <Box flex="0 0 auto">
          <StyledSmartLink
            to={determineDataPath(
              application,
              isJVenabled,
              irp_access_token,
              isLoggedIn,
            )}
            type="standalone"
            data-name="navigation-logo-link"
            useAnchor={isAnchoredPath}
            disableNavigation={disableLogoNavigation || disableNavBarNavigation}
          >
            {layout.window.width <= BP_LAPTOP ? (
              isPremiumLogo ? (
                <PremiumLogo initialized={initialized} compact />
              ) : (
                <RapidSOSLogoCompact static />
              )
            ) : isPremiumLogo ? (
              <PremiumLogo initialized={initialized} />
            ) : (
              <FullLogo
                static
                color={
                  themeName === DARK_THEME || themeName === DARKER_THEME
                    ? 'background'
                    : 'primaryText'
                }
              />
            )}
          </StyledSmartLink>
        </Box>
        {feAppName === EMERGENCY_DATA && isLoggedIn && (
          <Box horizontal align="center" flex="0 1 auto">
            <AppLauncher />
          </Box>
        )}
      </Box>

      {isObliqueImageryToolSelected && <EagleViewBanner />}
      {sinatra.user.isLoggedIn && !disableNavBarNavigation && (
        <Box horizontal>
          <SettingsControl path={path} />
          <NavigationControl
            user={sinatra.user}
            handleSAMLRequest={handleSAMLRequest}
            hasNewDataSource={hasNewDataSource}
            isLoadingSAML={isLoadingSAML}
            handleLogout={handleLogout}
            irpAccessToken={irp_access_token}
            isJVenabled={isJVenabled}
            data-name="navigation-navigation-control"
          />
        </Box>
      )}
    </>
  );
};

export default withTheme(RSPNavigation);
