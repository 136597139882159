import instance from './scorpiusInstance';
import {
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
} from './types';

export const registerUserStart = () => ({
  type: REGISTER_USER_START,
});

export const registerUserSuccess = response => ({
  type: REGISTER_USER_SUCCESS,
  payload: response,
});

export const registerUserError = error => ({
  type: REGISTER_USER_ERROR,
  error,
});

/**
 * Registers the given user with the Scorpius API.
 * @param {Object} user
 * @param {String} user.email
 * @param {String} user.password
 * @param {String} user.first_name
 * @param {String} user.last_name
 * @param {String} user.organization_name
 * @param {String} user.application
 * @param {Object} [user.attributes]
 */
export const registerUser = (user) => async (dispatch) => {
  dispatch(registerUserStart());
  try {
    const response = await instance.post('/user/register', user);
    return dispatch(registerUserSuccess(response.data));
  } catch (error) {
    throw dispatch(registerUserError(error));
  }
};
