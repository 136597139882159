import styled, { keyframes } from 'styled-components';
import Box from '../../Box';

const enter = keyframes`
 from {
   opacity: 0;
   transform: translateY(-5px);
 }
 to {
   opacity: 1;
   transform: translateY(0);
 }
`;
export const StyledErrorsWrapper = styled(Box).attrs({
  p: '3px',
  position: 'relative',
})`
  color: ${p => p.theme.colors.lightDanger};
  animation: 250ms ease ${enter};
`;
