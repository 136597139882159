import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@rsos/assets/icons';
import { Box, Text, Button } from '@rsos/components/capstone/base';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import { MainHeader, MainDescription } from '../Landing.styles.js';
import StyledSmartLink from '../StyledSmartLink';
import {
  SecondRowWrapper,
  SecondRowContentWrapper,
  ImageContainer,
  SecondRowTextBox,
} from './SecondRow.styles.js';

const landing_image = require('../../../assets/landing_image.png');

const SecondRow = () => {
  const { layout } = useContext(LayoutContext);

  return (
    <SecondRowWrapper>
      <Box justify="center" height="100%">
        <SecondRowContentWrapper>
          <SecondRowTextBox>
            <MainHeader
              textAlign={layout.window.width > BP_LAPTOP ? 'left' : 'center'}
              color="primaryText"
            >
              Data that goes beyond an emergency call
            </MainHeader>
            <MainDescription
              color="secondaryText"
              textAlign={layout.window.width > BP_LAPTOP ? 'left' : 'center'}
            >
              Each year, over one billion emergencies rely on the limited
              context of a phone call, resulting in delays in dispatch. But it
              doesn’t have to be that way—sharing additional data with first
              responders can save lives.
            </MainDescription>
            <Box
              horizontal
              flow={28}
              mt={40}
              align="center"
              justify={layout.window.width >= BP_LAPTOP ? 'unset' : 'center'}
            >
              <Link id="signup" to="sign-up" style={{ textDecoration: 'none' }}>
                <Button btnType="primary">New Agency Sign up</Button>
              </Link>
              <StyledSmartLink to="https://rapidsos.com/">
                <Box
                  horizontal
                  flow={3}
                  color="primaryBase"
                  align="center"
                  justify="center"
                >
                  <Text size="small">Learn more</Text>
                  <ArrowRightIcon width={14} height={14} />
                </Box>
              </StyledSmartLink>
            </Box>
          </SecondRowTextBox>
          <ImageContainer>
            <img src={landing_image} alt="landing image" />
          </ImageContainer>
        </SecondRowContentWrapper>
      </Box>
    </SecondRowWrapper>
  );
};

export default SecondRow;
