import React from 'react';
import styled from 'styled-components';
import Box from '../../Box';
import ModalClose from './ModalClose';
import { getFlexProp } from './utils';

const ModalHeaderContainer = styled(Box).attrs(p => ({
  justify: getFlexProp(p.align),
  mb: '13px',
}))`
  text-align: ${p => (p.align ? p.align : 'inherit')};
`;

const ModalHeader = ({ children, align, onClose, className }) => (
  <ModalHeaderContainer align={align} className={className}>
    <ModalClose onClose={onClose} />
    {children}
  </ModalHeaderContainer>
);

export default ModalHeader;
