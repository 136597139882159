import styled from 'styled-components';
import { Box, Text, Absolute } from '@rsos/components/capstone/base';

export const ItemCategoryName = styled(Text).attrs(({ sideBarOpen }) => ({
  p: sideBarOpen ? '20px 0 15px 20px' : '20px 5px 15px 5px',
  size: 'xsmall',
  textAlign: sideBarOpen ? 'inherit' : 'center',
  color: 'secondaryText',
  noWrap: true,
}))``;

export const ItemIconWrapper = styled(Box).attrs(({ isActive }) => ({
  align: 'center',
  justify: 'center',
  color: isActive ? 'foreground' : 'primaryBaseC2',
}))`
  min-height: 35px;
`;
export const ItemTitle = styled(Text).attrs(({ titleWrap, isActive }) => ({
  size: 'normal',
  noWrap: !titleWrap,
  color: isActive ? 'foreground' : 'primaryBaseC2',
}))`
  display: flex;
  align-items: center;
`;

export const Circle = styled(Absolute)`
  right: 5px;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  border: ${({ theme }) => `1px solid ${theme.colors.primaryBase}`};
  content: '';
  border-radius: 50%;
`;
