import { createContext, useContext, useState } from 'react';
import exportToGlobalNamespace from '@rsos/utils/exportToGlobalNamespace';

const FlagContext = createContext({});

export const FLAG_PREFIX = 'FLAG_';
export const RELEASE_FLAG_SCRIPT_ID = 'feature-flags-json';

export const readDevelopmentFlags = () => {
  const flags = {};
  Object.keys(process.env).forEach(key => {
    if (key.startsWith(FLAG_PREFIX)) {
      const value = process.env[key];
      const cleanKey = `${key}`.replace(FLAG_PREFIX, '').toLowerCase();
      if (typeof value === 'string') {
        flags[cleanKey] = value === 'true';
      } else {
        flags[cleanKey] = value;
      }
    }
  });
  return flags;
};

export const readReleaseFlags = () => {
  const script = document.querySelector(`#${RELEASE_FLAG_SCRIPT_ID}`);
  try {
    const flags = {};
    const releaseFlags = JSON.parse(script.textContent);
    Object.keys(releaseFlags).forEach(key => {
      flags[key.toLowerCase()] = releaseFlags[key];
    });
    return flags;
  } catch (error) {
    return {};
  }
};

export const readFlags = () => {
  const devFlags = readDevelopmentFlags();
  const releaseFlags = readReleaseFlags();

  // combine flags, with release flags taking precedence
  const combinedFlags = { ...devFlags, ...releaseFlags };

  // warn about missing flags in development
  if (process.env.NODE_ENV === 'development') {
    for (const flagName of Object.keys(releaseFlags)) {
      if (devFlags[flagName] === undefined) {
        // eslint-disable-next-line no-console -- this is a dev warning
        console.warn(
          `[RSOS]: Release flag ${flagName} was found remotely but is missing locally.`,
          `\n`,
          `If it's a valid flag, please add it locally so it can be tracked:`,
          `\n`,
          `\t yarn flags add ${flagName}`,
          `\n`,
          `Otherwise, please clean up the flag in hydra.`,
        );
      }
    }
  }

  return combinedFlags;
};

export const useFlag = name => {
  const flags = useContext(FlagContext);
  return flags[`${name}`.toLowerCase()];
};

export const useFlags = () => {
  return useContext(FlagContext);
};

const FlagProvider = ({ children }) => {
  const [flags, setFlags] = useState(readFlags());
  const { Provider } = FlagContext;

  exportToGlobalNamespace('flags', {
    override: (key, value) => {
      const lowerKey = key.toLowerCase();
      setFlags({ ...flags, [lowerKey]: value });
    },
    list: () => flags,
  });

  return <Provider value={flags}>{children}</Provider>;
};

export default FlagProvider;
