import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isStringsArray from '@rsos/utils/isStringsArray';

/**
 * Custom hook that checks if the current user has the permission(s) enabled.
 * @param {(String|Array.<String>)} permissions - Permission name or list of permission names.
 * @returns Boolean
 */
const useHasPermission = permissions => {
  let error = null;

  if (!permissions) {
    error = Error('A permission name is required.');
  } else if (
    (typeof permissions !== 'string' && !Array.isArray(permissions)) ||
    (Array.isArray(permissions) && !isStringsArray(permissions))
  ) {
    error = Error('Permission name must be a string or an array of strings.');
  }

  const activePermissions = useSelector(
    state => state?.sinatra?.user?.currentRole?.permissions || [],
  );

  const hasPermission = useMemo(() => {
    if (typeof permissions === 'string') {
      return activePermissions.includes(permissions);
    } else if (Array.isArray(permissions) && isStringsArray(permissions)) {
      return permissions.every(permission =>
        activePermissions.includes(permission),
      );
    }
  }, [activePermissions, permissions]);

  return { hasPermission, error };
};

export default useHasPermission;
