import { combineReducers } from 'redux';
import { sinatra } from '@rsos/sinatra';
import auth from './auth';
import psaps from './psaps';

const rootReducer = combineReducers({
  auth,
  psaps,
  sinatra,
});

export default rootReducer;
