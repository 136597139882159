/**
 * Priority order of portal positioning in the DOM is defined here.
 * The higher the value the lower it is defined in the DOM, so elements
 * can overlap lower priority elements.
 * e.g:
 * <div id="lower-portal" data-priority="1" />
 * <div id="overlapping-portal" data-priority="2" />
 */
export const PORTAL_SESSION_PRIORITY = 5000; // Should always be ontop
export const PORTAL_POPOVER_PRIORITY = 2;
export const PORTAL_LIGHTBOX_PRIORITY = 1;
export const PORTAL_STANDARD_MODAL_PRIORITY = 3;
export const PORTAL_OBLIQUE_IMAGERY_MODAL_PRIORITY = 4;
