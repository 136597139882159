export const TRAIN_DERAILMENT = 'TRAIN_DERAILMENT';
export const ACTIVE_ASSAILANT = 'ACTIVE_ASSAILANT';
export const TEST_TRAIN_DERAILMENT = 'TEST_TRAIN_DERAILMENT';
export const TEST_ACTIVE_ASSAILANT = 'TEST_ACTIVE_ASSAILANT';

export const ACTIVE_ASSAILANT_ALERT_TYPE = 'alerts_active_assailant';
export const MEDICAL_ALERT_TYPE = 'alerts_medical';
export const TRAIN_INCIDENT_ALERT_TYPE = 'alerts_train_incident';
export const TEST_MODE_ALERT_TYPE = 'alerts_test_mode';
export const LAW_ENFORCEMENT_ALERT_TYPE = 'alerts_law_enforcement';
export const FIRE_ALERT_TYPE = 'alerts_fire';
export const INCIDENT_MANAGEMENT_TYPE = 'alerts_incident_management';
