import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Button extends Component {
  render() {
    const {
      id,
      disabled,
      buttonClicked,
      className,
      name,
      type,
    } = this.props;

    return (
      <button id={id ? id : name}
              className={className}
              disabled={disabled}
              onClick={buttonClicked}
              name={name}
              type={type}
      >
        {this.props.children}
      </button>
    )
  }
}

Button.propTypes = {
  buttonClicked: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
};

Button.defaultProps = {
  className: '',
}

export default Button;