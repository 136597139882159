import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 17,
  height: 16,
  viewboxWidth: 17,
  viewboxHeight: 16,
};

const NavigationControlIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <g fill="currentColor">
        <path d="M15.6 6.672H2.063a1.23 1.23 0 1 0 0 2.462h13.539a1.231 1.231 0 0 0 0-2.462ZM15.6 11.597H2.063a1.23 1.23 0 1 0 0 2.461h13.539a1.231 1.231 0 0 0 0-2.461ZM15.6 1.75H2.063a1.23 1.23 0 1 0 0 2.462h13.539a1.231 1.231 0 0 0 0-2.462Z" />
      </g>
    </SVGLoader>
  );
};

export default NavigationControlIcon;
