// List of basemap names for Esri and Google maps.
// https://developers.arcgis.com/rest/basemap-styles/#arcgis-styles
// https://developers.arcgis.com/documentation/mapping-apis-and-services/maps/basemap-layers/
// https://developers.google.com/maps/documentation/javascript/maptypes#BasicMapTypes
export const BASEMAP_ESRI_NAVIGATION_DARK = 'arcgis-navigation-night';
export const BASEMAP_ESRI_NAVIGATION_LIGHT = 'arcgis-navigation';
export const BASEMAP_ESRI_SATELLITE = 'arcgis-imagery-standard';
export const BASEMAP_GOOGLE_HYBRID = 'hybrid';
export const BASEMAP_GOOGLE_ROADMAP = 'roadmap';

// List of basemap add-on names used in the basemap menu.
export const BASEMAP_ADDON_GIS = 'gis';
