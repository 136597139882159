exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("@rsos/base-assets/fonts/roboto-regular.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("@rsos/base-assets/fonts/roboto-regular.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("@rsos/base-assets/fonts/roboto-italic.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("@rsos/base-assets/fonts/roboto-italic.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("@rsos/base-assets/fonts/roboto-500.woff2"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("@rsos/base-assets/fonts/roboto-500.woff"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("@rsos/base-assets/fonts/roboto-500italic.woff2"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("@rsos/base-assets/fonts/roboto-500italic.woff"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("@rsos/base-assets/fonts/roboto-700.woff2"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("@rsos/base-assets/fonts/roboto-700.woff"));
var ___CSS_LOADER_URL___10___ = urlEscape(require("@rsos/base-assets/fonts/roboto-700italic.woff2"));
var ___CSS_LOADER_URL___11___ = urlEscape(require("@rsos/base-assets/fonts/roboto-700italic.woff"));
var ___CSS_LOADER_URL___12___ = urlEscape(require("@rsos/base-assets/fonts/Soleil-Regular.otf"));
var ___CSS_LOADER_URL___13___ = urlEscape(require("@rsos/base-assets/fonts/Soleil-Italic.otf"));
var ___CSS_LOADER_URL___14___ = urlEscape(require("@rsos/base-assets/fonts/Soleil-Bold.otf"));

// Module
exports.push([module.id, "@font-face{font-family:\"Roboto\";font-style:normal;font-weight:400;src:local(\"Roboto\"),local(\"Roboto-Regular\"),url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\")}@font-face{font-family:\"Roboto\";font-style:italic;font-weight:400;src:local(\"Roboto Italic\"),local(\"Roboto-Italic\"),url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___3___ + ") format(\"woff\")}@font-face{font-family:\"Roboto\";font-style:normal;font-weight:500;src:local(\"Roboto Medium\"),local(\"Roboto-Medium\"),url(" + ___CSS_LOADER_URL___4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___5___ + ") format(\"woff\")}@font-face{font-family:\"Roboto\";font-style:italic;font-weight:500;src:local(\"Roboto Medium Italic\"),local(\"Roboto-MediumItalic\"),url(" + ___CSS_LOADER_URL___6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___7___ + ") format(\"woff\")}@font-face{font-family:\"Roboto\";font-style:normal;font-weight:700;src:local(\"Roboto Bold\"),local(\"Roboto-Bold\"),url(" + ___CSS_LOADER_URL___8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___9___ + ") format(\"woff\")}@font-face{font-family:\"Roboto\";font-style:italic;font-weight:700;src:local(\"Roboto Bold Italic\"),local(\"Roboto-BoldItalic\"),url(" + ___CSS_LOADER_URL___10___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___11___ + ") format(\"woff\")}@font-face{font-family:\"Soleil\";font-style:normal;font-weight:400;src:local(\"Soleil\"),local(\"Soleil-Regular\"),url(" + ___CSS_LOADER_URL___12___ + ") format(\"opentype\")}@font-face{font-family:\"Soleil\";font-style:italic;font-weight:400;src:local(\"Soleil Italic\"),local(\"Soleil-Italic\"),url(" + ___CSS_LOADER_URL___13___ + ") format(\"opentype\")}@font-face{font-family:\"Soleil\";font-style:normal;font-weight:700;src:local(\"Soleil Bold\"),local(\"Soleil-Bold\"),url(" + ___CSS_LOADER_URL___14___ + ") format(\"opentype\")}", ""]);

