import { gspx } from '@rsos/capstone';
import { DARK_THEME, DARKER_THEME } from '@rsos/theme/constants';

export const trackThemeControlClicks = (mode, irpVersion) => {
  const themeStatus =
    mode === DARK_THEME || mode === DARKER_THEME ? 'on' : 'off';
  gspx.trackCustomEvent('Navigation', {
    irpVersion,
    name: `Click turn ${themeStatus} night mode`,
    Category: 'Navigation',
    'Launched date': new Date(),
  });
};
