import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';

export const StyledTooltip = styled(Box).attrs(({ maxWidth }) => ({
  maxWidth: maxWidth ? `${maxWidth}px` : 'auto',
  padding: '5px',
  backgroundColor: 'primaryText',
  color: 'background',
}))`
  border-radius: 5px;
  font-size: 10px;
  word-break: break-word;
`;
