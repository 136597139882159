import React, { lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import {
  INCIDENT_CENTRAL_STATION,
  CENTRAL_STATION,
} from '@rsos/constants/appNames';
import { readMetaTag } from '@rsos/utils/metaTags';
import onLogout from '@rsos/utils/onLogout';
import { StyledNavigation } from './Navigation.styles.js';
import RSPNavigation from './RSP/RSPNavigation';

const feAppName = readMetaTag('fe-app-name');

let ICSPNavigation;
let CSPNavigation;

if (feAppName === INCIDENT_CENTRAL_STATION) {
  ICSPNavigation = lazy(() => import('./ICSP/ICSPNavigation'));
}
if (feAppName === CENTRAL_STATION) {
  CSPNavigation = lazy(() => import('./CSP/CSPNavigation'));
}

const Navigation = ({ path, persistor }) => {
  const dispatch = useDispatch();
  const isLanding = path === '/';

  const handleLogout = () => {
    onLogout(persistor, dispatch);
  };

  return (
    <StyledNavigation
      align="center"
      position="relative"
      justify="space-between"
      horizontal
      isLanding={isLanding}
    >
      <>
        <Suspense fallback={<></>}>
          <NavByApp
            handleLogout={handleLogout}
            feAppName={feAppName}
            path={path}
            parsistor={persistor}
          />
        </Suspense>
      </>
    </StyledNavigation>
  );
};

export default Navigation;

const NavByApp = React.memo(({ feAppName, handleLogout, path, persistor }) => {
  if (feAppName === INCIDENT_CENTRAL_STATION) {
    return <ICSPNavigation handleLogout={handleLogout} path={path} />;
  } else if (feAppName === CENTRAL_STATION) {
    return <CSPNavigation handleLogout={handleLogout} path={path} />;
  }
  return (
    <RSPNavigation
      handleLogout={handleLogout}
      path={path}
      persistor={persistor}
    />
  );
});
