import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '../Box';
import Text from '../Text';

const CheckboxWrapper = styled(Box)`
  padding: 5px;
  &:hover {
    background: ${({ noHoverBG, hover, theme }) =>
      hover && !noHoverBG ? theme.colors.foreground : theme.colors.transparent};
  }
`;

const RSPCheckbox = styled.input.attrs({ type: 'checkbox' })``;

const CheckboxLabelWrapper = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  ${({ disabled }) => disabled && 'cursor: auto; pointer-events: none;'};
`;

const CheckboxContent = styled(Box).attrs({
  justify: 'center',
  paddingLeft: '5px',
})``;

const CheckBoxLabel = styled(Text).attrs({
  color: 'primaryText',
  size: 'small',
  textAlign: 'left',
  fontWeight: 'regular',
})`
  display: ${({ noText }) => (noText ? 'none' : 'flex')};
  ${({ disabled }) => disabled && 'opacity: 0.3; '};
`;

const CheckboxDescription = styled(Text).attrs(({ ellipsis }) => ({
  color: 'secondaryText',
  textAlign: 'left',
  size: 'xsmall',
  ellipsis,
  fontWeight: 'regular',
}))`
  display: ${({ noText }) => (noText ? 'none' : 'flex')};
`;

const Checkbox = ({
  isChecked,
  description,
  disabled,
  label,
  onChange,
  onHover,
  noText,
  noHoverBG,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseHover = () => {
    if (onHover) {
      onHover();
    }

    setIsHovered(true);
  };

  return (
    <CheckboxWrapper
      hover={isHovered}
      onMouseEnter={handleMouseHover}
      onMouseLeave={() => setIsHovered(false)}
      noHoverBG={noHoverBG}
    >
      <CheckboxLabelWrapper disabled={disabled}>
        <RSPCheckbox
          checked={isChecked}
          disabled={disabled}
          onChange={onChange}
        />
        <CheckboxContent>
          <CheckBoxLabel disabled={disabled} noText={noText}>
            {label}
          </CheckBoxLabel>
          <CheckboxDescription noText={noText}>
            {description}
          </CheckboxDescription>
        </CheckboxContent>
      </CheckboxLabelWrapper>
    </CheckboxWrapper>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
};
