import React from 'react';
import { Formik, Form } from 'formik';
import { Box, Text, Button } from '@rsos/components/capstone/base';
import InputText from '@rsos/components/capstone/base/form/inputs/InputText';
import { Wrapper, FormWrapper } from './NewPassword.styles.js';
import { hints, validationSchema } from './helpers';

const NewPassword = ({ handleSubmit, title, buttonLabel }) => {
  return (
    <Wrapper>
      <FormWrapper>
        <Text size="xlarge" fontWeight="semibold" color="primaryText" mb={32}>
          {title}
        </Text>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          onSubmit={values => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ values, handleBlur, handleChange, errors, touched, isValid }) => (
            <Form name="reset-password-form" style={{ width: '100%' }}>
              <Box flow={16}>
                <InputText
                  id="password"
                  label="New password"
                  size="small"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  shouldDisplayClearButton={false}
                  hints={hints}
                />
                <InputText
                  id="confirmPassword"
                  label="Confirm new password"
                  size="small"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  shouldDisplayClearButton={false}
                  error={
                    errors.confirmPassword &&
                    (!!values.confirmPassword || touched.confirmPassword)
                      ? errors.confirmPassword
                      : null
                  }
                />
              </Box>
              <Button
                btnType="primary"
                type="submit"
                noMargin
                mt={32}
                grow
                disabled={!isValid}
              >
                {buttonLabel || 'Submit'}
              </Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};

export default NewPassword;
