import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@rsos/assets/icons';
import { Box, Button, Text, Modal } from '@rsos/components/capstone/base';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@rsos/components/capstone/base/Modal/ModalComponents';
import CountdownTimer from '../CountdownTimer';

const SessionModal = ({
  isOpened = false,
  onLogout,
  warning,
  onClose,
  timer,
  portalPriority,
}) => {
  if (warning === 'final') {
    setTimeout(() => {
      onLogout();
    }, 3000);
  }

  return (
    <Box>
      {warning === 'final' ? (
        <Modal
          id="session-modal"
          isOpened={isOpened}
          portalPriority={portalPriority}
          padding="20px 25px"
        >
          <ModalBody noFooter>
            <Box
              align="center"
              justify="center"
              horizontal
              flow={7}
              color="primaryText"
            >
              <ClockIcon height={14} width={14} />
              <Text size="normal">
                Your session is{' '}
                <Text fontWeight="bold" inline>
                  expiring.
                </Text>
              </Text>
            </Box>
          </ModalBody>
        </Modal>
      ) : (
        <Modal
          id="session-modal"
          isOpened={isOpened}
          onClose={onClose}
          portalPriority={portalPriority}
          width={340}
        >
          <ModalHeader onClose={onClose}>
            <Text size="xlarge" color="lightDanger">
              Warning
            </Text>
            <Text size="xlarge" color="primaryText">
              Session expiring in{' '}
              <Text inline fontWeight="bold">
                <CountdownTimer timer={timer} isOpened={isOpened} /> min.
              </Text>
            </Text>
          </ModalHeader>
          <ModalBody>
            <Text size="small" color="primaryText">
              For security purposes you will be automatically logged out at this
              time.
            </Text>
          </ModalBody>
          <ModalFooter justify="space-between" flow={12}>
            <Box grow>
              <Button btnType="secondary" onClick={onClose} noMargin>
                Dismiss
              </Button>
            </Box>
            <Box grow>
              <Button btnType="primary" onClick={onLogout} noMargin>
                Log Out
              </Button>
            </Box>
          </ModalFooter>
        </Modal>
      )}
    </Box>
  );
};

SessionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  warning: PropTypes.string,
};

export default SessionModal;
