export { default as alertNewSoundFile } from './files/AlertNew.mp3';
export { default as alertUpdateSoundFile } from './files/AlertUpdate.mp3';
export { default as callNewSoundFile } from './files/JVcall.mp3';
export { default as smsNewSoundFile } from './files/JVsms.mp3';

export { default as approvalVibesSoundFile } from './files/audio-output/ApprovalVibes.mp3';
export { default as bouyantKettleSoundFile } from './files/audio-output/BouyantKettle.mp3';
export { default as calypsoAltSoundFile } from './files/audio-output/CalypsoAlt.mp3';
export { default as chimeySoundFile } from './files/audio-output/Chimey.mp3';
export { default as glassVibesSoundFile } from './files/audio-output/GlassVibes.mp3';
export { default as hollowTappingLongSoundFile } from './files/audio-output/HollowTappingLong.mp3';
export { default as cowbellsSoundFile } from './files/audio-output/Cowbells.mp3';
export { default as jungleDrumSoundFile } from './files/audio-output/JungleDrum.mp3';
export { default as incomingAltSoundFile } from './files/audio-output/IncomingAlt.mp3';
export { default as incomingSoundFile } from './files/audio-output/Incoming.mp3';
export { default as kettleJoySoundFile } from './files/audio-output/KettleJoy.mp3';
export { default as logTripleSoundFile } from './files/audio-output/LogTriple.mp3';
export { default as notifySoundFile } from './files/audio-output/Notify.mp3';
export { default as onPointSoundFile } from './files/audio-output/OnPoint.mp3';
export { default as peacefulWarningSoundFile } from './files/audio-output/PeacefulWarning.mp3';
export { default as popSoundFile } from './files/audio-output/Pop.mp3';
export { default as rapidoSoundFile } from './files/audio-output/Rapido.mp3';
export { default as seaQueenSoundFile } from './files/audio-output/SeaQueen.mp3';
export { default as softAlertSoundFile } from './files/audio-output/SoftAlert.mp3';
export { default as talletSoundFile } from './files/audio-output/Tallet.mp3';
export { default as tripleVibeSoundFile } from './files/audio-output/TripleVibe.mp3';
export { default as tripleBellVibesSoundFile } from './files/audio-output/TripleBellVibes.mp3';
export { default as tripleEggShakerFastSoundFile } from './files/audio-output/TripleEggShakerFast.mp3';
export { default as warningSoundFile } from './files/audio-output/Warning.mp3';
export { default as xyloToggleSoundFile } from './files/audio-output/XyloToggle.mp3';
