import React from 'react';
import SVGLoader from './SVGLoader';

const EllipsisIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 14}
      height={height || 14}
      viewboxWidth={viewboxWidth || 140}
      viewboxHeight={viewboxHeight || 140}
    >
      <g>
        <path d="M100 70a20 20 0 1040 0 20 20 0 10-40 0zM50 70a20 20 0 1040 0 20 20 0 10-40 0zM0 70a20 20 0 1040 0 20 20 0 10-40 0z" />
      </g>
    </SVGLoader>
  );
};

export default EllipsisIcon;
