import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  filterEmergencyCalls,
  filterAniAliCalls,
} from '../../utils/filterEmergencyCalls';

export const callQueueSelector = createSelector(
  [callQueue => callQueue, (state, hasAniAliAccess) => hasAniAliAccess],
  (callQueue, hasAniAliAccess) => {
    return hasAniAliAccess
      ? filterEmergencyCalls(callQueue)
      : filterAniAliCalls(filterEmergencyCalls(callQueue));
  },
);

// TODO: useSelectedCall must take into consideration outbound SMS. See callQueueSelectorNext
export const useSelectedCall = () => {
  const selectedCallerID = useSelector(
    state => state.emergencyCalls.selectedCallerID,
  );

  const call = useSelector(
    state => state.emergencyCalls.callQueue[selectedCallerID],
  );

  return call;
};
