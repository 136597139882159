import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 16,
  viewboxHeight: 16,
};
const ArrowLeft = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <path
        d="M14.8 6.8H4.098l1.95-1.952a1.2 1.2 0 0 0-1.696-1.696l-4 4a1.199 1.199 0 0 0 0 1.696l4 4a1.2 1.2 0 1 0 1.696-1.696L4.098 9.2H14.8a1.2 1.2 0 1 0 0-2.4Z"
        fill="currentColor"
      />
    </SVGLoader>
  );
};

export default ArrowLeft;
