import React from 'react';

const Tile = props => {
  const { ancestorClass = '', children, id, parentClass = '' } = props;
  return (
    <div id={id} className={'tile is-ancestor ' + ancestorClass}>
      <div className={'tile is-parent is-vertical ' + parentClass}>
        {children}
      </div>
    </div>
  );
};

export default Tile;
