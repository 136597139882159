import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 100,
  viewboxHeight: 100,
};
const ArrowRightIcon = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <path d="M81.33,46.55,61.11,26.33a4.83,4.83,0,0,0-6.83,6.83l11.93,12H22.05a4.83,4.83,0,0,0,0,9.66H66.21L54.28,66.77a4.85,4.85,0,1,0,6.9,6.83L81.33,53.45A4.94,4.94,0,0,0,82.77,50,4.66,4.66,0,0,0,81.33,46.55Z" />
    </SVGLoader>
  );
};

export default ArrowRightIcon;
