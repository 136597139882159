/**
 * Function that handles logout. It cleans the store and redirects.
 * @param {object} persistor - it is returned by the persistStore which specifies what should be persisted in the current store
 * @param {function} dispatch - responsible to dispatch the logout action
 * @returns redirects on return
 */
import localforage from 'localforage';
import NS_SSO from '@rsos/constants/ns_sso';
import { logout } from '@rsos/sinatra';
import { captureException } from '@rsos/utils/sentry';

const onLogout = async (persistor, dispatch) => {
  let redirectPath = '/';

  // NS sessions are tagged with ns_sso in localStorage. This will only be
  // present if the user knows of the /ns path, see the NSLogin component.
  if (localStorage.getItem(NS_SSO)) {
    redirectPath = '/ns';
  }

  try {
    await dispatch(logout());
    localStorage.clear();
    await localforage.clear();

    // flush() to flush all pending state serialization and immediately
    // write to disk before clearing out stored state with purge().
    // purge() only clears the contents of the storage and leaves data in
    // redux untouched
    await persistor.flush();
    await persistor.purge();

    if (window.indexedDB) {
      window.indexedDB.deleteDatabase('localforage');
    }
  } catch (error) {
    captureException(error);
  } finally {
    // Logout and redirect no matter what happens.
    window.location = redirectPath;
  }
};

export default onLogout;
