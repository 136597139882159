import React from 'react';
import PropTypes from 'prop-types';
import { StyledFakeLinkComponent } from './FakeLinkComponent.styles.js';

const FakeLinkComponent = ({ onClick, children, ...rest }) => {
  return (
    <StyledFakeLinkComponent onClick={onClick} {...rest}>
      {children}
    </StyledFakeLinkComponent>
  );
};

FakeLinkComponent.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  withBg: PropTypes.bool,
  withPadding: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default FakeLinkComponent;
