import React from 'react';

import StaticMessagePage from './StaticMessagePage';

const SomethingWentWrong = () => {
  return (
    <StaticMessagePage
      id="something-went-wrong-component"
      title="Oops, something went wrong."
      message={
        <>
          An error has occurred. If the error persists please {' '}
          <a href="mailto:support@rapidsos.com">contact support</a>.
        </>
      }
    />
  );
};

export default SomethingWentWrong;
