import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { JURISDICTION_VIEW } from '@rsos/capstone/src/constants/capabilities';
import { getUrlParameter } from '@rsos/utils';
import { confirmUser } from '../actions';
import { checkAccessLevel } from '../utils/handleRedirect';
import { isAdminApproved } from '../utils/isAdminApproved';

class ConfirmationEmail extends Component {
  componentDidMount() {
    const { confirmUser } = this.props;
    const token = getUrlParameter('token');
    if (token) {
      confirmUser(token);
    }
  }

  render() {
    const {
      isLoggedIn,
      jurisdiction,
      hendrix_enabled,
      currentRole,
    } = this.props;
    const boundarySubmitted = isAdminApproved(jurisdiction);
    let manage_user;
    let location;
    if (isLoggedIn) {
      manage_user = checkAccessLevel(currentRole).MANAGE_USERS;
      if (!boundarySubmitted && manage_user) {
        location = window.location = '/admin/psap#boundary-upload';
      } else if (hendrix_enabled) {
        location = window.location = '/home';
      } else {
        location = window.location = '/query-interface';
      }
    }
    return (
      <div>
        {location ? (
          { location }
        ) : (
          <div className="ConfirmationEmail columns mx-auto">
            <div className="column is-paddingless">
              <div className="confirm-email-container">
                <div className=" white-bg">
                  <div className="column mb-10">
                    <span className="confirm-page-title light-grey-text">
                      Your email is confirmed – time to submit your
                      jurisdictional boundary!{' '}
                    </span>
                  </div>
                  <div className="column confirm-detail">
                    <p>
                      We have strict security measures in place to ensure only
                      authorized ECCs are accessing data from RapidSOS Portal.
                      To ensure we are only providing user data to the agency
                      managing the 9-1-1 call, we need you to log in to RapidSOS
                      Portal and submit your jurisdictional boundary.
                    </p>
                    <p>
                      Please log in <Link to="/">here</Link> to submit your
                      boundary file.
                    </p>
                    <p style={{ color: '#ff5656' }}>
                      After you submit your jurisdictional boundary, it will
                      take two to four weeks to approve your account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.sinatra.user.profile,
    isLoggedIn: state.sinatra.user.isLoggedIn,
    jurisdiction: state.psaps.currentPSAP.jurisdiction,
    currentRole: state.sinatra.user.currentRole,
    hendrix_enabled: !!(
      state.psaps.currentPSAP &&
      state.psaps.currentPSAP.activeCapabilities &&
      state.psaps.currentPSAP.activeCapabilities[JURISDICTION_VIEW]
    ),
  };
};

const mapDispatchToProps = {
  confirmUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEmail);
