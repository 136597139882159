import React from 'react';
import { Box, Text } from '@rsos/components/capstone/base';
import { useLayoutContext } from '../base/Layout';

// TODO: `authentication` should use this component instead of the one in the
// app's legacy components directory
const StaticMessagePage = ({ id, title, message, pageBg }) => {
  const { layout } = useLayoutContext();
  return (
    <Box
      align="center"
      p="15vh"
      bg={pageBg ? pageBg : 'background'}
      id={id}
      flow={25}
      height={layout.body.height}
      justify="center"
    >
      <Text size="header" fontWeight="bold" color="primaryText">
        {title}
      </Text>
      <Text size="large" color="secondaryText">
        {message}
      </Text>
    </Box>
  );
};

export default StaticMessagePage;
