import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import CreateAccount from './CreateAccount';
import IntegrationInfo from './IntegrationInfo';
import LicenseAgreement from './LicenseAgreement';
import ProgressBar from './ProgressBar';
import ProvideInformation from './ProvideInformation';
import Review from './Review';

const AdminSignUp = () => {
  let navigate = useNavigate();

  let location = useLocation();

  const createAccountInfo = useSelector(state => state.auth.createAccountInfo);

  const isLoggedIn = useSelector(state => state.sinatra.user.isLoggedIn);

  const signUpError = useSelector(state => state.auth.signUpError);

  const [isCreateAccountCompleted, setIsCreateAccountCompleted] = useState(
    false,
  );

  const [isProvideInfoCompleted, setIsProvideInfoCompleted] = useState(false);

  const [
    isLicenseAndReviewCompleted,
    setIsLicenseAndReviewCompleted,
  ] = useState(false);

  const [isSystemInfoCompleted, setIsSystemInfoCompleted] = useState(false);

  useEffect(() => {
    if (
      createAccountInfo !== undefined &&
      Object.keys(createAccountInfo).length > 0
    ) {
      setIsCreateAccountCompleted(true);
    }
  }, [createAccountInfo]);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('agencyInfo'))) {
      setIsProvideInfoCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('systemInfo'))) {
      setIsSystemInfoCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('signature')) {
      setIsLicenseAndReviewCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && !signUpError) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, signUpError]);

  useEffect(() => {
    // Scroll to top on route change because scroll position will stay the same
    if (location?.pathname) {
      window.scrollTo(0, 0);
    }
  });

  return (
    <div
      id="admin-signup-container"
      className="admin-signup-container hero columns"
    >
      <div className="column is-3 white-bg">
        <ProgressBar
          isCreateAccountCompleted={isCreateAccountCompleted}
          isProvideInfoCompleted={isProvideInfoCompleted}
          isLicenseAndReviewCompleted={isLicenseAndReviewCompleted}
          isSystemInfoCompleted={isSystemInfoCompleted}
        />
      </div>
      <div className="column is-paddingless">
        <Routes>
          <Route
            path="account"
            element={
              <CreateAccount handleStatus={setIsCreateAccountCompleted} />
            }
          />
          <Route
            path="provide-info"
            element={
              <ProvideInformation handleStatus={setIsProvideInfoCompleted} />
            }
          />
          <Route
            path="license"
            element={
              <LicenseAgreement handleStatus={setIsLicenseAndReviewCompleted} />
            }
          />
          <Route
            path="integration-info"
            element={
              <IntegrationInfo handleStatus={setIsSystemInfoCompleted} />
            }
          />
          <Route path="review" element={<Review />} />
          <Route path="*" element={<Navigate to="account" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminSignUp;
