export const MODAL_BACKDROP_ZINDEX = 200;

export const BACKDROP_STYLE = {
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: MODAL_BACKDROP_ZINDEX,
};

export const CONTAINER_STYLE = {
  ...BACKDROP_STYLE,
  background: 'transparent',
  overflow: 'auto',
  display: 'flex',
  maxHeight: '100vw',
};

export const BODY_WRAPPER_STYLE = {
  borderRadius: 3,
  boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2)',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100vw',
};
