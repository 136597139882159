import { normalizeCapabilities } from '@rsos/capstone';
import { JURISDICTION_VIEW } from '@rsos/capstone/src/constants/capabilities';
import { selectUserOrgByApp, fetchUser } from '@rsos/sinatra';
import determineDataPath from '@rsos/utils/determineDataPath';
import { getPSAPInfo, forcedLogOut } from './../actions';
import { isAdminApproved } from './isAdminApproved';

export const checkAccessLevel = currentRole => {
  const application = currentRole.application;
  let permissions;
  if (application === 'capstone' || application === 'central_station') {
    permissions = {
      MANAGE_USERS: currentRole.permissions.includes('MANAGE_USERS'),
      RIDE_SHARING: currentRole.permissions.includes('RIDE_SHARING'),
      PROFILE_INFO: currentRole.permissions.includes('PROFILE_INFO'),
    };
  }
  return permissions;
};

const handleRedirect = async (dispatch, sinatra, navigate) => {
  const isICSP = sinatra.irp.isICSP;
  const application = sinatra.user.currentRole.application;
  const MANAGE_USERS = checkAccessLevel(sinatra.user.currentRole).MANAGE_USERS;
  const isLoggedIn = sinatra.user.isLoggedIn;

  if (!isLoggedIn) {
    return '/';
  }
  if (isICSP && application === 'central_station') {
    return '/incident-central-station';
  }
  try {
    const user = await dispatch(fetchUser());
    let { currentRole = {} } = user;
    const org = selectUserOrgByApp(sinatra, currentRole.application);
    const { irp_access_token } = sinatra.irp;
    const psap = await dispatch(getPSAPInfo(org.name, navigate));
    if (typeof psap !== 'object' || !psap.psapInfo) {
      throw new Error('Missing PSAP Info');
    }
    if (
      application !== 'central_station' &&
      !isAdminApproved(psap.psapInfo.jurisdictions) &&
      MANAGE_USERS
    ) {
      return '/admin/psap/#boundary-upload';
    }
    const { active_capabilities = {} } = psap.psapInfo;
    const normalizedActiveCapabilities = normalizeCapabilities(
      active_capabilities,
    );
    const isJVenabled = !!normalizedActiveCapabilities[JURISDICTION_VIEW];
    return determineDataPath(
      currentRole.application,
      isJVenabled,
      irp_access_token,
      isLoggedIn,
    );
  } catch (e) {
    dispatch(forcedLogOut(navigate));
    throw e;
  }
};
export default handleRedirect;
