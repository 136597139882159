import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  border: ${({ theme }) => `1px solid ${theme.colors.heavyLineOutline}`};
  background: ${({ theme }) => theme.colors.background};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.secondaryText};
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'inherit')};
  opacity: ${p => (p.disabled ? 0.4 : 1)};
  height: ${({ height }) => (height ? height : 'auto')};
  line-height: 16px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
  min-height: ${({ height }) => (height ? height : '32px')};
  outline: 0;
  overflow: auto;
  padding: ${({ padding }) => (padding ? padding : '5px 10px')};
  position: relative;
  resize: ${({ resize }) => resize};
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;
