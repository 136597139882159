import { setTokens } from '../utils/tokens';
import instance from './scorpiusInstance';
import {
  POST_AUTH_TOKEN_START,
  POST_AUTH_TOKEN_SUCCESS,
  POST_AUTH_TOKEN_ERROR,
} from './types';

export const postAuthTokenStart = () => ({
  type: POST_AUTH_TOKEN_START,
});

export const postAuthTokenSuccess = tokens => ({
  type: POST_AUTH_TOKEN_SUCCESS,
  tokens,
});

export const postAuthTokenError = error => ({
  type: POST_AUTH_TOKEN_ERROR,
  error,
});

export const postAuthToken = (email, password) => async dispatch => {
  // this will delete bearer token for 404 issue, see https://rapidsos.atlassian.net/browse/MC-10913
  // for more information
  if (instance.defaults && instance.defaults.headers) {
    delete instance.defaults.headers.Authorization;
  }
  dispatch(postAuthTokenStart());
  try {
    const response = await instance.post('/user/api-token-auth', {
      email,
      password,
    });
    const tokens = response.data;
    setTokens(tokens);
    return dispatch(postAuthTokenSuccess(tokens));
  } catch (error) {
    throw dispatch(postAuthTokenError(error));
  }
};

export default postAuthToken;
