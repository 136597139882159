import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from '@rsos/components/capstone/ErrorBoundary/NotFound';
import ChangePassword from '../components/Auth/ChangePassword';
import RequestPassword from '../components/Auth/RequestPassword';
import ResetPassword from '../components/Auth/ResetPassword';
import BrowserSupport from '../components/BrowserSupport/index';
import ConfirmationEmail from '../components/ConfirmationEmail';
import Landing from '../components/Landing';
import NSLogin from '../components/NSLogin';
import SSOLogin from '../components/SSOLogin';
import SignUp from '../components/SignUp';
import RequestConfirmation from '../components/SignUp/RequestConfirmation';

export const topLevelRoutes = [
  {
    path: '/',
    component: <Landing />,
  },
  {
    path: '/ns',
    component: <NSLogin />,
  },
  {
    path: '/sso',
    component: <SSOLogin />,
  },
  {
    path: 'reset-password',
    component: <ResetPassword />,
  },
  {
    path: 'sign-up/*',
    component: <SignUp />,
  },
  {
    path: 'request-password',
    component: <RequestPassword />,
  },
  {
    path: 'change-password',
    component: <ChangePassword />,
  },
  {
    path: 'request-confirmation',
    component: <RequestConfirmation />,
  },
  {
    path: 'browser-support',
    component: <BrowserSupport />,
  },
  {
    path: 'confirmation-email',
    component: <ConfirmationEmail />,
  },
];

const App = () => {
  return (
    <div className="App white-bg">
      <Routes>
        {topLevelRoutes.map(({ component, path, ...rest }) => (
          <Route key={path} path={path} element={component} {...rest} />
        ))}
        <Route path="login" element={<Navigate to="/" replace />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
