import SVGLoader, { SVGLoaderProps } from './SVGLoader';

const defaults = {
  viewboxHeight: 12,
  viewboxWidth: 12,
};

export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}: SVGLoaderProps) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path d="M5.99999 6C7.83876 6 9.32938 4.65685 9.32938 3C9.32938 1.34315 7.83876 0 5.99999 0C4.16121 0 2.67059 1.34315 2.67059 3C2.67059 4.65685 4.16121 6 5.99999 6Z" />
    <path d="M11.9739 11.4257C11.5375 10.3122 10.7287 9.34803 9.65876 8.66605C8.58885 7.98407 7.31055 7.61784 6 7.61784C4.68945 7.61784 3.41115 7.98407 2.34124 8.66605C1.27133 9.34803 0.462469 10.3122 0.0261146 11.4257C0.00182866 11.491 -0.00565328 11.5605 0.00423484 11.6288C0.014123 11.6971 0.0411221 11.7625 0.0831899 11.82C0.128486 11.875 0.187232 11.9198 0.25487 11.951C0.322508 11.9823 0.397219 11.999 0.473205 12H11.5268C11.6028 11.999 11.6775 11.9823 11.7451 11.951C11.8128 11.9198 11.8715 11.875 11.9168 11.82C11.9589 11.7625 11.9859 11.6971 11.9958 11.6288C12.0057 11.5605 11.9982 11.491 11.9739 11.4257Z" />
  </SVGLoader>
);
