/**
 * Determines if a call status should be updated in the unlikely event where the ALI location
 * payload from Hermes is not in order.
 * @param {Object} previousLocation - most recent location payload
 * @param {Object} currentLocation - current ALI location payload
 * @returns Boolean
 */
export const shouldUpdateCallStatus = (previousLocation, currentLocation) => {
  return (
    !!(!previousLocation && currentLocation && currentLocation?.call_status) ||
    !!(
      previousLocation &&
      currentLocation &&
      currentLocation?.call_status &&
      currentLocation.created_time > previousLocation.created_time
    )
  );
};
