// Validation for form fields
export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const validateRegex = name => {
  let regex;
  if (name.length === 1) {
    regex = /^[\w-]{1}/;
  } else if (name.length === 2) {
    regex = /^[\w-]{2}/;
  } else {
    regex = /^[\w-]+[\w- ]+[\w-]+$/;
  }
  return regex.test(name);
};

export const isValidAgencyName = name => {
  // Alphanumeric, spaces, dashes, underscores, commas allowed
  const regex = /^[\w ,-]+$/;
  if (!isEmpty(name)) {
    return regex.test(name) && name.length >= 5 && name.length <= 65;
  }
  return false;
};

export const isValidEmail = email => {
  const rexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return rexp.test(email) && email.length <= 50;
};

export const isValidTextField = (name, minLength, maxLength) => {
  if (minLength === 0) {
    if (isEmpty(name)) {
      return true;
    }
    return (
      validateRegex(name) &&
      minLength <= name.length &&
      name.length <= maxLength
    );
  } else {
    if (!isEmpty(name)) {
      return (
        validateRegex(name) &&
        minLength <= name.length &&
        name.length <= maxLength
      );
    }
    return false;
  }
};

/**
 * Expects the given population to either be a number or string.
 * Returns true if the value is a number and it is between the min and max.
 * @param {number|string} population - The population number
 * @param {Object} opts
 * @param {number} opts.min - The minimum population
 * @param {number} opts.max - The maximum population
 */
export const isValidPopulation = (population, opts = {}) => {
  const { min = 10, max = 99999999 } = opts;
  try {
    const number = parseInt(population, 10);

    let isNumber = typeof population === 'number';
    if (!isNumber) {
      isNumber = /^\d+$/.test(population);
    }
    return isNumber && number >= min && number <= max;
  } catch {
    return false;
  }
};

export const isValidPassword = pwd => {
  const rexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_?])(?=.{8,})(\S*$)/;
  return rexp.test(pwd);
};

export const isValidPhone = phone => {
  let formattedPhone;
  // react-phone-number-input strips the leading '1' if user types it, then
  // `phone` is undefined
  if (phone !== undefined && phone !== null && phone !== '') {
    if (phone.substring(0, 1) === '+') {
      formattedPhone = phone.substring(1);
    } else {
      formattedPhone = phone;
    }
    // North american phone number
    const rexp = /^(^1[2-9]{1}\d{9})$/;
    return rexp.test(formattedPhone);
  }
  return phone;
};

export const optional = fn => (...args) => {
  //NOTE: allows for optional field to be undefined, NaN, empty string, null
  const data = Array.isArray(args) && args[0];
  if (!data && data !== 0) {
    return true;
  }
  return fn(...args);
};

export const optionalTextField = optional(isValidTextField);
