import { combineReducers } from 'redux';
import { LOGOUT } from '../actions/types';
import account from './accountReducer';
import attributes from './attributesReducer';
import auth from './authReducer';
import irp from './irpReducer';
import management from './managementReducer';
import products from './productsReducer';
import ui from './uiReducer';
import user from './userReducer';

/**
 * Note whenever a LOGOUT action happens, the
 * redux state is reset to the initialState for all
 * reducers. This is handled by combining all of the
 * application's reducers into a single reducer and
 * decorating the result with a function
 * that checks the action's type and passes along a
 * state of undefined if LOGOUT is encountered.
 */

const combinedReducer = combineReducers({
  user,
  account,
  auth,
  products,
  management,
  ui,
  irp,
  attributes,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === LOGOUT) {
    newState = undefined;
  }
  return combinedReducer(newState, action);
};

export default rootReducer;
