import instance from './scorpiusInstance';
import {
  SET_USER_PASSWORD_START,
  SET_USER_PASSWORD_SUCCESS,
  SET_USER_PASSWORD_ERROR,
} from './types';

export const setUserPasswordStart = () => ({
  type: SET_USER_PASSWORD_START,
});

export const setUserPasswordSuccess = () => ({
  type: SET_USER_PASSWORD_SUCCESS,
});

export const setUserPasswordError = error => ({
  type: SET_USER_PASSWORD_ERROR,
  error,
});

export const setUserPassword = (password, token) => async dispatch => {
  dispatch(setUserPasswordStart());
  try {
    await instance.post('/user/password', {
      password,
      token,
    });

    // NOTE Success is a 204, nothing to pass along.
    return dispatch(setUserPasswordSuccess());
  } catch (error) {
    throw dispatch(setUserPasswordError(error));
  }
};
