import React from 'react';
import styled from 'styled-components';
import { Box, Text } from '@rsos/base-components';

const DropdownSectionContainer = styled(Box).attrs({
  grow: true,
  width: '100%',
})``;
const DropdownSectionTitle = styled(Text).attrs({
  textTransform: 'uppercase',
  color: 'primaryText',
  size: 'normal',
  textAlign: 'left',
})`
  border-bottom: ${({ borderless, theme }) =>
    borderless ? 'none' : `1px solid ${theme.colors.heavyLineOutline}`};
  cursor: auto;
  padding: 10px 16px;
`;
const DropdownSection = ({ sectionTitle, children, ...props }) => (
  <DropdownSectionContainer {...props}>
    <DropdownSectionTitle {...props}>{sectionTitle}</DropdownSectionTitle>
    {children}
  </DropdownSectionContainer>
);

export default DropdownSection;
