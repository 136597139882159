import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { Instruction } from '../';

const Input = props => {
  const {
    autoFocus = false,
    id,
    checked,
    className,
    error,
    labelName,
    labelPosition = 'top',
    max = 99999999,
    maxLength = 30,
    min = 10,
    minLength = 2,
    name,
    onChange = () => {},
    placeholder,
    type = 'text',
    value,
    wrapperClass = '',
  } = props;

  let guide;
  if (labelName === 'Agency Name') {
    guide = (
      <span>
        <ul>
          Agency Name must be:
          <li>Between 5 and 65 characters</li>
          <li>
            Only alphanumeric characters, spaces, dashes, underscores and commas
            allowed
          </li>
        </ul>
        <br />
        Example: if you are an agency RapidSOS Portal County, a format that
        meets above requirements should be: RapidSOS Portal County 911
      </span>
    );
  } else if (labelName === 'Email Address') {
    guide = (
      <span>
        <ul>
          <li>This will be your User ID</li>
        </ul>
      </span>
    );
  } else if (labelName === 'Password') {
    guide = (
      <span>
        <ul>
          <li>Use a combination of upper-case and lower-case letters</li>
          <li>Include at least 1 numerical digit</li>
          <li>
            Include at least 1 special character, such as: ! @ # $ % & ? - _
          </li>
          <li>Can't be similar to the email</li>
          <li>Minimum length is 8 characters</li>
          <li>Only alphanumeric characters, dashes, and underscores allowed</li>
        </ul>
      </span>
    );
  } else {
    guide = null;
  }

  return (
    <div className={'rl-input-wrapper ' + wrapperClass}>
      {/* TODO: refactor where the label tag is for PhoneInput. Clicking
       anywhere on the label triggers the "flag" functionality of the library*/}
      {type === 'tel' ? (
        <div>
          {labelPosition === 'top' ? (
            <label className="rl-input-label">{labelName}</label>
          ) : null}
          <PhoneInput
            className={className}
            country="US"
            countries={['US']}
            name={name}
            id={name}
            onChange={phone => onChange(name, phone)}
            placeholder={placeholder}
            value={value}
          />
          {labelPosition === 'bottom' ? (
            <label className="rl-input-label">{labelName}</label>
          ) : null}
        </div>
      ) : (
        <div>
          <label className="rl-input-label">
            <div className="is-flex">
              {(labelPosition === 'top' && labelName !== 'Email Address') ||
              type === 'radio'
                ? labelName
                : `${labelName} (this is your login ID)`}
              {window.location.pathname.includes('sign-up') &&
              (labelName === 'Agency Name' ||
                labelName === 'Email Address' ||
                labelName === 'Password') ? (
                <Instruction data={guide} />
              ) : null}
            </div>
          </label>
          <input
            autoFocus={autoFocus}
            checked={checked}
            className={className}
            id={id ? id : name}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
          />
          {/* TODO: use validation from the libphonenumber-js library instead
          the library currently throws an error when input value is erased*/}
          {labelPosition === 'bottom' && labelName}
        </div>
      )}
      <span className="is-block rl-input-error">{error}</span>
    </div>
  );
};

export default Input;
