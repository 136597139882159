import axios from 'axios';
import { getUrlParameter } from '@rsos/base-utils';
import { getAPIHost } from '@rsos/base-utils/metaTags';
import {
  DECRYPT_IRP_TOKEN_START,
  DECRYPT_IRP_TOKEN_SUCCESS,
  DECRYPT_IRP_TOKEN_ERROR,
  SET_IRP_VERSION,
} from './types';

const getBaseURL = isICSP => {
  const app = isICSP ? 'icsp' : 'irp';
  return `${getAPIHost()}/v1/deeplink/${app}/decrypt`;
};

export const decryptIRPTokenStart = () => ({
  type: DECRYPT_IRP_TOKEN_START,
});

export const decryptIRPTokenSuccess = data => ({
  type: DECRYPT_IRP_TOKEN_SUCCESS,
  data,
});

export const decryptIRPTokenError = error => ({
  type: DECRYPT_IRP_TOKEN_ERROR,
  error,
});

export const decryptIRPToken = token => async dispatch => {
  dispatch(decryptIRPTokenStart());
  if (token) {
    try {
      const isICSP = !!getUrlParameter('icsp_token');
      const baseURL = getBaseURL(isICSP);
      const instance = axios.create({
        baseURL: getBaseURL(isICSP),
      });
      const response = await instance.post(baseURL, { token });
      return dispatch(decryptIRPTokenSuccess({ ...response.data, isICSP }));
    } catch (error) {
      // Ported from normalizeError without the new Error()
      let msg = error.message;
      if (error.response) {
        const { data } = error.response;
        msg = data.detail || error.message;
      }
      throw dispatch(decryptIRPTokenError(msg));
    }
  } else {
    throw dispatch(decryptIRPTokenError(new Error('No token to decrypt')));
  }
};

export const setIRPVersion = version => ({
  type: SET_IRP_VERSION,
  version,
});
