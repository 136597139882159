import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const attrName = 'data-portal-priority';
const attrSelector = `[${attrName}]`;

/**
 * Creates a portal at the root of the page, injecting the portal in the order
 * of the given priority. Note the highest priority value means it will be the
 * last portal element in the DOM.
 * @example
 * <Portal priority={100}><ToBeRenderedIntoAnElementAtBodyRoot /></Portal>
 */
const Portal = ({ id, priority, children }) => {
  const [elem, setElem] = useState();
  useEffect(() => {
    const portalElems = Array.prototype.slice.call(
      document.querySelectorAll(attrSelector),
      0,
    );

    /**
     * Comparator to sort DOM Elements in ascending order (low to high).
     * @param {Element} a - Element 1
     * @param {Element} b - Element 2
     */
    const sortFn = (a, b) => {
      const aValue = parseInt(a.getAttribute(attrName), 10);
      const bValue = parseInt(b.getAttribute(attrName), 10);
      return aValue - bValue;
    };

    const prependToBody = el => {
      const children = document.body.childNodes;
      if (children.length === 0) {
        document.body.appendChild(el);
        return;
      }
      document.body.insertBefore(el, children[0]);
    };

    // Make sure items are sorted (sanity check - because they should be).
    const sorted = portalElems.sort(sortFn);

    // Create the div here so it can be injected in the right order later.
    const el = document.createElement('div');
    el.setAttribute(attrName, priority);
    el.id = id;

    if (sorted.length <= 0) {
      // It's the first portal, make it the first element in the <body>.
      prependToBody(el);
    } else {
      // Find the position where it belongs by push it into the current DOM
      // array and resorting it. Once sorted, decide to either prepend it
      // (as first element in body) or append it after an existing portal.
      sorted.push(el);
      const resorted = sorted.sort(sortFn);
      const idx = resorted.findIndex(elem => elem.id === id);
      if (idx === 0) {
        prependToBody(el);
      } else {
        const prevElem = resorted[idx - 1];
        prevElem.after(el);
      }
    }
    setElem(el);
    return () => document.body.removeChild(el);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!elem) {
    return null;
  }
  return ReactDOM.createPortal(children, elem);
};

Portal.propTypes = {
  id: PropTypes.string.isRequired,
  priority: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Portal.defaultTypes = {
  priority: 1,
  children: null,
};

export default Portal;
