import { useCallback, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { CrosshairIcon } from '@rsos/assets/icons';
import { Text, Button } from '@rsos/components/capstone/base';
import { selectAppLauncherApp } from '@rsos/reduxDataStore/src/features/appLauncher/appLauncherSlice';
import {
  AGENT_511_MAP_CARD,
  AVIVE_APP_LAUNCHER_MAP_CARD,
  closeCard,
} from '@rsos/reduxDataStore/src/features/mapCard/mapCardSlice';
import {
  SelectorBanner,
  TextIconWrapper,
  IconWrapper,
  ButtonWrapper,
  BannerText,
} from './eagleViewBanner.styles';

/**
 * ObliqueImagery Selector Tool lives inside of the App Launcher
 * It uses a Toast Message with AppLauncher state (part of the App Launcher Apps)
 * It also behaves like an appLauncher Map Card (only one appLauncher card can be open at a time)
 * but it allows other non-appLauncher Map Cards to render besides it (static/dynamic/RADe)
 */

const APP_LAUNCHER_CARDS = [AGENT_511_MAP_CARD, AVIVE_APP_LAUNCHER_MAP_CARD];

const EagleViewBanner = memo(() => {
  const dispatch = useDispatch();
  const closeAllLauncherCards = useCallback(() => {
    APP_LAUNCHER_CARDS.forEach(card => dispatch(closeCard(card)));
  }, [dispatch]);

  useEffect(() => {
    closeAllLauncherCards();
  }, [closeAllLauncherCards]);

  return (
    <SelectorBanner horizontal>
      <TextIconWrapper horizontal>
        <IconWrapper>
          <CrosshairIcon />
        </IconWrapper>
        <BannerText>EagleView selector tool is active</BannerText>
      </TextIconWrapper>
      <ButtonWrapper onClick={() => dispatch(selectAppLauncherApp())}>
        <Button
          btnType="tertiary"
          size="xsmall"
          grow
          px="30px"
          py="5px"
          noMargin
        >
          <Text size="normal">Exit</Text>
        </Button>
      </ButtonWrapper>
    </SelectorBanner>
  );
});

export default EagleViewBanner;
