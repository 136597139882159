import { instance } from '@rsos/sinatra';
import { getAPIHost } from '@rsos/utils/metaTags';

export const postShareEmergencyCall = data => {
  const { event, message, orgName, shareWith, uri } = data;
  const url = `${getAPIHost()}/v1/emergency-events/${orgName}/share`;

  // https://rapidsos.atlassian.net/browse/MC-21278
  // hardcode the value of `event_type` to `location` as it is the only type it can be shared.
  // this is to ensure phone numbers from search results can be shared as well.
  const requestPayload = {
    event,
    event_type: 'location',
    message,
    uri,
    share_with: shareWith,
  };
  if (data.adrData) requestPayload.adr_data = data.adrData;
  return instance.post(url, requestPayload);
};

/**
 * Post request to initiate a new Text-to-911 conversation via Agent511.
 * @param {string} orgName - current organization name
 * @param {string} phoneNumber - phone number to send to Agent511
 */
export const postAgent511Application = ({ orgName, phoneNumber }) => {
  return instance.post(
    `${getAPIHost()}/v1/deeplink/agent511/${orgName}/query`,
    { phone_number: phoneNumber },
    { headers: { retry: true } },
  );
};

export const patchAgent511SessionID = ({ orgName, agent511SessionID }) => {
  return instance.patch(
    `${getAPIHost()}/v1/deeplink/agent511/${orgName}/query`,
    { agent_511_session_id: agent511SessionID },
    { headers: { retry: true } },
  );
};
