import { useSelector } from 'react-redux';
import { IRP_MAP_ENABLED } from '@rsos/capstone/src/constants/permissionNames';
import { selectUserPermissionsByApp } from '@rsos/sinatra';

export default function useIsIRP() {
  const permissions = useSelector(state =>
    selectUserPermissionsByApp(state.sinatra.user, 'capstone'),
  );

  const { queryURI, irp_access_token, irpVersion } = useSelector(
    state => state.sinatra.irp,
  );

  const isIRPmode =
    Array.isArray(permissions) &&
    permissions.includes(IRP_MAP_ENABLED) &&
    irp_access_token;

  const isIRPresponsiveConditional = irp_access_token && !isIRPmode;

  return {
    irp_access_token: irp_access_token,
    isIRPmode: isIRPmode,
    isIRPresponsiveConditional: isIRPresponsiveConditional,
    queryURI: queryURI,
    irpVersion: irpVersion,
  };
}
