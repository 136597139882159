import { darkModeColorPalette } from './darkModeDefinition';

/**
 * Theme definition can extend existing theme, swap colors in the existing theme or be completely new.
 * For exp,
 const customThemeDefinition = {
   ...darkModeColorPalette,
   background: 'pink',
   highlights: 'green',
   newColor: 'blue'
 };
 */
const icspThemeDefinition = {
  ...darkModeColorPalette,
};
export default {
  palette: icspThemeDefinition,
  fieldsToOverride: [],
};
