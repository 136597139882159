import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { withLayout } from '@rsos/components/capstone/base/Layout';

export const Wrapper = withLayout(styled(Box).attrs({
  bg: 'foreground',
})`
  height: ${({ layout }) => layout.body.height}px;
`);
export const ContentWrapper = styled(Box).attrs({
  align: 'center',
  mt: 85,
})`
  flex: 1 0 auto;
`;

export const GridWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;
