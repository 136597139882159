import instance from './scorpiusInstance';
import {
  REFRESH_USER_TOKEN_START,
  REFRESH_USER_TOKEN_SUCCESS,
  REFRESH_USER_TOKEN_ERROR
} from './types';
import { setTokens } from '../utils/tokens';

export const refreshUserTokenStart = () => ({
  type: REFRESH_USER_TOKEN_START
});

export const refreshUserTokenSuccess = token => ({
  type: REFRESH_USER_TOKEN_SUCCESS,
  token,
});

export const refreshUserTokenError = error => ({
  type: REFRESH_USER_TOKEN_ERROR,
  error
});

export const refreshUserToken = refresh_token => async dispatch => {
  dispatch(refreshUserTokenStart());
  try {
    const response = await instance.post('/user/api-token-auth/refresh', {
      refresh_token,
    });

    const { token } = response.data;

    // Save the new set of tokens.
    setTokens({
      token,
      refresh_token,
    });

    // Update the scorpius instance with new Bearer token
    instance.defaults.headers.Authorization = `Bearer ${token}`;

    return dispatch(refreshUserTokenSuccess(token));
  } catch (error) {
    throw dispatch(refreshUserTokenError(error));
  }
};

export default refreshUserToken;
