import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 17,
  height: 16,
  viewboxWidth: 17,
  viewboxHeight: 16,
};

const CrosshairIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <g id="Cursor-Target-2--Streamline-Ultimate.svg_2">
        <path
          id="Vector"
          d="M6.33337 8.00004C6.33337 8.44207 6.50897 8.86599 6.82153 9.17855C7.13409 9.49111 7.55801 9.66671 8.00004 9.66671C8.44207 9.66671 8.86599 9.49111 9.17855 9.17855C9.49111 8.86599 9.66671 8.44207 9.66671 8.00004C9.66671 7.55801 9.49111 7.13409 9.17855 6.82153C8.86599 6.50897 8.44207 6.33337 8.00004 6.33337C7.55801 6.33337 7.13409 6.50897 6.82153 6.82153C6.50897 7.13409 6.33337 7.55801 6.33337 8.00004Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M8 5C8.26522 5 8.51957 4.89464 8.70711 4.70711C8.89464 4.51957 9 4.26522 9 4V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1V4C7 4.26522 7.10536 4.51957 7.29289 4.70711C7.48043 4.89464 7.73478 5 8 5Z"
          fill="currentColor"
        />
        <path
          id="Vector_3"
          d="M15 7H12C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8C11 8.26522 11.1054 8.51957 11.2929 8.70711C11.4804 8.89464 11.7348 9 12 9H15C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652 7 15 7Z"
          fill="currentColor"
        />
        <path
          id="Vector_4"
          d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11Z"
          fill="currentColor"
        />
        <path
          id="Vector_5"
          d="M4 7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H4C4.26522 9 4.51957 8.89464 4.70711 8.70711C4.89464 8.51957 5 8.26522 5 8C5 7.73478 4.89464 7.48043 4.70711 7.29289C4.51957 7.10536 4.26522 7 4 7Z"
          fill="currentColor"
        />
      </g>
    </SVGLoader>
  );
};

export default CrosshairIcon;
