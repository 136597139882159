import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 17,
  viewboxWidth: 17,
  height: 16,
  width: 16,
};

export default ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    width={width}
    height={height}
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path
      d="M6.66667 14.9593C6.85865 14.9576 7.04798 14.9144 7.22172 14.8327C7.39546 14.751 7.54951 14.6327 7.67334 14.486C7.77647 14.3938 7.8589 14.2807 7.91521 14.1543C7.97151 14.0279 8.00041 13.891 8 13.7527V9.97933C8 9.62571 7.85953 9.28657 7.60948 9.03652C7.35943 8.78647 7.02029 8.646 6.66667 8.646H1.33334C0.979716 8.646 0.640577 8.78647 0.390529 9.03652C0.14048 9.28657 4.23433e-06 9.62571 4.23433e-06 9.97933V13.7727C-0.000402497 13.911 0.0284969 14.0479 0.0848006 14.1743C0.141104 14.3007 0.223536 14.4138 0.326671 14.506C0.452359 14.6491 0.607236 14.7636 0.780879 14.8418C0.954522 14.9199 1.1429 14.96 1.33334 14.9593H3.2C3.24421 14.9593 3.2866 14.9769 3.31786 15.0081C3.34911 15.0394 3.36667 15.0818 3.36667 15.126V15.4593C3.36667 15.5035 3.34911 15.5459 3.31786 15.5772C3.2866 15.6084 3.24421 15.626 3.2 15.626H2.33334C2.15653 15.626 1.98696 15.6962 1.86193 15.8213C1.73691 15.9463 1.66667 16.1159 1.66667 16.2927C1.66667 16.4695 1.73691 16.639 1.86193 16.7641C1.98696 16.8891 2.15653 16.9593 2.33334 16.9593H5.66667C5.84348 16.9593 6.01305 16.8891 6.13807 16.7641C6.2631 16.639 6.33334 16.4695 6.33334 16.2927C6.33334 16.1159 6.2631 15.9463 6.13807 15.8213C6.01305 15.6962 5.84348 15.626 5.66667 15.626H4.83334C4.78913 15.626 4.74674 15.6084 4.71549 15.5772C4.68423 15.5459 4.66667 15.5035 4.66667 15.4593V15.126C4.66667 15.0818 4.68423 15.0394 4.71549 15.0081C4.74674 14.9769 4.78913 14.9593 4.83334 14.9593H6.66667ZM6.66667 10.126V12.7927C6.66746 12.8317 6.65455 12.8697 6.63018 12.9001C6.60582 12.9306 6.57155 12.9515 6.53334 12.9593H1.5C1.4558 12.9593 1.41341 12.9418 1.38215 12.9105C1.3509 12.8793 1.33334 12.8369 1.33334 12.7927V10.126C1.33334 10.0818 1.3509 10.0394 1.38215 10.0081C1.41341 9.97689 1.4558 9.95933 1.5 9.95933H6.53334C6.57524 9.96746 6.61242 9.99135 6.63723 10.0261C6.66203 10.0608 6.67257 10.1037 6.66667 10.146V10.126Z"
      fill="currentColor"
    />
    <path
      d="M12.7324 0.959473C11.1991 0.959473 9.98576 1.62614 9.7791 2.57281C9.74798 2.63486 9.73199 2.70339 9.73243 2.77281V6.85947C9.73243 7.93281 11.0658 8.77281 12.7324 8.77281C14.3991 8.77281 15.7324 7.93281 15.7324 6.85947V2.77281C15.7329 2.70339 15.7169 2.63486 15.6858 2.57281C15.4791 1.62614 14.2591 0.959473 12.7324 0.959473ZM12.7324 7.79281C11.5124 7.79281 10.7324 7.25281 10.7324 6.87947V6.55947C10.7328 6.53127 10.7404 6.50364 10.7544 6.47915C10.7684 6.45467 10.7883 6.43413 10.8124 6.41947C10.837 6.40605 10.8645 6.39902 10.8924 6.39902C10.9204 6.39902 10.9479 6.40605 10.9724 6.41947C11.5288 6.65714 12.1275 6.77967 12.7324 6.77967C13.3374 6.77967 13.9361 6.65714 14.4924 6.41947C14.517 6.40605 14.5445 6.39902 14.5724 6.39902C14.6004 6.39902 14.6279 6.40605 14.6524 6.41947C14.6765 6.43413 14.6965 6.45467 14.7105 6.47915C14.7245 6.50364 14.732 6.53127 14.7324 6.55947V6.85947C14.7324 7.23281 13.9524 7.77281 12.7324 7.77281V7.79281ZM14.7324 2.87947C14.7324 3.25281 13.9524 3.79281 12.7324 3.79281C11.5124 3.79281 10.7324 3.25281 10.7324 2.87947C10.7324 2.50614 11.5124 1.95947 12.7324 1.95947C13.9524 1.95947 14.7324 2.48614 14.7324 2.85947V2.87947ZM12.7324 5.79281C11.5124 5.79281 10.7324 5.25281 10.7324 4.87947V4.55947C10.7328 4.53127 10.7404 4.50364 10.7544 4.47915C10.7684 4.45467 10.7883 4.43413 10.8124 4.41947C10.837 4.40605 10.8645 4.39902 10.8924 4.39902C10.9204 4.39902 10.9479 4.40605 10.9724 4.41947C11.5263 4.6648 12.1267 4.7876 12.7324 4.77947C13.3382 4.7876 13.9385 4.6648 14.4924 4.41947C14.517 4.40605 14.5445 4.39902 14.5724 4.39902C14.6004 4.39902 14.6279 4.40605 14.6524 4.41947C14.6765 4.43413 14.6965 4.45467 14.7105 4.47915C14.7245 4.50364 14.732 4.53127 14.7324 4.55947V4.85947C14.7324 5.23281 13.9524 5.77281 12.7324 5.77281V5.79281Z"
      fill="currentColor"
    />
    <path
      d="M8.38621 5.14622L7.05288 6.47956C6.98295 6.5494 6.8939 6.59695 6.79697 6.6162C6.70004 6.63546 6.59957 6.62556 6.50826 6.58775C6.41695 6.54994 6.3389 6.48592 6.28395 6.40378C6.229 6.32163 6.19963 6.22505 6.19954 6.12622V5.29289H5.03288C4.98867 5.29289 4.94628 5.31045 4.91503 5.34171C4.88377 5.37296 4.86621 5.41535 4.86621 5.45956V7.21289C4.86621 7.3455 4.81353 7.47268 4.71976 7.56644C4.626 7.66021 4.49882 7.71289 4.36621 7.71289C4.2336 7.71289 4.10643 7.66021 4.01266 7.56644C3.91889 7.47268 3.86621 7.3455 3.86621 7.21289V5.45956C3.86621 5.15014 3.98913 4.85339 4.20792 4.6346C4.42671 4.41581 4.72346 4.29289 5.03288 4.29289H6.19954V3.45956C6.19987 3.35953 6.2298 3.26184 6.28556 3.17879C6.34132 3.09574 6.42042 3.03106 6.51288 2.99289C6.57211 2.96894 6.63568 2.95758 6.69954 2.95956C6.7654 2.95807 6.83085 2.97035 6.89169 2.9956C6.95253 3.02086 7.00743 3.05854 7.05288 3.10622L8.38621 4.43956C8.43338 4.48552 8.47087 4.54046 8.49647 4.60115C8.52207 4.66183 8.53526 4.72703 8.53526 4.79289C8.53526 4.85875 8.52207 4.92395 8.49647 4.98463C8.47087 5.04532 8.43338 5.10026 8.38621 5.14622Z"
      fill="currentColor"
    />
  </SVGLoader>
);
