import { useRef, useEffect } from 'react';

const usePrevious = previousValue => {
  const previousRef = useRef();

  useEffect(() => {
    previousRef.current = previousValue;
  }, [previousRef, previousValue]);

  return previousRef.current;
};

export default usePrevious;
