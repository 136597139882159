import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { BP_DESKTOP, BP_LAPTOP } from '@rsos/constants/breakpoints';
import {
  linerRapidSOSGradient,
  BP_DESKTOP_L,
  BP_DESKTOP_S,
  WIDTH_DESKTOP_XL,
  WIDTH_DESKTOP_L,
  WIDTH_DESKTOP,
  WIDTH_DESKTOP_S,
} from '../../../constants/cssConstants';
import { MainDescription } from '../Landing.styles.js';
import { calcLeft } from '../helpers';

export const FirstRowWrapper = styled(Box).attrs({
  height: 674,
  align: 'center',
})`
  position: relative;
  background: ${linerRapidSOSGradient};
`;

export const FirstRowContentWrapper = styled(Box).attrs({
  justify: 'space-between',
  horizontal: true,
  align: 'center',
  height: '100%',
  px: 116,
  width: WIDTH_DESKTOP_XL,
})`
  position: absolute;
  left: ${calcLeft(WIDTH_DESKTOP_XL)};

  @media (max-width: ${BP_DESKTOP_L}px) {
    width: ${WIDTH_DESKTOP_L}px;
    left: ${calcLeft(WIDTH_DESKTOP_L)};
  }
  @media (max-width: ${BP_DESKTOP}px) {
    width: ${WIDTH_DESKTOP}px;
    padding: 0 85px;
    left: ${calcLeft(WIDTH_DESKTOP)};
  }
  @media (max-width: ${BP_DESKTOP_S}px) {
    width: ${BP_DESKTOP_S}px;
    padding: 0 76px;
    left: ${calcLeft(WIDTH_DESKTOP_S)};
  }
  @media (max-width: ${BP_LAPTOP}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const FirstRowTextBox = styled(Box).attrs({
  color: 'background',
  width: 480,
})`
  justify-self: center;
  @media (max-width: ${BP_DESKTOP}px) {
    width: 400px;
  }
  @media (max-width: ${BP_DESKTOP_S}px) {
    width: 340px;
  }
`;

export const FirstRowDescription = styled(MainDescription)`
  opacity: 0.9;
`;
