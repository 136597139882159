import styled from 'styled-components';

const DropdownListItem = styled.li`
  display: flex;
  padding: 10px 15px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.highlights : 'inherit'};
  color: ${({ theme }) => theme.colors.primaryText};
  border-radius: ${({ noRadius }) => !noRadius && '5px 5px 0 0'};
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background: ${({ theme, selected, noLiHover }) =>
      !selected && !noLiHover && theme.colors.foreground};
  }
  ${({ disabled }) =>
    disabled && `opacity: 0.4; cursor: not-allowed; pointer-events: none`};
`;

export default DropdownListItem;
