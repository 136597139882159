import React from 'react';
import SVGLoader from './SVGLoader';

const EdgeIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 14}
      height={height || 14}
      viewboxWidth={viewboxWidth || 14}
      viewboxHeight={viewboxHeight || 14}
    >
      <path d="M6.93576 1.33046e-05C5.29954 -0.00317544 3.7139 0.566919 2.4543 1.61126C1.1947 2.6556 0.340733 4.10819 0.0407617 5.71668C0.0356662 5.75044 0.0421083 5.78492 0.0590459 5.81457C0.0759834 5.84421 0.102426 5.86727 0.134095 5.88001C0.16562 5.89088 0.199848 5.89106 0.231483 5.88051C0.263117 5.86997 0.290395 5.84929 0.309095 5.82168C1.7791 3.62835 3.49993 3.17918 4.55576 3.15001C5.48581 3.13338 6.40054 3.38828 7.18795 3.88352C7.97536 4.37876 8.60128 5.09284 8.9891 5.93835C9.21027 6.41972 9.26785 6.96024 9.15304 7.4774C9.03823 7.99456 8.75735 8.45995 8.35326 8.80251C8.33128 8.82405 8.3187 8.85341 8.31826 8.88418C8.31803 8.90007 8.32161 8.91578 8.32872 8.92999C8.33583 8.94421 8.34624 8.9565 8.3591 8.96585C8.40013 9.00031 8.44518 9.02969 8.49326 9.05335C10.5174 10.0625 12.2733 9.63668 13.7083 7.76418C13.8709 7.5537 13.9591 7.29519 13.9591 7.02918C13.9591 5.16594 13.2193 3.37893 11.9024 2.06087C10.5854 0.742813 8.799 0.00155956 6.93576 1.33046e-05V1.33046e-05Z" />
      <path d="M3.80949 11.2817C3.54916 10.6259 3.43317 9.92173 3.4694 9.21711C3.50564 8.51248 3.69326 7.82394 4.01949 7.19833C4.48911 6.23824 5.20842 5.42221 6.10199 4.83583C6.14704 4.80651 6.1833 4.76554 6.20692 4.71727C6.23054 4.66899 6.24065 4.61522 6.23616 4.56167C6.23101 4.50748 6.21079 4.45581 6.17781 4.41252C6.14482 4.36922 6.10037 4.33602 6.04949 4.31667C5.58509 4.128 5.08635 4.03858 4.58533 4.05417C2.71283 4.08333 1.12616 5.55917 0.0586612 8.2075C0.0468525 8.26327 0.0468525 8.3209 0.0586612 8.37667C0.346695 9.83041 1.08574 11.1563 2.1707 12.1659C3.25566 13.1755 4.6313 13.8172 6.10199 14C6.1362 14.005 6.17107 13.9977 6.2004 13.9794C6.22972 13.9611 6.25159 13.933 6.2621 13.9C6.27261 13.8671 6.27108 13.8315 6.25778 13.7996C6.24448 13.7677 6.22028 13.7416 6.18949 13.7258C5.15912 13.1813 4.3264 12.3262 3.80949 11.2817V11.2817Z" />
      <path d="M12.2152 10.2842C10.5002 11.235 6.11352 10.5 5.69352 7.64753C5.64204 7.29271 5.67604 6.93073 5.79268 6.5917C5.80332 6.5601 5.8028 6.52581 5.79122 6.49455C5.77965 6.46328 5.75771 6.43693 5.72906 6.41987C5.70041 6.40282 5.66678 6.3961 5.63378 6.40083C5.60078 6.40556 5.57039 6.42145 5.54768 6.44586C5.25023 6.78963 5.00099 7.17231 4.80685 7.58336C4.53289 8.0992 4.37431 8.66841 4.34208 9.25159C4.30985 9.83477 4.40474 10.418 4.62018 10.9609C5.20352 12.3259 6.98852 13.3642 8.59268 13.8367C8.64375 13.854 8.69911 13.854 8.75018 13.8367C10.4099 13.3935 11.8487 12.3554 12.7927 10.92C12.849 10.8379 12.8757 10.739 12.8682 10.6397C12.8607 10.5404 12.8196 10.4466 12.7516 10.3738C12.6836 10.301 12.5928 10.2536 12.4942 10.2395C12.3956 10.2253 12.2951 10.2452 12.2094 10.2959L12.2152 10.2842Z" />
    </SVGLoader>
  );
};

export default EdgeIcon;
