import React from 'react';
import styled from 'styled-components';
import { CaretIcon } from '@rsos/base-assets/icons';
import Box from '@rsos/base-components/Box';

const CaretTransformUpDown = ({ transformCondition, color, width, height }) => {
  return (
    <ChevronContainer
      style={{
        transform: `rotate(${transformCondition ? -180 : 0}deg)`,
      }}
      color={color}
    >
      <CaretIcon width={width || 12} height={height || 12} />
    </ChevronContainer>
  );
};

export default CaretTransformUpDown;

const ChevronContainer = styled(Box).attrs({
  cursor: 'pointer',
  color: 'secondaryLink',
  transition: '300ms ease transform',
})``;
