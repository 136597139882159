import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = props => {
  const {
    description,
    isChecked,
    customLabel,
    name,
    id,
    onCheckBoxChange,
    styleClass,
  } = props;
  return (
    <React.Fragment>
      <div className="custom-checkbox">
        <input
          type="checkbox"
          name={name}
          id={id}
          checked={isChecked}
          onChange={onCheckBoxChange}
          className={styleClass}
        />
        <label htmlFor={id}></label>
        {customLabel ? <span className="px-20">{customLabel}</span> : null}
      </div>
      {description ? (
        <div className="role-feature-description checkbox-description ash-grey-text">
          {description}
        </div>
      ) : null}
    </React.Fragment>
  );
};

CheckBox.propTypes = {
  customLabel: PropTypes.string,
  description: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
};

export default CheckBox;
