import React from 'react';
import { useSelector } from 'react-redux';
import { CENTRAL_STATION } from '@rsos/constants/adminContents';
import { AppWrapper, MainContainer } from './AdminLayout.styles';
import SideBarCSP from './SideBar/CSP/SideBarCSP';
import SideBarRSP from './SideBar/RSP/SideBarRSP';

const AdminLayout = ({ children }) => {
  const application = useSelector(
    state => state.sinatra.user?.currentRole?.application,
  );

  return (
    <MainContainer>
      {application === CENTRAL_STATION ? <SideBarCSP /> : <SideBarRSP />}
      <AppWrapper>{children}</AppWrapper>
    </MainContainer>
  );
};

export default AdminLayout;
