import {
  FETCH_APP_CIDRS_START,
  FETCH_APP_CIDRS_SUCCESS,
  FETCH_APP_CIDRS_ERROR,
  CREATE_APP_CIDR_START,
  CREATE_APP_CIDR_SUCCESS,
  CREATE_APP_CIDR_ERROR,
  UPDATE_APP_CIDR_START,
  UPDATE_APP_CIDR_SUCCESS,
  UPDATE_APP_CIDR_ERROR,
  DELETE_APP_CIDR_START,
  DELETE_APP_CIDR_SUCCESS,
  DELETE_APP_CIDR_ERROR,
} from '../../actions/types';

export const initialState = {
  records: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: {
    loading: null,
    creating: null,
    updating: null,
    deleting: null,
  },
};

const addCidr = (records, cidr) => [...records, cidr];

const updateCidr = (records, cidr) => records.map(record => {
  if (record.id === cidr.id) {
    return {
      ...record,
      ...cidr,
    }
  }
  return record;
});

const removeCidr = (records, cidrID) => (
  records.filter(record => record.id !== cidrID)
);

const whitelistedIPsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_CIDRS_START:
      return {
        ...state,
        loading: true,
        errors: {
          ...state.errors,
          loading: null,
        },
      };
    case FETCH_APP_CIDRS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.CIDRs,
      };
    case FETCH_APP_CIDRS_ERROR:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          loading: action.error.message,
        }
      };
    case CREATE_APP_CIDR_START:
      return {
        ...state,
        creating: true,
        errors: {
          ...state.errors,
          creating: null,
        }
      };
    case CREATE_APP_CIDR_SUCCESS:
      return {
        ...state,
        creating: false,
        records: addCidr(state.records, action.cidr),
      };
    case CREATE_APP_CIDR_ERROR:
      return {
        ...state,
        creating: false,
        errors: {
          ...state.errors,
          creating: action.error,
        },
      };

    case DELETE_APP_CIDR_START:
      return {
        ...state,
        deleting: true,
        errors: {
          ...state.errors,
          deleting: null,
        }
      };
    case DELETE_APP_CIDR_SUCCESS:
      return {
        ...state,
        deleting: false,
        records: removeCidr(state.records, action.cidrID)
      };
    case DELETE_APP_CIDR_ERROR:
      return {
        ...state,
        deleting: false,
        errors: {
          ...state.errors,
          deleting: action.error,
        },
      };
    case UPDATE_APP_CIDR_START:
      return {
        ...state,
        updating: true,
        errors: {
          ...state.errors,
          updating: null,
        },
      };
    case UPDATE_APP_CIDR_SUCCESS:
      return {
        ...state,
        updating: false,
        records: updateCidr(state.records, action.cidr)
      };
    case UPDATE_APP_CIDR_ERROR:
      return {
        ...state,
        updating: false,
        errors: {
          ...state.errors,
          updating: null,
        },
      };
    default:
      return {
        ...state,
      }
  }
};

export default whitelistedIPsReducer;