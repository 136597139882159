import {
  FETCH_USER_ATTRIBUTES_START,
  FETCH_USER_ATTRIBUTES_SUCCESS,
  FETCH_USER_ATTRIBUTES_ERROR,
} from '../actions/types';
import persistFactory from './persistFactory';

/*
  each object in the state uses names of the applications as keys
  for example:
  {
    loading: {
      rec: false,
      rec_lite: false
    },
    data: {
      rec_lite: {
        hello: 'world'
      }
    },
    errors: {
      rec: {
        message: 'Network error'
      }
    }
  }
*/

export const initialState = {
  loading: {},
  data: {},
  errors: {},
};

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_ATTRIBUTES_START: {
      const { applicationName } = action.payload;

      const errors = {
        ...state.errors,
      };
      delete errors[applicationName];

      return {
        ...state,
        errors,
        loading: {
          ...state.loading,
          [applicationName]: true,
        },
      };
    }
    case FETCH_USER_ATTRIBUTES_SUCCESS: {
      const { data, applicationName } = action.payload;

      const errors = {
        ...state.errors,
      };
      delete errors[applicationName];

      return {
        ...state,
        errors,
        loading: {
          ...state.loading,
          [applicationName]: false,
        },
        data: {
          ...state.data,
          [applicationName]: data,
        },
      };
    }
    case FETCH_USER_ATTRIBUTES_ERROR: {
      const { error, applicationName } = action.payload;

      return {
        ...state,
        loading: {
          ...state.loading,
          [applicationName]: false,
        },
        errors: {
          ...state.errors,
          [applicationName]: error,
        },
      };
    }
    default:
      return state;
  }
};

const opts = {
  whitelist: ['loading', 'data', 'errors'],
};

export default persistFactory('attributes', attributesReducer, opts);
