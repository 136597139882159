/**
 * [react-phone-number-input](https://gitlab.com/catamphetamine/react-phone-number-input)
 * Currently the frontend applications use the react-phone-number-input library for the telephone
 * input field and only supports domestic phone number format. In the future if international phone
 * number format is supported, we should revisit these util functions.
 */

/**
 * Util function to append a '+' sign to the phone number if it starts with 1,
 * otherwise return empty string.
 * @param {string} phoneNumber - phone number to check
 */
export const getInitialPhoneValue = phoneNumber => {
  if (phoneNumber) {
    return phoneNumber.startsWith('+')
      ? phoneNumber
      : phoneNumber.startsWith('1')
      ? '+' + phoneNumber
      : '';
  }
  return '';
};

/**
 * Util function to remove the '+' sign from the phone number if it starts with +,
 * otherwise return the phone number as is.
 * @param {string} phoneNumber - phone number to check
 */
export const sanitizedPhoneNumber = phoneNumber => {
  return phoneNumber && phoneNumber.substring(0, 1) === '+'
    ? phoneNumber.substring(1)
    : phoneNumber;
};

/**
 * cleans a phone number by removing all non-digit characters.
 *
 * @param {string} phoneNumber - The phone number or caller ID.
 * @returns {string} - The cleaned phone number containing only digits.
 * If the input type is not a string, it will be return the original input.
 */

export const cleanPhoneNumber = phoneNumber => {
  return typeof phoneNumber === 'string'
    ? phoneNumber.replace(/\D/g, '')
    : phoneNumber;
};
