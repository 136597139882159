import React, { Component } from 'react';
import { captureExceptionWithScope } from '@rsos/utils/sentry';

import SomethingWentWrong from './SomethingWentWrong';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    captureExceptionWithScope(error, { stackTrace: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
