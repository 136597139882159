import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 17,
  height: 16,
  viewboxWidth: 17,
  viewboxHeight: 16,
};

const PoliceIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <path
        d="M15.616 3.48a.697.697 0 0 0 .146-1.01l-.926-1.15a.678.678 0 0 0-.864-.164 4.36 4.36 0 0 1-2.146.685c-.86 0-1.692-.384-2.537-1.172a.67.67 0 0 0-.914 0C7.53 1.457 6.7 1.841 5.838 1.841a4.337 4.337 0 0 1-2.146-.685.678.678 0 0 0-.864.164L1.812 2.583a.672.672 0 0 0 .048.897 5.34 5.34 0 0 1 1.004 1.355 3.948 3.948 0 0 1 .083 3.414c-.44 1.034-.916 2.56 0 3.837.667.923 1.93 1.546 3.262 2.156.775.305 1.51.7 2.194 1.177a.667.667 0 0 0 .838 0 10.428 10.428 0 0 1 2.173-1.182c1.345-.61 2.61-1.235 3.276-2.159.923-1.277.446-2.8 0-3.837a3.955 3.955 0 0 1 .1-3.408c.265-.501.42-.958.826-1.353Z"
        fill="currentColor"
      />
    </SVGLoader>
  );
};

export default PoliceIcon;
