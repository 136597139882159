import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 16,
  height: 17,
  viewboxWidth: 16,
  viewboxHeight: 17,
};

const SMSSharedIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <path
        d="M8.457 4.342H.77a.769.769 0 0 0-.769.77v5.208a.769.769 0 0 0 .769.75h.96v1.345a.384.384 0 0 0 .208.342.384.384 0 0 0 .385-.027l2.399-1.66h3.736a.769.769 0 0 0 .77-.77V5.112a.769.769 0 0 0-.77-.769Zm0 5.863a.096.096 0 0 1-.096.096H4.613a.385.385 0 0 0-.219.069l-1.895 1.31v-.995a.385.385 0 0 0-.385-.384H.865a.096.096 0 0 1-.096-.096V5.207a.096.096 0 0 1 .096-.096h7.496a.096.096 0 0 1 .096.096v4.998Z"
        fill="currentColor"
      />
      <path
        d="M2.307 7.226H6.92a.384.384 0 0 0 0-.769H2.307a.384.384 0 1 0 0 .769ZM2.307 8.955H6.92a.384.384 0 0 0 0-.768H2.307a.384.384 0 0 0 0 .768ZM15.943 7.585a.207.207 0 0 0 0-.286l-2.074-2.074a.2.2 0 0 0-.232-.062.207.207 0 0 0-.128.207v.933a.103.103 0 0 1-.104.104H13.3c-2.015 0-2.591 1.845-2.973 3.065-.041.136-.083.27-.124.394a.203.203 0 0 0 .078.236.195.195 0 0 0 .117.037.194.194 0 0 0 .132-.05c.17-.14.324-.273.46-.414.859-.713 1.162-.987 2.103-.987h.31a.104.104 0 0 1 .105.104v.933a.207.207 0 0 0 .36.14l2.074-2.28Z"
        fill="currentColor"
      />
    </SVGLoader>
  );
};

export default SMSSharedIcon;
