/**
 * Returns a normalized pure JS Error from the given AxiosError type object.
 * The message in AxiosErrors are the response text rather than the
 * `detail` that RapidSOS APIs send back by convention.
 * This function replaces the message with the details value if found
 * within the response. Otherwise, it uses the AxiosError.message.
 * @param {(Error|axios.AxiosError)} axiosError - Error or axiosError
 * TODO: since the Error and AxiosError types mismatch, this function should be
 * updated in future release
 */
const normalizeError = axiosError => {
  let msg = axiosError.message;
  if (axiosError.response) {
    const { data } = axiosError.response;
    msg = data.detail || axiosError.message;
  }
  return new Error(msg);
};

export default normalizeError;
