import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 11,
  viewboxWidth: 16,
};

const CaretIcon = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <path
        d="M15.6463 0.666738C15.5289 0.463266 15.3597 0.294447 15.156 0.177376C14.9523 0.0603048 14.7213 -0.000861211 14.4863 7.15457e-05H1.51302C1.28021 0.0026303 1.05214 0.0661013 0.851487 0.18417C0.650832 0.302239 0.48459 0.47079 0.3693 0.673054C0.254009 0.875318 0.193689 1.10424 0.194341 1.33706C0.194993 1.56987 0.256594 1.79846 0.373015 2.00007L6.85968 9.7134C6.97853 9.90933 7.14587 10.0713 7.34555 10.1837C7.54524 10.2962 7.77053 10.3552 7.99968 10.3552C8.22884 10.3552 8.45413 10.2962 8.65381 10.1837C8.8535 10.0713 9.02084 9.90933 9.13968 9.7134L15.6263 2.00007C15.7464 1.79914 15.8115 1.57014 15.815 1.33608C15.8185 1.10203 15.7603 0.871181 15.6463 0.666738Z"
        id="Path"
      ></path>
    </SVGLoader>
  );
};

export default CaretIcon;
