import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isValidEmail } from '@rsos/utils/validators';
import { requestConfirmation } from '../../../actions';
import { Button, Input } from '../../capstoneLegacy';

const RequestConfirmation = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleError = value => {
    let error;
    if (!isValidEmail(value)) {
      error = `Invalid email`;
    }
    return error;
  };

  const onChange = e => {
    const fieldValue = e.target.value;
    setBtnDisabled(!isValidEmail(fieldValue));
    setEmail(fieldValue);
    setEmailError(handleError(fieldValue));
  };

  const handleRequestConfirmation = e => {
    e.preventDefault();
    if (!btnDisabled) {
      dispatch(requestConfirmation(email, navigate));
    }
  };

  return (
    <div
      id="RequestConfirmationEmail"
      className="RequestConfirmationEmail hero py-30"
    >
      <div className="custom-page-title has-text-centered">
        Request Confirmation Email
      </div>
      <h5 className="has-text-centered">
        Enter the email associated with your account
      </h5>
      <div className="form-wrapper">
        <form onSubmit={handleRequestConfirmation}>
          <Input
            className="rl-input is-fullwidth"
            error={emailError}
            labelName="Email Address"
            labelPosition="top"
            maxLength={50}
            name="email"
            id="RequestConfirmationEmail"
            onChange={onChange}
            placeholder="Email"
            type="email"
            value={email}
          />
          <div className="input-container">
            <Button
              name="RequestConfirmationEmail"
              buttonClicked={requestConfirmation}
              className={
                'mt-10 rapidlite-forms-button ' +
                (btnDisabled ? 'unclickable' : 'clickable')
              }
              type="submit"
            >
              Request confirmation email
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestConfirmation;
