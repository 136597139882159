import {
  sessionStart,
  setTokens,
  postAuthTokenSuccess,
  fetchUser,
} from '@rsos/sinatra';
import { configureUserScope } from '@rsos/utils/sentry';
import packageJson from '../../package.json';
import handleRedirect from '../utils/handleRedirect';
import { setUserInfo } from './auth';

export const irpLogin = (tokens, navigate) => (dispatch, getState) => {
  dispatch(sessionStart());
  setTokens(tokens);
  dispatch(postAuthTokenSuccess(tokens));

  return dispatch(fetchUser())
    .then(res => {
      dispatch(setUserInfo(res));
      configureUserScope({
        email: res.profile.email,
        version: packageJson.version,
      });
      const { sinatra } = getState();
      handleRedirect(dispatch, sinatra, navigate)
        .then(path => (window.location = path))
        .catch(err => {
          throw err;
        });
    })
    .catch(err => {
      throw err;
    });
};
