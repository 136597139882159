import React from 'react';
import SVGLoader from './SVGLoader';

const ResentIcon = props => {
  const { width, height, viewboxWidth, viewboxHeight } = props;

  return (
    <SVGLoader
      {...props}
      width={width || 14}
      height={height || 14}
      viewboxWidth={viewboxWidth || 16}
      viewboxHeight={viewboxHeight || 16}
    >
      <g>
        <path d="M15.4515 0.548803C15.2092 0.303517 14.9032 0.130759 14.568 0.0499916C14.2328 -0.0307758 13.8818 -0.0163443 13.5543 0.0916607L1.26861 4.19452C0.969063 4.29534 0.700091 4.47072 0.487009 4.70415C0.273926 4.93759 0.123741 5.2214 0.0505859 5.52888C-0.0225692 5.83636 -0.0162882 6.1574 0.0688377 6.46178C0.153964 6.76616 0.315135 7.04388 0.537185 7.2688L3.42861 10.1374C3.4807 10.1931 3.50935 10.2668 3.50861 10.3431L3.42861 13.9431C3.42453 14.041 3.44676 14.1382 3.49299 14.2247C3.53921 14.3111 3.60775 14.3836 3.69147 14.4345C3.77834 14.4847 3.87688 14.5111 3.97718 14.5111C4.07749 14.5111 4.17603 14.4847 4.2629 14.4345L6.37718 13.2917C6.43093 13.263 6.49268 13.2531 6.55268 13.2635C6.61269 13.2738 6.66755 13.3038 6.70861 13.3488L8.75433 15.3945C9.09034 15.7557 9.55317 15.9728 10.0458 16.0002C10.1862 16.0177 10.3282 16.0177 10.4686 16.0002C10.7785 15.9301 11.0648 15.7805 11.2993 15.5661C11.5338 15.3516 11.7083 15.0798 11.8058 14.7774L15.9086 2.44595C16.0166 2.11852 16.031 1.76744 15.9503 1.43226C15.8695 1.09707 15.6968 0.791098 15.4515 0.548803ZM12.4343 4.42309L6.40004 11.0059C6.3637 11.0455 6.31954 11.0771 6.27036 11.0988C6.22119 11.1204 6.16805 11.1316 6.11433 11.1317C6.00824 11.1317 5.9065 11.0895 5.83148 11.0145C5.75647 10.9395 5.71433 10.8377 5.71433 10.7317V8.3088C5.71405 8.1216 5.75975 7.93719 5.84744 7.77179C5.93512 7.60639 6.06209 7.46507 6.21718 7.36023L11.7829 3.65737C11.8624 3.61009 11.9532 3.58513 12.0458 3.58513C12.1383 3.58513 12.2291 3.61009 12.3086 3.65737C12.3895 3.69987 12.457 3.7641 12.5035 3.84283C12.5499 3.92157 12.5734 4.0117 12.5715 4.10309C12.5653 4.22268 12.5167 4.33615 12.4343 4.42309Z" />
      </g>
    </SVGLoader>
  );
};

export default ResentIcon;
