import * as Yup from 'yup';
import * as validator from '@rsos/utils/validators';

const EMAIL_ERROR = 'Invalid email';
export const validationSchema = Yup.object().shape({
  email: Yup.string().test('isValidEmail', EMAIL_ERROR, function (value) {
    const { path, createError } = this;
    const result = validator.isValidEmail(value)
      ? true
      : createError({ path, message: EMAIL_ERROR });
    return result;
  }),
});
