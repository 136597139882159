import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/**
 * Returns a redux-persist reducer for the given reducer.
 * @param {string} key
 * @param {Function} reducer
 * @param {Object} opts
 */
const persistFactory = (key, reducer, opts = {}) => {
  const config = {
    key,
    storage,
    ...opts,
    blacklist: ['user', 'auth'],
  };
  return persistReducer(config, reducer);
};

export default persistFactory;
