const fontSizeBySize = {
  xxsmall: '6px',
  xsmall: '10px',
  small: '12px',
  normal: '14px',
  large: '16px',
  xlarge: '18px',
  subheader: '21px',
  header: '24px',
  xheader: '48px',
};
const textTransformValues = {
  lowercase: 'lowercase',
  uppercase: 'uppercase',
  capitalize: 'capitalize',
  none: 'none',
  inherit: 'inherit',
};

export function getFontSize({ size }) {
  return (size && fontSizeBySize[size]) || 'inherit';
}

const fontWeightByFontWeight = {
  bold: 700,
  semibold: 500,
  regular: 400,
};

export function getFontWeight({ fontWeight }) {
  return (fontWeight && fontWeightByFontWeight[fontWeight]) || 'inherit';
}

export function getTextTransform({ textTransform }) {
  return textTransformValues[textTransform] || textTransformValues.inherit;
}
