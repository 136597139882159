import React from 'react';
import SVGLoader from './SVGLoader';

const ClockIcon = props => {
  return (
    <SVGLoader viewboxWidth={140} viewboxHeight={140} {...props}>
      <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
        <path
          d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm5.2,17.221a1.016,1.016,0,0,1-1.413.062l-4.959-4.546A1,1,0,0,1,10.5,12V6.5a1,1,0,0,1,2,0v5.06l4.634,4.248A1,1,0,0,1,17.2,17.222Z"
          stroke="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0"
        ></path>
      </g>
    </SVGLoader>
  );
};

export default ClockIcon;
