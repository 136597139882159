import PropTypes from 'prop-types';

export const applicationPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    call_to_action: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.shape({
      data_fields: PropTypes.arrayOf(PropTypes.string),
      deeplink_endpoint: PropTypes.string,
      launch_call: PropTypes.bool,
      launch_global: PropTypes.bool,
      show_in_toolbar: PropTypes.bool,
    }),
    display_name: PropTypes.string,
    display_name_short: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.string,
  }),
);
