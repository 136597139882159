import React from 'react';
import Tile from '../Common/Tile';

import './Modal.scss';

const Modal = (props) => {
  const {
    children,
    className,
    isActive,
    modalContentClassName,
    modalTileAncestorClass,
    modalTileParentClass,
    showClose,
    toggleModal,
    WrapContents,
  } = props;
  // TODO: when modal is active, content is still scrollable behind the modal
  // according to examples in bulma, `is-clipped` class is added to html tag
  // to give it overflow-y: hidden
  return (
    <div
      id="modal-component"
      className={
        'ModalComponent modal ' + (isActive ? 'is-active ' : ' ') + className
      }
    >
      <div className="modal-background" />
      <div className={'modal-content ' + modalContentClassName}>
        {WrapContents ? (
          <WrapContents>{children}</WrapContents>
        ) : (
          <Tile
            ancestorClass={modalTileAncestorClass}
            parentClass={modalTileParentClass}
          >
            <div className="tile is-child notification">{children}</div>
          </Tile>
        )}
      </div>
      {showClose && (
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={toggleModal}
        />
      )}
    </div>
  );
};

Modal.defaultProps = {
  modalContentClassName: '',
  showClose: true,
  WrapContents: null,
};

export default Modal;
