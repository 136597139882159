/**
 * Finds the data corresponding to the given lookup path.
 * @param {Object} data - The data object to traverse.
 * @param {Array<string>} path - An array representing the path to the data.
 */
const findData = (data, path = []) => {
  if (typeof data !== 'object' || path.length === 0) {
    return data;
  }
  const idxOrKey = path[0];
  const currData = data[idxOrKey];
  return findData(currData, path.slice(1));
};

export default findData;
