import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@rsos/components/capstone/base/Checkbox';
import Text from '@rsos/components/capstone/base/Text';
import { setSignature } from '../../actions';
import { Button, Input } from '../capstoneLegacy';
import LicenseAgreementContent from './LicenseAgreementContent';

const LicenseAgreement = ({ handleStatus }) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const currentPSAP = useSelector(state => state.auth.currentPSAP);

  const firstName = useSelector(state => state.auth.userInfo.first_name);

  const lastName = useSelector(state => state.auth.userInfo.last_name);

  const [state, setState] = useState({
    signature: '',
    signatureError: '',
    isCheckBoxChecked: false,
    isSignatureValid: false,
  });

  const {
    signature,
    signatureError,
    isCheckBoxChecked,
    isSignatureValid,
  } = state;

  const handleError = value => {
    let error;

    const rexp = /^[\w -.]{3,50}$/;

    if (!rexp.test(value.trim())) {
      error = (
        <ul>
          <li>
            Must be between 3 and 50 characters (does not include leading or
            trailing spaces)
          </li>
          <li>
            Only alphanumeric characters, dashes, underscores, and periods
            allowed
          </li>
        </ul>
      );
    }

    return error;
  };

  const isBtnDisabled = () => {
    return isCheckBoxChecked && isSignatureValid;
  };

  const btnDisabled = !isBtnDisabled();

  const onChange = e => {
    // const rexp = /^[\w -.]{3,50}$/;
    const error = handleError(e.target.value);

    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: handleError(e.target.value),
      isSignatureValid: !error,
    }));
  };

  const onCheckBoxChange = e => {
    const target = e.target;
    const value = target.checked;

    setState(prevState => ({
      ...prevState,
      isCheckBoxChecked: value,
    }));
  };

  const submitAgreement = e => {
    e.preventDefault();

    if (!btnDisabled) {
      const psapInfo = currentPSAP;

      if (typeof psapInfo.contact_phone === 'undefined') {
        psapInfo['contact_phone'] = '10000000000';
      }
      if (psapInfo['contact_name'] === '') {
        psapInfo['contact_name'] = `${firstName} ${lastName}`;
      }

      dispatch(setSignature(signature));

      localStorage.setItem('signature', signature);

      navigate('/sign-up/integration-info');

      handleStatus(true);
    }
  };

  const termsOfUse = () => {
    return (
      <Text size="normal">
        By checking this box and clicking ‘submit,’ I: (1) acknowledge that I
        have read the license agreement and RapidSOS Terms of Use; (2) represent
        that I am authorized to enter into these agreements on behalf of the
        Agency; and (3) agree to be bound by the license agreement
      </Text>
    );
  };

  return (
    <div className="license-agreement-container hero">
      <form className="pt-20" onSubmit={submitAgreement}>
        <div className="sign-up-wrapper bubble-blue-bg">
          <div className="custom-page-title black-text">
            UNITE End User License Agreement
          </div>
          <div className="agreement-container">
            <LicenseAgreementContent />
          </div>
          <div className="mt-20">
            <Input
              className="rl-input is-fullwidth"
              error={signatureError}
              labelName="Signature"
              labelPosition="top"
              maxLength={50}
              minLength={3}
              name="signature"
              onChange={onChange}
              placeholder="Signature"
              type="text"
              value={signature}
            />
            <span
              className={signatureError ? 'checkbox-signature-error' : null}
            >
              <Checkbox
                type="checkbox"
                label={termsOfUse()}
                name="license - checkbox"
                isChecked={isCheckBoxChecked}
                onChange={onCheckBoxChange}
                data-name="license-checkbox"
                noHoverBG
              />
            </span>
          </div>
        </div>
      </form>
      <div className="columns">
        <div className="column is-6">
          <div className="button-container">
            <Button
              buttonClicked={submitAgreement}
              className={
                'rapidlite-forms-button' +
                ' submit-agreement-button ml-10 ' +
                (btnDisabled ? 'unclickable' : 'clickable')
              }
              disabled={btnDisabled}
              id="submit-agreement-button"
              name="licenseAgreement"
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseAgreement;
