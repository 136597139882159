import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 12,
  viewboxWidth: 12,
};
export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path d="M8.14498 5.93C8.15617 5.94115 8.16505 5.9544 8.1711 5.96898C8.17716 5.98357 8.18028 5.99921 8.18028 6.015C8.18028 6.03079 8.17716 6.04643 8.1711 6.06102C8.16505 6.0756 8.15617 6.08885 8.14498 6.1L5.72498 8.655C5.65784 8.72541 5.6127 8.81389 5.5951 8.90958C5.57749 9.00526 5.58819 9.10401 5.62587 9.19371C5.66356 9.28341 5.72659 9.36017 5.80725 9.41457C5.88792 9.46898 5.9827 9.49867 6.07998 9.5H8.45998C8.52733 9.49974 8.59394 9.48588 8.6558 9.45925C8.71767 9.43261 8.77351 9.39375 8.81998 9.345L11.655 6.345C11.7422 6.25227 11.7907 6.12978 11.7907 6.0025C11.7907 5.87522 11.7422 5.75273 11.655 5.66L8.82498 2.66C8.77836 2.60973 8.7219 2.56959 8.65911 2.54206C8.59632 2.51454 8.52854 2.50022 8.45998 2.5L6.07998 2.5C5.98283 2.50031 5.88787 2.52892 5.80672 2.58233C5.72556 2.63574 5.66173 2.71163 5.62301 2.80073C5.58429 2.88984 5.57237 2.98829 5.5887 3.08406C5.60504 3.17983 5.64892 3.26877 5.71498 3.34L8.14498 5.93Z" />
    <path d="M0.344987 3.34L2.77499 5.93C2.79642 5.95311 2.80834 5.98347 2.80834 6.015C2.80834 6.04652 2.79642 6.07688 2.77499 6.1L0.354987 8.655C0.287441 8.72543 0.241982 8.81409 0.224212 8.91004C0.206443 9.006 0.217141 9.10505 0.254987 9.195C0.293264 9.28537 0.357291 9.36248 0.43909 9.41672C0.520889 9.47096 0.616841 9.49992 0.714987 9.5H3.09499C3.16234 9.49974 3.22895 9.48588 3.29081 9.45925C3.35267 9.43261 3.40851 9.39375 3.45499 9.345L6.28499 6.345C6.37217 6.25227 6.42072 6.12978 6.42072 6.0025C6.42072 5.87522 6.37217 5.75273 6.28499 5.66L3.45499 2.66C3.40793 2.61064 3.35128 2.57142 3.28851 2.54477C3.22574 2.51811 3.15818 2.50458 3.08999 2.505L0.709987 2.505C0.612835 2.50531 0.517878 2.53392 0.436723 2.58733C0.355568 2.64074 0.29173 2.71663 0.253012 2.80573C0.214294 2.89484 0.202374 2.99329 0.218709 3.08906C0.235043 3.18483 0.278923 3.27377 0.344987 3.345V3.34Z" />
  </SVGLoader>
);
