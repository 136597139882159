import React from 'react';
import { CheckIcon, AttentionTriangleIcon, InfoIcon } from '@rsos/assets/icons';
import { Text, Box } from '@rsos/components/capstone/base';
import { StandardToastContainer } from './ToastComponents.styles';

export const StandardToastBody = ({ message, icon }) => {
  const Icon = icon;
  const Message = typeof message === 'function' ? message : null;
  return (
    <StandardToastContainer>
      {icon && (
        <Box>
          <Icon height={16} width={16} />
        </Box>
      )}
      {Message ? <Message /> : <Text size="normal">{message}</Text>}
    </StandardToastContainer>
  );
};

export const toastMessageSuccess = (message, noIcon, customIcon) => (
  <StandardToastBody
    message={message}
    icon={customIcon ? customIcon : noIcon ? null : CheckIcon}
  />
);
export const toastMessageError = (message, noIcon, customIcon) => (
  <StandardToastBody
    message={message}
    icon={customIcon ? customIcon : noIcon ? null : AttentionTriangleIcon}
  />
);

export const toastMessageInfo = (message, noIcon, customIcon) => (
  <StandardToastBody
    message={message}
    icon={customIcon ? customIcon : noIcon ? null : InfoIcon}
  />
);
export const toastMessageDefault = (message, noIcon, customIcon) => (
  <StandardToastBody message={message} icon={customIcon ? customIcon : null} />
);
