import React from 'react';
import { UserIcon, HomeIcon, BellNotificationIcon } from '@rsos/assets/icons';
import {
  AGENCY_INFO_CONTENT,
  USERS_CONTENT,
  NOTIFICATIONS_CONTENT,
} from '@rsos/constants/adminContents';
import { MANAGE_AGENCY_INFO, MANAGE_USERS } from '@rsos/constants/pageAccess';

const cspSideBarItems = [
  {
    id: 'configCSPInfo',
    link: '/admin/psap',
    menuTitle: 'Account Info',
    menuIcon: <HomeIcon width={12} height={12} />,
    name: AGENCY_INFO_CONTENT,
    beName: MANAGE_AGENCY_INFO,
  },
  {
    id: 'configUsers',
    link: '/admin/access/users',
    menuTitle: 'Users',
    menuIcon: <UserIcon width={12} height={12} />,
    name: USERS_CONTENT,
    beName: MANAGE_USERS,
  },
  {
    id: 'configNotifications',
    link: '/admin/notifications',
    menuTitle: 'Notifications',
    menuIcon: <BellNotificationIcon width={12} height={12} />,
    beName: MANAGE_USERS,
    name: NOTIFICATIONS_CONTENT,
  },
];

export default cspSideBarItems;
