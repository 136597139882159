import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import * as Yup from 'yup';
import { Text, LinkComponent } from '@rsos/components/capstone/base';
import { Box } from '@rsos/components/capstone/base';
import InputText from '@rsos/components/capstone/base/form/inputs/InputText';
import { fetchUser, logout } from '@rsos/sinatra';
import { PROVIDER_LOGIN_SUCCESS } from '@rsos/sinatra/src/actions/types';
import { captureException } from '@rsos/utils/sentry';
import { notifyError } from '@rsos/utils/toastUtils';
import { trackSSOLogin } from '../../actions/authTrackings';
import rapidsosLogo from '../../assets/rapidsos_logo_light.png';
import { persistor } from '../../store';
import { AppRedirecting } from '../Landing/Landing.styles';
import {
  LoginButton,
  LoginWrapper,
  LoginImage,
  StyledPage,
  StyledForm,
} from './SSOLogin.styles';
import authenticateWithSSO from './authenticateWithSSO';
import authorizeWithSSO from './authorizeWithSSO';
import createRedirectURI from './createRedirectURI';
import determineAppPath from './determineAppPath';
import parseQueryString from './parseQueryString';

export const LOGIN_BUTTON_TEXT = 'Sign in with SSO';
export const LOGIN_ERROR_MESSAGE = `Login failed: We could not authenticate you using SSO. If this error persists, please reach out to your system admin.`;
export const EMAIL_ERROR_MESSAGE = 'Please enter a valid email address';
// NOTE: This is the same regex used in the authentication endpoint.
export const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const PORTAL_EULA_LINK = 'https://rapidsos.com/ecc-end-user-license/';
export const PRIVACY_POLICY_LINK =
  'https://rapidsos.com/legal/emergency-related-services-privacy-policy/';

class SSOLoginUserError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SSOLoginUserError';
  }
}

class SSOLoginAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SSOLoginAPIError';
  }
}

const reportAPIError = (errorInstance, errorExtras = {}) => {
  captureException(errorInstance, errorExtras);
};

const validationSchema = Yup.object({
  email: Yup.string()
    .matches(EMAIL_VALIDATION_REGEX, EMAIL_ERROR_MESSAGE)
    .required(EMAIL_ERROR_MESSAGE),
});

const SSOLogin = () => {
  const dispatch = useDispatch();
  const isFetching = useRef(false); // ensure only one fetch is made at a time
  const isLoggedIn = useSelector(state => state.sinatra?.user?.isLoggedIn);
  const [isAuthenticationSuccess, setIsAuthenticationSuccess] = useState(false);
  const [isAuthenticationFailure, setIsAuthenticationFailure] = useState(false);
  const [isAuthorizationFailure, setIsAuthorizationFailure] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false); // ui spinner
  const [parsedArgs, setParsedArgs] = useState(null);

  // Helper to redirect to the user's application after authorization.
  const redirectToApp = useCallback(async () => {
    if (!isFetching.current) {
      try {
        isFetching.current = true;
        setIsRedirecting(true);
        const user = await dispatch(fetchUser());
        trackSSOLogin(user?.currentRole?.application);
        const applicationPath = determineAppPath(user);
        window.location.assign(applicationPath);
      } catch (error) {
        reportAPIError(error);
        setIsAuthenticationSuccess(false);
        setIsAuthorizationFailure(true);
      } finally {
        isFetching.current = false;
        setIsRedirecting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // On mount check if authentication was successful or already logged in.
  useEffect(() => {
    if (isLoggedIn) {
      redirectToApp();
      return;
    }
    const parsedArgs = parseQueryString(window.location.search);
    const { code, error } = parsedArgs;
    setParsedArgs({ code, error });
    if (code && !error) {
      setIsAuthenticationSuccess(true);
    } else if (error) {
      setIsAuthenticationSuccess(false);
      setIsAuthenticationFailure(true);
      reportAPIError(new SSOLoginUserError('User error'), { error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle error states
  useEffect(() => {
    const resetLoginAttempt = async () => {
      // NOTE: This function is called when the user is not able to login at
      // any point in the process. It's responsible for clearing out any
      // artifacts that might be left behind from the login attempt in memory,
      // local storage, indexedDB, and redux state (persistor). The order of
      // operations is important here.
      await dispatch(logout()); // reset data in redux state (user profile)
      await persistor.flush(); // finish any pending operations in persistor
      await persistor.purge(); // purge possible in-memory persistor state
      localStorage.clear(); // clear local storage of already persisted data
      if (window.indexedDB) {
        // clear indexedDB which could be caching persistor data
        window.indexedDB.deleteDatabase('localforage');
      }
      // clear sso related query args from the URL (code, error)
      const url =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: url }, '', url);
    };

    if (isAuthenticationFailure || isAuthorizationFailure) {
      notifyError(LOGIN_ERROR_MESSAGE, { autoClose: false });
      resetLoginAttempt();
    }
  }, [dispatch, isAuthenticationFailure, isAuthorizationFailure]);

  // Authorization
  useEffect(() => {
    const doAuthorization = async code => {
      try {
        const tokens = await authorizeWithSSO(code);
        if (!isEmpty(tokens)) {
          // Ensure that sinatra sets the tokens internally
          dispatch({
            type: PROVIDER_LOGIN_SUCCESS,
            tokens,
          });
          redirectToApp();
        }
      } catch (error) {
        const ssoError = new SSOLoginAPIError('Token exchange failed');
        reportAPIError(ssoError, { code });
        setIsAuthenticationSuccess(false);
        setIsAuthorizationFailure(true);
      }
    };
    if (isAuthenticationSuccess && parsedArgs?.code) {
      const code = parsedArgs.code;
      doAuthorization(code);
    }
  }, [dispatch, isAuthenticationSuccess, parsedArgs?.code, redirectToApp]);

  return (
    <StyledPage>
      {isRedirecting ? (
        <AppRedirecting />
      ) : (
        <LoginWrapper>
          <LoginImage src={rapidsosLogo} />
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              // Authenticate with the user's IDP.
              try {
                setIsAuthenticationSuccess(true);
                const redirectURI = createRedirectURI('/sso');
                const authorizationURL = await authenticateWithSSO(
                  values.email,
                  redirectURI,
                );

                if (!authorizationURL) {
                  throw new SSOLoginAPIError('No authorization URL found');
                }

                // Leave the app momentarily for the user to authenticate with
                // their identity provider (handled via WorkOS).
                window.location.assign(authorizationURL);
              } catch (error) {
                reportAPIError(error);
                setIsAuthenticationSuccess(false);
                setIsAuthenticationFailure(true);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              dirty,
              errors,
              handleBlur,
              handleChange,
              isSubmitting,
              isValid,
              setFieldValue,
              touched,
              values,
            }) => (
              <StyledForm>
                <Box width="100%">
                  <Box>
                    <InputText
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="email@domain.com"
                      bgType="primary"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      shouldErrorTakeSpace
                      error={
                        errors.email && touched.email ? errors.email : null
                      }
                    />
                  </Box>
                  <Box mt={20}>
                    <LoginButton
                      isLoadingProp={isAuthenticationSuccess}
                      disabled={!dirty || isSubmitting || !isValid}
                      dataName="sso-button"
                    >
                      {LOGIN_BUTTON_TEXT}
                    </LoginButton>
                  </Box>
                </Box>
                <Box mt={20}>
                  <Text size="small" textAlign="center" role="status">
                    By logging in, you agree to be bound by the <br />{' '}
                    <LinkComponent to={PORTAL_EULA_LINK} withBg={false}>
                      Portal EULA
                    </LinkComponent>{' '}
                    and{' '}
                    <LinkComponent to={PRIVACY_POLICY_LINK} withBg={false}>
                      Privacy Policy
                    </LinkComponent>
                  </Text>
                </Box>
              </StyledForm>
            )}
          </Formik>
        </LoginWrapper>
      )}
    </StyledPage>
  );
};

export default SSOLogin;
