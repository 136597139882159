import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ExternalLinkIcon } from '@rsos/assets/icons';
import { Box, Text } from '@rsos/components/capstone/base';
import capitalizeFirstLetter from '@rsos/utils/capitalizeFirstLetter';
import { sharedStyleMenuItemRow } from '../../common/common.styles.js';

const SamlNavItem = styled(Box).attrs({
  horizontal: true,
  justify: 'space-between',
  align: 'center',
  width: '100%',
})`
  cursor: ${props => (props.isLoading ? 'progress' : 'pointer')};
  ${sharedStyleMenuItemRow}
`;

/**
 * Component used for SAML requests to Scorpius.
 * The `data-name` tag is used for GainSightPX.
 * @param {Function} handleClick - Function to handle `onClick`s
 * @param {Boolean} isLoadingSAML - Used with `service` to determine loading state
 * @param {String} service - Service that the user is trying to access
 */
const SamlLink = ({ handleClick, isLoadingSAML, service, withIcon }) => {
  const formattedName = capitalizeFirstLetter(service);
  return (
    <SamlNavItem
      isLoading={!!isLoadingSAML[service]}
      onClick={() => handleClick(service)}
      data-name={formattedName}
    >
      <Text ellipsis>{formattedName}</Text>
      {withIcon ? (
        <Box ml={1}>
          <ExternalLinkIcon
            viewboxWidth={100}
            viewboxHeight={100}
            width={12}
            height={12}
          />
        </Box>
      ) : null}
    </SamlNavItem>
  );
};

SamlLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isLoadingSAML: PropTypes.object,
  service: PropTypes.string.isRequired,
};

export default SamlLink;
