import {
  getAppName,
  getTalkJSServer,
  getTalkJSAppId,
} from '@rsos/utils/metaTags';
import { captureException } from '@rsos/utils/sentry';
import exportToGlobalNamespace from './exportToGlobalNamespace';

const TALKJS_URL = `${getTalkJSServer()}/cdn/talk.js`;
const TALKJS_APP_ID = getTalkJSAppId();

export const ALERT_PREFIX = 'alert-';
export const CHAT_MIDAS_PREFIX = 'chat-midas-default-';
export const CHAT_PORTAL_PREFIX = 'chat-portal-default-';
export const CHAT_RAPTOR_PREFIX = 'chat-raptor-';

const feAppName = getAppName();

/**
 * RapidSOS hosted TalkJS package.
 */
let Talk;

if (window.Talk) {
  Talk = window.Talk;
} else {
  ((t, a, l, k, j, s) => {
    s = a.createElement('script');
    s.async = 1;
    s.src = TALKJS_URL;
    a.head.appendChild(s);
    k = t.Promise;
    t.Talk = {
      v: 3,
      ready: {
        then: function (f) {
          if (k)
            return new k(function (r, e) {
              l.push([f, r, e]);
            });
          l.push([f]);
        },
        catch: function () {
          return k && new k();
        },
        c: l,
      },
    };
  })(window, document, []);

  Talk = window.Talk;
}

/**
 * Create a TalkJS user.
 * Can be used in a session constuctor or in a conversation setParticipant(user, settings).
 * https://talkjs.com/docs/Reference/JavaScript_Chat_SDK/Session.html#Session.constructor
 * https://talkjs.com/docs/Reference/JavaScript_Chat_SDK/ConversationBuilder.html#ConversationBuilder.setParticipant
 * @param {Object} user - user attributes should include id, name, email, and custom
 * @returns TalkJS user
 */
export const createTalkUser = user => {
  const { id = '', name = '', email = null, custom = {}, role = '' } = user;

  return new Talk.User({
    id: id,
    name: name,
    email: email,
    custom: custom,
    ...(role ? { role: role } : {}),
  });
};

/**
 * Create a TalkJS session.
 * Can be used to initialize a TalkJS session which can be used with any chat UI modes.
 * https://talkjs.com/docs/Features/Chat_UI_Modes/index.html
 * @param {Object} user - TalkJS user, see createTalkUSer(user)
 * @returns TalkJS session
 */
export const createTalkSession = async user => {
  try {
    await Talk.ready;

    const me = createTalkUser(user);

    let talkjsSession;

    if (window.__RSOS.talkjsSession) {
      talkjsSession = window.__RSOS.talkjsSession;
    } else {
      talkjsSession = new Talk.Session({
        appId: TALKJS_APP_ID,
        me: me,
        signature: user.signature,
      });

      exportToGlobalNamespace('talkjsSession', talkjsSession);
    }

    return talkjsSession;
  } catch (error) {
    captureException(error);
  }
};

/**
 * Check if the string is a valid alert conversation ID.
 * @param {String} conversationID - the conversation ID
 * @returns Boolean
 */
export const isValidAlertConversationID = conversationID => {
  if (typeof conversationID !== 'string') {
    return false;
  }
  const validFormats = [
    ALERT_PREFIX,
    CHAT_MIDAS_PREFIX,
    CHAT_PORTAL_PREFIX,
    CHAT_RAPTOR_PREFIX,
  ];

  return validFormats.some(prefix => conversationID.startsWith(prefix));
};

/**
 * Parse the alert ID from the conversation ID.
 * @param {Object} conversation - TalkJS conversation object
 * @returns String
 */
export const getAlertIdFromConversation = conversation => {
  if (conversation.id.startsWith(CHAT_PORTAL_PREFIX)) {
    return `${ALERT_PREFIX}${conversation.id.split(CHAT_PORTAL_PREFIX)[1]}`;
  } else if (conversation.id.startsWith(CHAT_MIDAS_PREFIX)) {
    return `${ALERT_PREFIX}${conversation.id.split(CHAT_MIDAS_PREFIX)[1]}`;
  } else if (conversation.id.startsWith(CHAT_RAPTOR_PREFIX)) {
    return `${ALERT_PREFIX}${conversation.id.split(CHAT_RAPTOR_PREFIX)[1]}`;
  }

  // Backwards compatibility. Assume the ID is the alert ID
  return conversation.id;
};

export const isValidOrigin = lastMessage => {
  const isRaptor = lastMessage.conversation.id.startsWith(CHAT_RAPTOR_PREFIX);
  const isNotRest = lastMessage.origin !== 'rest' && !isRaptor;

  return isNotRest || isRaptor;
};

/**
 * Helper function to use with TalkJS when sending chat messages based on the
 * custom permissions set for different types of users. Used in conjunction
 * with getChatFilterPermissions.
 */
export const getChatPermissions = () => {
  if (feAppName === 'incident_central_station') {
    return {
      'permission.csp': 'False',
      'permission.icsp': 'True',
      'permission.midas_user': 'True',
      'permission.rsp': 'False',
    };
  } else {
    return {
      'permission.csp': 'True',
      'permission.icsp': 'False',
      'permission.midas_user': 'False',
      'permission.rsp': 'True',
    };
  }
};

/**
 * Helper function that returns the `custom` object used with
 * `chatbox.setMessageFilter'`. Used in conjunction with `getChatPermissions`.
 * Each app should only care about its corresponding permission being set to
 * `True`. If there is more than 1 `True`, the filtering attempts to match the
 * entire object's permissions to the messages in the websocket.
 */
export const getChatFilterPermissions = () => {
  return {
    'permission.csp':
      feAppName === 'central_station' ? ['==', 'True'] : 'exists',
    'permission.icsp':
      feAppName === 'incident_central_station' ? ['==', 'True'] : 'exists',
    'permission.rsp':
      feAppName === 'emergency_data' ? ['==', 'True'] : 'exists',
    'permission.midas_user': 'exists',
  };
};
