import {
  AGENT_511_LOCATION,
  AGENT_511_ADR,
} from '@rsos/capstone/src/constants/capabilities';
import { AGENT_511 as AGENT_511_PERMISSION } from '@rsos/capstone/src/constants/permissionNames';

/**
 * Function to determine if the current user's authority has the `agent_511`
 * capability and permission enabled
 * @param {Object} psaps - PSAPS reducer
 * @param {Object} sinatra - Sinatra reducer
 */
const checkAgent511Access = (psaps, sinatra) => {
  const isLocationCapabilityEnabled = !!psaps?.currentPSAP
    ?.activeCapabilities?.[AGENT_511_LOCATION];
  const isADRCapabilityEnabled = !!psaps?.currentPSAP?.activeCapabilities?.[
    AGENT_511_ADR
  ];
  const isPermissionEnabled = !!sinatra?.user?.currentRole?.permissions?.includes(
    AGENT_511_PERMISSION,
  );
  return (
    isLocationCapabilityEnabled && isADRCapabilityEnabled && isPermissionEnabled
  );
};

export default checkAgent511Access;
