import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CAPSTONE } from '@rsos/constants/adminContents';
import {
  DISPATCH_REQUESTED,
  IGNORED,
  NEW,
  DECLINED,
  ACCEPTED,
} from '@rsos/constants/alertStatuses';
import { ACTIVE_ASSAILANT, TRAIN_DERAILMENT } from '@rsos/constants/alertTypes';
import postMidasFlow from '@rsos/midas/src/postMidasFlow';
import normalizeError from '@rsos/sinatra/src/utils/normalizeError';
import { MW_5_0_0 } from '../../constants/migrationVersions';
import {
  getAlerts,
  getAlert,
  getEmergencyTypes,
  getAlertTypes,
  postAlertFlow,
  patchAlertStatus,
} from './alertsAPI';

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array
    ? array.reduce((obj, item) => {
        return {
          ...obj,
          [item[key]]: item,
        };
      }, initialValue)
    : {};
};

/**
 * Normalize alerts for use with badge notification counters and map markers
 * @param {Array} alerts - list of alerts from fetching all alerts
 */
const normalizeAlerts = alerts => {
  return alerts.map(alert => {
    if (!alert.isIgnoredByUserID) {
      alert.isIgnoredByUserID = '';
    }
    if (!alert.isStatusUpdated) {
      alert.isStatusUpdated = false;
      alert.numStatusUpdates = 0;
    }
    if (!alert.isUnread) {
      alert.isUnread = false;
      alert.numUnreads = 0;
    }
    if (!alert.hasOwnProperty('location_history')) {
      alert.location_history = [
        {
          ...alert.location,
          created_at: alert.created_time,
        },
      ];
    }
  });
};

const addExistingWhat3Words = (existingAlert, fetchedAlert) => {
  // If the alert apis dont align or alert doesn't exist, just skip this entirely
  if (
    !existingAlert ||
    existingAlert.location_history?.length !==
      fetchedAlert.location_history?.length
  ) {
    return fetchedAlert;
  }

  existingAlert.location_history.forEach((location, index) => {
    fetchedAlert.location_history[index].what3words = location.what3words;
  });

  return fetchedAlert;
};

// In RSP, DISPATCH_REQUESTED status is treated as a NEW alert,
// when this happens, it should not display the unread bar on the left side of the alert.
// Additionally, if an alert is currently selected, it should not display the unread bar.
const checkAlertStatus = (
  alertID,
  selectedAlertID,
  messageName,
  currentApplication,
) => {
  return (
    (alertID !== selectedAlertID &&
      currentApplication === CAPSTONE &&
      messageName !== DISPATCH_REQUESTED) ||
    (alertID !== selectedAlertID &&
      currentApplication !== CAPSTONE &&
      messageName !== NEW)
  );
};

const updatedAlerts = (alerts, selectedAlertID, payload) => {
  const { alert_id: alertID, message, current_application } = payload;

  const updatedStatus = {
    display_name: message.display_name,
    name: message.name,
  };

  if (message.name === DISPATCH_REQUESTED) {
    return {
      ...alerts,
      [alertID]: {
        ...alerts[alertID],
        isStatusUpdated: checkAlertStatus(
          alertID,
          selectedAlertID,
          message.name,
          current_application,
        ),
        sla_expiration_time: payload.sla_expiration_time,
        status: updatedStatus,
      },
    };
  }
  if (message.name === ACCEPTED || message.name === DECLINED) {
    const enrichedStatus = {
      ...updatedStatus,
      sender: payload.sender,
    };
    return {
      ...alerts,
      [alertID]: {
        ...alerts[alertID],
        isStatusUpdated: checkAlertStatus(
          alertID,
          selectedAlertID,
          message.name,
          current_application,
        ),
        status: enrichedStatus,
      },
    };
  }

  return {
    ...alerts,
    [alertID]: {
      ...alerts[alertID],
      isStatusUpdated: checkAlertStatus(
        alertID,
        selectedAlertID,
        message.name,
        current_application,
      ),
      status: updatedStatus,
    },
  };
};

/**
 * - Set alertID as the selected alert ID.
 * - Set the selected alert ID as read.
 * - Removes the selected alert ID from unreadAlerts and audibleNotificationIDs.
 * @param {Object} state - The incidents state
 * @param {String} alertID - The alert ID
 */
const setSelectedAlert = (state, alertID) => {
  state.selectedAlertID = alertID;

  if (state.alerts[alertID] && state.alerts[alertID].isStatusUpdated) {
    state.alerts[alertID].isStatusUpdated = false;

    state.alerts[alertID].numStatusUpdates = 0;

    if (state.unreadAlerts.includes(a => a.alert_id === alertID)) {
      state.unreadAlerts = state.unreadAlerts.filter(
        alert => alert.alert_id !== alertID,
      );
    }
  }

  const notificationIDIndex = state.audibleNotificationIDs.indexOf(alertID);

  if (notificationIDIndex > -1) {
    state.audibleNotificationIDs = [
      ...state.audibleNotificationIDs.slice(0, notificationIDIndex),
      ...state.audibleNotificationIDs.slice(notificationIDIndex + 1),
    ];
  }
};

export const fetchAlerts = createAsyncThunk(
  'alerts/fetchAlerts',
  async ({ orgName, queueMaxSize, endTime }, { rejectWithValue }) => {
    // The startTime (currently unused) can be used to support infinite
    // scrolling / pagination from the UI by triggering a request when
    // scrolling to the end of the queue using the `incidents.alerts_until` value
    try {
      const response = await getAlerts(orgName, queueMaxSize, endTime);
      return response;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const fetchAlert = createAsyncThunk(
  'alerts/fetchAlert',
  async (
    { currentApplication, currentOrgName, alertID, shouldSetID },
    { rejectWithValue },
  ) => {
    // `applicationName` is used to determine if the request is coming from
    // CAPSTONE or CENTRAL_STATION. It is used when the request is fulfilled.
    try {
      const response = await getAlert(currentOrgName, alertID);
      return { alert: response.data, currentApplication, shouldSetID };
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const fetchEmergencyTypes = createAsyncThunk(
  'alerts/fetchEmergencyTypes',
  async (orgName, { rejectWithValue }) => {
    // The startTime (currently unused) can be used to support infinite
    // scrolling / pagination from the UI by triggering a request when
    // scrolling to the end of the queue using the `incidents.alerts_until` value
    try {
      const response = await getEmergencyTypes(orgName);
      return response;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const fetchAlertTypes = createAsyncThunk(
  'alerts/fetchAlertTypes',
  async (orgName, { rejectWithValue }) => {
    try {
      const response = await getAlertTypes(orgName);
      return response;
    } catch (error) {
      const { message } = normalizeError(error);
      const errorMessage = message || 'Error fetching alert types';
      return rejectWithValue(errorMessage);
    }
  },
);

export const triggerAlertFlow = createAsyncThunk(
  'alerts/triggerAlertFlow',
  async (
    {
      callflow,
      blocking = false,
      test_mode = false,
      variables = {},
      fetchConfig,
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const response = await postAlertFlow({
        callflow,
        blocking,
        test_mode,
        variables,
        getState,
        dispatch,
        fetchConfig,
      });

      return response;
    } catch (error) {
      const newError = await error.json();
      return rejectWithValue(newError.detail);
    }
  },
);

export const triggerMidasFlow = createAsyncThunk(
  'alerts/triggerMidasFlow',
  async ({ callflow, variables = {} }, { rejectWithValue }) => {
    try {
      const response = await postMidasFlow({
        callflow,
        variables,
      });

      return response;
    } catch (error) {
      const { message } = normalizeError(error);
      return rejectWithValue(message);
    }
  },
);

export const updateAlertStatus = createAsyncThunk(
  'alerts/updateAlertStatus',
  async (info, { rejectWithValue }) => {
    try {
      const response = await patchAlertStatus(info);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const initialState = {
  activeAlertToasts: [],
  alerts: {},
  audibleNotificationIDs: [],
  declinedAlertID: null,
  error: {
    allAlerts: null,
    alert: null,
    alertAdr: null,
    alertFlow: null,
    emergencyTypes: null,
    alertStatus: null,
    alertTypes: null,
  },
  IDWithNewAlert: null, // Note: this isn't persisted like numberWithNewLocation
  IDWithSound: null,
  loading: {
    allAlerts: false,
    alert: false,
    alertAdr: false,
    emergencyTypes: false,
    alertFlow: false,
    alertStatus: false,
    alertTypes: false,
  },
  numUnreadAlertNotifications: 0,
  removedAlerts: [],
  selectedAlertID: null,
  unreadAlerts: [],
  alertsWithRepeat: [],
  alertsQueueOrder: [],
  latestMsgTime: 0,
  activeAssailantAlerts: {},
  trainAlerts: {},
  testAlerts: {},
  emergencyTypes: [],
  alertTypes: [],
};

const incidentsSlice = createSlice({
  name: 'incidents',
  initialState: initialState,
  reducers: {
    addNewAlert: (state, action) => {
      const { alert_id: alertID } = action.payload;
      const normalizedAlert = {
        ...action.payload,
        isIgnoredByUserID: '',
        isStatusUpdated: true,
        isUnread: false,
        location: {
          ...action.payload.location,
          created_at: action.payload.created_time,
        },
        numStatusUpdates: 1,
        numUnreads: 0,
      };
      if (!state.alerts[alertID]) {
        state.alerts = {
          ...state.alerts,
          [alertID]: normalizedAlert,
        };
      } else {
        state.alerts[alertID] = {
          ...action.payload,
        };
      }
      state.IDWithNewAlert = alertID;
      state.IDWithSound = alertID;
      const { emergency_type: emergencyType } = action.payload;
      if (emergencyType?.name === ACTIVE_ASSAILANT) {
        state.activeAssailantAlerts = {
          ...state.activeAssailantAlerts,
          [alertID]: normalizedAlert,
        };
      }
      if (emergencyType?.name === TRAIN_DERAILMENT) {
        state.trainAlerts = {
          ...state.trainAlerts,
          [alertID]: normalizedAlert,
        };
      }
      if (emergencyType?.name.startsWith('TEST')) {
        state.testAlerts = {
          ...state.testAlerts,
          [alertID]: normalizedAlert,
        };
      }
      return state;
    },
    addFollower: (state, action) => {
      if (
        state.selectedAlertID &&
        !state.alerts[state.selectedAlertID].followers.includes(action.payload)
      ) {
        state.alerts[state.selectedAlertID].followers.push(action.payload);
      }
    },
    receivedAlertUpdatedStatus: (state, action) => {
      const alertID = action.payload.alert_id;
      if (action.payload.message_type === 'STATUS_UPDATE') {
        state.alerts = updatedAlerts(
          state.alerts,
          state.selectedAlertID,
          action.payload,
        );
        state.activeAssailantAlerts = updatedAlerts(
          state.activeAssailantAlerts,
          state.selectedAlertID,
          action.payload,
        );
        state.trainAlerts = updatedAlerts(
          state.trainAlerts,
          state.selectedAlertID,
          action.payload,
        );
        state.testAlerts = updatedAlerts(
          state.testAlerts,
          state.selectedAlertID,
          action.payload,
        );
        if (state.selectedAlertID !== alertID) {
          state.alerts[alertID].isStatusUpdated = true;
          state.alerts[alertID].numStatusUpdates = 1;
          state.numAudibleNotifications += 1;
        }

        state.alertsWithRepeat.map(alert => {
          if (alert.alertID === alertID) {
            alert.status = state.alerts[alertID].status.name;
            alert.audio.loop = false;

            if (state.IDWithSound === alertID) {
              state.IDWithSound = null;
            }
          }
        });
      }
      if (action.payload.message.name === IGNORED) {
        state.ignoreAlertID = alertID;
        if (state.selectedAlertID !== alertID) {
          delete state.alerts[alertID];
        }
      }
      if (action.payload.message.name === DECLINED) {
        state.declinedAlertID = alertID;
        if (alertID && state.selectedAlertID !== alertID) {
          delete state.alerts[alertID];
        }
      }
    },
    removeAlert: (state, action) => {
      if (action.payload) {
        state.alertsWithRepeat.map(alert => {
          if (alert.alertID === action.payload) {
            alert.audio.loop = false;
            if (state.IDWithSound === action.payload) {
              state.IDWithSound = null;
            }
          }
        });

        delete state.alerts[action.payload];
        if (state.activeAssailantAlerts[action.payload]) {
          delete state.activeAssailantAlerts[action.payload];
        }
        if (state.trainAlerts[action.payload]) {
          delete state.trainAlerts[action.payload];
        }
        if (state.testAlerts[action.payload]) {
          delete state.testAlerts[action.payload];
        }
        if (action.payload === state.selectedAlertID) {
          state.selectedAlertID = null;
        }
        state.removedAlerts = [...state.removedAlerts, action.payload];

        const alertIdx = state.alertsQueueOrder.findIndex(
          a => a.alertID === action.payload,
        );
        if (alertIdx !== -1) {
          state.alertsQueueOrder.splice(alertIdx, 1);
        }
      }
    },
    resetIDWithNewAlert: state => {
      state.IDWithNewAlert = null;
    },
    resetIDWithSound: state => {
      state.IDWithSound = null;
    },
    resetMidasError: state => {
      state.error.alertFlow = null;
    },
    setSelectedAlertID: (state, action) => {
      const alertID = action.payload;

      setSelectedAlert(state, alertID);
    },
    updateAlert: (state, action) => {
      const alertID = action.payload.body.alert_id;
      // If alert was removed from queue, don't do anything
      if (state.removedAlerts.includes(alertID)) {
        return state;
      }
      const newLocation = {
        ...action.payload.body,
        created_at: action.payload.created_time,
      };
      delete newLocation.alert_id;
      // Note: When not viewing an alert, the `location_history` may not
      // necessarily match the alert's full `location_history`. The fetch all
      // alerts request only returns the most recent location and not the
      // history

      // compare newLocation with most recent loc to prevent duplication
      const isDuplicatedNewLocation =
        newLocation.created_at ===
        state.alerts[alertID]?.location_history?.[0]?.created_at;

      let locationHistory = state.alerts[alertID].location_history.map(
        loc => loc,
      );

      if (state.alerts[alertID]?.location_history && !isDuplicatedNewLocation) {
        locationHistory = [newLocation, ...locationHistory];
      } else if (!state.alerts[alertID]?.location_history) {
        locationHistory = [newLocation];
      }

      state.alerts[alertID] = {
        ...state.alerts[alertID],
        location: newLocation,
        location_history: locationHistory,
      };

      // Only update IDWithNewAlert when viewing it and !isDuplicatedNewLocation to prevent map marker issues
      if (
        (state.selectedAlertID === alertID || state.selectedAlertID === null) &&
        !isDuplicatedNewLocation
      ) {
        state.IDWithNewAlert = alertID;
      }
    },
    viewAllAlerts: state => {
      state.selectedAlertID = null;
      state.IDWithNewAlert = null;
    },
    setAlertUnread: (state, action) => {
      const alert = action.payload;
      const alertID = alert.alert_id;

      if (state.selectedAlertID !== alertID) {
        // Set the alert in the queue as unread
        state.alerts[alertID].isUnread = true;
        state.alerts[alertID].numUnreads = 1;

        const doesAlertExist = !!state.unreadAlerts.find(
          alert => alert.alert_id === alertID,
        );

        if (!doesAlertExist) {
          // Alert markers rely on state.unreadAlerts to determine if it should be unread or not
          state.unreadAlerts.push(state.alerts[alertID]);
        }
      }
    },
    setAlertRead: (state, action) => {
      const alertID = action.payload;

      if (state.alerts[alertID] && state.selectedAlertID === alertID) {
        if (state.alerts[alertID].isUnread) {
          state.alerts[alertID].isUnread = false;
          state.alerts[alertID].numUnreads = 0;
        }

        state.unreadAlerts = state.unreadAlerts.filter(
          alert => alert.alert_id !== alertID,
        );
      }
    },
    resetUnreadAlerts: state => {
      state.unreadAlerts = [];
    },
    setAlertQueueOrder: (state, action) => {
      const alertsQueue = action.payload;

      state.alertsQueueOrder = alertsQueue.filter(
        alert => alert.alertID !== state.selectedAlertID,
      );
    },
    removeAlertQueueOrder: (state, action) => {
      const alertID = action.payload;
      const alertIdx = state.alertsQueueOrder.findIndex(
        a => a.alertID === alertID,
      );
      if (alertIdx !== -1) {
        state.alertsQueueOrder.splice(alertIdx, 1);
      }
    },
    setLatestMsgTime: (state, action) => {
      const latestMsgTime = action.payload;
      if (state.latestMsgTime < latestMsgTime) {
        state.latestMsgTime = latestMsgTime;
      }
    },
    getNumUnreadAlertNotifications: state => {
      let totalNotifications = 0;
      if (Object.keys(state.alerts).length) {
        Object.values(state.alerts).forEach(alert => {
          let notification = 0;
          if (alert.numStatusUpdates || alert.numUnreads) notification = 1;
          totalNotifications += notification;
        });
      }
      state.numUnreadAlertNotifications = totalNotifications;
    },
    setAudibleNotificationIDs: (state, action) => {
      const alertID = action.payload;
      if (
        state.selectedAlertID !== alertID &&
        !state.audibleNotificationIDs.includes(alertID)
      ) {
        state.audibleNotificationIDs.push(alertID);
      }
    },
    setAlertIgnoredBy: (state, action) => {
      const { alertID, userID } = action.payload;
      state.alerts[alertID].isIgnoredByUserID = userID;
    },
    setAlertsWithRepeat: (state, action) => {
      const { alertID } = action.payload;
      if (action.payload) {
        const payloadWithStatus = {
          ...action.payload,
          status: state?.alerts[alertID]?.status.name,
        };
        state.alertsWithRepeat = [payloadWithStatus, ...state.alertsWithRepeat];
      }
    },
    addActiveAlertToasts: (state, action) => {
      state.activeAlertToasts = [...state.activeAlertToasts, action.payload];
    },
    removeActiveAlertToasts: (state, action) => {
      state.activeAlertToasts = state.activeAlertToasts.filter(
        i => i.alertID !== action.payload,
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAlerts.pending, state => {
        state.loading.allAlerts = true;
      })
      .addCase(fetchAlerts.rejected, (state, action) => {
        state.loading.allAlerts = false;
        state.error.allAlerts = action.payload;
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        let allAlerts = [];
        let alertsUntil;
        // If action.payload is an array, there were multiple requests to the
        // alerts endpoint
        if (Array.isArray(action.payload)) {
          action.payload.forEach(payload => {
            allAlerts = allAlerts.concat(payload.alerts);
          });
          alertsUntil = action.payload[action.payload.length - 1].alerts_until;
          // Take the last alerts_until value
        } else if (action.payload.alerts) {
          allAlerts = action.payload.alerts;
          alertsUntil = action.payload.alerts_until;
        }

        // Splice the alerts if number of alerts exceeds the queue max size
        const queueMaxSize = Number(action.meta.arg.queueMaxSize);
        if (allAlerts.length > queueMaxSize) {
          allAlerts = allAlerts.splice(0, queueMaxSize);
        }

        if (allAlerts.length) {
          normalizeAlerts(allAlerts);
          (state.loading.allAlerts = false),
            (state.alerts = convertArrayToObject(allAlerts, 'alert_id')),
            (state.alertsUntil = alertsUntil);

          state.activeAssailantAlerts = convertArrayToObject(
            allAlerts.filter(
              a =>
                a.emergency_type.name === ACTIVE_ASSAILANT &&
                !state.removedAlerts.find(ra => a.alert_id === ra),
            ),
            'alert_id',
          );
          state.trainAlerts = convertArrayToObject(
            allAlerts.filter(
              a =>
                a.emergency_type.name === TRAIN_DERAILMENT &&
                !state.removedAlerts.find(ra => a.alert_id === ra),
            ),
            'alert_id',
          );

          state.testAlerts = convertArrayToObject(
            allAlerts.filter(
              a =>
                a.emergency_type.name.startsWith('TEST') &&
                !state.removedAlerts.find(ra => a.alert_id === ra),
            ),
            'alert_id',
          );
        } else {
          state.loading.allAlerts = false;
          state.alerts = {};
          state.activeAssailantAlerts = {};
          state.trainAlerts = {};
          state.testAlerts = {};
          state.selectedAlertID = null;
          state.IDWithNewAlert = null;
        }
      })
      .addCase(fetchAlert.pending, state => {
        state.loading.alert = true;
      })
      .addCase(fetchAlert.rejected, (state, action) => {
        state.loading.alert = false;
        state.error.alert = action.payload;
      })
      .addCase(fetchAlert.fulfilled, (state, action) => {
        state.error.alert = null;
        state.loading.alert = false;
        const { alert, currentApplication } = action.payload;
        const alertID = alert.alert_id;
        const fetchedAlert = addExistingWhat3Words(
          state.alerts[alertID],
          alert,
        );
        // If the request is coming from CAPSTONE, add the alert to the alerts object when the alert
        // status is not 'NEW'. This is because when the status is 'NEW' it means that it has been
        // received by CENTRAL_STATION but not yet dispatched to CAPSTONE yet.
        // If the request is coming from CENTRAL_STATION, add the alert to the alerts object.
        if (currentApplication === CAPSTONE) {
          if (alert.status.name !== NEW) {
            state.alerts[alertID] = {
              ...fetchedAlert,
              isIgnoredByUserID: '',
              isStatusUpdated: alert?.isStatusUpdated ? true : false,
              isUnread: alert?.isUnread ? true : false,
              numStatusUpdates: alert?.isStatusUpdated ? 1 : 0,
              numUnreads: alert?.isUnread ? 1 : 0,
            };
          }
        } else {
          state.alerts[alertID] = {
            ...fetchedAlert,
            isIgnoredByUserID: '',
            isStatusUpdated: alert?.isStatusUpdated ? true : false,
            isUnread: alert?.isUnread ? true : false,
            numStatusUpdates: alert?.isStatusUpdated ? 1 : 0,
            numUnreads: alert?.isUnread ? 1 : 0,
          };
        }
        if (action.payload.shouldSetID) {
          setSelectedAlert(state, alertID);
        }
      })
      .addCase(fetchEmergencyTypes.pending, state => {
        state.loading.emergencyTypes = true;
      })
      .addCase(fetchEmergencyTypes.rejected, (state, action) => {
        state.loading.emergencyTypes = false;
        state.error.emergencyTypes = action.payload;
      })
      .addCase(fetchEmergencyTypes.fulfilled, (state, action) => {
        state.error.emergencyTypes = null;
        state.loading.emergencyTypes = false;
        state.emergencyTypes = action.payload.data?.emergency_types || [];
      })
      .addCase(fetchAlertTypes.pending, state => {
        state.loading.alertTypes = true;
      })
      .addCase(fetchAlertTypes.rejected, (state, action) => {
        state.loading.alertTypes = false;
        state.error.alertTypes = action.payload;
      })
      .addCase(fetchAlertTypes.fulfilled, (state, action) => {
        state.error.alertTypes = null;
        state.loading.alertTypes = false;
        state.alertTypes = action.payload.data?.alert_types || [];
      })
      .addCase(triggerAlertFlow.pending, state => {
        state.loading.alertFlow = true;
      })
      .addCase(triggerAlertFlow.rejected, (state, action) => {
        state.loading.alertFlow = false;
        state.error.alertFlow = action.payload;
      })
      .addCase(triggerAlertFlow.fulfilled, state => {
        state.loading.alertFlow = false;
        state.error.alertFlow = null;
      })
      .addCase(updateAlertStatus.pending, state => {
        state.loading.alertStatus = true;
      })
      .addCase(updateAlertStatus.fulfilled, state => {
        state.loading.alertStatus = false;
      })
      .addCase(updateAlertStatus.rejected, (state, action) => {
        state.loading.alertStatus = false;
        state.error.alertStatus = action.payload;
      })
      .addCase('emergencyCalls/viewAllCalls', state => {
        state.selectedAlertID = null;
      })
      .addCase('emergencyCalls/setSelectedCallerID', (state, action) => {
        // If payload has a truthy value, set selectedAlertID to null
        if (action.payload) {
          state.selectedAlertID = null;
        }
      })
      .addCase('FORCED_LOGOUT_SUCCESS', () => {
        return initialState;
      })
      .addCase('LOGOUT_SUCCESS', () => {
        return initialState;
      })
      .addCase('SINATRA_LOGOUT', () => {
        return initialState;
      })
      .addCase('APP_INIT_SUCCESS', state => {
        // Reset the selectedAlertID if the user was viewing an alert and their
        // permissions for Alerts was turned off or if the `selectedAlertID`
        // doesn't exist in `alerts`
        if (
          !Object.keys(state.alerts).length ||
          !state.alerts[state.selectedAlertID]
        ) {
          state.selectedAlertID = null;
        }
      })
      .addCase('app/initialize/rejected', state => {
        state.selectedAlertID = null;
      })
      .addCase('APP_INIT_START', state => {
        // emergency_data uses `APP_INIT_START`,
        return state;
      })
      .addCase('app/initialize/pending', state => {
        // central_station uses `app/initialize/pending`
        return state;
      })
      .addCase('DECRYPT_IRP_TOKEN_SUCCESS', (state, action) => {
        state.selectedAlertID = action.data.query.split('//')[1];
      });
  },
});

export const {
  addNewAlert,
  addFollower,
  getNumUnreadAlertNotifications,
  receivedAlertUpdatedStatus,
  removeAlert,
  resetIDWithNewAlert,
  resetIDWithSound,
  resetUnreadAlerts,
  resetMidasError,
  setAlertIgnoredBy,
  setAlertsWithRepeat,
  setAlertRead,
  setAlertUnread,
  setAlertQueueOrder,
  removeAlertQueueOrder,
  setLatestMsgTime,
  setAudibleNotificationIDs,
  setSelectedAlertID,
  updateAlert,
  viewAllAlerts,
  addActiveAlertToasts,
  removeActiveAlertToasts,
} = incidentsSlice.actions;

export const sortedAlertsByTime = state =>
  Object.values(state.incidents.alerts).sort(
    (a, b) => b.incident_time - a.incident_time,
  );

const persistConfig = {
  key: 'incidents',
  storage,
  version: MW_5_0_0,
  blacklist: ['alerts', 'IDWithNewAlert'],
};

const persistedReducer = persistReducer(persistConfig, incidentsSlice.reducer);

export default persistedReducer;
