import { createSlice } from '@reduxjs/toolkit';
import { parseNestedData } from '@rsos/components/capstone/DataCards/utils';
import { emergencySessionApi } from '../emergencySession/emergencySessionApi';
import { VIDEO_STREAMING, mediaApi } from './mediaAPI';

export const DEVICE_MEDIA = 'apple_device_media';

const initialState = {
  data: {
    media: {},
    video: {},
  },
};

const mediaSlice = createSlice({
  name: 'media',
  initialState: initialState,
  reducers: {
    // TODO: is this necessary if we need to display both live video and media at the same time
    setMedia: (state, action) => {
      state.mediaType = action.payload;
    },
    setDeviceMediaData: (state, action) => {
      const callerId = action.payload.topic.split('tel://')[1];
      const uploadedMedia = action.payload.body.uploaded_media;
      return {
        ...state,
        data: {
          ...state.data,
          media: {
            ...state.data.media,
            [callerId]: {
              mediaUpload: uploadedMedia,
            },
          },
        },
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase('emergencyCalls/removeCall', (state, action) => {
        delete state.data.media[action.payload];
        delete state.data.video[action.payload];
      })
      .addCase('emergencyCalls/removeAllCalls', () => {
        return { ...initialState };
      })
      .addMatcher(
        mediaApi.endpoints.postMediaRequest.matchFulfilled,
        (state, action) => {
          const originalArgs = action.meta.arg.originalArgs;
          const mediaType = originalArgs.requestType;
          const callerId = originalArgs.callerId;
          if (mediaType === VIDEO_STREAMING) {
            return {
              ...state,
              data: {
                ...state.data,
                video: {
                  ...state.data.video,
                  [callerId]: { ...action.payload },
                },
              },
            };
          } else {
            return {
              ...state,
              data: {
                ...state.data,
                media: {
                  ...state.data.media,
                }, // relevant data will come through hermes
              },
            };
          }
        },
      )
      .addMatcher(
        emergencySessionApi.endpoints.postEmergencySession.matchFulfilled,
        (state, action) => {
          const callerId = action.meta.arg.originalArgs.query.split(
            'tel://',
          )[1];
          const uploadedMedia =
            parseNestedData(action.payload, 'uploaded_media')?.uploaded_media ||
            [];
          if (uploadedMedia.length) {
            return {
              ...state,
              data: {
                ...state.data,
                media: {
                  ...state.data.mediaUpload,
                  [callerId]: {
                    mediaUpload: uploadedMedia,
                  },
                },
              },
            };
          }
          return {
            ...state,
          };
        },
      )
      .addMatcher(
        action => ('LOGOUT_SUCCESS', 'SINATRA_LOGOUT').includes(action.type),
        () => {
          return initialState;
        },
      );
  },
});

export const { setMedia, setDeviceMediaData } = mediaSlice.actions;

export default mediaSlice.reducer;
