import React from 'react';

const Instruction = (props) => {
    return(
        <div className="tooltip-wrapper has-text-centered mx-10">
            ?
            <div className="instruction-container">{props.data}</div>
        </div>
    )
};

export default Instruction;