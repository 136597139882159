import {
  AGENCY_INFO_CONTENT,
  INBOUND_CONTENT,
  OUTBOUND_CONTENT,
  ROLES_CONTENT,
  SECURITY_CONTENT,
  USERS_CONTENT,
  PEER_AGENCIES,
  DATA_SOURCE_CONTENT,
  SETTINGS_CONTENT,
  ALERTS_ROUTING_CONTENT,
  RESPONDERS_CONTENT,
  COMMUNICATION_CONTENT,
  SSO_CONTENT,
  NOTIFICATIONS_CONTENT,
} from '@rsos/constants/adminContents';

export const whichItemIsActive = currentPathName => {
  if (
    currentPathName === '/admin/access' ||
    currentPathName.includes('/admin/access/users')
  ) {
    return USERS_CONTENT;
  } else if (currentPathName.includes('/admin/access/roles')) {
    return ROLES_CONTENT;
  } else if (currentPathName.includes('/admin/access/security')) {
    return SECURITY_CONTENT;
  } else if (currentPathName.includes('/admin/integrations/inbound')) {
    return INBOUND_CONTENT;
  } else if (currentPathName.includes('/admin/integrations/outbound')) {
    return OUTBOUND_CONTENT;
  } else if (currentPathName.includes('/admin/integrations')) {
    return INBOUND_CONTENT;
  } else if (currentPathName.includes('/admin/share-and-chat')) {
    return PEER_AGENCIES;
  } else if (currentPathName.includes('/admin/data-source')) {
    return DATA_SOURCE_CONTENT;
  } else if (currentPathName.includes('/admin/settings')) {
    return SETTINGS_CONTENT;
  } else if (currentPathName.includes('/admin/alerts-routing')) {
    return ALERTS_ROUTING_CONTENT;
  } else if (currentPathName.includes('/admin/responders')) {
    return RESPONDERS_CONTENT;
  } else if (currentPathName.includes('/admin/text-from-911')) {
    return COMMUNICATION_CONTENT;
  } else if (currentPathName.includes('/admin/access/sso')) {
    return SSO_CONTENT;
  } else if (currentPathName.includes('/admin/notifications')) {
    return NOTIFICATIONS_CONTENT;
  }
  return AGENCY_INFO_CONTENT;
};
