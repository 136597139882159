import styled from 'styled-components';

export const StyledLinkComponent = styled.a`
  opacity: ${p => (p.disabled ? 0.4 : 1)};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;
  padding: ${p => (p.noPadding ? '0px' : '3px')};
  color: ${p =>
    p.type === 'secondary'
      ? p.theme.colors.secondaryLink
      : p.theme.colors.primaryBase};

  &:hover {
    background: ${p =>
      p.withBg && !p.disabled
        ? p.type === 'secondary'
          ? p.theme.colors.lightLineDivider
          : p.theme.colors.highlights
        : 'none'};
    border-radius: ${p => (p.withBg ? '3px' : 'none')};
    color: ${p =>
      !p.disabled &&
      (p.type === 'secondary'
        ? p.theme.colors.primaryText
        : p.theme.colors.primaryBaseC2)};
  }
`;
