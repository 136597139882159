import React from 'react';
import styled, { css } from 'styled-components';

const trackHeight = '5px';
const thumbDiameter = '13px';

const track = css`
  box-sizing: border-box;
  border: none;
  background: ${({ theme }) => theme.colors.heavyLineOutline};
  border-radius: 8px;
`;

const trackFill = css`
  ${track};
  height: 5px;
  background-color: transparent;
  background-image: ${({
    theme,
  }) => `linear-gradient(${theme.colors.primaryBase}, ${theme.colors.primaryBase}),
  linear-gradient(${theme.colors.heavyLineOutline}, ${theme.colors.heavyLineOutline})`};
  background-size: var(--sx) 6px, calc(100% - var(--sx)) 4px;
  background-position: left center, right center;
  background-repeat: no-repeat;
`;

const fill = css`
  height: ${trackHeight};
  background: ${({ theme }) => theme.colors.primaryBase};
  border-radius: 4px;
`;

const thumb = css`
  box-sizing: border-box;
  width: ${thumbDiameter};
  height: ${thumbDiameter};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background};
  border: ${({ theme }) => `1px solid ${theme.colors.primaryBase}`};
`;

const Range = styled.input`
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &:focus::-webkit-slider-thumb {
    outline: -webkit-focus-ring-color auto 5px;
  }

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * ${thumbDiameter} + var(--ratio) * (100% - ${thumbDiameter}));

  margin: 0;
  padding: 0;
  height: ${thumbDiameter};
  background: transparent;

  &::-webkit-slider-runnable-track {
    ${trackFill};
  }

  &::-moz-range-track {
    ${track};
  }

  &::-ms-track {
    ${track};
  }

  &::-moz-range-progress {
    ${fill};
  }

  &::-ms-fill-lower {
    ${fill};
  }

  &::-webkit-slider-thumb {
    margin-top: calc(0.5 * (${trackHeight} - ${thumbDiameter}));
    ${thumb};
  }
`;

const Slider = ({ onChange, style, value, ...rest }) => {
  return (
    <Range
      onChange={onChange}
      type="range"
      style={style}
      value={value}
      {...rest}
    />
  );
};

export default Slider;
