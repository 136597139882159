const tt911OrgDefaultState = {
  sound_on: true,
  tone: 'tripleVibe',
  repeat: true,
  repeat_times: 2,
};
const tt911UserAudioDefaultState = {
  volume: 0.7,
};
const checkTT911OrgAudioSettingsObj = obj => {
  return obj.tt911 ? obj : { ...obj, tt911: tt911OrgDefaultState };
};
const checkTT911UserAudioSettingsObj = obj => {
  return obj.tt911 ? obj : { ...obj, tt911: tt911UserAudioDefaultState };
};
const checkStructure = (c, obj) => {
  switch (c) {
    case 'tt911OrgAudioSettings':
      return checkTT911OrgAudioSettingsObj(obj);
    case 'tt911UserAudioSettings':
      return checkTT911UserAudioSettingsObj(obj);
    default:
      return obj;
  }
};
/**
 * checkObjStructure - utility function to validate the expected JSON structure. IT is a fallback for when there is nothing from the BE.
 * @param {object} obj - an object that has to be evaluated against conditions
 * @param {array} conditions - an array of conditions that have a switch to determine the validation function
 */
export const checkObjStructure = (obj, conditions) => {
  let res;
  conditions.forEach(c => {
    res = checkStructure(c, obj);
  });
  return res;
};
