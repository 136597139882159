import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PORTAL_STANDARD_MODAL_PRIORITY } from '@rsos/capstone/src/constants/portalPriorities';
import { Box, Text, Button, Modal } from '@rsos/components/capstone/base';
import {
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@rsos/components/capstone/base/Modal/ModalComponents';
import { createDeeplinkURL } from '@rsos/reduxDataStore/src/features/appLauncher/appLauncherSlice';
import { notifySuccess, notifyError } from '@rsos/utils/toastUtils';
import useIsIRP from '@rsos/utils/useIsIRP';
import {
  trackDataShareConfirmClick,
  trackDataShareCancelClick,
} from '../../appLauncherTrackings';
import { ensureMinDisplayTime, handleAppData } from '../../utils';

const ConfirmationModal = ({ selectedApp, onClose, isOpened = false }) => {
  const dispatch = useDispatch();

  const { id: orgID } = useSelector(state => state.sinatra.user.currentOrg);
  const { error } = useSelector(state => state.appLauncher);
  const { selectedCallerID } = useSelector(state => state.emergencyCalls);
  const { irpVersion } = useIsIRP();

  const handleConfirm = () => {
    const { id, details } = selectedApp;
    const { data_fields, deeplink_endpoint } = details;
    trackDataShareConfirmClick(selectedApp, irpVersion);
    const requestData = {
      appEndpoint: deeplink_endpoint,
      appID: id,
      appName: selectedApp.display_name,
      orgID,
    };
    if (data_fields?.length)
      requestData.appData = handleAppData(data_fields, selectedCallerID);

    let start = Date.now();
    return dispatch(createDeeplinkURL(requestData))
      .then(unwrapResult)
      .then(async () => {
        await ensureMinDisplayTime(start);
        notifySuccess('Data has been shared');
      })
      .catch(async e => {
        await ensureMinDisplayTime(start);
        const errorMessage = error?.data?.detail || e?.message;
        notifyError(errorMessage);
      })
      .finally(() => {
        onClose();
      });
  };
  const handleClose = () => {
    trackDataShareCancelClick(selectedApp, irpVersion);
    onClose();
  };
  return (
    <Modal
      id="data-share-confirmation-modal"
      isOpened={isOpened}
      onClose={handleClose}
      portalPriority={PORTAL_STANDARD_MODAL_PRIORITY}
      width={340}
    >
      <ModalHeader onClose={onClose}>
        <Text size="xlarge" color="primaryText">
          Push this event’s data?
        </Text>
      </ModalHeader>
      <ModalBody>
        <Text size="small" color="primaryText">
          Are you sure you want to push this event’s data to{' '}
          {selectedApp?.display_name}
        </Text>
      </ModalBody>
      <ModalFooter justify="space-between" flow={12}>
        <Box grow>
          <Button btnType="secondary" onClick={onClose} noMargin>
            No, Cancel
          </Button>
        </Box>
        <Box grow>
          <Button btnType="primary" onClick={handleConfirm} noMargin>
            Yes, Push Data
          </Button>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default ConfirmationModal;
