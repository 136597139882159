export const RECEIVED = 'RECEIVED';
export const IGNORED = 'IGNORED';
export const DISPATCH_REQUESTED = 'DISPATCH_REQUESTED';
export const ACCEPTED = 'ACCEPTED';
export const DECLINED = 'DECLINED';
export const TIMEOUT = 'TIMEOUT';
export const CANCELED = 'CANCELED';
export const NEW = 'NEW';
// those 2 statuses will happen only in CSP
export const ECC_UNAVAILABLE = 'ECC_UNAVAILABLE';
export const RESPONDERS_NOTIFIED = 'RESPONDERS_NOTIFIED';

export const NEW_CALL_REQUIRED = 'NEW - CALL REQUIRED';
export const DATA_SENT = 'DATA SENT';
export const REQUESTING = 'REQUESTING…';

export const statusDisplayNameForTrackings = {
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  IGNORED: 'Ignored',
  NEW: 'New',
  DISPATCH_REQUESTED: 'Dispatch Requested',
  TIMEOUT: 'Timeout',
  CANCELED: 'Canceled',
  ECC_UNAVAILABLE: 'Ecc Unavailable',
  RESPONDERS_NOTIFIED: 'Responders Notified',
};
