import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledAnchor } from './Landing.styles';

const StyledSmartLink = ({ to, name, internal, children, ...rest }) => {
  return internal ? (
    <NavLink to={to} data-name={name} {...rest}>
      {children}
    </NavLink>
  ) : (
    <StyledAnchor href={to} data-name={name} {...rest} target="_blank">
      <>{children}</>
    </StyledAnchor>
  );
};

export default StyledSmartLink;
