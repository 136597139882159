import React from 'react';
import SVGLoader from './SVGLoader';

const BellNotificationIcon = ({ ...rest }) => {
  return (
    <SVGLoader {...rest} viewboxWidth={12} viewboxHeight={12}>
      <path
        d="M4.75 10.75h-1.5A.25.25 0 0 0 3 11a1 1 0 1 0 2 0 .25.25 0 0 0-.25-.25m2.5-3.925V6.5q0-.483-.105-.955a3.5 3.5 0 0 1-1.335-2.15 3 3 0 0 0-1.06-.53V2.25a.75.75 0 0 0-1.5 0v.615C1.79 3.26.75 4.755.75 6.5v.325A6.9 6.9 0 0 1 .025 9.89a.255.255 0 0 0 .115.335.3.3 0 0 0 .11.025h7.5a.25.25 0 0 0 .225-.36 6.9 6.9 0 0 1-.725-3.065M9.25 0a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5m-.375 1.25a.375.375 0 1 1 .75 0v1.5a.375.375 0 0 1-.75 0zm.375 3.5a.565.565 0 1 1-.106-1.125.565.565 0 0 1 .106 1.125"
        fill="currentColor"
      />
    </SVGLoader>
  );
};

export default BellNotificationIcon;
