/**
 * dispatches SAML request logic and takes care of the toast error messages
 * @param {string} service - SAML service, For exp, 'support'
 * @param {boolean} isJVenabled - if JV capability is enabled
 * @param {string} irpAccessToken - iRP token to indicate that the app is in iRP mode
 * @param {boolean} isLoggedIn - indicates user's authentication status
 * @returns string path
 */
import React from 'react';
import { requestSAML } from '@rsos/sinatra';
import normalizeError from '@rsos/sinatra/src/utils/normalizeError';
import { notifyError } from '@rsos/utils/toastUtils';
import onLogout from './onLogout';

const onSAMLrequest = (service, dispatch, isLoadingSAML, persistor) => {
  if (!isLoadingSAML[service]) {
    dispatch(requestSAML(service)).catch(action => {
      const { error } = action;
      if (!error.response) {
        const errMsg = (
          <div>
            An error occurred. Please try again and{' '}
            <a href="mailto:support@rapidsos.com">contact support</a> if the
            error repeats.
          </div>
        );
        notifyError(errMsg);
      } else {
        const { status } = error.response;
        const { message } = normalizeError(error);
        if (status === 401) {
          onLogout(persistor, dispatch);
        } else {
          const errMsg = () => {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              ></div>
            );
          };
          notifyError({ message: errMsg() });
        }
      }
    });
  }
};

export default onSAMLrequest;
