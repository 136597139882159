import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 140,
  viewboxWidth: 140,
};

export default ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <g transform="matrix(10,0,0,10,0,0)">
      <path
        d="M13.842,11.829l-5.5-11a1.5,1.5,0,0,0-2.684,0l-5.5,11A1.5,1.5,0,0,0,1.5,14h11a1.5,1.5,0,0,0,1.342-2.171ZM6.25,5a.75.75,0,0,1,1.5,0V8a.75.75,0,0,1-1.5,0ZM7,12a1,1,0,1,1,1-1A1,1,0,0,1,7,12Z"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0"
      ></path>
    </g>
  </SVGLoader>
);
