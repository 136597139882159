import midasInstance from './midasInstance';

/**
 * Utilize this function to POST call flows from RSP
 * @param {string} callflow - name of the REC flow
 * @param {object} variables - object of what goes into the callflow
 * @returns response of api call
 */

const postMidasFlow = ({ callflow, variables }) => {
  return midasInstance.post(
    '/internal_auth/trigger',
    {
      callflow,
      blocking: true,
      variables,
    },
    {
      headers: { retry: true },
    },
  );
};

export default postMidasFlow;
