// This import is processed by yaml-jest.
// The result of the import is a JavaScript object.
import yaml from './readonly/capstone_permissions.yaml';

const { permissions = {} } = yaml;

// Normalize the permissions to match what is returned from the API
const normalized = Object.keys(permissions).map(p => {
  return {
    ...permissions[p],
    name: p,
  };
});

export default normalized;
