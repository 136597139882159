import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  width: 17,
  height: 16,
  viewboxWidth: 17,
  viewboxHeight: 16,
};

const PlusIcon = ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      width={width}
      height={height}
      viewboxWidth={viewboxWidth}
      viewboxHeight={viewboxHeight}
      {...rest}
    >
      <path
        d="M13.99 6.158h-2.948a.368.368 0 0 1-.368-.369V2.842a1.842 1.842 0 0 0-3.684 0V5.79a.368.368 0 0 1-.369.369H3.674a1.842 1.842 0 0 0 0 3.684H6.62a.368.368 0 0 1 .369.368v2.948a1.842 1.842 0 1 0 3.684 0V10.21a.368.368 0 0 1 .368-.369h2.948a1.842 1.842 0 0 0 0-3.684Z"
        fill="currentColor"
      />
    </SVGLoader>
  );
};

export default PlusIcon;
