import {
  FETCH_APP_ROLES_START,
  FETCH_APP_ROLES_SUCCESS,
  FETCH_APP_ROLES_ERROR,
  CREATE_APP_ROLE_START,
  CREATE_APP_ROLE_SUCCESS,
  CREATE_APP_ROLE_ERROR,
  UPDATE_APP_ROLE_START,
  UPDATE_APP_ROLE_SUCCESS,
  UPDATE_APP_ROLE_ERROR,
  DELETE_APP_ROLE_START,
  DELETE_APP_ROLE_SUCCESS,
  DELETE_APP_ROLE_ERROR,
  ASSIGN_PERMISSIONS_TO_ROLES_START,
  ASSIGN_PERMISSIONS_TO_ROLES_SUCCESS,
  ASSIGN_PERMISSIONS_TO_ROLES_ERROR,
} from '../../actions/types';

export const initialState = {
  records: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: {
    loading: null,
    creating: null,
    updating: null,
    deleting: null,
  },
};

const addRole = (records, role) => [...records, role];

const updateRole = (records, role) => {
  return records.map(record => {
    if (record.id === role.id) {
      return {
        ...record,
        ...role,
      };
    }
    return record;
  });
};

const removeRole = (records, role) =>
  records.filter(record => record.id !== role.id);

const appRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_ROLES_START:
      return {
        ...state,
        loading: true,
        errors: {
          ...state.errors,
          loading: null,
        },
      };
    case FETCH_APP_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.roles,
      };
    case FETCH_APP_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          loading: action.error.message,
        },
      };
    case CREATE_APP_ROLE_START:
      return {
        ...state,
        creating: true,
        errors: {
          ...state.errors,
          creating: null,
        },
      };
    case CREATE_APP_ROLE_SUCCESS:
      return {
        ...state,
        creating: false,
        records: addRole(state.records, action.role),
      };
    case CREATE_APP_ROLE_ERROR:
      return {
        ...state,
        creating: false,
        errors: {
          ...state.errors,
          creating: action.error,
        },
      };
    case UPDATE_APP_ROLE_START:
      return {
        ...state,
        updating: true,
        errors: {
          ...state.errors,
          updating: null,
        },
      };
    case UPDATE_APP_ROLE_SUCCESS:
      return {
        ...state,
        updating: false,
        records: updateRole(state.records, action.role),
      };
    case UPDATE_APP_ROLE_ERROR:
      return {
        ...state,
        updating: false,
        errors: {
          ...state.errors,
          updating: action.error,
        },
      };
    case DELETE_APP_ROLE_START:
      return {
        ...state,
        deleting: true,
        errors: {
          ...state.errors,
          deleting: null,
        },
      };
    case DELETE_APP_ROLE_SUCCESS:
      return {
        ...state,
        deleting: false,
        records: removeRole(state.records, action.role),
      };
    case DELETE_APP_ROLE_ERROR:
      return {
        ...state,
        deleting: false,
        errors: {
          ...state.errors,
          deleting: action.error,
        },
      };
    case ASSIGN_PERMISSIONS_TO_ROLES_START:
      return {
        ...state,
        assigning: true,
        errors: {
          ...state.errors,
          assigning: null,
        },
      };
    case ASSIGN_PERMISSIONS_TO_ROLES_SUCCESS:
      return {
        ...state,
        assigning: false,
        records: action.role,
      };
    case ASSIGN_PERMISSIONS_TO_ROLES_ERROR:
      return {
        ...state,
        assigning: false,
        errors: {
          ...state.errors,
          assigning: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default appRolesReducer;
