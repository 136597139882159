import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NS_SSO from '@rsos/constants/ns_sso';
import { loginWithProvider } from '@rsos/sinatra';
import { fetchUser } from '@rsos/sinatra';
import { configureUserScope, captureException } from '@rsos/utils/sentry';
import { notifyError } from '@rsos/utils/toastUtils';
import {
  trackNSLoginForCSP,
  trackSessionStart,
} from '../../actions/authTrackings';
import rapidsosLogo from '../../assets/rapidsos_logo_light.png';
import { AppRedirecting } from '../Landing/Landing.styles';
import {
  LoginButton,
  LoginWrapper,
  LoginImage,
  StyledPage,
} from './NSLogin.styles';
import createLoginURI from './createLoginURI';
import parseQueryString from './parseQueryString';

export const LOGIN_URI = createLoginURI();
export const LOGIN_BUTTON_TEXT = 'Sign in with SSO';
export const LOGIN_ERROR_MESSAGE = `Login failed: We could not authenticate you using SSO. If this error persists, please reach out to your system admin.`;

class NSLoginUserError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NSLoginUserError';
  }
}

class NSLoginAPIError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NSLoginAPIError';
  }
}

const reportAPIError = (errorInstance, errorExtras = {}) => {
  captureException(errorInstance, errorExtras);
};

const NSLogin = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.sinatra?.user?.isLoggedIn);

  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isOIDCSuccess, setIsOIDCSuccess] = useState(false);
  const [isOIDCFailure, setIsOIDCFailure] = useState(false);
  const [parsedArgs, setParsedArgs] = useState(null);

  const doRedirect = useCallback(async () => {
    let user;
    try {
      user = await dispatch(fetchUser());
    } catch (error) {
      reportAPIError(error);
      setIsOIDCFailure(true);
    }

    // While here, identify the user to Sentry.
    try {
      configureUserScope({
        email: user?.email,
        version: process.env.REACT_APP_FE_VERSION || '',
      });
    } catch (error) {
      captureException(error);
    }

    // Track the user's session start.
    try {
      trackSessionStart();
    } catch (error) {
      captureException(error);
    }

    if (user?.currentRole?.application === 'central_station') {
      try {
        trackNSLoginForCSP();
      } catch (error) {
        captureException(error);
      }
      window.location.assign('/central-station');
      return;
    } else if (user?.currentRole?.application) {
      const error = new NSLoginUserError('User is not a central_station user');
      reportAPIError(error);
      setIsOIDCFailure(true);
    } else if (user && !user?.currentRole?.application) {
      const error = new NSLoginUserError(
        'User missing currentRole.application',
      );
      reportAPIError(error);
      setIsOIDCFailure(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn && isRedirecting) {
      doRedirect();
      return;
    }
  }, [isLoggedIn, isRedirecting, doRedirect]);

  useEffect(() => {
    // NOTE: Only users that know of the /ns will navigate to the page.
    // This allows us to tag the user as an NS user (ns_sso in localStorage).
    // The tag is used to redirect the user back to the /ns page when their
    // central_station session expires or they logout.
    // Although NS users will be trained to login via the /ns page. If the user
    // manually changes the URL to visit the homepage (gets out of the ns flow),
    // the NS tag is removed from localStorage from the Landing component.
    // This is prevents the user from being redirected to the /ns page if they
    // ever login with RSOS credentials (QA will do this).
    // Search for NS_SSO to see exactly where it's used.
    localStorage.setItem(NS_SSO, true);

    // Check if the user is logged in. If so, redirect to central_station.
    if (isLoggedIn) {
      setIsRedirecting(true);
      return;
    }

    const parsedArgs = parseQueryString(window.location.search);
    const { code, state, error } = parsedArgs;
    setParsedArgs({ code, state, error });
    if (error) {
      setIsOIDCFailure(true);
    } else if (code && state) {
      setIsOIDCSuccess(true);
    } else if ((code && !state) || (!code && state)) {
      setIsOIDCFailure(true);
      reportAPIError(new NSLoginAPIError('Missing OIDC params'), {
        code,
        state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOIDCFailure) {
      notifyError(LOGIN_ERROR_MESSAGE, { autoClose: false });
    }
  }, [isOIDCFailure]);

  // When isOIDCSuccess is true, we need complete token exchange with Scorpius,
  // then fetch the user with the new access token, and finally redirect to the
  // central_station app after confirming the user has a central_station role.
  useEffect(() => {
    if (isOIDCSuccess) {
      const code = parsedArgs.code;
      const state = parsedArgs.state;

      (async () => {
        let tokens;
        try {
          const resp = await dispatch(loginWithProvider('ns', { code, state }));
          tokens = resp?.tokens;
        } catch {
          const error = new NSLoginAPIError('Token exchange failed');
          reportAPIError(error, { code, state });
          setIsOIDCFailure(true);
          setIsOIDCSuccess(false);
        }

        if (tokens) {
          doRedirect();
        }
      })();
    }
  }, [dispatch, isOIDCSuccess, parsedArgs, doRedirect]);

  return (
    <StyledPage>
      {isRedirecting ? (
        <AppRedirecting />
      ) : (
        <LoginWrapper>
          <LoginImage src={rapidsosLogo} />
          <LoginButton
            isLoadingProp={isOIDCSuccess}
            onClick={() => window.location.assign(LOGIN_URI)}
            dataName="ns-sso-button"
          >
            {LOGIN_BUTTON_TEXT}
          </LoginButton>
        </LoginWrapper>
      )}
    </StyledPage>
  );
};

export default NSLogin;
