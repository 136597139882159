import React from 'react';
import { CogIcon, AudioMuteIcon } from '@rsos/assets/icons';
import {
  Box,
  Button,
  Text,
  StyledHR,
  Dropdown,
} from '@rsos/components/capstone/base';
import {
  DropdownDialog,
  DropdownBody,
  DropdownList,
  DropdownHeader,
  DropdownListItem,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';
import SoundPlayer from '../SoundPlayer';
import { DropdownTitle } from './SettingsResponsive.styles';
import ThemeSettings from './ThemeSettings';

const SettingsResponsive = ({ userAudioSettings, orgAudioSettings }) => {
  const isSoundOn = Object.values(orgAudioSettings).some(o => o?.sound_on);
  const isVolumeOn = Object.keys(userAudioSettings).some(
    o => userAudioSettings[o].volume > 0 && orgAudioSettings[o]?.sound_on,
  );

  return (
    <Box data-name="navigation-setting-mobile" position="relative">
      <Dropdown
        triggerElement={
          !isSoundOn || (isSoundOn && isVolumeOn)
            ? Settings
            : SettingsVolumeMuted
        }
      >
        <DropdownDialog top={43} right={2} width="278px">
          <DropdownHeader>
            <Text size="normal" fontWeight="bold" color="secondaryText">
              Controls
            </Text>
          </DropdownHeader>
          <DropdownBody>
            <DropdownList>
              <DropdownListItem>
                <ThemeSettings />
              </DropdownListItem>
            </DropdownList>
            <StyledHR
              borderTop="1px solid"
              borderBottom="0px"
              borderColor="heavyLineOutline"
              width="100%"
              m={0}
            />
            <Box data-name="navigation-audio-control">
              {isSoundOn ? (
                <>
                  <DropdownTitle>Sound Control</DropdownTitle>
                  <SoundPlayer orgAudioSettings={orgAudioSettings} />
                </>
              ) : (
                <DropdownTitle>Sound Control has been disabled</DropdownTitle>
              )}
            </Box>
          </DropdownBody>
        </DropdownDialog>
      </Dropdown>
    </Box>
  );
};

export default SettingsResponsive;

export const Settings = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      data-name="navigation-control-button-menu"
      onClick={onClick}
      selected={selected}
    >
      <CogIcon width={14} height={16} color="secondaryLink" />
    </Button>
  );
};
export const SettingsVolumeMuted = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      data-name="navigation-control-button-menu"
      onClick={onClick}
      selected={selected}
    >
      <Box flow={3} horizontal align="center" color="secondaryLink">
        <CogIcon width={14} height={16} />
        <AudioMuteIcon width={13} height={15} />
      </Box>
    </Button>
  );
};
