import { useEffect, useState, useRef } from 'react';

function CountdownTimer({
  timer = 600,
  closeModal = () => {},
  isOpened = false,
}) {
  const [seconds, setSeconds] = useState(timer);
  const increment = useRef(null);

  useEffect(() => {
    if (seconds > 0 && isOpened) {
      increment.current = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else {
      if (closeModal) {
        closeModal();
        setSeconds(timer);
      }
    }
    return () => {
      clearInterval(increment.current);
    };
    //eslint-disable-next-line
  }, []);

  const formatTimer = seconds => {
    let displayMinutes =
      Math.floor(seconds / 60) > 9
        ? Math.floor(seconds / 60)
        : `0${Math.floor(seconds / 60)}`;
    let displaySeconds =
      Math.floor(seconds % 60) > 9
        ? Math.floor(seconds % 60)
        : `0${Math.floor(seconds % 60)}`;

    return `${displayMinutes}:${displaySeconds}`;
  };

  let timeLeft = formatTimer(seconds);

  return timeLeft;
}

export default CountdownTimer;
