import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@rsos/components/capstone/base/Box';
import { ItemIconWrapper, ItemTitle } from '../common/SideBarItem.styles';
import SmartLink from '../common/SmartLink';

const SideBarItem = ({ activeItem, menuItem, sideBarOpen }) => {
  const isActive = useMemo(() => !!(menuItem.name === activeItem), [
    menuItem,
    activeItem,
  ]);
  return (
    <SmartLink
      to={menuItem.link}
      toolTip={menuItem.hoverText}
      className={isActive ? ' active' : ''}
    >
      <Box horizontal flow={10}>
        <ItemIconWrapper isActive={isActive}>
          {menuItem.menuIcon}
        </ItemIconWrapper>
        <ItemTitle isActive={isActive}>
          {sideBarOpen ? menuItem.menuTitle : ''}
        </ItemTitle>
      </Box>
    </SmartLink>
  );
};
SideBarItem.propTypes = {
  activeItem: PropTypes.string.isRequired,
  menuItem: PropTypes.object.isRequired,
  sideBarOpen: PropTypes.bool.isRequired,
};
export default SideBarItem;
