import React from 'react';
import { AgencyStates } from '@rsos/capstone/src/constants/Systems';
import { Input } from '../';
import './PSAPInfoForm.scss';

const PSAPInfoForm = props => {
  const {
    displayName,
    displayNameError,
    contactEmail,
    contactEmailError,
    contactName,
    contactNameError,
    contactPhone,
    contactPhoneError,
    contactTitle,
    contactTitleError,
    fccPSAPID,
    fccPSAPIDError,
    handleOnChangePhone,
    handleSelectedState,
    nonEmergencyNum,
    nonEmergencyNumError,
    onChange,
    population,
    populationError,
    selectedAgencyState,
    showAgencyStateDropdown,
    toggleDropdown,
    currentApp,
  } = props;
  let agencyStateName;

  const agencyState = AgencyStates.find(
    ({ value }) => value === selectedAgencyState.toUpperCase(),
  );
  if (agencyState) {
    agencyStateName = agencyState.label;
  }
  return (
    <div className="psap-info-wrapper">
      <div className="columns">
        {currentApp === 'central_station' ? null : (
          <div className="column is-4">
            <Input
              className="rl-input is-fullwidth"
              error={fccPSAPIDError}
              labelName="FCC PSAP ID"
              labelPosition="top"
              maxLength={50}
              name="fccPSAPID"
              onChange={onChange}
              placeholder="0000"
              type="text"
              value={fccPSAPID}
            />
          </div>
        )}

        <div className="column is-4">
          <Input
            className="rl-input is-fullwidth"
            error={displayNameError}
            labelName={
              currentApp === 'central_station'
                ? 'Organization Name *'
                : 'Agency Name *'
            }
            labelPosition="top"
            maxLength={65}
            minLength={5}
            name="displayName"
            onChange={onChange}
            placeholder={
              currentApp === 'central_station'
                ? 'Organization Name'
                : 'Agency Name'
            }
            type="text"
            value={displayName}
          />
        </div>

        {currentApp === 'central_station' ? null : (
          <div className="column is-4">
            <div className="psap-info-form-dropdown">
              <div className="input-label">{'State *'}</div>
              <div
                onClick={() => toggleDropdown('AgencyState')}
                id="agencyStateDropdown"
                className={
                  'dropdown is-fullwidth' +
                  (showAgencyStateDropdown ? ' is-active' : '')
                }
              >
                <div className="dropdown-trigger is-fullwidth">
                  <div
                    className="button dropdown-button has-text-left"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                  >
                    <span>{agencyStateName ? agencyStateName : 'Select'}</span>
                    <span className="icon is-small">
                      <i
                        className={
                          showAgencyStateDropdown ? 'arrow-up' : 'arrow-down'
                        }
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="dropdown-menu"
                  id="agency-state-dropdown-menu"
                  role="menu"
                >
                  <div className="dropdown-content agency-states">
                    {AgencyStates.map(state => {
                      return (
                        <span
                          onClick={() => handleSelectedState(state.value)}
                          className={'dropdown-item ' + state.value}
                          key={state.value}
                          id={state.label}
                        >
                          {state.label}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="columns">
        <div className="column is-6">
          <Input
            className="rl-input is-fullwidth"
            error={contactNameError}
            labelName={
              currentApp === 'central_station'
                ? 'Point of Contact *'
                : 'Agency point of contact *'
            }
            labelPosition="top"
            maxLength={30}
            minLength={2}
            name="contactName"
            onChange={onChange}
            placeholder="First and Last Name"
            type="text"
            value={contactName || ''}
          />
        </div>
        <div className="column is-6">
          <Input
            className="rl-input is-fullwidth"
            error={contactTitleError}
            labelName={
              currentApp === 'central_station'
                ? 'Point of Contact Title *'
                : 'Agency point of contact title *'
            }
            labelPosition="top"
            minLength={3}
            name="contactTitle"
            onChange={onChange}
            placeholder="Title"
            type="text"
            value={contactTitle || ''}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <Input
            className="rl-input is-fullwidth"
            error={contactPhoneError}
            onChange={handleOnChangePhone}
            labelName={
              currentApp === 'central_station'
                ? 'Point of contact phone no.*'
                : 'Agency point of contact phone no. *'
            }
            labelPosition="top"
            name="contactPhone"
            placeholder="000-000-0000"
            type="tel"
            value={contactPhone || ''}
          />
        </div>
        <div className="column is-6">
          <Input
            className="rl-input is-fullwidth"
            error={contactEmailError}
            labelName={
              currentApp === 'central_station'
                ? 'Point of contact email *'
                : 'Agency point of contact email *'
            }
            labelPosition="top"
            maxLength={50}
            name="contactEmail"
            onChange={onChange}
            placeholder="Example@gmail.com"
            type="email"
            value={contactEmail}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <Input
            className="rl-input is-fullwidth"
            error={nonEmergencyNumError}
            labelName="24x7 Non-Emergency Number *"
            labelPosition="top"
            name="nonEmergencyNum"
            onChange={handleOnChangePhone}
            placeholder="Example:0000000000"
            type="tel"
            value={nonEmergencyNum}
          />
          {!nonEmergencyNumError && currentApp === 'central_station' && (
            <span className="non-emergency-info-text">
              This number is generally given to alarm companies.
            </span>
          )}
        </div>
        {currentApp === 'central_station' ? null : (
          <div className="column is-6">
            <Input
              className="rl-input is-fullwidth"
              error={populationError}
              labelName="Estimated Population Served *"
              labelPosition="top"
              name="population"
              onChange={onChange}
              placeholder="Estimated Population Served"
              type="text"
              value={population}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PSAPInfoForm;
