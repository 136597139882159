import React from 'react';
import { css } from 'styled-components';
import NavigationControlIcon from '@rsos/assets/icons/src/NavigationControlIcon';
import Button from '@rsos/components/capstone/base/Button';

export const sharedStyleMenuItemRow = css`
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${({ type }) => (type === 'standalone' ? '8px 12px' : 0)};
  font-weight: ${({ type }) => (type === 'standalone' ? 700 : 'inherit')};
  color: ${({ theme }) => theme.colors.primaryText};
  text-decoration: none;

  &:hover {
    background: ${({ theme, type }) =>
      type !== 'standalone' && theme.colors.foreground};
  }
`;

export const HamburgerMenu = ({ selected, onClick }) => {
  return (
    <Button
      btnType="tertiary"
      size="small"
      data-name="navigation-control-button-menu"
      onClick={onClick}
      selected={selected}
    >
      <NavigationControlIcon width={20} color="primaryText" />
    </Button>
  );
};
