import React from 'react';
import SVGLoader from './SVGLoader';

const CogIcon = props => {
  return (
    <SVGLoader viewboxWidth={16} viewboxHeight={17} {...props}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-497.000000, -687.000000)">
          <g transform="translate(497.000000, 687.000000)">
            <path
              d="M14.7039104,9.5948 L14.7039104,7.4052 L15.7061851,6.1795 C16.0515163,5.75976731 16.0965395,5.17609824 15.8194857,4.7107 L15.2756428,3.7893 C14.9999385,3.32334512 14.4597036,3.06968657 13.9142923,3.1501 L12.3245974,3.3847 L10.379313,2.2899 L9.79363594,0.8313 C9.59231879,0.330388304 9.09723869,0.000625772453 8.54558605,0 L7.45441395,0 C6.90276131,0.000625772453 6.40768121,0.330388304 6.20636406,0.8313 L5.62068702,2.2899 L3.67540256,3.3847 L2.08570773,3.1501 C1.54029643,3.06968657 1.00006147,3.32334512 0.724357224,3.7893 L0.180514256,4.7107 C-0.0965395433,5.17609824 -0.0515163496,5.75976731 0.293814875,6.1795 L1.29608957,7.4052 L1.29608957,9.5948 L0.293814875,10.8205 C-0.0515163496,11.2402327 -0.0965395433,11.8239018 0.180514256,12.2893 L0.724357224,13.2107 C1.00006147,13.6766549 1.54029643,13.9303134 2.08570773,13.8499 L3.67540256,13.6153 L5.62068702,14.7101 L6.20636406,16.1687 C6.40768121,16.6696117 6.90276131,16.9993742 7.45441395,17 L8.54558605,17 C9.09723869,16.9993742 9.59231879,16.6696117 9.79363594,16.1687 L10.379313,14.7101 L12.3245974,13.6153 L13.9142923,13.8499 C14.4597036,13.9303134 14.9999385,13.6766549 15.2756428,13.2107 L15.8194857,12.2893 C16.0965395,11.8239018 16.0515163,11.2402327 15.7061851,10.8205 L14.7039104,9.5948 Z M8,11.05 C6.55597992,11.05 5.38537035,9.90832611 5.38537035,8.5 C5.38537035,7.09167389 6.55597992,5.95 8,5.95 C9.44402008,5.95 10.6146297,7.09167389 10.6146297,8.5 C10.6146297,9.17630205 10.3391605,9.82490453 9.84882236,10.3031223 C9.35848418,10.7813401 8.6934429,11.05 8,11.05 Z"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </SVGLoader>
  );
};

export default CogIcon;
