// General utilities.
export { default as getUrlParameter } from '@rsos/base-utils/getUrlParameter';

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 541) {
    // Force reload on deprecated application status code response
    window.location.reload(true);
  }
  return Promise.reject(response);
}

export async function parseJSON(response) {
  try {
    return await response.json();
  } catch {
    return null;
  }
}

// TODO: put the functions below into separate file
export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export const checkEmailExists = (arr, value) => {
  return arr.some(ele => ele.email === value);
};

export function sanitizeAgencyName(name) {
  // Trim leading/trailing spaces
  // Replace all non alphanumeric characters with dashes (including spaces)
  return name.replace(/^\s+|\s+$/g, '').replace(/[^\w-]/gim, '-');
}

export const convertMilesToMeters = miles => {
  const mileToMeters = 1609.34;
  return miles * mileToMeters;
};

export const convertMetersToMiles = meters => {
  const meterToMiles = 0.000621371;
  return meters * meterToMiles;
};

export const calcDistance = (newLocation, previousLocation) => {
  if (!Object.keys(newLocation).length > 0) {
    return `Unable to determine distance`;
  }

  // This script [in Javascript] calculates great-circle distances between the
  // two points – that is, the shortest distance over the earth's surface –
  // using the 'Haversine' formula.
  // source https://www.movable-type.co.uk/scripts/latlong.html
  const deg2rad = deg => {
    return deg * (Math.PI / 180);
  };

  let R = 6371000; // Radius of the earth in meters
  let dLat = deg2rad(previousLocation.latitude - newLocation.latitude); // deg2rad above
  let dLon = deg2rad(previousLocation.longitude - newLocation.longitude); // deg2rad above
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(newLocation.latitude)) *
      Math.cos(deg2rad(previousLocation.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in m

  return d.toFixed(2); //return distance in m
};

export const formatPhoneWithDashes = number => {
  if (number) {
    let number_val = number.replace(/\D[^]/g, '');
    let formattedNumber;
    // for US with leading 1
    if (number.length === 11) {
      formattedNumber =
        number_val.slice(0, 1) +
        '-' +
        number_val.slice(1, 4) +
        '-' +
        number_val.slice(4, 7) +
        '-' +
        number_val.slice(7);
    } else {
      formattedNumber =
        number_val.slice(0, 3) +
        '-' +
        number_val.slice(3, 6) +
        '-' +
        number_val.slice(6);
    }
    return formattedNumber;
  }
  return number;
};

// TODO check if it is safe to combine formatPhoneWithDashes
export const formattedDisplayNumber = (number, formatType) => {
  if (number) {
    let number_val = number.replace(/\D[^]/g, '');

    let formattedNumber = '';
    if (number_val.length === 11) {
      formattedNumber =
        number_val.slice(0, 1) +
        formatType +
        number_val.slice(1, 4) +
        formatType +
        number_val.slice(4, 7) +
        formatType +
        number_val.slice(7);
    } else if (number_val.length === 10) {
      formattedNumber =
        number_val.slice(0, 3) +
        formatType +
        number_val.slice(3, 6) +
        formatType +
        number_val.slice(6);
    }

    return formattedNumber;
  }
  return number;
};

export const convertLatLngToPoint = loc => {
  return [parseFloat(loc.longitude), parseFloat(loc.latitude)];
};

/**
 * Returns true if the NODE_ENV is set to 'development'. Otherwise, false.
 */
export const isDevelopmentEnv = () => process.env.NODE_ENV === 'development';
