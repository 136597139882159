import { gspx } from '@rsos/capstone';

export const trackClickMuteAudioControl = (irpVersion, type) => {
  gspx.trackCustomEvent('Navigation', {
    irpVersion,
    name: `Mute Sound Control for ${type} was clicked`,
    Category: 'Navigation',
    'Launched date': new Date(),
  });
};

export const trackChangeAudioVolume = (irpVersion, type) => {
  gspx.trackCustomEvent('Navigation', {
    irpVersion,
    name: `The volume for ${type} Audio Notifications was changed`,
    Category: 'Navigation',
    'Launched date': new Date(),
  });
};
