import { decryptIRPToken } from '@rsos/sinatra/src/actions/irpToken';
import { getUrlParameter } from '@rsos/utils';
import { forcedLogOut, irpLogin } from './../../actions';

export const infoLinks = {
  company: [
    {
      title: 'About RapidSOS',
      to: 'https://rapidsos.com/',
      dataName: 'landing-company-about',
      internal: false,
    },
    {
      title: 'Visit our blog',
      to: 'https://rapidsos.com/blog/',
      dataName: 'landing-company-blog',
      internal: false,
    },
    {
      title: 'News',
      to: 'https://rapidsos.com/share-your-save/',
      dataName: 'landing-company-news',
      internal: false,
    },
    {
      title: 'Terms of Use',
      to: 'https://rapidsos.com/legal/',
      dataName: 'landing-company-terms-of-use',
      internal: false,
    },
  ],
  resources: [
    {
      title: 'Why RapidSOS?',
      to: 'https://rapidsos.com/platform/',
      dataName: 'landing-resources-why-rapidsos',
      internal: false,
    },
    {
      title: 'Join our newsletter',
      to: 'https://rapidsos.com/newsletter/',
      dataName: 'landing-resources-newsletter',
      internal: false,
    },
    {
      title: 'View videos',
      to: 'https://rapidsos.com/videos/',
      dataName: 'landing-resources-videos',
      internal: false,
    },
  ],
  support: [
    {
      title: 'Contact us',
      to: 'https://rapidsos.com/contact/',
      dataName: 'landing-support-contact',
      internal: false,
    },
    {
      title: 'Create Account',
      to: '/sign-up/account',
      dataName: 'landing-support-create-account',
      internal: true,
    },
  ],
};

export const getCategoryName = name => {
  return name[0].toUpperCase() + name.slice(1);
};

export const calcLeft = width => {
  return `calc((100% - ${width}px) / 2)`;
};
/**
 * takes care of all iRP login related logic to reduce noise in Landing
 * @param {object} sinatra - whole sinatra object
 * @param {funciton} dispatch - redux dispatch
 * @returns string path or if there is any error in api calls, it log the user out
 */

export const handleIrpLoginAndRedirect = (dispatch, sinatra, navigate) => {
  const irpToken = getUrlParameter('irp_token');
  const irpAccessToken = sinatra?.irp?.irp_access_token;

  if (irpToken) {
    return dispatch(decryptIRPToken(irpToken))
      .then(res => {
        const tokens = {
          refresh_token: null,
          token: res.data.rsp_session_token,
        };
        dispatch(irpLogin(tokens, navigate)).catch(e => {
          dispatch(forcedLogOut(navigate));
          throw e;
        });
      })
      .catch(e => {
        dispatch(forcedLogOut(navigate));
        throw e;
      });
  }
  if (irpAccessToken) {
    const tokens = {
      refresh_token: null,
      token: irpAccessToken,
    };
    return dispatch(irpLogin(tokens, navigate)).catch(e => {
      dispatch(forcedLogOut(navigate));
      throw e;
    });
  }
};
