import React from 'react';
import styled from 'styled-components';
import { XCloseIcon } from '@rsos/base-assets/icons';

const ModalCloseContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  cursor: pointer;
`;

const ModalClose = ({ onClose }) => (
  <ModalCloseContainer onClick={onClose}>
    <XCloseIcon color="secondaryLink" />
  </ModalCloseContainer>
);

export default ModalClose;
