import React from 'react';
import PropTypes from 'prop-types';
import { StyledLinkComponent } from './LinkComponent.styles.js';

const LinkComponent = ({ to, target = '_blank', children, ...rest }) => {
  return (
    <StyledLinkComponent
      href={to}
      target={target}
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </StyledLinkComponent>
  );
};

LinkComponent.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  withBg: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
};
export default LinkComponent;
