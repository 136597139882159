import normalizeError from '../../utils/normalizeError';
import instance from '../scorpiusInstance';
import {
  DELETE_APP_USER_START,
  DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_ERROR,
} from '../types';

export const deleteAppUserStart = (orgId, appName) => ({
  type: DELETE_APP_USER_START,
  orgId,
  appName,
});

export const deleteAppUserSuccess = (orgId, appName, user) => ({
  type: DELETE_APP_USER_SUCCESS,
  orgId,
  appName,
  user,
});

export const deleteAppUserError = (orgId, appName, user, error) => ({
  type: DELETE_APP_USER_ERROR,
  orgId,
  appName,
  user,
  error,
});

/**
 * Deletes the user from the organization's app.
 * @param {String} orgId
 * @param {String} appName
 * @param {Object} user
 * @param {String} user.email
 * @param {String} user.role
 */
export const deleteAppUser = (orgId, appName, user) => async dispatch => {
  dispatch(deleteAppUserStart(orgId, appName));
  const encodedEmail = encodeURIComponent(user.email);
  try {
    await instance.delete(
      `/organizations/${orgId}/${appName}/users/${encodedEmail}`,
      { headers: { retry: true } },
    );
    return dispatch(deleteAppUserSuccess(orgId, appName, user));
  } catch (error) {
    throw dispatch(
      deleteAppUserError(orgId, appName, user, normalizeError(error)),
    );
  }
};

export default deleteAppUser;
