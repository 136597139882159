import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextArea } from './TextArea.styles';

const TextArea = ({
  height,
  id,
  inputRef,
  maxLength,
  maxWidth,
  name,
  onChange,
  onKeyDown,
  padding,
  placeholder,
  value,
  onBlur,
  onFocus,
  rows,
  autoFocus = true,
  resize = 'none',
  isDisabled,
}) => (
  <StyledTextArea
    autoFocus={autoFocus}
    id={id ? id : name}
    height={height}
    maxLength={maxLength}
    maxWidth={maxWidth}
    name={name}
    onChange={onChange}
    onKeyDown={onKeyDown}
    padding={padding}
    ref={inputRef}
    placeholder={placeholder}
    value={value}
    onBlur={onBlur}
    onFocus={onFocus}
    resize={resize}
    rows={rows}
    disabled={isDisabled}
  />
);

PropTypes.TextArea = {
  height: PropTypes.string,
  maxLength: PropTypes.number,
  maxWidth: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  padding: PropTypes.string,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  rows: PropTypes.number,
  resize: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default TextArea;
