import instance from './scorpiusInstance';
import {
  CONFIRM_USER_START,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_ERROR,
} from './types';

export const confirmUserStart = () => ({
  type: CONFIRM_USER_START,
});

export const confirmUserSuccess = () => ({
  type: CONFIRM_USER_SUCCESS,
});

export const confirmUserError = error => ({
  type: CONFIRM_USER_ERROR,
  error,
});

/**
 * Confirm the user's account.
 * @param {String} token
 */
export const confirmUser = token => async (dispatch) => {
  dispatch(confirmUserStart());
  try {
    await instance.post('/user/confirm', {
      token,
    });
    // NOTE Successful response is a 204, nothing to pass along.
    return dispatch(confirmUserSuccess());
  } catch (error) {
    throw dispatch(confirmUserError(error));
  }
};
