import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  alignSelf,
  border,
  color,
  flex,
  flexWrap,
  grid,
  layout,
  space,
} from 'styled-system';

const px = n => `${n}px`;

const direction = props => (props.horizontal ? 'row' : 'column');
const flexGrow = props => (props.grow ? 1 : 'initial');
const align = props => props.align || 'stretch';
const justify = props => props.justify || 'flex-start';
const position = props => props.position || 'static';
const displayFlex = props => (props.inline ? 'inline-flex' : 'flex');
const flexShrink = props => ('noShrink' in props ? 0 : 1);
const cursor = props => props.cursor || 'inherit';
const overflow = props => ('overflow' in props ? props.overflow : 'unset');
const transition = props => props.transition || 'all 0s ease 0s';
const ellipsis = props => {
  if (props.ellipsis !== true) return '';
  return `
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  `;
};

const flow = props => {
  if (!('flow' in props)) return;

  if (props.horizontal) {
    return `
      > * + * {
        margin-left: ${px(props.flow || 0)} !important;
      }
    `;
  }
  return `
    > * + * {
      margin-top: ${px(props.flow || 0)} !important;
    }
  `;
};
const Box = styled.div`
  display: ${displayFlex};
  flex-direction: ${direction};
  flex-grow: ${flexGrow};
  flex-shrink: ${flexShrink};
  align-items: ${align};
  justify-content: ${justify};
  overflow: ${overflow};
  cursor: ${cursor};
  position: ${position};
  transition: ${transition};

  ${alignSelf};
  ${border};
  ${color};
  ${ellipsis};
  ${flex};
  ${flexWrap};
  ${flow};
  ${grid};
  ${layout};
  ${space};
`;

Box.propTypes = {
  flow: PropTypes.number,
  onClick: PropTypes.func,
  horizontal: PropTypes.bool,
  position: PropTypes.oneOf(['absolute', 'relative']),
  align: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  justify: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-evenly',
    'unset',
  ]),
  inline: PropTypes.bool,
  ellipsis: PropTypes.bool,
  overflow: PropTypes.string,
  cursor: PropTypes.string,
  grow: PropTypes.bool,
  transition: PropTypes.string,
};

export default Box;
