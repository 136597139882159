import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxWidth: 9,
  viewboxHeight: 14,
};
const HeadphonesOff = ({
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => {
  return (
    <SVGLoader
      viewboxHeight={viewboxHeight}
      viewboxWidth={viewboxWidth}
      {...rest}
    >
      <g>
        <path
          d="M15.9999 9.50008C16.0009 8.87886 15.8091 8.27264 15.4508 7.76513C15.0926 7.25761 14.5856 6.87382 13.9999 6.66675V6.16675C13.9999 4.57545 13.3678 3.04933 12.2426 1.92411C11.1173 0.798889 9.59122 0.166748 7.99992 0.166748C6.40862 0.166748 4.88249 0.798889 3.75728 1.92411C2.63206 3.04933 1.99992 4.57545 1.99992 6.16675V6.66675C1.41623 6.87427 0.911088 7.25739 0.553873 7.76352C0.196658 8.26964 0.00488281 8.87393 0.00488281 9.49341C0.00488281 10.1129 0.196658 10.7172 0.553873 11.2233C0.911088 11.7294 1.41623 12.1126 1.99992 12.3201C2.15098 12.3726 2.31223 12.3891 2.4708 12.3683C2.62937 12.3474 2.78088 12.2898 2.91325 12.2001C3.04178 12.1066 3.14669 11.9844 3.21961 11.8432C3.29253 11.702 3.33145 11.5457 3.33325 11.3867V6.16675C3.33325 4.92907 3.82492 3.74209 4.70009 2.86692C5.57526 1.99175 6.76224 1.50008 7.99992 1.50008C9.23759 1.50008 10.4246 1.99175 11.2997 2.86692C12.1749 3.74209 12.6666 4.92907 12.6666 6.16675V11.3867C12.6679 11.5255 12.6982 11.6624 12.7556 11.7887C12.813 11.915 12.8962 12.0279 12.9999 12.1201V12.5001C12.9999 13.4867 12.1399 13.8334 11.3333 13.8334H10.1466C10.0294 13.6304 9.86075 13.4619 9.65767 13.3449C9.4546 13.2279 9.22426 13.1664 8.98987 13.1668C8.75548 13.1671 8.52533 13.2293 8.32261 13.3469C8.11989 13.4646 7.95177 13.6336 7.83519 13.8369C7.71861 14.0403 7.65769 14.2707 7.65857 14.5051C7.65946 14.7395 7.72211 14.9695 7.84022 15.172C7.95833 15.3744 8.12772 15.5422 8.33132 15.6583C8.53492 15.7744 8.76553 15.8348 8.99992 15.8334C9.2326 15.832 9.46087 15.7697 9.66203 15.6528C9.86319 15.5358 10.0302 15.3683 10.1466 15.1667H11.3333C13.0999 15.1667 14.3333 14.0734 14.3333 12.5001V12.1801C14.8326 11.9323 15.2531 11.5503 15.5475 11.077C15.8419 10.6036 15.9985 10.0575 15.9999 9.50008Z"
          fill="currentColor"
        />
        <path
          d="M1.29167 15.7317C1.59361 16.0152 2.06825 16.0003 2.35181 15.6984L15.8176 1.35979C16.1011 1.05785 16.0862 0.583213 15.7843 0.299654C15.4823 0.0160953 15.0077 0.0309958 14.7242 0.332935L1.25839 14.6716C0.97483 14.9735 0.98973 15.4481 1.29167 15.7317Z"
          fill="currentColor"
        />
        <path
          d="M1.29167 15.7317C1.67413 16.0909 2.27534 16.072 2.63451 15.6895L15.8264 1.6425C16.1856 1.26004 16.1667 0.658829 15.7843 0.299654C16.0057 0.507597 15.9553 0.920939 15.6718 1.22288L2.20602 15.5615C1.92246 15.8634 1.51309 15.9396 1.29167 15.7317ZM15.7843 0.299654C15.4018 -0.0595204 14.8006 -0.040647 14.4414 0.34181L1.24951 14.3888C0.89034 14.7713 0.909213 15.3725 1.29167 15.7317C1.07025 15.5237 1.12062 15.1104 1.40418 14.8085L14.8699 0.469849C15.1535 0.16791 15.5629 0.091711 15.7843 0.299654ZM0.74496 15.2183L1.83838 16.2451L0.74496 15.2183ZM16.331 0.813083L15.2376 -0.213775L16.331 0.813083Z"
          fill="currentColor"
        />
      </g>
    </SVGLoader>
  );
};
export default HeadphonesOff;
