import { setTokens } from '@rsos/sinatra';
import { getAPIHost } from '@rsos/utils/metaTags';

/**
 * Authorize with SSO
 * @param {string} code - The code returned from the SSO redirect
 * @returns tokens
 */
const authorizeWithSSO = async code => {
  const response = await fetch(
    `${getAPIHost()}/v1/scorpius/sso/authorize?code=${code}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );

  const tokens = await response.json();

  if (!tokens.token) {
    throw new Error('No access token found');
  }

  setTokens(tokens);
  return tokens;
};

export default authorizeWithSSO;
