import React from 'react';
import { gspx } from '@rsos/capstone';
import { JURISDICTION_VIEW } from '@rsos/capstone/src/constants/capabilities';
import { getTokens } from '@rsos/sinatra';
import { checkStatus, parseJSON } from '@rsos/utils';
import { getAPIHost } from '@rsos/utils/metaTags';
import {
  captureException,
  captureExceptionWithScope,
} from '@rsos/utils/sentry';
import serializeRequestError from '@rsos/utils/serializeRequestError';
import { notifyError } from '@rsos/utils/toastUtils';
import fetchRetry from '../fetchRetry';
import { store } from '../store';
import {
  SAVE_PSAP_INFO,
  SET_SIGNATURE,
  GET_PSAP_INFO_START,
  GET_PSAP_INFO_SUCCESS,
  GET_PSAP_INFO_FAILED,
  CREATE_PSAP_GEOFENCE_START,
  CREATE_PSAP_GEOFENCE_SUCCESS,
  CREATE_PSAP_GEOFENCE_FAILED,
} from './actionTypes';
import { forcedLogOut } from './index';

const baseURL = `${getAPIHost()}/v1/capstone`;

// TODO: consolidate capstone backend requests into /libs/

export const createPSAPGeofenceStart = () => ({
  type: CREATE_PSAP_GEOFENCE_START,
});

export const createPSAPGeofenceFail = error => {
  const errMsg = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            'An error occurred. Please try again and <a href="mailto:support@rapidsos.com">contact support</a> if the error repeats.',
        }}
      ></div>
    );
  };
  notifyError({ message: errMsg() });

  return {
    type: CREATE_PSAP_GEOFENCE_FAILED,
    error,
  };
};

export const createPSAPGeofenceSuccess = () => ({
  type: CREATE_PSAP_GEOFENCE_SUCCESS,
});
// TODO: this set of actions should be renamed since geofences aren't handled during signup
export const createPSAPgeofence = psapData => dispatch => {
  dispatch(createPSAPGeofenceStart());

  fetch(`${baseURL}/psaps/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(psapData),
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(() => dispatch(createPSAPGeofenceSuccess()))
    .catch(async err => {
      const serializedError = await serializeRequestError(err);
      captureException(err);
      dispatch(createPSAPGeofenceFail(serializedError));
    });
};

export const savePSAPInfo = psapInfo => ({
  type: SAVE_PSAP_INFO,
  psapInfo,
});

export const setSignature = signature => ({
  type: SET_SIGNATURE,
  signature,
});

// Get PSAP Info
export const getPSAPInfoStart = () => ({
  type: GET_PSAP_INFO_START,
});

export const getPSAPInfoFail = error => {
  const errMsg = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            'An error occurred, please try again.' +
            ' If this error persists, submit a ticket via the "Support" tab.',
        }}
      ></div>
    );
  };
  notifyError({ message: errMsg() });
  return {
    type: GET_PSAP_INFO_FAILED,
    error,
  };
};

// TODOv4 Spotted this while working on Johannesburg, code duplication
// here an in emergency_data.
export const getPSAPInfoSuccess = psapInfo => {
  const {
    account_id,
    contact_email,
    dispatch_type,
    display_name,
    active_capabilities = {},
    population,
    state: agency_state,
  } = psapInfo;

  const userState = store.getState().sinatra?.user;
  const gspxUserInfo = gspx.normalizeGSPXUserInfo(userState);

  const gspxAccountInfo = {
    id: account_id,
    name: display_name,
    PrimaryPointOfContact: contact_email,
    JurisdictionViewEnabled: !!active_capabilities[JURISDICTION_VIEW],
    AddressablePopulation: population,
    AgencyState: agency_state,
    DispatchType: dispatch_type,
  };

  gspx.addIdentifier(gspxUserInfo, gspxAccountInfo);

  return {
    type: GET_PSAP_INFO_SUCCESS,
    psapInfo,
  };
};

export const getPSAPInfo = (psapName, navigate) => dispatch => {
  dispatch(getPSAPInfoStart());

  const apiAuthToken = getTokens().token;

  const options = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${apiAuthToken}`,
    },
  };
  return fetchRetry(`${baseURL}/psaps/${psapName}`, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(res => {
      return dispatch(getPSAPInfoSuccess(res));
    })
    .catch(async err => {
      const serializedError = await serializeRequestError(err);
      const { auth } = store.getState();
      const { tokenExpiration, refreshExpiration } = auth;
      captureExceptionWithScope(err, {
        extra: {
          accessTokenExpiration: tokenExpiration,
          refreshTokenExpiration: refreshExpiration,
        },
      });
      if (err.status === 401 || err.status === 403) {
        dispatch(forcedLogOut(navigate));
      } else {
        dispatch(getPSAPInfoFail(serializedError));
      }
    });
};
