import React from 'react';
import SVGLoader from './SVGLoader';

const defaults = {
  viewboxHeight: 17,
  viewboxWidth: 17,
  height: 16,
  width: 16,
};

export default ({
  width = defaults.width,
  height = defaults.height,
  viewboxWidth = defaults.viewboxWidth,
  viewboxHeight = defaults.viewboxHeight,
  ...rest
}) => (
  <SVGLoader
    width={width}
    height={height}
    viewboxHeight={viewboxHeight}
    viewboxWidth={viewboxWidth}
    {...rest}
  >
    <path
      d="M6.22703 11.5164L4.8937 10.183C4.82377 10.1132 4.73472 10.0657 4.63779 10.0464C4.54086 10.0271 4.44039 10.037 4.34908 10.0748C4.25777 10.1127 4.17972 10.1767 4.12477 10.2588C4.06982 10.341 4.04045 10.4375 4.04036 10.5364V11.3697H2.8737C2.8295 11.3697 2.7871 11.3521 2.75585 11.3209C2.72459 11.2896 2.70703 11.2472 2.70703 11.203V9.44971C2.70703 9.3171 2.65435 9.18992 2.56058 9.09615C2.46682 9.00239 2.33964 8.94971 2.20703 8.94971C2.07442 8.94971 1.94725 9.00239 1.85348 9.09615C1.75971 9.18992 1.70703 9.3171 1.70703 9.44971V11.203C1.70703 11.5125 1.82995 11.8092 2.04874 12.028C2.26753 12.2468 2.56428 12.3697 2.8737 12.3697H4.04036V13.203C4.04069 13.3031 4.07062 13.4008 4.12638 13.4838C4.18214 13.5669 4.26124 13.6315 4.3537 13.6697C4.41293 13.6937 4.4765 13.705 4.54036 13.703C4.60622 13.7045 4.67167 13.6922 4.73251 13.667C4.79335 13.6417 4.84825 13.6041 4.8937 13.5564L6.22703 12.223C6.2742 12.1771 6.31169 12.1221 6.33729 12.0614C6.36289 12.0008 6.37608 11.9356 6.37608 11.8697C6.37608 11.8038 6.36289 11.7386 6.33729 11.678C6.31169 11.6173 6.2742 11.5623 6.22703 11.5164Z"
      fill="currentColor"
    />
    <path
      d="M15.4131 8.79649V12.6165C15.4135 12.7549 15.3846 12.8917 15.3283 13.0181C15.272 13.1445 15.1895 13.2576 15.0864 13.3498C14.9626 13.4965 14.8085 13.6148 14.6348 13.6965C14.4611 13.7782 14.2717 13.8214 14.0797 13.8232H12.2464C12.2022 13.8232 12.1598 13.8407 12.1286 13.872C12.0973 13.9032 12.0797 13.9456 12.0797 13.9898V14.3232C12.0797 14.3674 12.0973 14.4098 12.1286 14.441C12.1598 14.4723 12.2022 14.4898 12.2464 14.4898H13.0797C13.2566 14.4898 13.4261 14.5601 13.5512 14.6851C13.6762 14.8101 13.7464 14.9797 13.7464 15.1565C13.7464 15.3333 13.6762 15.5029 13.5512 15.6279C13.4261 15.7529 13.2566 15.8232 13.0797 15.8232H9.74642C9.5696 15.8232 9.40003 15.7529 9.27501 15.6279C9.14999 15.5029 9.07975 15.3333 9.07975 15.1565C9.07975 14.9797 9.14999 14.8101 9.27501 14.6851C9.40003 14.5601 9.5696 14.4898 9.74642 14.4898H10.5797C10.624 14.4898 10.6663 14.4723 10.6976 14.441C10.7289 14.4098 10.7464 14.3674 10.7464 14.3232V13.9498C10.7464 13.9056 10.7289 13.8632 10.6976 13.832C10.6663 13.8007 10.624 13.7832 10.5797 13.7832H8.74642C8.54987 13.7879 8.35471 13.7491 8.17492 13.6696C7.99512 13.5901 7.83513 13.4718 7.70642 13.3232C7.60547 13.2295 7.52494 13.1161 7.46988 12.9899C7.41481 12.8637 7.3864 12.7275 7.38642 12.5898V8.79649C7.38642 8.44287 7.52689 8.10373 7.77694 7.85368C8.02699 7.60363 8.36613 7.46316 8.71975 7.46316H14.0531C14.2304 7.45961 14.4067 7.49148 14.5715 7.55689C14.7364 7.62231 14.8865 7.71996 15.0132 7.84413C15.1398 7.96829 15.2404 8.11648 15.3091 8.28001C15.3778 8.44354 15.4131 8.61913 15.4131 8.79649ZM8.91308 11.7965H13.9131C13.9573 11.7965 13.9997 11.7789 14.0309 11.7477C14.0622 11.7164 14.0797 11.674 14.0797 11.6298V8.96316C14.0797 8.91895 14.0622 8.87656 14.0309 8.84531C13.9997 8.81405 13.9573 8.79649 13.9131 8.79649H8.91308C8.86888 8.79649 8.82649 8.81405 8.79523 8.84531C8.76397 8.87656 8.74642 8.91895 8.74642 8.96316V11.6298C8.74642 11.674 8.76397 11.7164 8.79523 11.7477C8.82649 11.7789 8.86888 11.7965 8.91308 11.7965Z"
      fill="currentColor"
    />
    <path
      d="M-0.000312328 5.28301C-0.000394344 5.25303 0.00761271 5.22358 0.0228658 5.19777C0.0381184 5.17196 0.0600514 5.15074 0.0863538 5.13634C0.112193 5.11969 0.142282 5.11084 0.173021 5.11084C0.20376 5.11084 0.233848 5.11969 0.259687 5.13634C0.964825 5.54768 1.77023 5.75538 2.58635 5.73634C3.40464 5.75592 4.21234 5.54822 4.91969 5.13634C4.94553 5.11969 4.97561 5.11084 5.00635 5.11084C5.03709 5.11084 5.06718 5.11969 5.09302 5.13634C5.11932 5.15074 5.14126 5.17196 5.15651 5.19777C5.17176 5.22358 5.17977 5.25303 5.17969 5.28301V6.10301C5.17969 6.72968 4.11302 7.43634 2.58635 7.43634C1.05969 7.43634 -0.000312328 6.76968 -0.000312328 6.10301V5.28301Z"
      fill="currentColor"
    />
    <path
      d="M5.17969 3.76317C5.17969 4.38984 4.11302 5.0965 2.58635 5.0965C1.05969 5.0965 -0.000312328 4.42984 -0.000312328 3.76317V2.9565C0.000284195 2.92666 0.00857496 2.89748 0.0237584 2.87179C0.0389419 2.84609 0.060503 2.82475 0.0863538 2.80984C0.112911 2.79525 0.142721 2.7876 0.173021 2.7876C0.203321 2.7876 0.23313 2.79525 0.259687 2.80984C0.964825 3.22117 1.77023 3.42887 2.58635 3.40984C3.40465 3.42941 4.21234 3.22171 4.91969 2.80984C4.94624 2.79525 4.97605 2.7876 5.00635 2.7876C5.03665 2.7876 5.06646 2.79525 5.09302 2.80984C5.11887 2.82475 5.14043 2.84609 5.15562 2.87179C5.1708 2.89748 5.17909 2.92666 5.17969 2.9565V3.76317Z"
      fill="currentColor"
    />
    <path
      d="M5.17969 1.42952C5.17969 2.04952 4.11302 2.76286 2.58635 2.76286C1.05969 2.76286 -0.000312328 2.06286 -0.000312328 1.42952C-0.000312328 0.796191 1.06635 0.0961914 2.58635 0.0961914C4.10635 0.0961914 5.17969 0.802858 5.17969 1.42952Z"
      fill="currentColor"
    />
  </SVGLoader>
);
