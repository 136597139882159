import styled from 'styled-components';

const DropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  height: ${({ height }) => height || 'auto'};
  margin: 0;
  padding: ${({ padding }) => padding || 0};
  overflow: ${({ overflow }) => overflow || 'hidden'};

  li {
    border-bottom: ${({ theme, borderless }) =>
      !borderless && `1px solid ${theme.colors.heavyLineOutline}`};
    &:last-child {
      border-bottom: none;
    }
  }
`;

export default DropdownList;
