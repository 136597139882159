import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUrlParameter } from '@rsos/utils';
import { resetPassword } from '../../../actions';
import NewPassword from '../Common/NewPassword';

const ResetPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleSubmit = values => {
    let token = getUrlParameter('token');
    let data = {
      password: values.password,
      token: token,
    };
    dispatch(resetPassword(data, navigate));
  };
  return (
    <NewPassword handleSubmit={handleSubmit} title="Create new password" />
  );
};

export default ResetPassword;
