/**
 * check if any of the jurisdiction's egress status is active
 * (egress_status = 3)
 * @param {Array.<Object>} jurisdictions - The jurisdiction list
 */
export const isAdminApproved = (jurisdictions = []) => {
  if (jurisdictions.length > 0) {
    return jurisdictions.some(jurisdiction => jurisdiction.egress_status === 3);
  }
  return false;
};
