import React from 'react';
import SVGLoader from './SVGLoader';

export default ({ viewboxWidth, viewboxHeight, ...rest }) => {
  return (
    <SVGLoader
      {...rest}
      viewboxWidth={viewboxWidth || 24}
      viewboxHeight={viewboxHeight || 24}
    >
      <path
        d="M22.783,11.116l-2.46-2.46a.249.249,0,0,1-.074-.176V5A1.251,1.251,0,0,0,19,3.75H15.52a.251.251,0,0,1-.177-.073l-2.46-2.46a1.251,1.251,0,0,0-1.767,0l-2.46,2.46a.251.251,0,0,1-.177.073H5A1.252,1.252,0,0,0,3.749,5V8.48a.248.248,0,0,1-.073.176l-2.46,2.46a1.252,1.252,0,0,0,0,1.768l2.46,2.46a.249.249,0,0,1,.073.177V19A1.251,1.251,0,0,0,5,20.25h3.48a.251.251,0,0,1,.177.073l2.46,2.46a1.25,1.25,0,0,0,1.767,0l2.46-2.46a.251.251,0,0,1,.177-.073H19A1.25,1.25,0,0,0,20.249,19V15.521a.25.25,0,0,1,.074-.177l2.46-2.46A1.25,1.25,0,0,0,22.783,11.116ZM17,12a5,5,0,0,1-4.8,5,.186.186,0,0,1-.2-.187V7.257a.25.25,0,0,1,.263-.25A5,5,0,0,1,17,12Z"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0"
      ></path>
    </SVGLoader>
  );
};
