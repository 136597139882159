import { getSessionAge } from '@rsos/base-utils/metaTags';
import { SESSION_START, UPDATE_SESSION, SESSION_END } from './types';

export const sessionStart = () => ({
  type: SESSION_START,
  sessionStart: Math.floor(Date.now() / 1000),
  sessionExpiration: Math.floor(Date.now() / 1000) + getSessionAge(),
});

export const updateSession = hourInSeconds => {
  // 3600s = 1 hour
  if (!hourInSeconds) {
    hourInSeconds = 3600;
  }

  return {
    type: UPDATE_SESSION,
    hourInSeconds,
  };
};

export const sessionEnd = () => ({
  type: SESSION_END,
  sessionEnd: Math.floor(Date.now() / 1000),
});
