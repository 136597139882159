import instance from './scorpiusInstance';
import {
  FETCH_ORG_TOKEN_START,
  FETCH_ORG_TOKEN_SUCCESS,
  FETCH_ORG_TOKEN_ERROR,
  PRODUCT_TOKEN,
} from './types';

export const fetchOrgTokenStart = () => ({
  type: FETCH_ORG_TOKEN_START,
});

export const fetchOrgTokenSuccess = (token, productName = null) => ({
  type: FETCH_ORG_TOKEN_SUCCESS,
  token,
  productName,
});

export const fetchOrgTokenError = (error, productName = null) => ({
  type: FETCH_ORG_TOKEN_ERROR,
  error,
  productName,
});

export const fetchOrgToken = (org_id, product) => async dispatch => {
  dispatch(fetchOrgTokenStart());
  try {
    const response = await instance.get(
      `/organizations/${org_id}/token/?product=${product}`,
      { headers: { retry: true } },
    );
    const { token } = response.data;

    // The Scorpius API sometimes returns 200 ok with token = {}. If it's not
    // a string, there's no token.
    let productToken = token;
    if (typeof productToken !== 'string') {
      productToken = '';
    }
    localStorage.setItem(PRODUCT_TOKEN, productToken);
    return dispatch(fetchOrgTokenSuccess(productToken, product));
  } catch (error) {
    throw dispatch(fetchOrgTokenError(error, product));
  }
};
