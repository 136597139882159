import { normalizeCapabilities } from '@rsos/capstone';
import { SINATRA_LOGOUT } from '@rsos/sinatra';
import {
  FORCED_LOGOUT_SUCCESS,
  GET_PSAP_INFO_START,
  GET_PSAP_INFO_SUCCESS,
  GET_PSAP_INFO_FAILED,
  LOGOUT_SUCCESS,
  SAVE_PSAP_INFO,
  SET_SIGNATURE,
  SIGNUP_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  currentPSAP: JSON.parse(localStorage.getItem('agencyInfo')) || {
    account_id: null,
    agency_name: '',
    agency_state: '',
    id: null,
    fcc_id: '',
    contact_name: '',
    contact_title: '',
    contact_email: '',
    contact_phone: '',
    display_name: '',
    name: '',
    non_emergency_phone: '',
    population: '',
    phone_system: '',
    cad_system: '',
    mapping_system: '',
    jurisdictions: [],
    activeCapabilities: {},
  }, // TODO: whitelist in redux-persist
  currentPSAPUsers: [],
  error: {},
  selectedUser: {},
  isGettingPSAPInfo: false,
  loading: {
    addUser: false,
    deleteUser: false,
    getPSAPInfo: false,
  },
  signature: '', // might not be necessary to keep this since it's only used
  // by user to accept license
};

const psaps = (state = initialState, action) => {
  switch (action.type) {
    case FORCED_LOGOUT_SUCCESS:
      return initialState;
    case GET_PSAP_INFO_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          getPSAPInfo: true,
        },
        isGettingPSAPInfo: true,
      };
    case GET_PSAP_INFO_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getPSAPInfo: false,
        },
        isGettingPSAPInfo: false,
        currentPSAP: {
          ...state.currentPSAP,
          account_id:
            action.psapInfo.account_id || state.currentPSAP.account_id,
          id: action.psapInfo.id || state.currentPSAP.id,
          fcc_id: action.psapInfo.fcc_id || state.currentPSAP.fcc_id,
          cad_system: action.psapInfo.cad_system,
          contact_name: action.psapInfo.contact_name,
          contact_email: action.psapInfo.contact_email,
          contact_phone: action.psapInfo.contact_phone,
          contact_title: action.psapInfo.contact_title,
          display_name:
            action.psapInfo.display_name || state.currentPSAP.display_name,
          name: action.psapInfo.name || state.currentPSAP.name,
          agency_name: action.psapInfo.name || state.currentPSAP.name,
          agency_state: action.psapInfo.state || state.currentPSAP.agency_state,
          non_emergency_phone: action.psapInfo.non_emergency_phone,
          mapping_system: action.psapInfo.mapping_system,
          phone_system: action.psapInfo.phone_system,
          population: action.psapInfo.population,
          jurisdictions:
            action.psapInfo.jurisdictions || state.currentPSAP.jurisdictions,
          active_capabilities: normalizeCapabilities(
            action.psapInfo.active_capabilities,
          ),
        },
      };
    case GET_PSAP_INFO_FAILED:
      return {
        ...state,
        error: action.error,
        loading: {
          ...state.loading,
          getPSAPInfo: false,
        },
        isGettingPSAPInfo: false,
      };
    case LOGOUT_SUCCESS:
    case SINATRA_LOGOUT:
      return initialState;
    case SAVE_PSAP_INFO:
      return {
        ...state,
        currentPSAP: {
          ...state.currentPSAP,
          account_id:
            action.psapInfo.account_id || state.currentPSAP.account_id,
          agency_name:
            action.psapInfo.agency_name || state.currentPSAP.agency_name,
          agency_state:
            action.psapInfo.agency_state || state.currentPSAP.agency_state,
          id: action.psapInfo.id || state.currentPSAP.id,
          fcc_id: action.psapInfo.fcc_id || state.currentPSAP.fcc_id,
          cad_system:
            action.psapInfo.cad_system || state.currentPSAP.cad_system,
          contact_name: action.psapInfo.contact_name,
          contact_email: action.psapInfo.contact_email,
          contact_phone:
            action.psapInfo.contact_phone || state.currentPSAP.contact_phone,
          contact_title: action.psapInfo.contact_title,
          display_name:
            action.psapInfo.display_name || state.currentPSAP.display_name,
          name: action.psapInfo.name || state.currentPSAP.name,
          non_emergency_phone:
            action.psapInfo.non_emergency_phone ||
            state.currentPSAP.non_emergency_phone,
          mapping_system:
            action.psapInfo.mapping_system || state.currentPSAP.mapping_system,
          phone_system:
            action.psapInfo.phone_system || state.currentPSAP.phone_system,
          population: action.psapInfo.population,

          jurisdictions:
            action.psapInfo.jurisdictions || state.currentPSAP.jurisdictions,
          activeCapabilities: normalizeCapabilities(
            action.psapInfo.active_capabilities,
          ),
        },
      };
    case SET_SIGNATURE:
      return {
        ...state,
        signature: action.signature,
      };
    case SIGNUP_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default psaps;
