import { useSelector } from 'react-redux';
import { RAPIDSOS_PREMIUM } from '@rsos/capstone/src/constants/capabilities';

/**
 * It is used for Gainsight to report the portaltype. As well as if isPremiumUser check is needed
 * Value of PortalType will be 0 or 1, if we need to scale to other paid versions later it will be easy to scale
 * '0’ = free
 * '1' = premimum
 */

export default function usePortalType() {
  const isPremiumUser = useSelector(state => {
    return !!(
      state.psaps.currentPSAP &&
      state.psaps.currentPSAP.activeCapabilities &&
      state.psaps.currentPSAP.activeCapabilities[RAPIDSOS_PREMIUM]
    );
  });
  // currently it is only 2 cases, no need to over complicate it. but can be generalized if needed
  return {
    isPremiumUser: isPremiumUser,
    portalType: isPremiumUser ? '1' : '0',
  };
}
