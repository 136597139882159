import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  isValidEmail,
  isValidPassword,
  isValidTextField,
} from '@rsos/utils/validators';
import { saveCreateAccountInfo } from '../../actions';
import { Button, Input } from '../capstoneLegacy';

const FIELD_EMAIL = 'email';
const FIELD_FIRST_NAME = 'firstName';
const FIELD_LAST_NAME = 'lastName';
const FIELD_PASSWORD = 'password';
const FIELD_PASSWORD_CONFIRM = 'passwordConfirm';
const PASSWORDS_DO_NOT_MATCH = 'Passwords do not match';

const CreateAccount = ({ handleStatus }) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const createAccountInfo = useSelector(state => state.auth.createAccountInfo);

  const signUpError = useSelector(state => state.auth.signUpError);

  const [state, setState] = useState({
    [FIELD_EMAIL]: '',
    [`${FIELD_EMAIL}Error`]: '',
    [FIELD_FIRST_NAME]: '',
    [`${FIELD_FIRST_NAME}Error`]: '',
    [FIELD_LAST_NAME]: '',
    [`${FIELD_LAST_NAME}Error`]: '',
    [FIELD_PASSWORD]: '',
    [`${FIELD_PASSWORD}Error`]: '',
    [FIELD_PASSWORD_CONFIRM]: '',
    [`${FIELD_PASSWORD_CONFIRM}Error`]: '',
    formSubmitted: false,
    isPasswordMatch: false,
  });

  const {
    email,
    emailError,
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    password,
    passwordError,
    passwordConfirm,
    passwordConfirmError,
    formSubmitted,
    isPasswordMatch,
  } = state;

  const isBtnDisabled = () => {
    return !(
      isValidTextField(firstName, 2, 30) &&
      isValidTextField(lastName, 2, 30) &&
      isValidEmail(email) &&
      isValidPassword(password) &&
      isValidPassword(passwordConfirm) &&
      isPasswordMatch
    );
  };

  const isEnabled = !isBtnDisabled();

  const handleError = (name, value) => {
    let error;

    if (name === FIELD_EMAIL && !isValidEmail(value)) {
      error = (
        <ul>
          <li>Invalid email</li>
          <li>Must be less than 50 characters</li>
        </ul>
      );
    } else if (
      (name === FIELD_FIRST_NAME || name === FIELD_LAST_NAME) &&
      !isValidTextField(value, 2, 30)
    ) {
      error = (
        <ul>
          <li>Must be between 2 and 30 characters</li>
          <li>
            Only alphanumeric characters, spaces, dashes, and underscores
            allowed
          </li>
        </ul>
      );
    } else if (name === FIELD_PASSWORD) {
      if (!isValidPassword(value)) {
        error = (
          <ul>
            <li>Use a combination of upper-case and lower-case letters</li>
            <li>Include at least 1 numerical digit</li>
            <li>
              Include at least 1 special character, such as: ! @ # $ % & ? - _
            </li>
            <li>No spaces</li>
            <li>Can't be similar to the email</li>
            <li>Minimal length 8 characters</li>
          </ul>
        );
      } else if (passwordConfirm !== '' && value !== passwordConfirm) {
        error = PASSWORDS_DO_NOT_MATCH;
      }
    } else if (
      name === FIELD_PASSWORD_CONFIRM &&
      (!isValidPassword(value) || password !== value)
    ) {
      error = PASSWORDS_DO_NOT_MATCH;
    } else {
      error = null;
    }

    return error;
  };

  const onChange = e => {
    setState(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: handleError(e.target.name, e.target.value),
    }));
  };

  const createAccount = e => {
    e.preventDefault();

    let contactName = `${firstName} ${lastName}`;

    let createAccountInfo = {
      username: email,
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      contact_name: contactName,
    };

    if (!isBtnDisabled()) {
      setState(prevState => ({ ...prevState, formSubmitted: true }));

      dispatch(saveCreateAccountInfo(createAccountInfo));

      navigate('/sign-up/provide-info');
    }

    localStorage.setItem('createAccount', JSON.stringify(createAccountInfo));

    handleStatus(true);
  };

  useEffect(() => {
    const validPasswordMatch =
      password !== '' &&
      isValidPassword(password) &&
      isValidPassword(passwordConfirm) &&
      passwordConfirm !== '' &&
      password === passwordConfirm;

    if (validPasswordMatch) {
      setState(prevState => ({
        ...prevState,
        isPasswordMatch: validPasswordMatch,
        passwordError: null,
        passwordConfirmError: null,
      }));
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    if (
      createAccountInfo !== undefined &&
      Object.keys(createAccountInfo).length > 0
    ) {
      setState(prevState => ({
        ...prevState,
        email: createAccountInfo['email'],
        firstName: createAccountInfo['first_name'],
        lastName: createAccountInfo['last_name'],
      }));
    }
  }, [createAccountInfo]);

  return (
    <div
      id="create-account-container"
      className="create-account-container hero mb-20"
    >
      <form className="pt-20" onSubmit={createAccount}>
        <div className="sign-up-wrapper bubble-blue-bg">
          <div className="custom-page-title pb-30 black-text">
            Register Your Account
          </div>
          <div className="columns is-gapless mb-0">
            <div className="column is-12-desktop">
              <Input
                className={
                  'rl-input is-fullwidth' +
                  (signUpError && signUpError.includes('User with username')
                    ? ' input-error'
                    : '')
                }
                error={emailError}
                labelName="Email Address"
                labelPosition="top"
                maxLength={50}
                name={FIELD_EMAIL}
                onChange={onChange}
                placeholder="example@gmail.com"
                type="email"
                value={email || ''}
              />
            </div>
          </div>
          <div className="columns is-gapless mb-0">
            <div className="column is-6">
              <Input
                className="rl-input is-fullwidth"
                error={firstNameError}
                labelName="First Name (*)"
                labelPosition="top"
                maxLength={30}
                minLength={2}
                name={FIELD_FIRST_NAME}
                onChange={onChange}
                placeholder="First Name"
                type="text"
                value={firstName || ''}
              />
            </div>
            <div className="column is-6">
              <Input
                className="rl-input is-fullwidth"
                error={lastNameError}
                labelName="Last Name (*)"
                labelPosition="top"
                maxLength={30}
                minLength={2}
                name={FIELD_LAST_NAME}
                onChange={onChange}
                placeholder="Last Name"
                type="text"
                value={lastName || ''}
              />
            </div>
          </div>
          <div className="columns is-gapless mb-0">
            <div className="column is-6">
              <Input
                className="rl-input is-fullwidth"
                error={passwordError}
                labelName="Password (*)"
                labelPosition="top"
                minLength={8}
                name={FIELD_PASSWORD}
                onChange={onChange}
                placeholder="Password"
                type="password"
                value={password || ''}
              />
            </div>
            <div className="column is-6">
              <Input
                className="rl-input is-fullwidth"
                error={passwordConfirmError}
                labelName="Confirm Password (*)"
                labelPosition="top"
                minLength={8}
                name={FIELD_PASSWORD_CONFIRM}
                onChange={onChange}
                placeholder="Confirm Password"
                type="password"
                value={passwordConfirm || ''}
              />
            </div>
          </div>
        </div>
        <div className="button-container">
          {formSubmitted ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="button is-loading rapidlite-forms-button unclickable">
              Loading
            </a>
          ) : (
            <Button
              name="SignUp"
              buttonClicked={createAccount}
              className={
                'mt-10 rapidlite-forms-button ' +
                (!isEnabled ? 'unclickable' : 'clickable')
              }
              disabled={!isEnabled}
            >
              CONTINUE {'>'}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateAccount;
