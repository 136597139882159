import React from 'react';

const StaticMessagePage = ({ id, className, title, message }) => {
  return (
    <div
      id={id}
      className={`StaticMessagePage tile is-child has-text-centered ${className}`}
      style={{ background: 'white', padding: '15vh' }}
    >
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  );
};

export default StaticMessagePage;
