/**
 * Function that returns an object containing calls with locations to prevent
 * unexpected app crashes when additional data is available before location
 * data for a given call in the queue due to expected location data for the
 * queue to render
 * @param {Object} callQueue
 */
export const filterEmergencyCalls = callQueue => {
  return Object.keys(callQueue)
    .filter(
      key =>
        callQueue[key] &&
        callQueue[key].core &&
        callQueue[key].core.locations &&
        (callQueue[key].core.locations?.length ||
          callQueue[key].core.secondaryLocations?.length),
    )
    .reduce((res, key) => ((res[key] = callQueue[key]), res), {});
};

/**
 * @function filterAniAliCalls - only need to check against class_of_service_category in primary locations
 * if primary locations includes a location WITHOUT class_of_service_category,
 * we can safely assume its DBH / Ani/Ali phase 0
 * ANI/ALI phase 0 does not contain class_of_service_category and secondaryLocations
 * ANI/ALI phase 1 contain class_of_service_category and secondaryLocations
 *
 */
export const filterAniAliCalls = filteredCallQueue => {
  return Object.keys(filteredCallQueue)
    .filter(
      key =>
        filteredCallQueue[key] &&
        filteredCallQueue[key].core &&
        filteredCallQueue[key].core.locations &&
        filteredCallQueue[key].core.locations?.length &&
        filteredCallQueue[key].core.locations.find(
          loc => !loc.class_of_service_category,
        ),
    )
    .reduce((res, key) => ((res[key] = filteredCallQueue[key]), res), {});
};
