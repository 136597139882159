import {
  FETCH_APP_USERS_START,
  FETCH_APP_USERS_SUCCESS,
  FETCH_APP_USERS_ERROR,
  CREATE_APP_USER_START,
  CREATE_APP_USER_SUCCESS,
  CREATE_APP_USER_ERROR,
  UPDATE_APP_USER_START,
  UPDATE_APP_USER_SUCCESS,
  UPDATE_APP_USER_ERROR,
  DELETE_APP_USER_START,
  DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_ERROR,
} from '../../actions/types';

export const initialState = {
  records: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  errors: {
    loading: null,
    creating: null,
    updating: null,
    deleting: null,
  },
};

const addUser = (records, user) => [user, ...records];

const updateUser = (records, user) =>
  records.map(record => {
    if (record.email === user.email) {
      return {
        ...record,
        role: user.role,
      };
    }
    return record;
  });

const removeUser = (records, user) =>
  records.filter(record => record.email !== user.email);

const appUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_USERS_START:
      return {
        ...state,
        loading: true,
        errors: {
          ...state.errors,
          loading: null,
        },
      };
    case FETCH_APP_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.users,
      };
    case FETCH_APP_USERS_ERROR:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          loading: action.error,
        },
      };
    case CREATE_APP_USER_START:
      return {
        ...state,
        creating: true,
        errors: {
          ...state.errors,
          creating: null,
        },
      };
    case CREATE_APP_USER_SUCCESS:
      return {
        ...state,
        creating: false,
        records: action.resentInvite
          ? state.records
          : addUser(state.records, action.user),
      };
    case CREATE_APP_USER_ERROR:
      return {
        ...state,
        creating: false,
        errors: {
          ...state.errors,
          creating: action.error,
        },
      };
    case UPDATE_APP_USER_START:
      return {
        ...state,
        updating: true,
        errors: {
          ...state.errors,
          updating: null,
        },
      };
    case UPDATE_APP_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        records: updateUser(state.records, action.user),
      };
    case UPDATE_APP_USER_ERROR:
      return {
        ...state,
        updating: false,
        errors: {
          ...state.errors,
          updating: action.error,
        },
      };
    case DELETE_APP_USER_START:
      return {
        ...state,
        deleting: true,
        errors: {
          ...state.errors,
          deleting: null,
        },
      };
    case DELETE_APP_USER_SUCCESS:
      return {
        ...state,
        deleting: false,
        records: removeUser(state.records, action.user),
      };
    case DELETE_APP_USER_ERROR:
      return {
        ...state,
        deleting: false,
        errors: {
          ...state.errors,
          deleting: action.error,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default appUsersReducer;
