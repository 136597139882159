import styled from 'styled-components';
import { border, color, layout, size, space } from 'styled-system';

const StyledHR = styled.hr`
  ${border}
  ${color}
  ${layout}
  ${size}
  ${space}
`;

export default StyledHR;
