import instance from './scorpiusInstance';
import {
  UPDATE_USER_ATTRIBUTES_START,
  UPDATE_USER_ATTRIBUTES_SUCCESS,
  UPDATE_USER_ATTRIBUTES_ERROR,
  UPDATE_ORG_ATTRIBUTES_START,
  UPDATE_ORG_ATTRIBUTES_SUCCESS,
  UPDATE_ORG_ATTRIBUTES_ERROR,
  PATCH_ORG_ATTRIBUTES_START,
  PATCH_ORG_ATTRIBUTES_SUCCESS,
  PATCH_ORG_ATTRIBUTES_ERROR,
} from './types';

const updateUserAttributesStart = applicationName => ({
  type: UPDATE_USER_ATTRIBUTES_START,
  payload: {
    applicationName,
  },
});

const updateUserAttributesSuccess = (data, applicationName) => {
  return {
    type: UPDATE_USER_ATTRIBUTES_SUCCESS,
    payload: {
      applicationName,
      data,
    },
  };
};

const updateUserAttributesError = (error, applicationName) => ({
  type: UPDATE_USER_ATTRIBUTES_ERROR,
  payload: {
    error,
    applicationName,
  },
});

export const updateUserAttributes = (
  applicationName,
  data,
) => async dispatch => {
  dispatch(updateUserAttributesStart(applicationName));
  try {
    await instance.post('/user/attributes', {
      application_name: applicationName,
      data: data,
      headers: { retry: true },
    });
    return dispatch(updateUserAttributesSuccess(data, applicationName));
  } catch (error) {
    throw dispatch(updateUserAttributesError(error, applicationName));
  }
};

const updateOrgAttributesStart = applicationName => ({
  type: UPDATE_ORG_ATTRIBUTES_START,
  payload: {
    applicationName,
  },
});

const updateOrgAttributesSuccess = (data, orgID, applicationName) => ({
  type: UPDATE_ORG_ATTRIBUTES_SUCCESS,
  payload: {
    data,
    orgID,
    applicationName,
  },
});

const updateOrgAttributesError = (error, applicationName) => ({
  type: UPDATE_ORG_ATTRIBUTES_ERROR,
  payload: {
    error,
    applicationName,
  },
});

const patchOrgAttributesStart = applicationName => ({
  type: PATCH_ORG_ATTRIBUTES_START,
  payload: {
    applicationName,
  },
});

const patchOrgAttributesSuccess = (data, orgID, applicationName) => ({
  type: PATCH_ORG_ATTRIBUTES_SUCCESS,
  payload: {
    data,
    orgID,
    applicationName,
  },
});

const patchOrgAttributesError = (error, applicationName) => ({
  type: PATCH_ORG_ATTRIBUTES_ERROR,
  payload: {
    error,
    applicationName,
  },
});

export const updateOrgAttributes = (
  orgID,
  applicationName,
  data,
) => async dispatch => {
  dispatch(updateOrgAttributesStart(applicationName));
  try {
    await instance.post(
      `organizations/${orgID}/attributes`,
      {
        application_name: applicationName,
        data: data,
      },
      { headers: { retry: true } },
    );
    return dispatch(updateOrgAttributesSuccess(data, orgID, applicationName));
  } catch (error) {
    throw dispatch(updateOrgAttributesError(error, applicationName));
  }
};

export const patchOrgAttributes = (
  orgID,
  applicationName,
  data,
) => async dispatch => {
  dispatch(patchOrgAttributesStart(applicationName));
  try {
    await instance.patch(
      `organizations/${orgID}/attributes`,
      {
        application_name: applicationName,
        data: data,
      },
      { headers: { retry: true } },
    );
    return dispatch(patchOrgAttributesSuccess(data, orgID, applicationName));
  } catch (error) {
    throw dispatch(patchOrgAttributesError(error, applicationName));
  }
};
