import * as Sentry from '@sentry/browser';
import { isDevelopmentEnv } from './index';
import { getSentryDSN, getMCOEnvironment, getAppName } from './metaTags';
import safeCall from './safeCall';

// Expose sentry to the global scope allowing it to be called via console.
window.__SENTRY_RSP__ = Sentry;

const appName = getAppName();

const PRODUCTION_ENV = 'gaia-prod-mco';
const environment = getMCOEnvironment();

const options = {
  dsn: getSentryDSN(),
  release: `${appName}@${process.env.REACT_APP_FE_VERSION}`,
  environment,
  replaysSessionSampleRate: environment === PRODUCTION_ENV ? 0.1 : 0, // https://docs.sentry.io/platforms/javascript/session-replay/
  replaysOnErrorSampleRate: 0.1,
  integrations: [
    new Sentry.Replay({
      stickySession: true,
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-nameignore-errors-
  // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
  ignoreErrors: ['Non-Error promise rejection captured'],
};

// Delete the DSN when in development per Sentry's documentation.
if (isDevelopmentEnv()) {
  delete options.dsn;
}

// Initialize sentry the moment this module is executed.
safeCall(() => Sentry.init(options))();

// Tag the FE application name into the global scope.
if (appName) {
  safeCall(() =>
    Sentry.configureScope(scope => scope.setTag('fe-app', appName)),
  )();
}

/**
 * Sets a tag in the global scope. Tags are key/value pairs that allow for
 * additional data to be associated with the events being tracked.
 * Good use-cases: tracking language, local timezone, etc.
 * @param {string} key - A value to be used as key for the tag
 * @param {*} value - Value for the tag
 */
export const setGlobalTag = safeCall((key, value) => {
  Sentry.configureScope(scope => scope.setTag(key, value));
});

/**
 * Sets the user object in Sentry's global scope, which is
 * passed along with every request.
 * @param {Object} user
 * @param {string} user.id - Optional, but expected by Sentry
 * @param {string} user.username - Optional, but expected by Sentry
 * @param {string} user.email - Optional, but expected by Sentry
 */
export const configureUserScope = safeCall(user => {
  if (!user) {
    return;
  }
  Sentry.configureScope(scope => {
    scope.setUser({
      ...user,
    });
  });
});

/**
 * Clears the global scope.
 */
export const clearGlobalScope = safeCall(() => {
  Sentry.configureScope(scope => scope.clear());
});

/**
 * Captures the given exception and sets the extra properties
 * as part of the scope for the current exception.
 * @param {Error} error
 * @param {Object} extra
 */
export const captureExceptionWithScope = safeCall((error, extra = {}) => {
  if (isDevelopmentEnv()) {
    // eslint-disable-next-line no-console
    console.error('[DEV] captureExceptionWithScope:', error, extra);
  }
  Sentry.withScope(scope => {
    Object.keys(extra).forEach(key => {
      scope.setExtra(key, extra[key]);
    });
    Sentry.captureException(error);
  });
});

/**
 * Captures the error without any extra scopes. Passes along
 * any globally defined scopes from initialization.
 * @param {Error} error
 */
export const captureException = safeCall(error => {
  if (isDevelopmentEnv()) {
    // eslint-disable-next-line no-console
    console.error('[DEV] captureException:', error);
  }
  Sentry.captureException(error);
});
