import {
  AGENCY_INFO_CONTENT,
  USERS_CONTENT,
  NOTIFICATIONS_CONTENT,
} from '@rsos/constants/adminContents';

export const whichItemIsActive = currentPathName => {
  if (
    currentPathName === '/admin/access' ||
    currentPathName.includes('/admin/access/users')
  ) {
    return USERS_CONTENT;
  } else if (currentPathName.includes('/admin/notifications')) {
    return NOTIFICATIONS_CONTENT;
  }
  return AGENCY_INFO_CONTENT;
};
