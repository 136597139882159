import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Container } from './Button.styles';
import Loader from './Loader';
import { getLoaderBySize, getLoaderByColor } from './helpers';

const Button = React.forwardRef((props, ref) => {
  let isUnmounted = useRef(false);
  const {
    onClick,
    children,
    disabled,
    isLoadingProp,
    dataName = 'button',
    ...rest
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    isUnmounted.current = true;

    return () => (isUnmounted.current = false);
  }, []);

  const handleClick = () => {
    if (!onClick || disabled) return;

    setIsLoading(true);
    Promise.resolve()
      .then(onClick)
      .catch(e => e)
      .finally(() => {
        if (isUnmounted.current) {
          setIsLoading(false);
        }
      });
  };

  return (
    <ButtonBase
      {...rest}
      disabled={disabled || isLoading || isLoadingProp}
      isLoading={isLoading || isLoadingProp}
      onClick={handleClick}
      ref={ref}
      data-name={dataName}
    >
      <Container isLoading={isLoading || isLoadingProp}>{children}</Container>
      {(isLoading || isLoadingProp) && (
        <Loader
          style={{ position: 'absolute' }}
          size={getLoaderBySize(props)}
          color={getLoaderByColor(props)}
        />
      )}
    </ButtonBase>
  );
});

Button.propTypes = {
  size: PropTypes.oneOf(['large', 'med', 'small', 'xsmall', 'xxsmall']),
  btnType: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'invisible',
    'warning_primary',
    'warning_tertiary',
    'success',
  ]),
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  grow: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  isLoadingProp: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'cancel', 'button']),
  dataTest: PropTypes.string,
  noMargin: PropTypes.bool,
};

Button.defaultProps = {
  btnType: 'primary',
  isLoadingProp: false,
};

export default Button;
