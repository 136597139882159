import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DoubleArrowLeft, DoubleArrowRight } from '@rsos/assets/icons';
import Box from '@rsos/components/capstone/base/Box';
import { LayoutContext } from '@rsos/components/capstone/base/Layout';
import Text from '@rsos/components/capstone/base/Text';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';
import { Wrapper, FooterContainer } from '../common/SideBar.styles';
import { ItemCategoryName } from '../common/SideBarItem.styles';
import useRenderSideBarItems from '../common/useRenderSideBarItems';
import SideBarItem from './SideBarItem';
import rspSideBarItems from './rspSideBarItems';
import { whichItemIsActive } from './utils';

const SideBarRSP = () => {
  const location = useLocation();
  const currentPathName = location.pathname;
  const sideBarItems = useRenderSideBarItems(rspSideBarItems);

  const { layout } = useContext(LayoutContext);
  const windowWidth = layout.window.width;

  const [sideBarOpen, setSideBarOpen] = useState(
    window.width <= BP_LAPTOP ? false : true,
  );

  const sideBarHandler = () => {
    setSideBarOpen(prev => !prev);
  };

  useEffect(() => {
    windowWidth <= BP_LAPTOP ? setSideBarOpen(false) : setSideBarOpen(true);
  }, [windowWidth]);

  const hasNewDataSource = useSelector(
    state => state.sinatra.user.hasNewDataSource,
  );

  return (
    <Wrapper sideBarOpen={sideBarOpen}>
      <Box pt={30}>
        <ItemCategoryName sideBarOpen={sideBarOpen}>Agency</ItemCategoryName>
        {sideBarItems.map(menuItem => (
          <SideBarItem
            key={menuItem.id}
            activeItem={whichItemIsActive(currentPathName)}
            hasNewDataSource={hasNewDataSource}
            menuItem={menuItem}
            sideBarOpen={sideBarOpen}
          />
        ))}
      </Box>
      <FooterContainer onClick={sideBarHandler}>
        {sideBarOpen ? (
          <Box horizontal align="flex-end" flow={7}>
            <DoubleArrowLeft width={12} height={12} />
            <Text noWrap size="normal">
              Collapse
            </Text>
          </Box>
        ) : (
          <DoubleArrowRight width={12} height={12} />
        )}
      </FooterContainer>
    </Wrapper>
  );
};

export default SideBarRSP;
