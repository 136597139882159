import React from 'react';
import { LogoWhiteFullIcon } from '@rsos/assets/icons';
import VersionNumber from '@rsos/components/capstone/Layout/VersionNumber';
import { Box, Text } from '@rsos/components/capstone/base';
import StyledSmartLink from '../StyledSmartLink';
import { infoLinks, getCategoryName } from '../helpers';
import {
  FooterWrapper,
  FooterContentWrapper,
  InfoLinksWrapper,
  InfoLinkCategoryName,
} from './Footer.styles.js';

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <Box>
          <LogoWhiteFullIcon />
          <VersionNumber color="background" />
        </Box>
        <InfoLinksWrapper>
          {Object.entries(infoLinks).map(([key, value]) => {
            return (
              <Box key={key}>
                <InfoLinkCategoryName>
                  {getCategoryName(key)}
                </InfoLinkCategoryName>
                <Box flow={10}>
                  {value.map(i => (
                    <StyledSmartLink
                      data-name={i.dataName}
                      to={i.to}
                      key={i.title}
                      internal={i.internal}
                    >
                      <Text color="background">{i.title}</Text>
                    </StyledSmartLink>
                  ))}
                </Box>
              </Box>
            );
          })}
        </InfoLinksWrapper>
      </FooterContentWrapper>
    </FooterWrapper>
  );
};

export default Footer;
