import React from 'react';
import Box from '@rsos/base-components/Box';
import DropdownClose from './DropdownClose';

const DropdownBody = ({ children, align, onClose, ...props }) => (
  <Box align={align} {...props}>
    {onClose && (
      <Box align="flex-end" padding="11px 16px">
        <DropdownClose onClose={onClose} />
      </Box>
    )}
    {children}
  </Box>
);

export default DropdownBody;
