//helper breakpoints:
export const BP_DESKTOP_XXL = 1920;
export const BP_DESKTOP_XL = 1366;
export const BP_DESKTOP_L = 1200;
export const BP_DESKTOP_S = 900;

export const WIDTH_DESKTOP_XXL = 1920;
export const WIDTH_DESKTOP_XL = 1366;
export const WIDTH_DESKTOP_L = 1200;
export const WIDTH_DESKTOP = 1024;
export const WIDTH_DESKTOP_S = 900;

export const linerRapidSOSGradient = `linear-gradient(
  90.72deg,
  #29a8dd 0.51%,
  #001559 34.53%,
  #001559 66.75%,
  #e82629 115.09%
)`;
