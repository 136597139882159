import React from 'react';
import styled from 'styled-components';
import Box from '../../Box';
import ModalClose from './ModalClose';
import { getFlexProp } from './utils';

const ModalBodyContainer = styled(Box).attrs(p => ({
  mb: p.noFooter ? 0 : 20,

  justify: getFlexProp(p.align),
}))`
  text-align: ${p => (p.align ? p.align : 'inherit')};
  height: ${p => ('height' in p ? p.height : 'auto')};
  z-index: ${p => (p.zIndex ? p.zIndex : 'inherit')};
`;

const ModalBody = ({ children, align, onClose, ...props }) => (
  <ModalBodyContainer align={align} {...props}>
    {onClose ? <ModalClose onClose={onClose} /> : null}
    {children}
  </ModalBodyContainer>
);

export default ModalBody;
