import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import SVGLoader from '@rsos/assets/icons/src/SVGLoader';
import { Box, Text } from '@rsos/components/capstone/base';

export const TextContainer = styled(animated(Box)).attrs({
  justify: 'center',
  align: 'center',
  bg: 'transparent',
})`
  overflow: hidden;
`;
export const TextWrapper = styled(Text).attrs({
  color: 'primaryText',
  size: 'normal',
})`
  font-family: Soleil;
`;

export const PremiumText = props => (
  <SVGLoader
    width={73}
    height={19}
    viewboxHeight={19}
    viewboxWidth={73}
    fill={props.color}
  >
    <path d="M1.66285 4.2V14H3.00685V10.08H4.58885C6.18485 10.08 8.04685 9.296 8.04685 7.126C8.04685 4.984 6.17085 4.2 4.58885 4.2H1.66285ZM4.23885 8.876H3.00685V5.404H4.51885C5.76485 5.404 6.61885 6.062 6.61885 7.168C6.61885 8.428 5.59685 8.876 4.23885 8.876ZM10.9409 4.2V14H12.2849V9.856H13.2929L16.2329 14H17.9129L14.8049 9.772C16.0089 9.52 17.1989 8.68 17.1989 7.014C17.1989 4.984 15.3509 4.2 13.8529 4.2H10.9409ZM13.5029 8.652H12.2849V5.404H13.7829C14.9729 5.404 15.7709 6.062 15.7709 7.056C15.7709 8.176 14.8049 8.652 13.5029 8.652ZM20.8068 4.2V14H26.6868V12.796H22.1508V9.548H26.6868V8.344H22.1508V5.404H26.6868V4.2H20.8068ZM30.2079 4.2V14H31.5519V5.726L34.8419 14H35.7799L39.1259 5.726V14H40.4699V4.2H38.3419L35.3179 11.858L32.3359 4.2H30.2079ZM44.4079 4.2V14H45.7519V4.2H44.4079ZM53.3897 14.168C56.0777 14.168 57.2117 12.474 57.2117 9.394V4.2H55.8677V9.744C55.8677 11.914 54.9297 12.964 53.4177 12.964C51.9057 12.964 50.9677 11.914 50.9677 9.744V4.2H49.6237V9.394C49.6237 12.474 50.7577 14.168 53.3897 14.168ZM61.0772 4.2V14H62.4212V5.726L65.7112 14H66.6492L69.9952 5.726V14H71.3392V4.2H69.2112L66.1872 11.858L63.2052 4.2H61.0772Z" />
  </SVGLoader>
);
