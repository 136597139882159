import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Box, Text, Button } from '@rsos/components/capstone/base';
import InputText from '@rsos/components/capstone/base/form/inputs/InputText';
import { requestPassword } from '../../../actions';
import { Wrapper, FormWrapper } from './RequestPassword.styles.js';
import { validationSchema } from './helpers';

const RequestPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const { requestingResetPassword } = useSelector(
    state => state.sinatra.account.requestingResetPassword,
  );
  const handleSubmit = values => {
    if (!requestingResetPassword) {
      dispatch(requestPassword(values.email, navigate));
    }
  };
  useEffect(() => {
    setBtnDisabled(requestingResetPassword);
  }, [requestingResetPassword]);

  return (
    <Wrapper>
      <FormWrapper>
        <Text size="xlarge" fontWeight="semibold" color="primaryText" mb={13}>
          Reset your password
        </Text>
        <Text color="secondaryText" size="small" mb={32}>
          Enter the email address associated with your account and we’ll send a
          link to reset your password.
        </Text>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={values => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ values, handleBlur, handleChange, errors, touched, isValid }) => (
            <Form name="request-password-form" style={{ width: '100%' }}>
              <InputText
                id="email"
                label="Email"
                size="small"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                shouldDisplayClearButton={false}
                enableLastPass
                error={
                  errors.email && (!!values.email || touched.email)
                    ? errors.email
                    : null
                }
              />
              <Button
                btnType="primary"
                type="submit"
                noMargin
                mt={32}
                grow
                disabled={btnDisabled || !isValid}
              >
                Continue
              </Button>
              <Box mt={18} align="center">
                <Link
                  id="forgotPassword"
                  to="/"
                  style={{ textDecoration: 'none' }}
                >
                  <Text color="primaryBase" size="small">
                    Return to sign in
                  </Text>
                </Link>
              </Box>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  );
};

export default RequestPassword;
