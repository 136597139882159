export const MANAGE_AGENCY_INFO = 'MANAGE_AGENCY_INFO';
export const MANAGE_USERS = 'MANAGE_USERS';
export const MANAGE_PEER_AGENCIES = 'MANAGE_PEER_AGENCIES';
export const MANAGE_SECURITY = 'MANAGE_SECURITY';
export const MANAGE_DATA_SOURCES = 'MANAGE_DATA_SOURCES';
export const MANAGE_INTEGRATIONS = 'MANAGE_INTEGRATIONS';
export const MANAGE_SOUND = 'MANAGE_SOUND';
export const MANAGE_SSO = 'MANAGE_SSO';
export const MANAGE_ALERT_PARTNERS = 'MANAGE_ALERT_PARTNERS';
export const MANAGE_RESPONDERS = 'MANAGE_RESPONDERS';
export const MANAGE_COMMUNICATION = 'MANAGE_COMMUNICATION';
