import {
  FETCH_APP_ROLES_START,
  FETCH_APP_ROLES_SUCCESS,
  FETCH_APP_ROLES_ERROR,
  CREATE_APP_ROLE_START,
  CREATE_APP_ROLE_ERROR,
  CREATE_APP_ROLE_SUCCESS,
  UPDATE_APP_ROLE_START,
  UPDATE_APP_ROLE_ERROR,
  UPDATE_APP_ROLE_SUCCESS,
  DELETE_APP_ROLE_SUCCESS,
  DELETE_APP_ROLE_ERROR,
  DELETE_APP_ROLE_START,
  FETCH_APP_USERS_START,
  FETCH_APP_USERS_SUCCESS,
  FETCH_APP_USERS_ERROR,
  CREATE_APP_USER_START,
  CREATE_APP_USER_SUCCESS,
  CREATE_APP_USER_ERROR,
  UPDATE_APP_USER_START,
  UPDATE_APP_USER_SUCCESS,
  UPDATE_APP_USER_ERROR,
  DELETE_APP_USER_START,
  DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_ERROR,
  ASSIGN_PERMISSIONS_TO_ROLES_START,
  ASSIGN_PERMISSIONS_TO_ROLES_SUCCESS,
  ASSIGN_PERMISSIONS_TO_ROLES_ERROR,
  FETCH_ALL_PERMISSIONS_START,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_PERMISSIONS_ERROR,
  UPDATE_PERMISSION_ROLES_START,
  UPDATE_PERMISSION_ROLES_SUCCESS,
  UPDATE_PERMISSION_ROLES_ERROR,
  FETCH_APP_CIDRS_START,
  FETCH_APP_CIDRS_SUCCESS,
  FETCH_APP_CIDRS_ERROR,
  CREATE_APP_CIDR_START,
  CREATE_APP_CIDR_SUCCESS,
  CREATE_APP_CIDR_ERROR,
  DELETE_APP_CIDR_START,
  DELETE_APP_CIDR_SUCCESS,
  DELETE_APP_CIDR_ERROR,
  UPDATE_APP_CIDR_START,
  UPDATE_APP_CIDR_SUCCESS,
  UPDATE_APP_CIDR_ERROR,
} from '../../actions/types';
import appRolesReducer from './appRolesReducer';
import appUsersReducer from './appUsersReducer';
import permissionsReducer from './permissionsReducer';
import whitelistedIPsReducer from './whitelistedIPsReducer';

export const initialState = {
  organizations: {},
};

const organizationsReducer = (state = {}, action) => {
  const { orgId, appName } = action;
  const organizations = { ...state };
  if (!organizations[orgId]) {
    return organizations;
  }
  const app = { ...organizations[orgId].apps[appName] };

  return {
    ...organizations,
    [orgId]: {
      ...organizations[orgId],
      apps: {
        ...organizations[orgId].apps,
        [appName]: {
          ...organizations[orgId].apps[appName],
          users: appUsersReducer(app.users, action),
          roles: appRolesReducer(app.roles, action),
          permissions: permissionsReducer(
            app.permissions,
            action,
          ),
          whitelistedIPs: whitelistedIPsReducer(
            app.whitelistedIPs,
            action,
          )
        }
      }
    }
  };
};

/**
 * Makes sure the organization field in state is initialized
 * before the reducers for app users/roles access the state tree.
 * This creates a guarantee that the state will be properly
 * initialized before the reducers modify the tree with new state.
 * @param {Object} state - The slice of state for `organizations`
 * @param {Object} action - The app related action
 */
const ensureOrgInit = (state = initialState, action) => {
  const { orgId, appName } = action;
  const organizations = { ...state.organizations };

  /** Creates a default app with initial state */
  const createDefaultApp = appName => ({
    name: appName,
    users: undefined,
    roles: undefined,
    permissions: undefined,
    whitelistedIPs: undefined,
  });

  // No organization was found at all. Create it and init apps.
  const hasOrg = !!organizations[orgId];
  if (!hasOrg) {
    organizations[orgId] = {
      orgId,
      apps: {
        [appName]: createDefaultApp(appName),
      },
    };
    return {
      ...state,
      organizations,
    };
  }

  // The organization was found, but no matching app. Create the app.
  const hasApp = !!organizations[orgId].apps[appName];
  if (!hasApp) {
    organizations[orgId].apps[appName] = createDefaultApp(appName);
    return {
      ...state,
      organizations,
    };
  }

  // The organization and app were already initialized. Return the state.
  return {
    ...state,
    organizations,
  };
};

const managementReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_ROLES_START:
    case FETCH_APP_ROLES_SUCCESS:
    case FETCH_APP_ROLES_ERROR:
    case CREATE_APP_ROLE_START:
    case CREATE_APP_ROLE_SUCCESS:
    case CREATE_APP_ROLE_ERROR:
    case UPDATE_APP_ROLE_START:
    case UPDATE_APP_ROLE_SUCCESS:
    case UPDATE_APP_ROLE_ERROR:
    case DELETE_APP_ROLE_START:
    case DELETE_APP_ROLE_SUCCESS:
    case DELETE_APP_ROLE_ERROR:
    case ASSIGN_PERMISSIONS_TO_ROLES_START:
    case ASSIGN_PERMISSIONS_TO_ROLES_SUCCESS:
    case ASSIGN_PERMISSIONS_TO_ROLES_ERROR:
    case FETCH_APP_USERS_START:
    case FETCH_APP_USERS_SUCCESS:
    case FETCH_APP_USERS_ERROR:
    case CREATE_APP_USER_START:
    case CREATE_APP_USER_SUCCESS:
    case CREATE_APP_USER_ERROR:
    case UPDATE_APP_USER_START:
    case UPDATE_APP_USER_SUCCESS:
    case UPDATE_APP_USER_ERROR:
    case DELETE_APP_USER_START:
    case DELETE_APP_USER_SUCCESS:
    case DELETE_APP_USER_ERROR:
    case FETCH_ALL_PERMISSIONS_START:
    case FETCH_ALL_PERMISSIONS_SUCCESS:
    case FETCH_ALL_PERMISSIONS_ERROR:
    case UPDATE_PERMISSION_ROLES_START:
    case UPDATE_PERMISSION_ROLES_SUCCESS:
    case UPDATE_PERMISSION_ROLES_ERROR:
    case FETCH_APP_CIDRS_START:
    case FETCH_APP_CIDRS_SUCCESS:
    case FETCH_APP_CIDRS_ERROR:
    case CREATE_APP_CIDR_START:
    case CREATE_APP_CIDR_SUCCESS:
    case CREATE_APP_CIDR_ERROR:
    case DELETE_APP_CIDR_START:
    case DELETE_APP_CIDR_SUCCESS:
    case DELETE_APP_CIDR_ERROR:
    case UPDATE_APP_CIDR_START:
    case UPDATE_APP_CIDR_SUCCESS:
    case UPDATE_APP_CIDR_ERROR: {
      const ensuredState = ensureOrgInit(state, action);
      return {
        ...state,
        organizations: organizationsReducer(ensuredState.organizations, action),
      };
    }
    default:
      return { ...state };
  }
};

export default managementReducer;
