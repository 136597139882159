import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Box } from '@rsos/components/capstone/base';
import { sharedStyleMenuItemRow } from './common.styles';
import { trackLogOutClick } from './navigationTrackings';

const LogoutNavItem = styled(Box).attrs({
  align: 'center',
  justify: 'center',
  cursor: 'pointer',
})`
  ${sharedStyleMenuItemRow}
  font-size: 14px;
`;

const StyledAuthLink = ({ onClick }) => {
  const currentApp = useSelector(
    state => state.sinatra.user.currentRole.application,
  );
  const irpVersion = useSelector(state => state.sinatra.irp.irpVersion);

  const handleLogout = () => {
    trackLogOutClick(currentApp, irpVersion);
    onClick();
  };

  return (
    <LogoutNavItem
      onClick={handleLogout}
      data-name="navigation-control-logout-link"
    >
      Log Out
    </LogoutNavItem>
  );
};

export default StyledAuthLink;
