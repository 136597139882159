import getUrlParameter from '@rsos/base-utils/getUrlParameter';
import { SCORPIUS_KEY, PRODUCT_TOKEN } from '../actions/types';

/**
 * Returns the Scorpius tokens from localStorage.
 * The auto login/query feature requires the access_token to be parsed out,
 * otherwise the fetchUser request will return 401 and redirect the user
 * @returns {Object} tokens
 */
export const getTokens = () => {
  let tokenString = localStorage.getItem(SCORPIUS_KEY);
  const accessToken = getUrlParameter('access_token');
  if (!tokenString && accessToken) {
    tokenString = JSON.stringify({ refresh_token: null, token: accessToken });
  }
  return JSON.parse(tokenString);
};

/**
 * Saves the Scorpius tokens to localStorage.
 * @param {Object} tokens
 * @param {string} tokens.token
 * @param {string} tokens.refresh_token
 */
export const setTokens = tokens => {
  localStorage.setItem(SCORPIUS_KEY, JSON.stringify(tokens));
};

/**
 * Deletes the tokens from localStorage.
 */
export const clearTokens = () => {
  localStorage.removeItem(SCORPIUS_KEY);
  localStorage.removeItem(PRODUCT_TOKEN);
};
