import styled from 'styled-components';
import { Box, Button, Text } from '@rsos/components/capstone/base';
import {
  DropdownDialog as Dialog,
  DropdownHeader as Header,
  DropdownList as List,
} from '@rsos/components/capstone/base/Dropdown/DropdownComponents';

const DROPDOWN_DIALOG_WIDTH = '420px';
const DROPDOWN_DIALOG_HEIGHT = '223px';
const APP_LIST_WIDTH = '243px';
const APP_DETAILS_WIDTH = '177px';

export const DropdownDialog = styled(Dialog).attrs({
  width: DROPDOWN_DIALOG_WIDTH,
  top: 37,
  left: 12,
  zIndex: 10,
})``;

export const DropdownHeader = styled(Header).attrs({
  padding: '15px',
})``;

export const DropdownHeaderText = styled(Text).attrs({
  color: 'primaryText',
  size: 'normal',
  fontWeight: 'bold',
})``;

export const DropdownList = styled(List).attrs({
  height: DROPDOWN_DIALOG_HEIGHT,
  justify: 'center',
  borderless: true,
  overflow: 'auto',
})``;

export const ListItemRow = styled(Box).attrs({
  width: '100%',
  horizontal: true,
  align: 'center',
  justify: 'space-between',
  color: 'primaryText',
  flow: 15,
})``;

export const ListItemCTA = styled(Text).attrs({
  size: 'normal',
  color: 'primaryText',
})``;

export const ListItemDisplayName = styled(Text).attrs({
  size: 'xsmall',
  color: 'secondaryText',
})``;

export const AppListPane = styled(Box).attrs({
  width: APP_LIST_WIDTH,
  height: '100%',
  backgroundColor: 'background',
  overflow: 'auto',
})`
  border-right: ${({ theme }) => `1px solid ${theme.colors.heavyLineOutline}`};
`;

export const AppDetailsPane = styled(Box).attrs({
  width: APP_DETAILS_WIDTH,
  height: DROPDOWN_DIALOG_HEIGHT,
  padding: '15px',
  backgroundColor: 'background',
  overflow: 'auto',
  flow: 16,
})``;

export const AppDetailsCTA = styled(Text).attrs({
  color: 'primaryText',
  size: 'normal',
  fontWeight: 'bold',
})``;

export const AppDetailsDisplayName = styled(Text).attrs({
  color: 'secondaryText',
  size: 'small',
})``;

export const AppDetailsDescription = styled(Text).attrs({
  color: 'primaryText',
  size: 'small',
})``;

export const AppDetailsRequiredInfo = styled(Box).attrs({
  width: '100%',
  horizontal: true,
  align: 'flex-start',
})``;

export const Toolbar = styled(Box).attrs({
  height: '50px',
  horizontal: true,
  flexWrap: 'wrap',
  marginX: '4px',
  overflow: 'hidden',
})``;

export const ToolbarButton = styled(Button).attrs({
  btnType: 'tertiary',
  size: 'small',
  margin: '10px 4px',
})``;

export const ToolbarButtonContent = styled(Box).attrs(({ width }) => ({
  width: width,
  horizontal: true,
  align: 'center',
  justify: 'center',
  flow: 4,
}))``;

export const ToolbarButtonText = styled(Text).attrs({
  color: 'primaryText',
  size: 'small',
  lineHeight: 1,
  ellipsis: true,
})``;

export const AppTooltip = styled(Box).attrs({
  maxWidth: 150,
  align: 'center',
  justify: 'center',
  backgroundColor: 'background',
  padding: '8px 12px',
  borderRadius: 5,
})`
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

export const AppTooltipText = styled(Text).attrs({
  color: 'primaryText',
  size: 'small',
  textAlign: 'center',
})``;
