import styled from 'styled-components';
import { space, color, border } from 'styled-system';
import {
  getStylesByState,
  getFontSizeBySize,
  getHeightBySize,
  getPxBySize,
  getPyBySize,
} from './helpers';

export const ButtonBase = styled.button.attrs(p => ({
  px: p.px || getPxBySize(p),
  py: p.py || getPyBySize(p),
  bg: 'transparent',
}))`
  ${color}
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.3' : '1')};
  font-size: ${p => getFontSizeBySize(p)}px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => getHeightBySize(p)}px;
  letter-spacing: normal;
  margin: ${props => (props.noMargin ? 0 : '5px')};
  outline: none;
  border: none;
  width: ${props => props.grow && '100%'};

  ${space}
  ${border}
  ${p => getStylesByState(p, 'default')};
  &:hover {
    ${p => getStylesByState(p, 'hover')};
  }
  &:active {
    ${p => getStylesByState(p, 'active')};
  }
  &:focus {
    ${p => getStylesByState(p, 'focus')};
  }
`;

export const Container = styled.div`
  opacity: ${p => (p.isLoading ? 0 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;
